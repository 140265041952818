import React from "react";
import { Modal } from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCircle} from "@fortawesome/free-solid-svg-icons";


export default function progUownModal(props){
  const {show,redirectProgUownApplication,progressiveApp,uownApp, message}=props;
    return(
      <Modal show={show} >  
        <Modal.Header className="pb-0"><b className="text-secondary h4">{message?.description?.split("~`~")[0]}</b></Modal.Header>
          <Modal.Body className="pb-0">
              <div>
                <p className="mb-0"><b>{message?.description?.split("~`~")[1]}</b></p>
                {progressiveApp &&
                <div className="form-check form-check-inline mr-0 mb-0 mt-2 ml-1">
                    {/* <input type="checkbox"
                        name="isProg"
                        id="isProg"
                        onClick={(event)=>handleOnchange(event)}
                        value={isProgChecked}
                        checked={isProgChecked ? "checked" : ""}
                    /> */}
                    <FontAwesomeIcon icon={faCircle} className="pr-2" size="xs" /><label htmlFor="isProg" className="mb-0 ml-2">{message?.description?.split("~`~")[2]}</label>
                </div>
                }<br></br>
                {uownApp &&
                <div className="form-check form-check-inline mr-0 mb-0 ml-1">
                    {/* <input type="checkbox"
                        name="isUown"
                        id="isUown"
                        onClick={(event)=>handleOnchange(event)}
                        value={isUownChecked}
                        checked={isUownChecked}
                    /> */}
                    <FontAwesomeIcon icon={faCircle} className="pr-2" size="xs" /><label htmlFor="isUown" className="mb-0 ml-2">{message?.description?.split("~`~")[3]}</label>
                </div>
                }
              </div>
          </Modal.Body> 
          <Modal.Footer className="border-top-0">
              {/* <button className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}} onClick={(event)=>{cancelProgUownApplication(event)}}>No</button> */}
              <button className="btn mr-1 ml-1 mb-0 reLogout" style={{backgroundColor:"#5c068c",color:"white"}} onClick={(event)=>{redirectProgUownApplication(event)}}>OK</button>
          </Modal.Footer>
      </Modal>
    )
  }
