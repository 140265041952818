import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,faCircleXmark
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import BootstrapTable from "react-bootstrap-table-next";
import * as billingCycleActions from "../../redux/actions/billingCycle.actions";
import { trackPromise } from "react-promise-tracker";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";

class BillingCycle extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: "",
      billingCyles: [],
      noDataFound: false,
      billingCyleId:"",
      isEditCyle:false,
      formObj:{
        id:"",
        createdDate:"",
        cycle:"",
        fromDate:"",
        toDate:"" }
      }
    };
  async componentDidMount() {
      if (sessionStorage.getItem("isLoggedIn") === "true") {
         //get the user roles
         trackPromise(
          this.props.userRightsactions
            .getUserRights()
            .then((response) => {
              if (response) {
                this.setState({
                  roles: response.roles,
                  site_page_details: response.sitePageDetails,
                  sitePages: response.sitePages,
                  userRights: response.userRights,
                });
                this.getPermissions();
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
          this.getCycles();
      } 
      else 
        this.props.history.push("/");
  }
  getPermissions() {
    let role_name = localStorage.getItem("role_name"),
      page_name = "Billing Cycle";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return (
          site_page_details.sitePage_id === getsitepages[0].id &&
          site_page_details.name === page_name
        );
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({ permission_status: role_permission_check[0] });
  }

    getCycles() {
        trackPromise(
            this.props.actions.getBillingCycles()
                .then(resp => {
                  if(resp.data?.length===0){
                    this.setState({ noDataFound:true});
                  }
                  else{
                    resp.data.map((cycle,index)=>{
                      if(index===0){
                        cycle.createdDate=cycle.fromDate+"st - "+cycle.toDate+"th of Month";
                      }
                      else if(index==1 || index==2){ 
                        cycle.createdDate=cycle.fromDate+"th - "+cycle.toDate+"th of Month";
                      }
                      else if(index==3){
                        cycle.createdDate=cycle.fromDate+"th - "+cycle.toDate+"rd of Month";
                      }
                      else{
                        cycle.createdDate=cycle.fromDate+"th - End of Month";
                      }
                     });
                    //  let lastObj=resp.data[resp.data.length-1];
                    //  lastObj.createdDate=lastObj.fromDate+"th - End of Month";
                    //  resp.data[resp.data.length-1]=lastObj;
                     this.setState({ billingCyles: resp.data,noDataFound:false });
                  }
                  
                }).catch(err => {
                    APIFailedMessage.displayError();
                })
        );
    }

  handleToggleSidebar(){
    this.setState({ toggled: !this.state.toggled });
  }
  onEdit=()=>{
    this.setState({isEditCyle:!this.state.isEditCyle});
    const billingCyle = this.state.billingCyles.filter(
        (data) => data.id === this.state.billingCyleId
    );
    this.setState({formObj:{...billingCyle[0]}});
    
}
  onChange=(event)=>{
    let reg = /^(?!\s)[A-Z]*$/;
    if(reg.test(event.target.value)){
        this.setState({ formObj: {...this.state.formObj,cycle: event.target.value } });
    }
  }
  handleSaveCycle=()=>{
    let formObj=this.state.formObj;
    let cycleObj={
      id: formObj.id,
      fromDate: formObj.fromDate,
      toDate: formObj.toDate,
      cycle: formObj.cycle,
      modifiedBy: localStorage.getItem('loggedUserEmail')
    }
    this.setState({isEditCyle:!this.state.isEditCyle});
    trackPromise(
        this.props.actions.saveBillingCycle(cycleObj)
        .then(resp=>{
          if(resp==true){
            this.setState({billingCyleId:""})
            this.getCycles();
          }
        }).catch(err=>{
            APIFailedMessage.displayError();
        })
    )
  }
  handleCardSelect = (id) => {
    this.setState({ billingCyleId: id });
  };
  render() {

    let adminMenus = [];
    adminMenus = localStorage.getItem("sitePageDetails");
    adminMenus = JSON.parse(adminMenus);
    const columns = [
        {
          dataField: "createdDate",
          text: "Created Date",
          headerClasses: "table_header_class input_form_control",
        },
        {
          dataField: "cycle",
          text: "Set For Cycle",
          headerClasses: "table_header_class input_form_control",
        },
      ];
      const selectRow = {
        clickToSelect: true,
        onSelect: (row) => {
          this.setState({billingCyleId: row.id });
        }
      };
  
    const rowClasses = (row, rowIndex) => {
      let classes = null;
      if (row.id === this.state.billingCyleId) {
        classes = "selected_row_class";
      }
      return classes;
    };
    
    const CardView = ({ data, handleCardSelect, billingCyleId }) => {
      return (
        <>
          <hr className="mt-1 mb-1"></hr>
          <div className="row">
            {data &&
              data.map((val) => (
                <div
                  className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                  key={val.id}
                >
                  <Card
                    className="text-left shadow"
                    style={{ width: "100%" }}
                    onClick={(id) => handleCardSelect(val.id)}
                  >
                    <Card.Body
                      className={`m-3 ${
                        val.id === billingCyleId ? "bg-secondary text-primary" : ""
                      }`}
                      style={{ border: "2px solid #1B1B1B" }}
                    >
                      <p className="h3">{val.createdDate}</p>
                      <hr
                        style={
                          val.id === billingCyleId
                            ? { backgroundColor: "#f8f6fa" }
                            : { backgroundColor: "#1B1B1B" }
                        }
                      ></hr>
                      <div className="row">
                        <div className="col-6">Set For Cycle :</div>
                        <div className="col-6">{val.cycle}</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </>
      );
    };
    return (
      <>
      <div className={this.state.isEditCyle? " RolePanelShow": "RolePanelHide "}>
          {this.state.isEditCyle && (
            <div className="RolePanel">
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col-12 pl-4 pr-4">
                    <p>
                      <span className="h5">
                        <b> Edit Billing Cycle </b>
                      </span>
                        <span
                          className="float-right btn p-0 mr-0"
                          onClick={() => this.setState({ isEditCyle: !this.state.isEditCyle,billingCyleId:"" })}
                        >
                          <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                        </span>
                    </p>
                    <form>
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-5 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                          <label>Created Date</label>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 pt-2">
                          <input
                            type="text"
                            className="form-control border"
                            name="name"
                            id="name"
                            value={this.state.formObj?.createdDate}
                            onChange={this.handleEditChange}
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-5 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                          <label>Set For Cycle</label>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 pt-2">
                          <input
                            type="text"
                            className="form-control border"
                            name="name"
                            id="name"
                            value={this.state.formObj?.cycle}
                            onChange={this.onChange}
                          ></input>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-4 mb-4">
                          <div className="row">
                            <div className="col-12 col-md-4 col-lg-5">
                              <button
                                className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                                onClick={() =>
                                  this.setState({ isEditCyle: false,billingCyleId:"" })
                                }
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-12 col-md-4 col-lg-5">
                              <button
                                className="btn bg-secondary text-primary w-100"
                                onClick={this.handleSaveCycle}
                                disabled={this.state.formObj.cycle?false:true}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => this.handleToggleSidebar()}
            isAdmin={true}
            adminMenus={adminMenus}
          />
          <main>
            <CustomNavbar
              handleToggleSidebar={() => {this.handleToggleSidebar()}}
              isAdmin={true}
            />
            {/* <div> */}
              <div className="row mr-3 ml-3 mb-4">
                <div className="col-12 mt-4 pl-0 pr-0 pl-md-3 pr-md-3">
                  <div
                    className="row ml-0 mr-0 ml-md-2 mr-md-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="h4 text-left">
                      <b>New Account Billing Cycle</b>
                    </p>
                  </div>
                  <div className="row pl-3 pr-3 mb-2">
                      <div className="col-12 mt-3 command_bar">
                          <button
                            className="btn add_button ml-3"
                            onClick={() => this.onEdit()}
                            disabled={(this.state.permission_status?.canAdd &&
                              this.state.permission_status.canEdit && (this.state.billingCyleId !== ""))?false:true}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            Edit
                          </button>
                      </div>
                  </div>
                </div>
                <div className="col-12">
                  <>
                    <div id="cardrow">
                      <CardView
                        data={this.state.billingCyles}
                        handleCardSelect={this.handleCardSelect}
                        billingCyleId={this.state.billingCyleId}
                      />
                    </div>
                    {this.state.billingCyles.length>0?
                      <div className={` ${
                        this.state.billingCyles.length !== 0 ? "creditApplicationTable" : "emptyCreditTable"
                      }`}>
                          <BootstrapTable
                            className="pagination"
                            bootstrap4
                            hover
                            bordered={false}
                            condensed={true}
                            keyField="id"
                            data={this.state.billingCyles}
                            columns={columns}
                            selectRow={selectRow}  
                            rowClasses={rowClasses}
                          />
                      </div>:
                      (this.state.billingCyles.length==0 && this.state.noDataFound==true)&&
                        <NoDataIndication/>
                      }
                    </>
                </div>
              </div>
            {/* </div> */}
          </main>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(billingCycleActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(BillingCycle);

const NoDataIndication = () => {
    return(
      <div className="row mt-4 ml-2 mr-2">
          <div className="col-12">
            <Card>
              <Card.Body>
                  <p className="text-secondary font-weight-bold text-center">No Data found </p>
              </Card.Body>
            </Card>
          </div>
      </div>
    ) 
  };
