import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import Constants from "../../utilites/constants";

const RemotePagination = ({
  data,
  page,
  sizePerPage = Constants.LookUpPageSize,
  onTableChange,
  totalSize,
  dataField,
  order,
  columns,
  customTotal,
  selectRow,
  rowStyle,
  rowEvents,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        paginationTotalRenderer: customTotal,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div>
          <div id="outerCoverForLookUpTable">
          <BootstrapTable
            className="pagination"
            bootstrap4
            hover
            remote={{
              sort: true,
              pagination: true,
            }}
            bordered={false}
            condensed={true}
            keyField="customer_Id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            selectRow={selectRow}
            rowStyle={rowStyle}
            sort={{ dataField: dataField, order: order }}
            {...paginationTableProps}
            rowEvents={rowEvents}
          />
          </div>
          <div className="d-flex justify-content-between">
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

export default RemotePagination;
