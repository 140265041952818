import React, { Component } from "react";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import "./component.creditApplication.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faAngleDown,
  faAngleUp,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Card } from "react-bootstrap";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import Loader from "react-loader-spinner";
import * as customerLookupActions from "../../redux/actions/customerLookup.actions";
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import * as creditApplication from "../../redux/actions/creditApplication.actions";
import * as applyNowActions from "../../redux/actions/applyNow.actions";
import ReactToPrint from "react-to-print";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import Constants from "../../utilites/constants";
import * as synchronyActions from "../../redux/actions/synchronyLookup.actions";
import formatPhoneNumber from "../../components/component.formatPhoneNumber";
import { DanielsOffersCard, FortivaOffersStatus, SynchronyOffersStatus, ProgressiveOffersStatus, UOwnOffersStatus } from "../../components/component.applicationStatus";
import GetInfo from "../../components/component.getInfo";
import * as accountSummaryAction from "../../redux/actions/accountSummary.actions";

const RemotePagination = ({
  data,
  page,
  sizePerPage = 5,
  onTableChange,
  totalSize,
  dataField,
  order,
  columns,
  customTotal,
  selectRow,
  rowStyle,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        paginationTotalRenderer: customTotal,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div
          className={` ${
            data.length !== 0 ? "creditApplicationTable" : "emptyCreditTable"
          }`}
        >
          <BootstrapTable
            className="pagination"
            bootstrap4
            hover
            remote={{
              sort: true,
              pagination: true,
            }}
            bordered={false}
            condensed={true}
            keyField="application_id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            selectRow={selectRow}
            rowStyle={rowStyle}
            sort={{ dataField: dataField, order: order }}
            {...paginationTableProps}
            filter={filterFactory()}
          />
          <div className="d-flex justify-content-between">
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

class CreditApplicationList extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      show: false,
      creditAppList: [],
      creditId: "",
      isLoad: false,
      isPaginate: false,
      noDataFound: false,
      isSort: false,
      pageNo: 1,
      from: 1,
      to: 5,
      isFirstTime: false,
      dataField: "modifiedDate",
      order: "desc",
      isView: false,
      filterObj: {
        idNumber: "",
        creditId: "",
        password: "",
        userName: "",
        userId: "",
        role: "",
        homeStore: "",
        district: "",
        lastActivity: "",
        status: "",
        modifiedDate: "",
        mobileNo: "",
      },
      districtList: [],
      rolesList: [],
      storeList: [],
      isAddUserOpen: false,
      totalCreditApps: undefined,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: "",
      showSpinner: false,
      isSearchDropDown: "firstName",
      filterValue: "",
      formObj: {
        credit_Id: 0,
        customerNo: "NEW",
        storeCode: "0",
        suffix: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mailingAdr: "",
        emailAdr: "",
        aptUnit: "",
        dob: "",
        city: "",
        mobileNo: "",
        state: "",
        secPhone: "",
        zipCode: "",
        empName: "",
        empStartDate: "",
        empPhoneNo: "",
        netIncome: "",
        duties: "",
        housingInfo: "",
        ssn: "",
        primaryIdType: "",
        primaryIdNo: "",
        primaryIdState: "",
        relativeName: "",
        relativePhone: "",
        relativeRelation: "",
        verificationCode: "",
        oftenPaid: "",
        cc: "",
        lastPayDay: "",
        ccExpiryDate: "",
        nextPayDay: "",
        ccName: "",
        primarySourceIncome: "",
        creditCardBillingAddress: false,
        ccmailingAdr: "",
        ccaptUnit: "",
        cccity: "",
        ccstate: "",
        cczipCode: "",
        bankRouteNo: "",
        checkAccNo: "",
        autopay: "",
        bankAccountDuration: "",
        associateEmployeeNo: "",
        associatePassword: "",
        primaryIdExpiryDate: "",
        primaryIdNumber: "",
        secondIdType: "",
        secondIdIssuedBy: "",
        secondIdExpiryDate: "",
        rdApply: "DS",
        lstSuffix: [],
        lstState: [],
        lstCCState: [],
        lstHousingInfo: [],
        lstPrimaryIdType: [],
        lstPrimaryIdState: [],
        lstOftenPaid: [],
        lstPrimarySourceIncome: [],
        lstBankAccountDuration: [],
        lstSecondIdType: [],
        lstStoreCode: [],
        mobileNoVerified: "",
        smsCodeSent: "",
        smsOverride: "",
        customerPreApprove:false,
        danielsStatus: "",
        synchronyStatus: "",
        progressiveStatus: "",
        uownStatus: ""
      },
      offerStatus: {
        danielsStatus: "",
        synchronyStatus: "",
        progressiveStatus: "",
        uownStatus: "",
      },
      cusPreApproval: [],
      comments: "",
      mobileVerificationExpand: true,
      psInformationExpand: false,
      residenceExpand: false,
      employmentExpand: false,
      identificationExpand: false,
      synchronyExpand: false,
      progressiveExpand: false,
      uownExpand: false,
      autopayRollExpand: false,
      estatementExpand : false,
      getCodeMaster: {
        suffix: [],
        house_info: [],
        state: [],
        nearest_relative_relationship: [],
        oftern_paid: [],
        p_id_type: [],
        p_is_number: [],
        p_id_state: [],
        s_id_type: [],
        s_id_exp_date: [],
        c_card_exp_date: [],
      },
      applicationId: "",
      customerId: "",
      createdDate : "",
      customerPreApprove:false,
      stateList_credit:[],
      synchronyOffers:undefined,
      isDaniel:false
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      trackPromise(
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      // get code master
      this.getCodeMasterDetails("Suffix");
      this.getCodeMasterDetails("HousingInfo");
      this.getCodeMasterDetails("PrimaryIdType");
      this.getCodeMasterDetails("State");
      this.getCodeMasterDetails("State_Credit");
      this.getCodeMasterDetails("OftenPaid");
      this.getCodeMasterDetails("SecondIdType");

      this.setState({
        creditAppList: [],
        totalCreditApps: undefined,
        pageNo: 1,
        creditId: "",
      });
      let obj = {
        name: "",
        address: "",
        phone: "",
        idNumber: "",
        ssn: "",
        store: "",
        account: "",
        applicationDate: "",
        pageIndex: 1,
        pageSize: Constants.CreditAppPageSize,
        sortBy: "modifiedDate",
        sortOrder: "desc",
      };
      //get credit list
      trackPromise(
        this.props.creditApplication
          .getCreditApplication(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);
                item.modifiedDate =  item.modifiedDate && Moment(item.modifiedDate).format("MM/DD/YYYY");
                if(item.mobileNo!=null){
                  if(!item.mobileNo.includes("-")){
                    item.mobileNo = formatPhoneNumber(item.mobileNo)
                  }
              }
              });
              this.setState({
                creditAppList: response.data,
                totalCreditApps: response.totalCount,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else this.props.history.push("/");
  }

  loadViewOffers(cust_Id,obj) {
    trackPromise(
        this.props.creditApplication.getApplicationViewOffers(cust_Id,obj).then(
            response => {
              let isFortiva = response?.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
              if(isFortiva) {
                trackPromise(
                  this.props.accountSummaryAction.getFortivaActSummmary(GetInfo.getCustomerId(), obj).then(
                      res => {
                          response.data.map(item => {
                            if(item.fpType?.toLowerCase() === "fortiva") {
                              item.openToBuy = res.openToBuy;
                            }
                            return item;
                          });
                      }
                  ).catch(err => {
                      APIFailedMessage.displayError(err);
                  })
              )
              }
                this.setState({ cusPreApproval: response.data });
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
    )
  }
  prefixChanger = async (value, last_count, type) => {
    let final_value;
    if (type === "idNumber") {
      let data = value.slice(value.length - last_count);
      final_value = "XXX" + data;
    } else if (type === "ssn") {
      let data = value.slice(value.length - last_count);
      final_value = "XXXX-XX-" + data;
    } else if (type === "credit") {
      let data = value.slice(value.length - last_count);
      final_value = "XXXX-" + data;
    } else if (type === "checkaccount") {
      let data = value.slice(value.length - last_count);
      final_value = "XXXXX" + data;
    }
    return final_value;
  };

  getCodeMasterDetails = async (type) => {
    trackPromise(
      this.props.actions.getCodeMaster({ type: type }).then((response) => {
        if (response.totalCount > 0) {
          if (type === "Suffix")
            this.setCodeMasterState("suffix", response.data);
          if (type === "HousingInfo")
            this.setCodeMasterState("house_info", response.data);
          if (type === "State") this.setCodeMasterState("state", response.data);
          if (type === "OftenPaid")
            this.setCodeMasterState("oftern_paid", response.data);
          if (type === "PrimaryIdType")
            this.setCodeMasterState("p_id_type", response.data);
          if (type === "SecondIdType")
            this.setCodeMasterState("s_id_type", response.data);
          if (type === "State_Credit") {
              this.setState({stateList_credit : response.data});
          }
        }
      })
    );
  };

  setCodeMasterState = (type, data) => {
    this.setState({
      getCodeMaster: { ...this.state.getCodeMaster, [type]: data },
    });
  };

  getPermissions() {
    let role_name = localStorage.getItem("role_name"),
      page_name = "Credit Application";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return (
          site_page_details.sitePage_id === getsitepages[0].id &&
          site_page_details.name === page_name
        );
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({ permission_status: role_permission_check[0] });
  }

  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }
  handleCardSelect = (creditId, customerId,isDaniel,createdDate) => {
    this.setState({
      isView: true,
      creditId: creditId,
      applicationId: creditId,
      customerId: customerId,
      createdDate : createdDate,
      isDaniel:isDaniel
    });
  };

  handleViewCreditDetials = () => {
    this.setState({ show: !this.state.show });
    let obj = this.state.creditId;
    this.props.customerDetailsActions
      .getCustomerDetails(obj)
      .then(async (response) => {
        let customerInfo = response[0];
        if (customerInfo.suffix_Id) {
          let result = await this.state.getCodeMaster.suffix.filter((data) => {
            return data.id === customerInfo.suffix_Id;
          });
          customerInfo.suffix_Id = result[0]?.description;
        }
        if (customerInfo.state_Id) {
          let state=this.state.stateList_credit?.find((val) => val.id === customerInfo.state_Id);
            if(state) {
                state=this.state.getCodeMaster.state?.find((val) => val.description?.toLowerCase() === state.description?.toLowerCase());
                customerInfo.state_Id = state.description;
            }
            else {
              let result = await this.state.getCodeMaster.state.filter((data) => {
                return data.id === customerInfo.state_Id;
              });
              customerInfo.state_Id = result[0]?.description;
            }
            }
        if (customerInfo.primaryState_Id) {
          let result = await this.state.getCodeMaster.state.filter((data) => {
            return data.id === customerInfo.primaryState_Id;
          });
          customerInfo.primaryState_Id = result[0]?.description;
        }
        if (customerInfo.housingInfo_Id) {
          let result = await this.state.getCodeMaster.house_info.filter(
            (data) => {
              return data.id === customerInfo.housingInfo_Id;
            }
          );
          customerInfo.housingInfo_Id = result[0]?.description;
        }
        if (customerInfo.primaryType_Id) {
          let result = await this.state.getCodeMaster.p_id_type.filter(
            (data) => {
              return data.id === customerInfo.primaryType_Id;
            }
          );

          customerInfo.primaryType_Id = result[0]?.description;
        }

        if (customerInfo.howOftenPaid_Id) {
          let result = await this.state.getCodeMaster.oftern_paid.filter(
            (data) => {
              return data.id === customerInfo.howOftenPaid_Id;
            }
          );
          customerInfo.howOftenPaid_Id = result[0]?.description;
        }

        if (customerInfo.ccState_Id) {
          let result = await this.state.getCodeMaster.state.filter((data) => {
            return data.id === customerInfo.ccState_Id;
          });
          customerInfo.ccState_Id = result[0]?.description;
        }

        if (customerInfo.secondIdType_Id) {
          let result = await this.state.getCodeMaster.s_id_type.filter(
            (data) => {
              return data.id === customerInfo.secondIdType_Id;
            }
          );
          customerInfo.secondIdType_Id = result[0]?.description;
        }

        // if(customerInfo.idNumber) {
        //   const primaryData = await this.prefixChanger(customerInfo.idNumber, 4, 'idNumber');
        //   customerInfo.idNumber = primaryData
        // }

        // if(customerInfo.ssn) {
        //   const primaryData = await this.prefixChanger(customerInfo.ssn, 4, 'ssn');
        //   customerInfo.ssn = primaryData
        // }

        if (customerInfo.creditCardBin) {
          const primaryData = await this.prefixChanger(
            customerInfo.creditCardBin,
            2,
            "credit"
          );
          customerInfo.creditCardBin = primaryData;
        }

        if (customerInfo.checkingAccountNo) {
          const primaryData = await this.prefixChanger(
            customerInfo.checkingAccountNo,
            4,
            "checkaccount"
          );
          customerInfo.checkingAccountNo = primaryData;
        }
        if(customerInfo.mobileNo!=null){
          if(!customerInfo.mobileNo?.includes("-")){
            customerInfo.mobileNo = formatPhoneNumber(customerInfo.mobileNo)
          }
      }

        this.setState({
          formObj: {
            ...this.state.formObj,
            storeCode: customerInfo.store_code,
            isDaniel:customerInfo.isDaniel,
            suffix: customerInfo.suffix_Id==0?"":customerInfo.suffix_Id,
            credit_Id: customerInfo.LastDraftCreditId,
            id: customerInfo.id,
            firstName: customerInfo.firstName,
            middleName: customerInfo.middleName,
            lastName: customerInfo.lastName,
            mailingAdr: customerInfo.address,
            emailAdr: customerInfo.emailAddress,
            ccmailingAdr: customerInfo.ccAddress,
            apt: customerInfo.apt,
            city: customerInfo.city,
            dob:
              customerInfo.birthDate === null
                ? ""
                : Moment(customerInfo.birthDate).format("MM/DD/YYYY"),
            mobileNo: customerInfo.mobileNo,
            state: customerInfo.state_Id,
            secPhone: customerInfo.secondaryPhone,
            zipCode: customerInfo.zipCode,
            empName: customerInfo.empName,
            empPhoneNo: customerInfo.empPhone,
            monthlyIncome: customerInfo.monthlyIncome,

            duties: customerInfo.workJob,
            empStartDate:
              customerInfo.howLong === null
                ? ""
                : Moment(customerInfo.howLong).format("MM/YYYY"),
            housingInfo: customerInfo.housingInfo_Id==0?"":customerInfo.housingInfo_Id,
            ssn: customerInfo.ssn,
            // ssn: customerInfo.masked_ssn,
            // primaryIdNumber: customerInfo.masked_idNumber,
            primaryIdNumber: customerInfo.idNumber,
            primaryIdType: customerInfo.primaryType_Id==0?"":customerInfo.primaryType_Id,
            ciprimaryIdNoty: customerInfo.idNumber,
            primaryIdState: customerInfo.primaryState_Id==0?"":customerInfo.primaryState_Id,
            relativeName: customerInfo.relativeName,
            relativePhone: customerInfo.relativePhone,
            relativeRelation: customerInfo.relativeRelationship,
            primaryIdExpiryDate:
              customerInfo.primaryIdExpDate === null
                ? ""
                : (customerInfo.primaryIdExpDate == "1900-01-01T00:00:00") ? "" : Moment(customerInfo.primaryIdExpDate).format("MM/YYYY"),
            secondIdType: customerInfo.secondIdType_Id == 0 ? "" : customerInfo.secondIdType_Id,
            secondIdIssuedBy: customerInfo.secondIdIssuedBy_Id,
            secondIdExpiryDate:
              customerInfo.secondIdExpDate === null
                ? ""
                : (customerInfo.primaryIdExpDate == "1900-01-01T00:00:00") ? "" : Moment(customerInfo.secondIdExpDate).format("MM/YYYY"),
            oftenPaid: customerInfo.howOftenPaid_Id,
            lastPayDay:
              customerInfo.lastPayDay === null
                ? ""
                : Moment(customerInfo.lastPayDay).format("MM/DD/YYYY"),
            nextPayDay:
              customerInfo.nextPayDay === null
                ? ""
                : Moment(customerInfo.nextPayDay).format("MM/DD/YYYY"),
            bankRouteNo: customerInfo.bankRoutingNo,
            checkAccNo: customerInfo.checkingAccountNo,
            cc: customerInfo.creditCardBin,
            ccExpiryDate:
              customerInfo.creditCardExpDate === null
                ? ""
                : Moment(customerInfo.creditCardExpDate).format("MM/YYYY"),
            ccName: customerInfo.nameOnCreditCard,
            creditCardBillingAddress: customerInfo.creditCardHomeAddress,
            ccAddress: customerInfo.ccAddress,
            aptUnit: customerInfo.apt,
            ccaptUnit: customerInfo.ccApt,
            cccity: customerInfo.ccCity,
            ccstate: customerInfo.ccState_Id,
            // mobileNo: customerInfo.creditCardBin,
            cczipCode: customerInfo.ccZipCode,
            autopay: customerInfo.autoPayStatus,
            mobileNoVerified: customerInfo.isMobileNoVerified,
            smsCodeSent: customerInfo.isSMSCodeSent,
            smsOverride: customerInfo.isSMSOverride,
            danielsStatus: customerInfo.danielsStatus,
            synchronyStatus: customerInfo.synchronyStatus,
            progressiveStatus: customerInfo.progressiveStatus,
            uownStatus: customerInfo.uownStatus,
            isSynchrony:customerInfo.isSynchrony,
            isDanielEStatementDisclosure : customerInfo.isDanielEStatementDisclosure
          },
        });
        if(customerInfo.isSynchrony){
          this.synchronyOffers(customerInfo.customer_Id,this.state.creditId, customerInfo);
      }
      this.loadViewOffers(customerInfo.customer_Id,this.state.creditId);
      })
      .catch((err) => {
        APIFailedMessage.displayError(err);
      });
  };

  synchronyOffers(cust_Id,applicationId,rowData) {
 // let obj = JSON.parse(localStorage.getItem("associateId")) === null ? localStorage.getItem("magixassociateId") : localStorage.getItem("associateId");
  let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
      trackPromise(
        this.props.customerDetailsActions.getSynchronyViewOffers(cust_Id,applicationId,homeStore).then(
              response => {
                  if(response){
                    let filterObj = {
                      ssn:rowData?.ssn?.replace(/-/g,""),
                      zipCode:rowData?.zipCode,
                      phoneNumber:"",
                      accountNumber:"",
                      homeStore : rowData?.store_code
                  }
                  trackPromise(
                      this.props.syncActions.getsynchronyLookup(filterObj).then(
                          res => {
                              if(res.accountNumber){
                                  response.openToBuy = res.opentobuy;
                              }
                          }
                      ).catch(err => {
                          APIFailedMessage.displayError(err);
                      })
                  );
                      this.setState({ synchronyOffers: response });
                  }
              
              }).catch(err => {
                  APIFailedMessage.displayError(err);
              })
      )
}

// getAccountSummary(offersResponse,obj,mobileNo) {
//   let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
//   if(mobileNo.includes("-")){
//     mobileNo = mobileNo.slice(0,3)+mobileNo.slice(4,7)+mobileNo.slice(8,12);
//   }
//   trackPromise(
//     this.props.syncActions.getsynchronyActSummmary(GetInfo.getCustomerId(),obj,mobileNo,homeStore).then(
//           response => {
//               if(response.accountNumber){
//                   offersResponse.approvalLimit = response.creditLine;
//               }
//               this.setState({ synchronyOffers: offersResponse });
             
//           }
//       ).catch(err => {
//           APIFailedMessage.displayError(err);
//       })
//   );
// }

  cancel() {
    this.setState({
      show: !this.state.show,
      creditId: "",
      isView: false,
      autopayRollExpand: false,
      estatementExpand : false,
      identificationExpand: false,
      employmentExpand: false,
      residenceExpand: false,
    });
  }

  handleSpinner() {
    this.setState({ showSpinner: false });
    this.paginationWithIndex(this.state.pageNo);
  }
  handleSearchDropdown = (e) => {
    let data = this.state.filterObj;
    this.setState({
      isSearchDropDown: e.target.value,
      filterValue: data[e.target.value],
    });
  };

  handleChangeSearchDropdown = (e) => {
    this.setState({
      filterValue: e.target.value,
      filterObj: {
        ...this.state.filterObj,
        [this.state.isSearchDropDown]: e.target.value,
      },
    });
    if (e.target.value) {
      if (e.target.value.length > 1) {
        let obj = {
          name:
            this.state.isSearchDropDown === "firstName"
              ? e.target.value
              : this.state.filterObj?.firstName,
          address:
            this.state.isSearchDropDown === "address"
              ? e.target.value
              : this.state.filterObj?.address,
          phone:
            this.state.isSearchDropDown === "mobileNo"
              ? e.target.value
              : this.state.filterObj?.mobileNo,
          idNumber:
            this.state.isSearchDropDown === "idNumber"
              ? e.target.value
              : this.state.filterObj?.idNumber,
          ssn:
            this.state.isSearchDropDown === "ssn"
              ? e.target.value
              : this.state.filterObj?.ssn,
          store:
            this.state.isSearchDropDown === "store_code"
              ? e.target.value
              : this.state.filterObj?.store_code,
          account:
            this.state.isSearchDropDown === "account"
              ? e.target.value
              : this.state.filterObj?.account,
          applicationDate:
            this.state.isSearchDropDown === "modifiedDate"
              ? e.target.value
              : this.state.filterObj?.modifiedDate,
          pageIndex: this.state.pageNo,
          sortBy: "",
          sortOrder: "",
          pageSize: Constants.CreditAppPageSize,
        };
        if(this.state.isSearchDropDown==="modifiedDate"){
          if((Moment(e.target.value, 'MM/DD/YYYY',true).isValid() || Moment(e.target.value, 'MM-DD-YYYY',true).isValid())){
                  this.loadData(obj);
            }
        }
        else{
          this.loadData(obj);
        }
      }
    } else {
      let data = {  ...this.state.filterObj, [this.state.isSearchDropDown]: e.target.value};
      this.paginationWithIndex(this.state.pageNo, data, "mobile");
    }
  };

  loadData(obj) {
    trackPromise(
      this.props.creditApplication
        .getCreditApplication(obj)
        .then((response) => {
          this.setState({ creditAppList: [] });
          if (response.data.length === 0) {
            this.setState({ noDataFound: true });
          }
          if (response) {
            response.data.map((item) => {
              item.name =
                (item.firstName == null ? "" : item.firstName + " ") +
                (item.middleName == null ? "" : item.middleName + " ") +
                (item.lastName == null ? "" : item.lastName);
              item.modifiedDate =
                item.modifiedDate &&
                Moment(item.modifiedDate).format("MM/DD/YYYY");
                if(item.mobileNo!=null){
                  if(!item.mobileNo.includes("-")){
                    item.mobileNo = formatPhoneNumber(item.mobileNo)
                  }
              }
            });
            this.setState({
              creditAppList: response.data,
              totalCreditApps: response.totalCount,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  }

  paginationWithIndex = (page, filters, type) => {
    let obj;
    if (type === "mobile") {
      obj = {
        name: filters?.firstName,
        address: filters?.address,
        phone: filters?.mobileNo,
        idNumber: filters?.idNumber,
        ssn: filters?.ssn,
        store: filters?.store_code,
        account: filters?.account,
        applicationDate: filters?.modifiedDate,
        pageIndex: this.state.pageNo,
        pageSize: Constants.CreditAppPageSize,
        sortBy:
          this.state.dataField === "firstName" ? "name" : this.state.dataField,
        sortOrder: this.state.order,
      };
    } else {
      obj = {
        name: filters?.firstName?.filterVal
          ? filters?.firstName?.filterVal
          : this.state.filterObj?.firstName,
        address: filters?.address?.filterVal
          ? filters?.address?.filterVal
          : this.state.filterObj?.address,
        phone: filters?.mobileNo?.filterVal
          ? filters?.mobileNo?.filterVal
          : this.state.filterObj?.mobileNo,
        idNumber: filters?.idNumber?.filterVal
          ? filters?.idNumber?.filterVal
          : this.state.filterObj?.idNumber,
        ssn: filters?.ssn?.filterVal
          ? filters?.ssn?.filterVal
          : this.state.filterObj?.ssn,
        store: filters?.store_code?.filterVal
          ? filters?.store_code?.filterVal
          : this.state.filterObj?.store_code,
        account: filters?.account?.filterVal
          ? filters?.account?.filterVal
          : this.state.filterObj?.account,
        applicationDate: filters?.modifiedDate?.filterVal
          ? filters?.modifiedDate?.filterVal
          : this.state.filterObj?.modifiedDate,
        pageIndex: page,
        pageSize: Constants.CreditAppPageSize,
        sortBy:
          this.state.dataField === "firstName" ? "name" : this.state.dataField,
        sortOrder: this.state.order,
      };
    }
    window.scrollTo(0, 0);
    trackPromise(
      this.props.creditApplication
        .getCreditApplication(obj)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({ noDataFound: true });
          }
          if (response) {
            response.data.map((item) => {
              item.name =
                (item.firstName == null ? "" : item.firstName + " ") +
                (item.middleName == null ? "" : item.middleName + " ") +
                (item.lastName == null ? "" : item.lastName);
              item.modifiedDate =
                item.modifiedDate &&
                Moment(item.modifiedDate).format("MM/DD/YYYY");
                if(item.mobileNo!=null){
                  if(!item.mobileNo?.includes("-")){
                    item.mobileNo = formatPhoneNumber(item.mobileNo)
                  }
              }
            });

            this.setState({
              creditAppList: response.data,
              pageNo: page,
              totalCreditApps: response.totalCount,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  loadFortivaOffers=() => {
    let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
   return FortivaOffersStatus(offers);
}

  loadSynchronyOffers() {
    if(this.state.formObj.isSynchrony===true){
        let offers = this.state.synchronyOffers;
        return SynchronyOffersStatus(offers,this.state?.createdDate);
   }
    else {
      return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
    }      
}

loadProgressiveOffers=()=> {
    let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "progressive" })[0];
    return ProgressiveOffersStatus(offers);
}

loadUOwnOffers=()=> {
    let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "uown" })[0];
    return UOwnOffersStatus(offers);
}

  render() {
    let adminMenus = [];
    adminMenus = localStorage.getItem("sitePageDetails");
    adminMenus = JSON.parse(adminMenus);

    const columns = [
      {
        dataField: "firstName",
        text: "Name",
        sort: true,
        headerClasses: "input_form_control",
        formatter: (cell, row) => {
          return (
            <div>{`${row.firstName === null ? "" : row.firstName} ${
              row.middleName === null ? "" : row.middleName
            } ${row.lastName === null ? "" : row.lastName}`}</div>
          );
        },
        filter: textFilter({
          placeholder: "Name",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "address",
        text: "Address",
        sort: true,
        filter: textFilter({
          placeholder: "Address",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
        headerClasses: "input_form_control userId",
      },
      {
        dataField: "mobileNo",
        text: "Phone",
        sort: true,
        filter: textFilter({
          placeholder: "Phone",
        }),
        headerClasses: "input_form_control roleName",
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
        type: Number,
      },
      {
        dataField: "idNumber",
        text: "ID#",
        headerClasses: "input_form_control",
        sort: true,
        filter: textFilter({
          placeholder: "Id",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "ssn",
        text: "SSN/ITIN",
        sort: true,
        headerClasses: "input_form_control",
        filter: textFilter({
          placeholder: "SSN/ITIN",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "store_code",
        text: "Store",
        headerClasses: "input_form_control storeCode",
        sort: true,
        filter: textFilter({
          placeholder: "Store",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "customer_Id",
        text: "Customer ID",
        headerClasses: "input_form_control status",
        sort: true,
        filter: textFilter({
          placeholder: "Customer ID",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
      {
        dataField: "modifiedDate",
        text: "Application Date",
        headerClasses: "input_form_control applicationDate",
        sort: true,
        filter: textFilter({
          placeholder: "Application Date",
        }),
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
      },
    ];

    const sortingPage = (page, filters) => {
      let obj = {
        name: filters?.firstName?.filterVal
          ? filters?.firstName?.filterVal
          : this.state.filterObj?.firstName,
        address: filters?.address?.filterVal
          ? filters?.address?.filterVal
          : this.state.filterObj?.address,
        phone: filters?.mobileNo?.filterVal
          ? filters?.mobileNo?.filterVal
          : this.state.filterObj?.mobileNo,
        idNumber: filters?.idNumber?.filterVal
          ? filters?.idNumber?.filterVal
          : this.state.filterObj?.idNumber,
        ssn: filters?.ssn?.filterVal
          ? filters?.ssn?.filterVal
          : this.state.filterObj?.ssn,
        store: filters?.store_code?.filterVal
          ? filters?.store_code?.filterVal
          : this.state.filterObj?.store_code,
        account: filters?.account?.filterVal
          ? filters?.account?.filterVal
          : this.state.filterObj?.account,
        applicationDate: filters?.modifiedDate?.filterVal
          ? filters?.modifiedDate?.filterVal
          : this.state.filterObj?.modifiedDate,
        pageIndex: this.state.pageNo,
        pageSize: Constants.CreditAppPageSize,
        sortBy:
          this.state.dataField === "firstName" ? "name" : this.state.dataField,
        sortOrder: this.state.order,
      };

      trackPromise(
        this.props.creditApplication
          .getCreditApplication(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);

                item.modifiedDate =
                  item.modifiedDate &&
                  Moment(item.modifiedDate).format("MM/DD/YYYY");
                  if(item.mobileNo!=null){
                    if(!item.mobileNo.includes("-")){
                      item.mobileNo = formatPhoneNumber(item.mobileNo)
                    }
                }
              });
              this.setState({
                creditAppList: response.data,
                totalCreditApps: response.totalCount,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };
    const sortingOnCards = (sortOrder, SortBy) => {
      let obj = {
        name: this.state.filterObj?.firstName,
        address: this.state.filterObj.address,
        phone: this.state.filterObj?.mobileNo,
        idNumber: this.state.filterObj?.idNumber,
        ssn: this.state.filterObj?.ssn,
        store: this.state.filterObj?.store_code,
        account: this.state.filterObj?.account,
        applicationDate: this.state.filterObj?.modifiedDate,
        pageIndex: this.state.pageNo,
        pageSize: Constants.CreditAppPageSize,
        sortBy: SortBy,
        sortOrder: sortOrder,
      };

      trackPromise(
        this.props.creditApplication
          .getCreditApplication(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);

                item.modifiedDate =item.modifiedDate && Moment(item.modifiedDate).format("MM/DD/YYYY");
                if(item.mobileNo!=null){
                  if(!item.mobileNo.includes("-")){
                    item.mobileNo = formatPhoneNumber(item.mobileNo)
                  }
              }
              });
              this.setState({
                creditAppList: response.data,
                totalCreditApps: response.totalCount,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const searchHeader = (filters, page) => {
      let obj = {
        name: filters?.firstName?.filterVal
          ? filters?.firstName?.filterVal
          : null,
        address: filters?.address?.filterVal
          ? filters?.address?.filterVal
          : null,
        phone: filters?.mobileNo?.filterVal
          ? filters?.mobileNo?.filterVal
          : null,
        idNumber: filters?.idNumber?.filterVal
          ? filters?.idNumber?.filterVal
          : null,
        ssn: filters?.ssn?.filterVal ? filters?.ssn?.filterVal : null,
        store: filters?.store_code?.filterVal
          ? filters?.store_code?.filterVal
          : null,
          account: filters?.customer_Id?.filterVal
          ? filters?.customer_Id?.filterVal
          : null,
        applicationDate: filters?.modifiedDate?.filterVal
          ? filters?.modifiedDate?.filterVal
          : null,
        pageIndex: this.state.pageNo,
        pageSize: Constants.CreditAppPageSize,
        sortBy:
          this.state.dataField === "firstName" ? "name" : this.state.dataField,
        sortOrder: this.state.order,
      };
      trackPromise(
        this.props.creditApplication
          .getCreditApplication(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true });
            }
            if (response) {
              response.data.map((item) => {
                item.name =
                  (item.firstName == null ? "" : item.firstName + " ") +
                  (item.middleName == null ? "" : item.middleName + " ") +
                  (item.lastName == null ? "" : item.lastName);

                item.modifiedDate =
                  item.modifiedDate &&
                  Moment(item.modifiedDate).format("MM/DD/YYYY");
                  if(item.mobileNo!=null){
                    if(!item.mobileNo.includes("-")){
                      item.mobileNo = formatPhoneNumber(item.mobileNo)
                    }
                }
              });
              this.setState({
                creditAppList: response.data,
                totalCreditApps: response.totalCount,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const handleTableChange = (
      type,
      { page, sizePerPage, sortField, sortOrder, filters }
    ) => {
      if (type === "pagination") {
        this.setState({ creditAppList: [], isPaginate: true, isSort: false });
        this.paginationWithIndex(page, filters);
      } else if (type === "sort") {
        this.setState({ isFirstTime: false });
        if (this.state.isSort && !this.state.isFirstTime) {
          this.setState({ dataField: sortField, order: sortOrder });
          setTimeout(() => {
            sortingPage(page, filters);
          }, 1000);
        }
      } else if (type === "filter") {
        if (filters?.firstName) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              firstName: filters?.firstName?.filterVal,
            },
          });
        } else if (filters?.address) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              address: filters?.address?.filterVal,
            },
          });
        } else if (filters?.mobileNo) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              mobileNo: filters?.mobileNo?.filterVal,
            },
          });
        } else if (filters?.idNumber) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              idNumber: filters?.idNumber?.filterVal,
            },
          });
        } else if (filters?.ssn) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              ssn: filters?.ssn?.filterVal,
            },
          });
        } else if (filters?.store_code) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              store_code: filters?.store_code?.filterVal,
            },
          });
        } else if (filters?.customer_Id) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              customer_Id: filters?.customer_Id?.filterVal,
            },
          });
        } else if (filters?.modifiedDate) {
          this.setState({
            filterObj: {
              ...this.state.filterObj,
              modifiedDate: filters?.modifiedDate?.filterVal,
            },
          });
        } else {
          this.setState({
            filterObj: { ...this.state.filterObj, name: "", status: "" },
          });
        }

        if (
          !filters?.firstName &&
          !filters?.address &&
          !filters?.mobileNo &&
          !filters?.idNumber &&
          !filters?.ssn &&
          !filters?.store_code &&
          !filters?.customer_Id &&
          !filters?.modifiedDate
        ) {
          let obj = {
            name: null,
            address: null,
            phone: null,
            idNumber: null,
            ssn: null,
            store: null,
            customer_Id: null,
            applicationDate: null,
          };
          this.setState({ filterObj: { ...obj } });
          this.paginationWithIndex(this.state.pageNo);
        } else if ( 
          filters?.firstName?.filterVal.length > 1 ||
          filters?.mobileNo?.filterVal.length > 1 ||
          filters?.address?.filterVal.length > 1 ||
          filters?.idNumber?.filterVal.length > 1 ||
          filters?.ssn?.filterVal.length > 1 ||
          filters?.store_code?.filterVal.length > 1 ||
          filters?.customer_Id?.filterVal.length > 1 ) {
            if(filters?.modifiedDate?.filterVal){
              if((Moment(filters?.modifiedDate?.filterVal, 'MM/DD/YYYY',true).isValid() || Moment(filters?.modifiedDate?.filterVal, 'MM-DD-YYYY',true).isValid())){
                searchHeader(filters, page);
              }
            }
            else{
              searchHeader(filters, page);
            }
          
        } else if(filters?.modifiedDate?.filterVal){
          if((Moment(filters?.modifiedDate?.filterVal, 'MM/DD/YYYY',true).isValid() || Moment(filters?.modifiedDate?.filterVal, 'MM-DD-YYYY',true).isValid())){
            searchHeader(filters, page);
          }
        }
      }
    };

    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {from}-{to} of {size}
          </div>
        </span>
      );
    };

    const selectRow = {
      clickToSelect: true,
      onSelect: (row) => {
        this.setState({
          isView: true,
          creditId: row.application_id,
          applicationId: row.application_id,
          customerId: row.customer_Id,
          createdDate : row.createdDate,
          isDaniel:row.isDaniel
        });
      },
      onSelectAll: this.handleOnSelectAll,
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row.application_id === this.state.creditId) {
        style.backgroundColor = "#5C068C";
        style.color = "white";
      }

      return style;
    };

    const onValueChange = (e) => {
      if (e.target.value === "asc") {
        this.setState({ order: "asc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else if (e.target.value === "desc") {
        this.setState({ order: "desc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else {
        this.setState({ dataField: e.target.value, pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      }
    };

    const CardView = ({ data, handleCardSelect, activeCreditId }) => {
      return (
        <>
          <hr className="mt-1 mb-1"></hr>
          <div className="row">
            {data &&
              data.map((val) => (
                <div
                  className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                  key={val.creditId}
                >
                  <Card
                    className="text-left shadow"
                    onClick={() => handleCardSelect(val.application_id, val.customer_Id,val.isDaniel,val.createdDate)}
                  >
                    <Card.Body
                      className={`m-3 pt-0 ${
                        val.id === activeCreditId
                          ? "bg-secondary text-primary"
                          : ""
                      }`}
                      style={{ border: "2px solid #1B1B1B" }}
                    >
                      <p className="h3 ml-0 mt-2 mb-0">
                        {val.name === null || undefined ? "" : val.name}
                      </p>
                      <hr
                        style={
                          val.creditId === activeCreditId
                            ? { backgroundColor: "#f8f6fa" }
                            : { backgroundColor: "#1B1B1B" }
                        }
                      ></hr>
                      <div className="row">
                        <div className="col-6">Address :</div>
                        <div className="col-6">{val.address}</div>
                        <div className="col-6">Phone:</div>
                        <div className="col-6">{val.mobileNo}</div>
                        <div className="col-6">ID :</div>
                        <div className="col-6">{val.idNumber}</div>
                        <div className="col-6">SSN/ITIN :</div>
                        <div className="col-6">{val.ssn}</div>
                        <div className="col-6">Store :</div>
                        <div className="col-6">{val.store_code}</div>
                        <div className="col-6">Customer ID :</div>
                        <div className="col-6">{val.customer_Id}</div>
                        <div className="col-6">Application Date :</div>
                        <div className="col-6">{val.modifiedDate}</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </>
      );
    };

    return (
      <>
       <div
        className={` ${
          this.state.show ? "printSummaryPanelShow" : "printSummaryPanelHide"
        }`}
      >
        <div className="printSummaryPanel">
          <div className="container-fluid pt-4">
            <div className="row">
              <div className="col-12 pl-4 pr-4">
                <p>
                  <span className="h4 text-secondary m-0">
                    <b> Credit Application</b>
                  </span>
                  <span
                    className="float-right btn p-0 mr-0"
                    onClick={() => this.setState({ show: !this.state.show })}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                  </span>
                </p>
                <p className="mb-0">
                  <b>Customer ID : </b>{" "}
                  {this.state.customerId ? this.state.customerId : "-"}
                </p>
                <p className="mb-0">
                  <b>Application ID : </b>{" "}
                  {this.state.applicationId === "null"
                    ? "-"
                    : this.state.applicationId}
                </p>

                <p className="h5 font-weight-bold mt-4">
                  Mobile Verification
                  {this.state.mobileVerificationExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          mobileVerificationExpand:
                            !this.state.mobileVerificationExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          mobileVerificationExpand:
                            !this.state.mobileVerificationExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.mobileVerificationExpand === true
                      ? ""
                      : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Mobile Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {this.state.formObj.mobileNo}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Is MobileNo Verified
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {typeof this.state.formObj.mobileNoVerified ===
                            "boolean"
                              ? this.state.formObj.mobileNoVerified
                                ? "true"
                                : "false"
                              : this.state.formObj.mobileNoVerified}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Is SMS Code Sent
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {typeof this.state.formObj.smsCodeSent === "boolean"
                              ? this.state.formObj.smsCodeSent
                                ? "true"
                                : "false"
                              : this.state.formObj.smsCodeSent}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Is SMS Override
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {typeof this.state.formObj.smsOverride === "boolean"
                              ? this.state.formObj.smsOverride
                                ? "true"
                                : "false"
                              : this.state.formObj.smsOverride}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Personal Information
                  {this.state.psInformationExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          psInformationExpand:
                            !this.state.psInformationExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          psInformationExpand:
                            !this.state.psInformationExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.psInformationExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Store Code
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.storeCode}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Social Security Number/ITIN
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.ssn}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Date of Birth(MM/DD/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.dob}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          First Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.firstName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Middle Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {this.state.formObj.middleName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Last Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.lastName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Suffix
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.suffix:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Mailing Address
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.mailingAdr}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Apt/Unit
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.apt}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          City
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.city}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          State
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.state}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Zip Code
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.zipCode}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Housing Information
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.housingInfo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Secondary Phone(Optional)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.secPhone:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Email Address
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.emailAdr}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <p className="h5 font-weight-bold mt-4">
                  Residence
                  {this.state.residenceExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          residenceExpand: !this.state.residenceExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          residenceExpand: !this.state.residenceExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.residenceExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                  </form>
                </div> */}
                <p className="h5 font-weight-bold mt-4">
                  Employment
                  {this.state.employmentExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          employmentExpand: !this.state.employmentExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          employmentExpand: !this.state.employmentExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.employmentExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Employer Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(this.state.formObj.danielsStatus || this.state.formObj.uownStatus)? this.state.formObj.empName:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Type of Work/Job Duties
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {this.state.formObj.danielsStatus? this.state.formObj.duties:""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Start Date(MM/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.danielsStatus? this.state.formObj.empStartDate:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Employer's Phone Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.empPhoneNo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Monthly Net Income(from all sources)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.monthlyIncome}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Identification
                  {this.state.identificationExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          identificationExpand:
                            !this.state.identificationExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          identificationExpand:
                            !this.state.identificationExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.identificationExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID Type
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.primaryIdType:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus || this.state.formObj.progressiveStatus)? this.state.formObj.primaryIdNumber:""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID State
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.primaryIdState:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Daniel's Required Field
                  {this.state.synchronyExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          synchronyExpand: !this.state.synchronyExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          synchronyExpand: !this.state.synchronyExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.synchronyExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    {/* <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID Expirtaion Date(MM/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.primaryIdExpiryDate}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Second ID Type
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.synchronyStatus? this.state.formObj.secondIdType:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Second ID Issued By
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {this.state.formObj.synchronyStatus? this.state.formObj.secondIdIssuedBy:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Second ID Expiration Date(MM/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.synchronyStatus? this.state.formObj.secondIdExpiryDate:""}
                        </p>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Nearest Relative Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.danielsStatus? this.state.formObj.relativeName:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Nearest Relative Phone
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.danielsStatus? this.state.formObj.relativePhone:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Nearest Relative Relationship
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.danielsStatus? this.state.formObj.relativeRelation:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Progressive Required Field
                  {this.state.progressiveExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          progressiveExpand: !this.state.progressiveExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          progressiveExpand: !this.state.progressiveExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.progressiveExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Bank routing number(9 digits)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.progressiveStatus? this.state.formObj.bankRouteNo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Checking Account Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.progressiveStatus? this.state.formObj.checkAccNo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          How often are you paid?
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {this.state.formObj.progressiveStatus?
                            this.state.formObj.oftenPaid != "0"
                            ? this.state.formObj.oftenPaid
                            : null:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Last pay day(MM/DD/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.progressiveStatus? this.state.formObj.lastPayDay:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Next pay day(MM/DD/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.progressiveStatus? this.state.formObj.nextPayDay:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Name on Credit Card
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.ccName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Credit Card Number(first 6 digits)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.cc}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Credit Card Expiration Date(MM/YYYY){" "}
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.ccExpiryDate}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Credit Card Billing Address{" "}
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {this.state.formObj.ccAddress}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Apt/Unit
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {this.state.formObj.progressiveStatus? this.state.formObj.ccaptUnit:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          City
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.progressiveStatus? this.state.formObj.cccity:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          State
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.progressiveStatus? this.state.formObj.ccstate==0?"":this.state.formObj.ccstate:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Zip Code
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                            {this.state.formObj.progressiveStatus? this.state.formObj.cczipCode:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Uown Required Field
                  {this.state.uownExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({ uownExpand: !this.state.uownExpand })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({ uownExpand: !this.state.uownExpand })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                className={` ${
                  this.state.uownExpand === true ? "" : " d-none"
                }`}
              >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          How often are you paid?
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {this.state.formObj.uownStatus?
                          this.state.formObj.oftenPaid != "0"
                          ? this.state.formObj.oftenPaid
                          : null:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          // style={{ wordBreak: "break-all" }}
                        >
                          Last Day (MM/DD/YYYY) Pay $ Was Available
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {this.state.formObj.uownStatus?this.state.formObj.lastPayDay:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          // style={{ wordBreak: "break-all" }}
                        >
                          Next Day (MM/DD/YYYY) Pay $ Will Be Available
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {this.state.formObj.uownStatus? this.state.formObj.nextPayDay:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Auto-Pay Enroll(Daniels Credit only)
                  {this.state.autopayRollExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          autopayRollExpand: !this.state.autopayRollExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          autopayRollExpand: !this.state.autopayRollExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.autopayRollExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Status
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {this.state.formObj.autopay?.toLowerCase() ==="enrolled" ? "ENROLLED" : "NOT ENROLLED"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  E-Statement Enroll(Daniels Credit only)
                  {this.state.estatementExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          estatementExpand: !this.state.estatementExpand,
                        })
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() =>
                        this.setState({
                          estatementExpand: !this.state.estatementExpand,
                        })
                      }
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    this.state.estatementExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Status
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {this.state.formObj.isDanielEStatementDisclosure ? "ENROLLED" : "NOT ENROLLED"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>

                <p className="h5 font-weight-bold mt-4">Application Status
                            {this.state.customerPreApprove === false ?
                                <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => this.setState({customerPreApprove:!this.state.customerPreApprove})} />
                                :
                                <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => this.setState({customerPreApprove:!this.state.customerPreApprove})} />
                            }
                        </p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        <div className={` ${this.state.customerPreApprove === true ? "" : " d-none"}`}>
                            <form>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Daniel’s Credit</label>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pt-2 pl-1 pr-1">
                                    <p className="pl-2"><div dangerouslySetInnerHTML={{ __html: DanielsOffersCard(this.state.formObj.isDaniel,Moment(this.state?.createdDate).format("MM/DD/YYYY")) }}></div></p>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Fortiva</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <p className="pl-2"><div dangerouslySetInnerHTML={{ __html: this.loadFortivaOffers() }}></div></p>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Synchrony Bank Credit</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <p className="pl-2"><div dangerouslySetInnerHTML={{ __html: this.loadSynchronyOffers() }}></div></p>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Progressive Leasing</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <p className="pl-2"><div dangerouslySetInnerHTML={{ __html: this.loadProgressiveOffers() }}></div></p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Uown Leasing</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <p className="pl-2"><div dangerouslySetInnerHTML={{ __html: this.loadUOwnOffers() }}></div></p>
                                    </div>
                                </div>
                                <hr></hr>
                            </form>
                        </div>
                <div className="row">
                  <div className="col-12 mt-4 mb-3">
                    <div className="row">
                      <div className="col-6 col-md-6 col-lg-6">
                        <p
                          className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                          onClick={() =>
                            this.setState({ show: !this.state.show })
                          }
                        >
                          Cancel
                        </p>
                      </div>
                      <div className="col-6 col-md-6 col-lg-6">
                        <ReactToPrint
                          trigger={() => (
                            <p className="btn bg-secondary text-primary w-100">
                              Print
                            </p>
                          )}
                          content={() => this.componentRef}
                          pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                          documentTitle={`${this.state.formObj.firstName}-Information`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                 <div id="customerInfo" ref={(el) => (this.componentRef = el)}>
                  <div className="ml-5 mb-3 mr-5 mt-4">
                    <h2 className="text-secondary">Customer Information</h2>
                    <h5 className="font-weight-bold">
                      For store use only. Not for customer distribution.
                      Please dispose of this form properly.
                    </h5>
                    <div className="mt-4 printDatas">
                      <p className="mb-0">
                        <b>Customer ID : </b>{" "}
                        {this.state.customerId ? this.state.customerId : "-"}
                      </p>
                      <p className="mb-0">
                        <b>Application ID : </b>{" "}
                        {this.state.applicationId === "null"
                          ? "-"
                          : this.state.applicationId}
                      </p>
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Personal Information</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Mobile Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.mobileNo}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Store Code
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {this.state.formObj.storeCode}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Social Security Number/ITIN
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.ssn}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Date of Birth(MM/DD/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {this.state.formObj.dob}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                First Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.firstName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Middle Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.middleName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Last Name</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {this.state.formObj.lastName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Suffix</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.suffix:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Mailing Address
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {this.state.formObj.mailingAdr}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Apt/Unit</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {this.state.formObj.apt}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">City</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.city}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">State</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.state}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Zip Code</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {this.state.formObj.zipCode}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Housing Information
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.housingInfo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Secondary Phone(Optional)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.secPhone:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Email Address
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {this.state.formObj.emailAdr}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Residence</h4>
                          <hr className="mt-0"></hr>
                        </div>
                      </div>
                    </div> */}
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Employment</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Employer Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.danielsStatus || this.state.formObj.uownStatus)? this.state.formObj.empName:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Type of Work/Job Duties
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.danielsStatus? this.state.formObj.duties:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Start Date(MM/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.danielsStatus? this.state.formObj.empStartDate:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Employer's Phone Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.empPhoneNo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Monthly Net Income(from all sources)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.monthlyIncome}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Identification</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID Type
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                              {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.primaryIdType:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus || this.state.formObj.progressiveStatus)? this.state.formObj.primaryIdNumber:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID State
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {(this.state.formObj.danielsStatus || this.state.formObj.synchronyStatus)? this.state.formObj.primaryIdState:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Daniel's Required Field</h4>
                          <hr className="mt-0"></hr>
                        </div>

                        {/* <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID Expirtaion Date(MM/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.primaryIdExpiryDate}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Second ID Type
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {this.state.formObj.synchronyStatus? this.state.formObj.secondIdType:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Second ID Issued By
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.synchronyStatus? this.state.formObj.secondIdIssuedBy:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Second ID Expiration Date(MM/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {this.state.formObj.synchronyStatus? this.state.formObj.secondIdExpiryDate:""}
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Nearest Relative Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.danielsStatus? this.state.formObj.relativeName:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Nearest Relative Phone
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.danielsStatus? this.state.formObj.relativePhone:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Nearest Relative Relationship
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.danielsStatus? this.state.formObj.relativeRelation:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Progressive Required Field</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Bank routing number(9 digits)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.progressiveStatus? this.state.formObj.bankRouteNo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Checking Account Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {this.state.formObj.progressiveStatus? this.state.formObj.checkAccNo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                How often are you paid?
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.progressiveStatus?
                                 this.state.formObj.oftenPaid != "0"
                                 ? this.state.formObj.oftenPaid
                                 : null:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Last pay day(MM/DD/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.progressiveStatus? this.state.formObj.lastPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Next pay day(MM/DD/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.progressiveStatus? this.state.formObj.nextPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Name on Credit Card
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.progressiveStatus? this.state.formObj.ccName:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Credit Card Number(first 6 digits)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.progressiveStatus? this.state.formObj.cc:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Credit Card Expiration Date(MM/YYYY){" "}
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                              {this.state.formObj.progressiveStatus? this.state.formObj.ccExpiryDate:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Credit Card Billing Address
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.progressiveStatus && !this.state.formObj.creditCardBillingAddress)? this.state.formObj.ccAddress:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Apt/Unit
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {(this.state.formObj.progressiveStatus && !this.state.formObj.creditCardBillingAddress)?
                                  this.state.formObj.ccaptUnit != null
                                  ? this.state.formObj.ccaptUnit
                                  : this.state.formObj.aptUnit:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">City</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {(this.state.formObj.progressiveStatus && !this.state.formObj.creditCardBillingAddress)?
                                  this.state.formObj.cccity != ""
                                  ? this.state.formObj.cccity
                                  : this.state.formObj.city:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">State</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.progressiveStatus && !this.state.formObj.creditCardBillingAddress)?
                                 this.state.formObj.ccstate != "0"
                                 ? this.state.formObj.ccstate
                                 : this.state.formObj.state:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Zip Code</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(this.state.formObj.progressiveStatus && !this.state.formObj.creditCardBillingAddress)? 
                                this.state.formObj.cczipCode !== ""
                                ? this.state.formObj.cczipCode
                                : this.state.formObj.zipCode:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Uown Required Field</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                How often are you paid?
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.uownStatus?
                                this.state.formObj.oftenPaid != "0"
                                ? this.state.formObj.oftenPaid
                                : null:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Last Day (MM/DD/YYYY) Pay $ Was Available
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {this.state.formObj.uownStatus? this.state.formObj.lastPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Next Day (MM/DD/YYYY) Pay $ Will Be Available
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.uownStatus? this.state.formObj.nextPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Auto-Pay Enroll(Daniels Credit only)</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Status</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.autopay?.toLowerCase() === "enrolled" ? "ENROLLED" : "NOT ENROLLED"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>E-statement Enroll(Daniels Credit only)</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Status</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {this.state.formObj.isDanielEStatementDisclosure ? "ENROLLED" : "NOT ENROLLED"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12 mt-4 mb-2">
                                        <h4>Application Status</h4>
                                        <hr className="mt-0"></hr>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Daniel’s Credit</p>
                                            </div>
                                            <div className="col-7">
                                            <p className="mb-1"><div dangerouslySetInnerHTML={{ __html:DanielsOffersCard(this.state.formObj.isDaniel,Moment(this.state?.createdDate).format("MM/DD/YYYY")) }}></div></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Fortiva</p>
                                            </div>
                                            <div className="col-7">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadFortivaOffers() }}></div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Synchrony Bank Credit</p>
                                            </div>
                                            <div className="col-7">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadSynchronyOffers() }}></div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Progressive Leasing</p>
                                            </div>
                                            <div className="col-7">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadProgressiveOffers() }}></div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">UOWN Leasing</p>
                                            </div>
                                            <div className="col-7">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadUOwnOffers() }}></div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span className="float-right mt-2">{new Date(this.state.createdDate).toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={adminMenus}
          />
          {this.state.showSpinner ? (
            <div
              style={{
                flexDirection: "column",
              }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Loader
                type="ThreeDots"
                color="#5C068C"
                height="100"
                width="100"
              />
            </div>
          ) : (
            <main>
              <CustomNavbar
                handleToggleSidebar={() => {
                  this.handleToggleSidebar();
                }}
                isAdmin={true}
              />
              <div id="main" className="container-fluid mt-3">
                <div className="row mb-2 mt-3 pl-4 pr-4">
                  <div className=" col-6 d-flex justify-content-left">
                    <h4>
                      <b> Existing Application</b>
                    </h4>
                  </div>
                </div>
                <div className="row m-1 mr-1 mb-2">
                  <div className="row m-0 pl-1 pr-1 mb-2 w-100">
                    <div className="col-12 command_bar">
                      <button
                        className={
                          this.state.isView
                            ? "edit_button"
                            : "edit_button_unselect"
                        }
                        onClick={() => this.handleViewCreditDetials()}
                        disabled={this.state.isView ? false : true}
                      >
                        <FontAwesomeIcon icon={faEye} className="mr-1" />
                        View
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row mt-2 pl-1 pr-1">
                  <div className="col-12">
                    <>
                      <div id="cardrow">
                        <p className="h4 text-secondary mb-0 mt-3 ml-2">
                          Sort By
                        </p>
                        <div className="row pl-1 pr-3">
                          <div className="col-6 mt-3 mb-2">
                            <select
                              value={this.state.dataField}
                              className="form-select pt-2 pb-2 drp"
                              onChange={(e) => onValueChange(e)}
                            >
                              <option value="name">Name</option>
                              <option value="address">Address</option>
                              <option value="mobileNo">Phone</option>
                              <option value="idNumber">Id</option>
                              <option value="ssn">SSN/ITIN</option>
                              <option value="store_code">Store</option>
                              <option value="account">Customer ID</option>
                              <option value="modifiedDate">
                                Application Date
                              </option>
                            </select>
                          </div>
                          <div className="col-3 mt-3 mb-2">
                            <select
                              value={this.state.order}
                              className="form-select pt-2 pb-2 drp"
                              onChange={(e) => onValueChange(e)}
                            >
                              <option value="asc">ASC</option>
                              <option value="desc">DESC</option>
                            </select>
                          </div>
                        </div>
                        <p className="h4 text-secondary mb-0 mt-3 ml-2">
                          Filter By
                        </p>
                        <div className="row pl-1 pr-3">
                          <div className="col-6 mt-3 mb-2">
                            <select
                              value={this.state.isSearchDropDown}
                              className="form-select pt-2 pb-2 drp"
                              onChange={(e) => this.handleSearchDropdown(e)}
                            >
                              <option value="firstName">Name</option>
                              <option value="address">Address</option>
                              <option value="mobileNo">Phone</option>
                              <option value="idNumber">Id</option>
                              <option value="ssn">SSN/ITIN</option>
                              <option value="store_code">Store</option>
                              <option value="account">Customer ID</option>
                              <option value="modifiedDate">
                                Application Date
                              </option>
                            </select>
                          </div>
                          <div className="col-6 mt-3 mb-2 form-group text-white mt-md-2 input_width ">
                            <form className="form_pad">
                              <input
                                type="text"
                                className="form-control bg-secondary text-white text_box_style"
                                placeholder={this.state.isSearchDropDown}
                                value={this.state.filterValue}
                                onChange={(e) =>
                                  this.handleChangeSearchDropdown(e)
                                }
                              ></input>
                            </form>
                          </div>
                        </div>
                        <CardView
                          data={this.state.creditAppList}
                          handleCardSelect={this.handleCardSelect}
                          activeCreditId={this.state.creditId}
                        />
                      </div>
                      <RemotePagination
                        data={this.state.creditAppList}
                        page={this.state.pageNo}
                        from={this.state.from}
                        to={this.state.to}
                        sizePerPage={5}
                        firstPageText="<<"
                        totalSize={this.state.totalCreditApps}
                        onTableChange={handleTableChange}
                        dataField={this.state.dataField}
                        order={this.state.order}
                        columns={columns}
                        customTotal={customTotal}
                        selectRow={selectRow}
                        rowStyle={rowStyle}
                      />
                    </>
                    <>
                      <div
                        id="matchnotfound"
                        className="text-center mw-100 d-flex justify-content-center align-items-center"
                      >
                        {this.state.creditAppList.length === 0 &&
                        !this.state.isPaginate ? (
                          <p className="mb-0 pt-0 text-secondary font-weight-bold">
                            No data found
                          </p>
                        ) : this.state.isPaginate ? (
                          <p className="mb-0 pt-0 text-secondary"></p>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </main>
          )}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    creditApplication: bindActionCreators(creditApplication, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
    customerActions: bindActionCreators(customerLookupActions, dispatch),
    customerDetailsActions: bindActionCreators(customerDetailsActions,dispatch),
    actions: bindActionCreators(applyNowActions, dispatch),
    syncActions: bindActionCreators(synchronyActions, dispatch),
    accountSummaryAction: bindActionCreators(accountSummaryAction, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CreditApplicationList);
