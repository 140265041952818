import React, { Component } from "react";
import { Card } from "react-bootstrap";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import "./component.synchronyLookUp.css";
 import IdleTimeModal from "../../components/component.idleTimeModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import * as synchronyActions from "../../redux/actions/synchronyLookup.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import TitleCard from "../../components/component.titleCard";
import danielsLogo from "../../images/DanielBlack.png";import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HamburgerMenu from "../../components/component.hamburgerMenu";
import {
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../components/component.confirmDialog";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import * as authActions from "../../redux/actions/authActions";
import { IMaskInput } from 'react-imask';

class SynchronyLookUp extends Component{
    constructor(){
        super();
        this.state={
            show: false,
            toggled: false,
            content: "",
            filterObj:{
              customerId : "0",
              ssn:"",
              zipCode:"",
              phoneNumber:"",
              accountNumber:""
            },
            syncLookupResult:{
                accountNumber: "",
                opentobuy: "",
                creditLine: "",
                balance: "",
                firstName: "",
                lastName: "",
                address: "",
                addressLine2: "",
                city: "",
                state: "",
                zipCode: "",
                previousCredit: ""
            },
            roles: [],
            site_page_details: [],
            sitePages: [],
            userRights: [],
            permission_status: "",
            hasAdminAccess : false,
            noDatafound:false,
            loadComp:true,
            isSearch:true
        };
    }

    componentDidMount() {
        if (JSON.parse(sessionStorage.getItem("isLoggedIn")) && JSON.parse(localStorage.getItem("hasPortalAccess"))) {
          let hasAdminAccess = localStorage.getItem("hasAdminAccess");
          hasAdminAccess = JSON.parse(hasAdminAccess);
          this.setState({ hasAdminAccess: hasAdminAccess });
          trackPromise(
            this.props.userRightsactions
              .getUserRights()
              .then((response) => {
                if (response) {
                  this.setState({
                    roles: response.roles,
                    site_page_details: response.sitePageDetails,
                    sitePages: response.sitePages,
                    userRights: response.userRights,
                  });
                  this.getPermissions();
                }
              })
              .catch((err) => {
                APIFailedMessage.displayError(err);
              })
          );
        } else this.props.history.push("/");
      }

    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
      };

      logOut = async () => {
        try {
          await this.props.authActions.logout();
        } catch (error) {
          APIFailedMessage.displayError(error);
        }
      };

    onChange(event){
      if(event.target.name == "ssn") {
        const re = /^[0-9\b]+$/;
            if (event.target.value?.trimStart() === '' || re.test(event.target.value?.trimStart())) {
              this.setState({filterObj:{...this.state.filterObj,[event.target.name]:event.target.value?.trimStart()}})
            }
      }
      else if(event.target.name == "zipCode") {
        const re = /^[0-9\b]+$/;
            if (event.target.value?.trimStart() === '' || re.test(event.target.value?.trimStart())) {
              this.setState({filterObj:{...this.state.filterObj,[event.target.name]:event.target.value?.trimStart()}})
            }
      }
      else if(event.target.name == "phoneNumber") {
        const re = /^[0-9\b]+$/;
            if (event.target.value?.trimStart() === '' || re.test(event.target.value?.trimStart())) {
              this.setState({filterObj:{...this.state.filterObj,[event.target.name]:event.target.value?.trimStart()}})
            }
      }
      else {
        this.setState({filterObj:{...this.state.filterObj,[event.target.name]:event.target.value?.trimStart()}})
      }
    }

    handleModal = () => {
        this.setState({ show: !this.state.show , toggled : !this.state.toggled });
      };

      async getPermissions() {
        let role_name = localStorage.getItem("role_name");
        const getPageDetailsArray =
          this.state.sitePages.length > 0
            ? this.state.sitePages.map((data) => {
                return this.state.site_page_details.filter((site_page_details) => {
                  return site_page_details.sitePage_id === data.id;
                });
              })
            : [];
        let role_permissions = await Promise.all(getPageDetailsArray);
        const getSitepagedetails = [...role_permissions[0], ...role_permissions[1]];
        const getRole = this.state.roles.filter((roles) => {
          return roles.name.toLowerCase() === role_name.toLowerCase();
        });
        let role_id = getRole[0].id;
        const applyNowPermission = this.state.userRights.filter((user_rights) => {
          return (
            user_rights.sitePageDetail_id === getSitepagedetails[2].id &&
            user_rights.role_id === role_id
          );
        });
        this.setState({
          applyNowPermission: applyNowPermission && applyNowPermission[0],
        });
        let permisson_roles = {
          admin_settings: false,
          user_list: false,
          user_role: false,
          user_rights: false,
          customer_details: false,
          account_summary: false,
          view_offer: false,
          apply_now: false,
          credit_application: false,
          application_history: false,
          transaction_history: false,
        };
        const role_permissions_check_1 =
          getSitepagedetails.length > 0 &&
          getSitepagedetails.map((data) => {
            const role_permission_check = this.state.userRights.filter(
              (user_rights) => {
                return (
                  user_rights.sitePageDetail_id === data.id &&
                  user_rights.role_id === role_id
                );
              }
            );
            if (data.name === "User List") {
              permisson_roles.user_list = role_permission_check[0]?.canView;
              sessionStorage.setItem(
                "user_list_permission",
                role_permission_check[0]?.canView
              );
            } else if (data.name === "User Role") {
              permisson_roles.user_role = role_permission_check[0]?.canView;
              sessionStorage.setItem(
                "user_role_permission",
                role_permission_check[0]?.canView
              );
            } else if (data.name === "User Rights") {
              permisson_roles.user_rights = role_permission_check[0]?.canView;
              sessionStorage.setItem(
                "user_rights_permission",
                role_permission_check[0]?.canView
              );
            } else if (data.name === "Admin Settings") {
              permisson_roles.admin_settings = role_permission_check[0]?.canView;
              sessionStorage.setItem(
                "admin_settings_permission",
                role_permission_check[0]?.canView
              );
            }
          });
    
        this.setState({ permission_status: permisson_roles });
      }

      onAdmin(event) {
        event.preventDefault();
        if (this.state.permission_status?.admin_settings) {
          this.props.history.push("/admin");
        } else if (this.state.permission_status?.user_list) {
          this.props.history.push("/admin-user");
        } else if (this.state.permission_status?.user_role) {
          this.props.history.push("/admin-userRole");
        } else if (this.state.permission_status?.user_rights) {
          this.props.history.push("/admin-userRights");
        } else if (this.state.permission_status?.credit_application) {
          this.props.history.push("/credit-application");
        }
      }

    onSearch(){
        this.setState({loadComp: true});
         let filterObj=this.state.filterObj;
         let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
         filterObj.homeStore=homeStore;
        trackPromise(
        this.props.actions.getsynchronyLookup(this.state.filterObj)
        .then(response=>{
            if(response.accountNumber){
                this.setState({syncLookupResult:response,loadComp:false});
            }
            else{
                this.setState({noDatafound:true,loadComp:false});
            }
        }).catch(error=>{
            APIFailedMessage.displayError();
        }))
    }

    render()
    {
        let res=this.state.syncLookupResult;
        return(
            <>
            <IdleTimeModal />
            <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
              {JSON.parse(sessionStorage.getItem("hamburger")) ? 
            <HamburgerMenu
                        toggled={this.state.toggled}
                        handleToggleSidebar={() => { this.handleToggleSidebar() }}
                        hasAdminAccess={this.state.hasAdminAccess}
                        handleModal={() => {this.handleModal()}}
                        onAdmin={(event) => {this.onAdmin(event);}}
                    />
                    :
                    <Sidebar
                toggled={this.state.toggled}
                handleToggleSidebar={() => {this.handleToggleSidebar()}}
                />
        }
                <main>
                {JSON.parse(sessionStorage.getItem("hamburger")) ? 
                <nav className="navbar bg-white justify-content-start">
                    <button onClick={() => this.handleToggleSidebar()} className="btn border-0 p-0 m-0 text-secondary ml-md-3"><span><FontAwesomeIcon icon={faBars} size="lg"/></span></button>
          <a className="navbar-brand ml-4" href="/">
            <img
              src={danielsLogo}
              alt="danielsLogo"
              className="img-fluid"
              width="100"
            ></img>
          </a>
          <ConfirmDialog
            show={this.state.show}
            handleModalPopup={this.handleModal}
            message={"<p>Are you sure you want to logout?</p>"}
            button1_name="Cancel"
            button1={this.handleModal}
            button2_name="Logout"
            button2={this.logOut}
          />
        </nav>
        :
                    <CustomNavbar  handleToggleSidebar={() => {this.handleToggleSidebar()}}/>
      }
                        <div id="main" className="container-fluid pl-4 pr-4">
                            <div className="row mt-4">
                            {JSON.parse(sessionStorage.getItem("hamburger")) ? 
                            ""
                            :
                            <TitleCard accType={"Synchrony"}/> }
                                <div className="col-12 mb-2">
                                    <h4 className="ml-1"><b>Synchrony look Up</b></h4>
                                </div>
                                <div className="col-12">
                                    <Card>
                                        <Card.Body>
                                            <div className="row">
                                                <div className="col-12 col-md-3 searchDiv">
                                                    <div className="form-group ml-2 mr-2">
                                                        <label className="text-secondary font-weight-bold">SSN/ITIN</label>
                                                        <input type="text" className="form-control border"  name="ssn" placeholder="SSN/ITIN" value={this.state.filterObj.ssn} onChange={(event)=>{this.onChange(event)}} maxLength="9"/>
                                                    </div>
                                                    <div className="form-group ml-2 mr-2">
                                                        <label className="text-secondary font-weight-bold">Zip Code</label>
                                                        <input type="text" className="form-control border" name="zipCode" placeholder="Zip code" value={this.state.filterObj.zipCode} onChange={(event)=>{this.onChange(event)}} maxLength="5"/>
                                                    </div>
                                                    <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                                                </div>
                                                <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">
                                                    <div className="form-group w-100 ml-2 mr-2">
                                                        <label className="text-secondary font-weight-bold">Phone Number</label>
                                                        <input type="tel" className="form-control border" name="phoneNumber" placeholder="Phone Number" value={this.state.filterObj.phoneNumber} onChange={(event)=>{this.onChange(event)}} maxLength="10"/>
                                                    </div>
                                                    <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                                                </div>
                                                <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">
                                                    <div className="form-group w-100 ml-2 mr-2">
                                                        <label className="text-secondary font-weight-bold">Synchrony Account Number</label>
                                                        <input type="text" className="form-control border" name="accountNumber" placeholder="Account Number" value={this.state.filterObj.accountNumber} onChange={(event)=>{this.onChange(event)}}/>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                                                    <button className="btn bg-secondary text-primary w-75" id="syncLookUpSearch" onClick={()=>this.onSearch()} disabled={(this.state.filterObj.ssn!=="" && this.state.filterObj.zipCode!=="")||(this.state.filterObj.phoneNumber!=="")||(this.state.filterObj.accountNumber!=="") ? false : true}>Search</button>
                                                    <button className="btn bg-secondary text-primary w-75 mt-4" id="syncLookUpSearch" onClick={()=>this.setState({
                                                      filterObj : { ...this.state.filterObj, ssn: "", zipCode : "", phoneNumber : "", accountNumber:"" }, loadComp : true
                                                    })} disabled={(this.state.filterObj.ssn!=="" || this.state.filterObj.zipCode!=="")||(this.state.filterObj.phoneNumber!=="")||(this.state.filterObj.accountNumber!=="") ? false : true}>Clear Search</button>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12">
                                    <hr className="mt-5 mb-5 pl-2 pr-2"></hr>
                                    <p className="m-0 pl-3 pr-3" id="resultSpliter">Result</p>
                                </div>
                                <div className="col-12 mb-4">
                                {this.state.loadComp?"":
                                    this.state.noDatafound?
                                    <div className="card mt-3 mb-3 d-flex" id="cardnotfound">
                                    <div className="card-body d-flex justify-content-center align-items-center">
                                        <p className="text-secondary font-weight-bold">
                                          No data found
                                        </p>
                                    </div>
                                  </div>
                                    :<Card>
                                        <Card.Body>
                                        <div>
                                                <p className="h5 mt-3 mb-3"><b>Account Info</b></p>
                                                <div className="row">
                                                    <div className=" col-6 col-md-3 border-right pr-md-3 pt-2 pb-2 pl-md-4">
                                                        <p><b>Account ID</b></p>
                                                        <p>{res.accountNumber}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-3 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Credit Limit</b></p>
                                                        <p>{"$"+res.creditLine}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-3 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Open to buy</b></p>
                                                        <p>{"$"+res.opentobuy}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-3 pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Account Balance</b></p>
                                                        <p>{"$"+res.balance}</p>
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <p className="h5 mt-3 mb-3"><b>Customer Info</b></p>
                                                <div className="row">
                                                    <div className=" col-6 col-md-2 border-right pr-md-3 pt-2 pb-2 pl-md-4">
                                                        <p><b>Name</b></p>
                                                        <p>{res.firstName+" "+res.lastName}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Address</b></p>
                                                        <p>{res.address}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>City</b></p>
                                                         <p>{res.city}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>State</b></p>
                                                        <p>{res.state}</p>
                                                    </div>
                                                    <div className=" col-6 col-md-2 pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Zip Code</b></p>
                                                        <p>{res.zipCode}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>}
                                </div>
                            </div>
                    </div>
                </main>
            </div>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(synchronyActions, dispatch),
        userRightsactions: bindActionCreators(userRightsActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
    }
}
export default connect(null, mapDispatchToProps)(SynchronyLookUp);
