import React, { Component } from "react";
import { Card, Modal } from "react-bootstrap";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import './component.accountSummary.css';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import IdleTimeModal from "../../components/component.idleTimeModal";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import ReactToPrint from 'react-to-print';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import TenderModal from '../../components/component.tenderModal';
import FortivaErrorDialog from "../../components/component.fortivaErrorDialog";
import { faShieldAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import * as insuranceActions from "../../redux/actions/insurance.actions";
import * as adminActions from '../../redux/actions/admin.actions';
import {ClaimAndCancel} from "../../components/component.insuranceClaimAndCancel";
import {toast} from "react-toastify";
import * as customerLooUpActions from "../../redux/actions/customerLookup.actions";
import Insurance from "../insurance/component.insurance";

class AccountSummary extends Component {
    constructor() {
        super();
        this.state = {
            summaryDetails: [],
            toggled: false,
            showTender : false,
            currentTender : "",
            isSummary:false,
            activeTab : "",
            optForInsurance:"",
            isInsuranceEnabled: false,
            claimOrCancelContent : "",
            reason : "",
            permission_status : null,
            offerAcceptedState : "",
            showCancelAndClaimPopup:false,
            isDanielsFileAClaim:false,
            isDanielsCancelEnrollment:false,
            danielsInsuranceObj:{
                daniel_accountNumber: "",
                firstName: "",
                lastName: "",
                middleName: "",
                city: "",
                address: "",
                zipCode: "",
                state_Id: "",
                mobileNo: "",
                emailAddress: ""
            },
            danielsOffers : [],
            alredayInsuranceEnrolled : false,
            offerAcceptedStateHavingInsurnace : false,
            insuranceCancelPopUp: false,
            autoPaynEStateStatus : {},
            currentTabAccnStore : "",
        };

    }

    async componentDidMount() {
        if (JSON.parse(sessionStorage.getItem('isLoggedIn'))) {
            let obj = sessionStorage.getItem("ssn&dob");
            obj={
                ssn:obj?.split("&")[0],
                dob:Moment(obj?.split("&")[1]).format("MM/DD/YYYY")
            }
            await trackPromise(
                this.props.actions.getAllMagixAccountNumbers(obj).then(response => {
                    if(response) {
                        if(response?.length > 0) {
                            let magixID = response?.map((val) => {
                                return (val.magix_Id+"-"+val.magixStoreCode+"-"+val.currentBalance);
                            });
                            sessionStorage.setItem("multipleMagixId",magixID);
                        }
                        else {
                            localStorage.setItem("magix_account_no", "");
                            sessionStorage.setItem("multipleMagixId","");
                        }
                    }
                })
            );
            let magix_account_no = localStorage.getItem("magix_account_no");
            let multipleMagixId = sessionStorage.getItem("multipleMagixId");
            multipleMagixId = multipleMagixId?.split(",");
            this.setState({
                activeTab : multipleMagixId[0]?.split("-")[0]
            });
            if (magix_account_no!=null && magix_account_no!=undefined && magix_account_no!="") {
                let obj = { associateId: multipleMagixId[0]?.split("-")[0], storecode: multipleMagixId[0]?.split("-")[1] }
                await trackPromise(
                    this.props.actions.getMagixCustomerDetails(obj).then(
                        response => {
                            this.setState({ summaryDetails: response[0] });
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
                trackPromise(
                    this.props.actions.getAutopaynEstateStatus(multipleMagixId[0]?.split("-")[0],multipleMagixId[0]?.split("-")[1]).then((res) => {
                        if(res) {
                            this.setState({autoPaynEStateStatus : res});
                        }
                    }).catch(err => {
                        APIFailedMessage.displayError(err);
                    }))
            }
            else{
                // this.setState({isSummary:!this.state.isSummary});
            }
            let obj2 = {
                customerId: localStorage.getItem("customerId"),
                applicationId: null
            }
            //Getting e-statement data based on customer
            trackPromise(
                this.props.insuranceAction.getDanielsAccountInfo(obj2).then((res) => {
                if(res) {
                    this.setState({danielsOffers : res});
                }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            }))
            //Getting offer accepted store code
            let cust_Id = localStorage.getItem("customerId");
            trackPromise(
            this.props.actions.getCustomerViewOffers(cust_Id).then((res) => {
                if(res) {
                    this.getState(res?.data?.find((item) => {
                        return item.fpType == "Daniel";
                    })?.store_id);
                }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            }))
            trackPromise(
                this.props.adminActions?.getSignOutTime().then( async response => {
                    if (response) {
                        this.setState({ isInsuranceEnable: response.isInsurance_DanielsEnabled,
                            isDanielsFileAClaim:response.isDanielsFileAClaim,isDanielsCancelEnrollment:response.isDanielsCancelEnrollment });
                    }
                    if (response?.isInsurance_DanielsEnabled === true) {
                        await this.triggerInsurance("Completed");
                        await this.getDanielsAccountInfo();
                    }
                }).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
            trackPromise(
                this.props.userRightsactions.getUserRights()
                  .then((response) => {
                    if (response) {
                      let role_name = localStorage.getItem("role_name"),
                        page_name = "Insurance Cancellation Access";
                      const getsitepages = response.sitePages.filter((site_pages) => {
                        return site_pages.name === "Portal Page";
                      });
                      const getSitepagedetails = response.sitePageDetails.filter(
                        (site_page_details) => {
                          return (
                            site_page_details.sitePage_id === getsitepages[0].id &&
                            site_page_details.name === page_name
                          );
                        }
                      );
                      const getRole = response.roles.filter((roles) => {
                        return roles.name.toLowerCase() === role_name.toLowerCase();
                      });
                      let role_id = getRole[0].id;
                      const role_permission_check = response.userRights.filter(
                        (user_rights) => {
                          return (
                            user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
                            user_rights.role_id === role_id
                          );
                        }
                      );
                      this.setState({permission_status :  role_permission_check[0]});
                    }
                  })
                  .catch((err) => {
                    APIFailedMessage.displayError(err);
                  })
              );  
        }
        else
            this.props.history.push("/");
    }

    getState(store_Id) {
        var storeList = JSON.parse(sessionStorage.getItem("storeList"));
        let offerAcceptedState = "";
        storeList = storeList?.find((item) => {
            return item.code == store_Id
        });
        offerAcceptedState = storeList?.address2?.split(",")[1]?.trim();
        this.setState({offerAcceptedState : offerAcceptedState});
        trackPromise(
        this.props.insuranceAction.getInsurancePlans().then((res) => {
            if(res) {
                let havingPlan = res.getInsuranceResponses?.find(item => {
                    return item.insurance_partner === "Daniels" && item.isActive === true && item.state == offerAcceptedState;
                });
                if(havingPlan !== undefined)
                  this.setState({offerAcceptedStateHavingInsurnace : true});
                else this.setState({offerAcceptedStateHavingInsurnace : false});
            }
        }).catch((err) => {
            APIFailedMessage.displayError(err);
        })
        )
    }

    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
    };

    triggerTender = (curTen) => {
        let multipleMagixId = sessionStorage.getItem("multipleMagixId");
        multipleMagixId = multipleMagixId?.split(",");
        multipleMagixId = multipleMagixId?.find((item) => {
            return item?.split("-")[0] === this.state.activeTab;
        })
        let data = multipleMagixId
        this.setState({showTender : !this.state.showTender,  currentTender : curTen, currentTabAccnStore : data});
    }

    changeTab(currentTab) {
        if(currentTab !== "" && this.state.activeTab != currentTab) {
            this.setState({activeTab : currentTab});
            let multipleMagixId = sessionStorage.getItem("multipleMagixId");
            multipleMagixId = multipleMagixId?.split(",");
            multipleMagixId = multipleMagixId?.find((item) => {
                return item?.split("-")[0] === currentTab;
            })
            let obj = { associateId: currentTab, storecode: multipleMagixId?.split("-")[1] }
            trackPromise(
                this.props.actions.getMagixCustomerDetails(obj).then(
                    response => {
                        this.setState({ summaryDetails: response[0] });
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
            trackPromise(
                this.props.actions.getAutopaynEstateStatus(currentTab,multipleMagixId?.split("-")[1]).then((res) => {
                    if(res) {
                        this.setState({autoPaynEStateStatus : res});
                    }
                }).catch(err => {
                    APIFailedMessage.displayError(err);
                }))
        }  
    }
    triggerInsurance = (type) => {
        if (type === "Completed") {
            let obj = {
                cust_Id: localStorage.getItem("customerId"),
                partner_type: "Daniels"
            }
            trackPromise(
                this.props.insuranceAction.getInsurancePlanValue(obj).then(res => {
                    if (res) {
                        if (res.getInsuranceplan_values?.length === 0) {
                            this.setState({optForInsurance: "Enroll" });
                        }
                        else {
                            let acceptStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "accept"
                            })
                            let claimStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "claimreqraised" && item.plan_type !== "None selected"
                            })
                            let cancelStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "cancelreqraised" && item.plan_type !== "None selected"
                            })
                            let notEnrollStatus = res.getInsuranceplan_values?.filter(item => {
                                return item.isInsurance_Status?.toLowerCase() === "cancelreqfinalized" && item.plan_type !== "None selected"
                            })
                            if (acceptStatus !== undefined && acceptStatus?.length !== 0) {
                                this.setState({ planValue: acceptStatus, optForInsurance: "Enrolled",alredayInsuranceEnrolled : true});
                            }
                            else if (claimStatus !== undefined && claimStatus?.length !== 0) {
                                this.setState({  planValue: claimStatus, optForInsurance: "claimReqRaised", alredayInsuranceEnrolled : true });
                            }
                            else if (cancelStatus !== undefined && cancelStatus?.length !== 0) {
                                this.setState({  planValue: cancelStatus, optForInsurance: "cancelReqRaised", alredayInsuranceEnrolled : true });
                            }
                            else if (notEnrollStatus !== undefined && notEnrollStatus?.length !== 0) {
                                this.setState({  optForInsurance: "Not Enrolled",alredayInsuranceEnrolled : false });
                            }
                            else {
                                this.setState({ optForInsurance: "Declined", alredayInsuranceEnrolled : false });
                            }
                        }
                    }
                }).catch((err) => {
                    APIFailedMessage.displayError(err);
                })
            );
            this.setState({ insurance: false });
        }
        else
            this.setState({ insurance: !this.state.insurance });
    }
    getDanielsAccountInfo(){
        let obj = {
            customerId: localStorage.getItem("customerId"),
            applicationId: localStorage.getItem("applicationId")
        }
        trackPromise(
            this.props.insuranceAction.getDanielsAccountInfo(obj).then(res => {
                if (res) {
                    this.setState({danielsInsuranceObj:res})
                }
            }).catch((err) => {
                APIFailedMessage.displayError(err);
            })
        );
    }
    onChange=(e)=> {
        this.setState({reason : e.target.value});
    }
    handleClaimAndCancel =(type) => {
        if(type === "claimReqRaised") {
            this.setState({claimOrCancelContent : "claimReqRaised"});
        }
        else if(type === "cancelReqRaised") {
            this.setState({claimOrCancelContent : "cancelReqRaised"});
        }
        else if(type==="actionConfirm" && this.state.claimOrCancelContent==="cancelReqRaised") {
            this.fileAClaimOrCancelEnroll();
        }
        else if(type==="actionConfirm" && this.state.claimOrCancelContent==="claimReqRaised" && this.state.reason !== "") {
            this.fileAClaimOrCancelEnroll();
        }
        else if(type === "revert") {
            this.setState({reason : ""});
        }
        this.setState({showCancelAndClaimPopup : !this.state.showCancelAndClaimPopup});
    }
    async fileAClaimOrCancelEnroll(){
        let insuranceStatus = this.state.claimOrCancelContent;
        let danielsInsuranceObj= this.state.danielsInsuranceObj;
        let reason = this.state.reason?.split("\n")?.map(val => {
            return (val +
                '<br>'
            );
        });
        reason = String(reason)?.replace(/,/g, '');
        let date = new Date();
        date = date.toLocaleString("en-US", {timeZone: "America/Los_Angeles"});
        let resStatus = null;
        let obj = {
            "credit_id": sessionStorage.getItem("credit_Id"),
            "application_id": localStorage.getItem("applicationId"),
            "isInsurance_Status": insuranceStatus,
            "state": sessionStorage.getItem("AssociateState"),
            "plan_type": this.state.planValue[0].plan_type,
            "plan_value": this.state.planValue[0].plan_value,
            "createdBy": "",
            "modifiedBy": localStorage.getItem("loggedUserEmail"),
            "insurance_life": "",
            "insurance_unemployment": "",
            "insurance_disability": "",
            "insurance_property": "",
            "financial_partner": "Daniels",
            "account_number": danielsInsuranceObj.daniel_accountNumber,
            "customer_id": localStorage.getItem("customerId"),
            "finalize_cancellationDate": insuranceStatus === "cancelReqFinalized" ? Moment(date).format("MM/DD/YYYY") : "",
        };
        await trackPromise(
            this.props.insuranceAction.enrollInsurace(obj).then((res) => {
                resStatus = res;
                if(res === false)
                    toast.error("Failed to raise a request, please try again");
                else if(insuranceStatus === "cancelReqRaised") {
                    this.setState({insuranceCancelPopUp : true});
                }
            })
        );
        if(resStatus === true && insuranceStatus !== "cancelReqFinalized") {
           let danielsInsuranceObj= this.state.danielsInsuranceObj;
            let htmlContent = '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '</head>' +
            '<body>' +
            (insuranceStatus==="claimReqRaised" ? '<h2>DANIELS CREDIT INSURANCE - FILE A CLAIM REQUEST</h2>' : '<h2>DANIELS CREDIT INSURANCE - CANCEL ENROLLMENT REQUEST</h2>') +
            '<br>' +
            '<table>' +
            '<tr>' +
            '<td>' +
            '<b>Associate Name : </b>' + (localStorage.getItem("loggedUserName")) +
            '<br>' +
            '<b>Apply Store : </b>' + (JSON.parse(localStorage.getItem("associateStores"))) +
            '<br>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<br>' +
            '<table>' +
            '<tr>' +
            '<td>' +
            '<b><u>Customer Info</u></b>' +
            '<br>' +
            '<b>Daniels Account Number : </b>' + (danielsInsuranceObj?.daniel_accountNumber) +
            '<br>' +
            '<b>Customer Name : </b>' + (danielsInsuranceObj?.firstName+" "+danielsInsuranceObj?.lastName) +
            '<br>' +
            '<b>Address : </b>' + (danielsInsuranceObj?.address+", "+danielsInsuranceObj?.city+", "+danielsInsuranceObj?.state_Id+", "+danielsInsuranceObj?.zipCode) +
            '<br>' +
            '<b>Phone : </b>' + (danielsInsuranceObj?.mobileNo) +
            '<br>' +
            '<b>Email : </b>' + (danielsInsuranceObj?.emailAddress?.toLowerCase()) +
            '<br>' +
            '</td>' +
            '</tr>' +
            '</table>' +
            '<br>' +
            (insuranceStatus==="claimReqRaised" ? '<p><b>Briefly explain reason for filing a claim</b></p>' : '') +
            (insuranceStatus==="claimReqRaised" ? reason : '') +
            '</body>' +
            '</html>';
            obj = {
                toEmail: danielsInsuranceObj?.emailAddress?.toLowerCase(),
                cc: "",
                subject: "Daniels Credit Insurance - " + (insuranceStatus==="claimReqRaised" ? "File a claim request" : "Cancel enrollment request"),
                emailContent: " ",                
                attachmentContent: htmlContent,
                is_insurance: insuranceStatus==="claimReqRaised" ? "Claim" : "Cancel",
                financial_partner : "Daniels",
            }
            await trackPromise(
                this.props.customerLooUpActions.sendApplyEmailLink(obj).then(
                    response => {
                        if (response.requestStatus?.toLowerCase() === "success") {
                            toast.success("Request raised successfully.")
                            this.triggerInsurance("Completed");
                            this.setState({claimOrCancelContent : "", reason : ""});
                        }
                        else {
                            toast.error("Failed to raise a request, please try again")
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
        else if(resStatus === true && insuranceStatus === "cancelReqFinalized") {
            this.triggerInsurance("Completed");
            toast.success("Cancellation has been done.");
        }
    }

    render() {
        let userStoreCode = localStorage.getItem("storeCode");
        let multipleMagixId = sessionStorage.getItem("multipleMagixId");
        multipleMagixId = multipleMagixId?.split(",");
        return (
            <>
                <IdleTimeModal />
                <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                    <Sidebar
                        toggled={this.state.toggled}
                        handleToggleSidebar={() => this.handleToggleSidebar()}
                    />
                    <main>
                        <CustomNavbar handleToggleSidebar={() => this.handleToggleSidebar()} />
                        <div id="main" className="container-fluid" ref={el => (this.accountPrintOut = el)}>
                            <div className="row ml-2 mt-3">
                                <div className="ml-auto d-flex align-items-center mr-4 pr-2">
                                    <ReactToPrint
                                        trigger={() =>
                                            <span className="mr-2 accountPrintOut btn"><FontAwesomeIcon icon={faPrint} className="pr-2" />Print</span>
                                        }
                                        content={() => this.accountPrintOut}
                                        pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                                        documentTitle={`Information`}
                                    />
                                </div>
                            </div>
                            <div className="row accSummaryPrint">
                                <div className="col-12 printAlone">
                                    <Card className="text-left ml-2 mr-2 shadow w-100">
                                        <Card.Body className="pt-2 pb-2">
                                            <div className="row bg-white">
                                                {/* <div className="col-4 border-right">
                                                <b className="mb-0">Account ID</b>
                                                            <p className="mb-0" style={{ wordBreak: "break-all" }}>
                                                                {localStorage.getItem("magixassociateId") !== "null"
                                                                    ? selectedUserAccount
                                                                    : sessionStorage.getItem("magixInstoreData") !== "" &&
                                                                        sessionStorage.getItem("magixInstoreData") !==
                                                                        "null" &&
                                                                        sessionStorage.getItem("magixInstoreData") !==
                                                                        undefined
                                                                        ? selectedUserAccount
                                                                        : "-"}
                                                            </p>
                                                </div> */}
                                                
                                                <div className="col-4 border-right">
                                                            <p className="mt-2"><b>Borrower Name</b></p>
                                                            <p className="" style={{ wordBreak: "break-all" }}>
                                                                {localStorage.getItem("selectedUserName")}
                                                            </p>
                                                </div>
                                                <div className="col-4 border-right">
                                                <p className="mt-2"><b>Home Store</b></p>
                                                <p className="" style={{ wordBreak: "break-all" }}>{userStoreCode === "null" || userStoreCode === undefined || userStoreCode === "" ? "-" : userStoreCode}</p>
                                                </div>
                                                <div className="col-4">
                                                            <p className="mt-2"><b>Apply Store</b></p>
                                                            <p className="" style={{ wordBreak: "break-all" }}>{JSON.parse(localStorage.getItem("associateStores"))}</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12 d-flex flex-column magixAccSummary">
                                    <div className="ml-3">
                                        <ul className="nav nav-tabs pl-3 pr-3">
                                            {multipleMagixId?.map((val) => {
                                                return (
                                                    <>
                                                        <li className="nav-item pr-2">
                                                            <button 
                                                                id={val?.split("-")[0]} 
                                                                className={"nav-link p-3 pl-md-5 pr-md-5 "+(this.state.activeTab == val?.split("-")[0] ? "active shadow" : "")} 
                                                                aria-current={this.state.activeTab == val?.split("-")[0] ? "page" : ""} 
                                                                onClick={() => this.changeTab(val?.split("-")[0])}
                                                            >
                                                                <b className="h5">{val?.split("-")[0]}</b>
                                                            </button>
                                                        </li>
                                                    </>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                    <Card className="text-left mt-0 ml-2 mr-2 mb-2 border-top-0 shadow w-100">
                                        <Card.Body>
                                            <div>
                                            <p className="h5 pt-2 pb-2"><b>Magix Account ID : </b>
                                                <span>{this.state.activeTab}</span>
                                            </p>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <p className="h4 mb-2"><b>Payment Status</b></p>
                                                <div className="row">
                                                    <div className="printdiv1 col-6 col-md-3 col-lg-2 border-right pr-md-3 pt-2 pb-2 pl-md-4">
                                                        <p><b>Current Balance</b></p>
                                                        <p>$ {this.state.summaryDetails?.strbalanceDue}</p>
                                                    </div>
                                                    <div className="printdiv1 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Total Payment Past Due</b></p>
                                                        <p className="text-danger">$ {this.state.summaryDetails?.strbalanceDue30}</p>
                                                    </div>
                                                    <div className="printdiv1 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Total Payment Now Due</b></p>
                                                        <p>$ {this.state.summaryDetails?.strbalanceDue0}</p>
                                                    </div>
                                                    <div className="printdiv1 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Next Due Date</b></p>
                                                        <p>{(this.state.summaryDetails?.schedPymtDate !== null && this.state.summaryDetails?.schedPymtDate !== undefined) ? Moment(this.state.summaryDetails?.schedPymtDate).format("MM/DD/YYYY") : ""}</p>
                                                    </div>
                                                    <div className="printdiv1 col-6 col-md-3 col-lg-2 pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Scheduled Payment Amount</b></p>
                                                        <p>$ {this.state.summaryDetails?.strschedPymtAmt}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div>
                                                <p className="h4 mt-3 mb-2"><b>Charge and Payment Summary</b></p>
                                                <div className="row">
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pt-2 pb-2 pl-md-4">
                                                        <p><b>High Balance</b></p>
                                                        <p>$ {this.state.summaryDetails?.strhighCustBal}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>First Charge Date</b></p>
                                                        <p>{(this.state.summaryDetails?.firstChgDate !== null && this.state.summaryDetails?.firstChgDate !== undefined) ? Moment(this.state.summaryDetails?.firstChgDate).format("MM/DD/YYYY") : ""}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Last Charge Date</b></p>
                                                        <p>{(this.state.summaryDetails?.lastChgDate !== null && this.state.summaryDetails?.lastChgDate !== undefined) ? Moment(this.state.summaryDetails?.lastChgDate).format("MM/DD/YYYY") : ""}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Last Charge Amount</b></p>
                                                        <p>$ {this.state.summaryDetails?.strlastChgSaleAmt}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Last Payment Date</b></p>
                                                        <p>{(this.state.summaryDetails?.lastPymtDate !== null && this.state.summaryDetails?.lastPymtDate !== undefined) ? Moment(this.state.summaryDetails?.lastPymtDate).format("MM/DD/YYYY") : ""}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Last Payment Amount</b></p>
                                                        <p className="text-danger">$ {this.state.summaryDetails?.strlastPymtAmt}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 border-right col-lg-2 pr-md-3 pl-md-4 mt-2 pb-2">
                                                        <p><b>Cycles Bal</b></p>
                                                        <p>{this.state.summaryDetails?.custBalCount}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 mt-2 pb-2">
                                                        <p><b>Months Open</b></p>
                                                        <p>{this.state.summaryDetails?.monthOpenCount}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                        <p><b>Financial Terms</b></p>
                                                        <p>{this.state.summaryDetails?.finTermCode}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 border-right pr-md-3 pl-md-4 mt-2 pb-2">
                                                        <p><b>Account Type</b></p>
                                                        <p>{this.state.summaryDetails?.custAcctCode}</p>
                                                    </div>
                                                    <div className="printdiv2 col-6 col-md-3 col-lg-2 pr-md-3 pl-md-4 mt-2 pb-2">
                                                        <p><b>Insurance Code</b></p>
                                                        <p>{this.state.summaryDetails?.insCode}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6 maindiv3 d-flex pr-3 pr-md-2 pr-lg-2">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-2 shadow w-100">
                                        <Card.Body>
                                            <p className="h4 mb-4"><b>Billing History</b></p>
                                            <div className="row mb-4">
                                                <div className="col-6 border-right pr-md-3 pt-2 pb-2">
                                                    <p><b>Current D/S Code</b></p>
                                                    <p>{this.state.summaryDetails?.dlqCode}</p>
                                                </div>
                                                <div className="col-6 pl-md-4 pt-2 pb-2">
                                                    <p><b>D/S History</b></p>
                                                    <p>{this.state.summaryDetails?.dlqCodeHist}</p>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-6 border-right pr-md-3 pt-2 pb-2 dynamicCol1">
                                                    <p><b>Last Delinquency</b></p>
                                                    <p>{(this.state.summaryDetails?.highRollDlqDate !== null && this.state.summaryDetails?.highRollDlqDate !== undefined) ? Moment(this.state.summaryDetails?.highRollDlqDate).format("MM/DD/YYYY") : ""}</p>
                                                </div>
                                                <div className="col-6 pl-md-4 pt-2 pb-2 dynamicCol2">
                                                    <p><b>D/S Code</b></p>
                                                    <table className="table w-100 dsTable">
                                                        <tr>
                                                            <th className="p-1">Neg</th>
                                                            <th className="p-1">0</th>
                                                            <th className="p-1">1</th>
                                                            <th className="p-1">2</th>
                                                            <th className="p-1">3</th>
                                                            <th className="p-1">4</th>
                                                            <th className="p-1">5</th>
                                                            <th className="p-1">9</th>
                                                        </tr>
                                                        <tr>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCountNeg !== null ? this.state.summaryDetails?.dlqCodeCountNeg : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount0 !== null ? this.state.summaryDetails?.dlqCodeCount0 : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount1 !== null ? this.state.summaryDetails?.dlqCodeCount1 : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount2 !== null ? this.state.summaryDetails?.dlqCodeCount2 : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount3 !== null ? this.state.summaryDetails?.dlqCodeCount3 : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount4 !== null ? this.state.summaryDetails?.dlqCodeCount4 : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount5 !== null ? this.state.summaryDetails?.dlqCodeCount5 : ""}</td>
                                                            <td className="p-1">{this.state.summaryDetails?.dlqCodeCount9 !== null ? this.state.summaryDetails?.dlqCodeCount9 : ""}</td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-6 border-right pr-md-3 pt-2 pb-2">
                                                    <p><b>Highest D/S Date</b></p>
                                                    <p>{(this.state.summaryDetails?.highDlqDate !== null && this.state.summaryDetails?.highDlqDate !== undefined) ? Moment(this.state.summaryDetails?.highDlqDate).format("MM/DD/YYYY") : ""}</p>
                                                </div>
                                                <div className="col-6 pl-md-4 pt-2 pb-2">
                                                    <p><b>Highest D/S Code</b></p>
                                                    <p>{this.state.summaryDetails?.highDlqCode}</p>
                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-6 border-right pr-md-3 pt-2 pb-2">
                                                    <div className="row mb-3">
                                                        <div className="col-6 pr-2">
                                                            <p><b>Cycle Bal</b></p>
                                                            <p>33</p>
                                                        </div>
                                                        <div className="col-6 pl-md-4">
                                                            <p><b>Mons Open</b></p>
                                                            <p>47</p>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                    <div className="col-6 pr-2">
                                                            <p><b>Cycle Stmt</b></p>
                                                            <p>87</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12 col-md-3 maindiv4 d-flex pl-3 pl-md-2 pl-lg-2 pr-3 pr-md-2 pr-lg-2">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-2 shadow w-100">
                                        <Card.Body>
                                            <p className="h4 mb-4"><b>Purchase Summary</b></p>
                                            <div className="row mb-4">
                                                <div className="col-12 pr-md-3 pt-2 pb-2">
                                                    <p><b>Total Charge Amount</b></p>
                                                    <p>$ {this.state.summaryDetails?.strtotChgAmt}</p>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-12 pl-md-4 pt-2 pb-2">
                                                    <p><b>Total Down Payment</b></p>
                                                    <p>$ {this.state.summaryDetails?.strtotDownPymtAmt}</p>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-12 pl-md-4 pt-2 pb-2">
                                                    <p><b>Total Down Payment %</b></p>
                                                    <p>{this.state.summaryDetails?.strtotDownPymtPct} %</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                <div className="col-12 col-md-3 maindiv4 d-flex pl-3 pl-md-2 pl-lg-2">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-2 shadow w-100">
                                        <Card.Body>
                                            <p className="h4 mb-4"><b>Billing History</b></p>
                                            <div className="row mb-4">
                                                <div className="col-12 pr-md-3 pt-2 pb-2">
                                                    <p><b>Statement Cycle</b></p>
                                                    <p>{this.state.summaryDetails?.cycleId}</p>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-12 pl-md-4 pt-2 pb-2">
                                                    <p><b>Last Statement Date</b></p>
                                                    <p>{(this.state.summaryDetails?.lastStmtDate !== null && this.state.summaryDetails?.lastStmtDate !== undefined) ? Moment(this.state.summaryDetails?.lastStmtDate).format("MM/DD/YYYY") : ""}</p>
                                                </div>
                                            </div>
                                            <div className="row mb-4">
                                                <div className="col-12 pl-md-4 pt-2 pb-2">
                                                    <p><b>Last Statement Amount</b></p>
                                                    <p>$ {this.state.summaryDetails?.strlastBilledCustBal}</p>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-3 shadow w-100">
                                        <Card.Body>
                                        <div className="row">
                                                    <div className="col-6">
                                                        <p className="h4 mt-3"><b>Auto Pay Status</b></p>
                                                        <p className="mb-1">{this.state.autoPaynEStateStatus?.autoEnrollStatus}</p>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-end align-items-center">
                                                        {sessionStorage.getItem("danielsAutopayAccess") == "true" && this.state.autoPaynEStateStatus?.autoEnrollStatus != undefined ? 
                                                        <button className="btn bg-secondary text-primary pl-5 pr-5" onClick={() => this.triggerTender("DanielsAutoPay")} disabled={(localStorage.getItem("magix_account_no") !== "") ? false : true}>
                                                            {this.state.autoPaynEStateStatus?.autoEnrollStatus?.toLowerCase() == "not enrolled" ? "ENROLL" : "UPDATE"}</button>
                                                        : null}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <p className="h4 mt-3"><b>E-Statement Enrollment Status</b></p>
                                                        <p className="mb-1">{this.state.autoPaynEStateStatus?.eStatementStatus}</p>
                                                    </div>
                                                    {sessionStorage.getItem("danielsEStatement") == "true" && this.state.autoPaynEStateStatus?.eStatementStatus != undefined ? 
                                                        <div className="col-12 col-md-6 d-flex justify-content-center justify-content-md-end align-items-center">
                                                            <button className="btn bg-secondary text-primary pl-5 pr-5 mt-3 mt-md-0" onClick={() => this.triggerTender("DanielsEStatement")} >
                                                                {this.state.autoPaynEStateStatus?.eStatementStatus?.toLowerCase() == "not enrolled" ? "ENROLL" : "UPDATE"}
                                                            </button>
                                                        </div>
                                                    : null }
                                                </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            {this.state.offerAcceptedStateHavingInsurnace || this.state.alredayInsuranceEnrolled ?
                            <div className="row">
                                  {(this.state.isInsuranceEnable && this.state.optForInsurance !== "")?
                                <div className="col-12 d-flex">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-4 shadow w-100">
                                        <Card.Body>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={faShieldAlt} className="text-secondary" size="2x" />
                                                <p className="h4 mb-0 pl-3 text-secondary">Credit Insurance</p>
                                            </div>
                                            <div className="row mt-3">
                                                    <div className="col-12 col-md-8 col-lg-10">
                                                        {this.state.optForInsurance === "Enroll" ?
                                                            <p className="mb-0">Your current credit approval has an option to add <b>Credit Insurance</b> to protect your purchase.</p>
                                                            :
                                                            this.state.optForInsurance === "Declined" || this.state.optForInsurance === "Not Enrolled" ?
                                                                <>
                                                                    <p className="mb-0">Your current credit approval has an option to add <b>Credit Insurance</b> to protect your purchase.</p>
                                                                    <div className="row ">
                                                                        <div className="col-6 col-md-4 col-lg-3 mt-3 border-right">
                                                                            <p><b>Enrollment Status</b></p>
                                                                            <p>{this.state.optForInsurance}</p>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {this.state.optForInsurance === "claimReqRaised" ? <p className="mb-0">File a cliam request has been raised.</p> : this.state.optForInsurance === "cancelReqRaised" ? <p className="mb-0">Insurance cancellation request has been raised.</p> : <p className="mb-0">Your current Daniels offer is protected by Credit Insurance.</p>}
                                                                    <div className="row ">
                                                                        <div className="col-6 col-md-4 col-lg-3 mt-3 border-right">
                                                                            <p><b>Enrollment Status</b></p>
                                                                            <p>Enrolled</p>
                                                                        </div>
                                                                        <div className="col-6 col-md-4 col-lg-3 mt-3 border-right pl-md-4">
                                                                            <p><b>Plan</b></p>
                                                                            {this.state.planValue?.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <p>{item.plan_type}</p>
                                                                                    </>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                   
                                                        <div className="col-12 col-md-4 col-lg-2 d-flex flex-column mt-3 mt-md-0">
                                                            {this.state.optForInsurance === "Enroll" || this.state.optForInsurance === "Declined" || this.state.optForInsurance === "Not Enrolled" ?
                                                                (this.state.offerAcceptedStateHavingInsurnace ?
                                                                <button
                                                                    className="btn bg-secondary text-primary w-100 my-auto"
                                                                    onClick={() => this.triggerInsurance()}
                                                                >
                                                                    ENROLL
                                                                </button>
                                                                : null )
                                                            : 
                                                            <>
                                                                {/* {this.state.optForInsurance === "cancelReqRaised" ?
                                                                    <button 
                                                                        className="btn bg-secondary text-primary w-100 my-auto"
                                                                        onClick={() => this.setState({claimOrCancelContent : "cancelReqFinalized"},() => {
                                                                            if(this.state.permission_status.canAdd === true || this.state.permission_status.canEdit === true) {
                                                                                this.fileAClaimOrCancelEnroll()
                                                                            }
                                                                        })}
                                                                         disabled={!(this.state.permission_status.canAdd === true || this.state.permission_status.canEdit === true)}
                                                                    >
                                                                        Finalize Cancellation
                                                                    </button>
                                                                : */}
                                                                {this.state.isDanielsCancelEnrollment?
                                                                <button className="btn bg-secondary text-primary w-100 my-auto" 
                                                                    // style={{ backgroundColor: "#E5D7EC" }}
                                                                    onClick={() => this.handleClaimAndCancel("cancelReqRaised")}
                                                                    // disabled={this.state.optForInsurance === "claimReqRaised" || this.state.optForInsurance === "cancelReqRaised"}
                                                                >
                                                                    Cancel Enrollment
                                                                </button>:""
                                                                }
                                                                {this.state.offerAcceptedStateHavingInsurnace && this.state.alredayInsuranceEnrolled ?
                                                                
                                                                (this.state.isDanielsFileAClaim?
                                                                    <>
                                                                    <p></p>
                                                                <button 
                                                                    className="btn bg-secondary text-primary w-100 my-auto" 
                                                                    onClick={() => this.handleClaimAndCancel("claimReqRaised")}
                                                                    disabled={this.state.optForInsurance === "claimReqRaised" || this.state.optForInsurance === "cancelReqRaised"}
                                                                >
                                                                    File a claim
                                                                </button></>:"") : null }
                                                                </>
                                                            }
                                                        </div>
                                                        
                                                </div>
                                        </Card.Body>
                                    </Card>
                                </div>:null}
                            </div>:null}
                        </div>
                        <FortivaErrorDialog
                            show={this.state.isSummary}
                            handleModalPopup={(event)=>this.setState({isSummary:!this.state.isSummary})}
                            message={`<span class=\"mb-0 \">No Account found</span>`}
                            submitFortiva={(event)=>this.setState({isSummary:!this.state.isSummary})}
                        />

                        <Modal show={this.state.insuranceCancelPopUp}>
                            <Modal.Header className="align-items-center">
                                <b className="mr-auto text-secondary h4">Info</b><FontAwesomeIcon icon={faTimesCircle} className="ml-auto" style={{cursor : "pointer"}} size="2x" onClick={() => {this.setState({insuranceCancelPopUp : false})}}/>
                            </Modal.Header>
                            <Modal.Body>
                                <p>Your request to cancel credit insurance enrollment has been sent to customer service. The enrollment status will be updated when it is completed.</p>
                                <button className="float-right btn bg-secondary text-primary" onClick={() => {this.setState({insuranceCancelPopUp : false})}}>Ok</button>
                            </Modal.Body>
                        </Modal>

                    </main>
                </div>

                <TenderModal 
                    showTender = {this.state.showTender}
                    triggerTender = {this.triggerTender}
                    currentTender = {this.state.currentTender}
                    currentTabAccnStore = {this.state.currentTabAccnStore}
                />
                <ClaimAndCancel 
                    showCancelAndClaimPopup={this.state.showCancelAndClaimPopup}
                    content={this.state.claimOrCancelContent}
                    handleClaimAndCancel={this.handleClaimAndCancel}
                    reason= {this.state.reason}
                    onChange={this.onChange}
                />
                 {this.state.insurance ?
                <Insurance
                    from="summary"
                    insurance={this.state.insurance}
                    triggerInsurance={this.triggerInsurance}
                    partnerType = "Daniels" 
                    offerAcceptedState={this.state.offerAcceptedState} />
                : null}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(customerDetailsActions, dispatch),
        userRightsactions: bindActionCreators(userRightsActions, dispatch),
        insuranceAction: bindActionCreators(insuranceActions, dispatch),
        adminActions: bindActionCreators(adminActions, dispatch),
        customerLooUpActions:bindActionCreators(customerLooUpActions,dispatch)
    }

}
export default connect(null, mapDispatchToProps)(AccountSummary);