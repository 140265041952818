import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../../redux/_http.methods";

export function getInsurancePlans(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: obj,
      ResourceUrl: 
        obj === undefined 
        ? "v1/customers/Get_insuranceplans_statewise"
        : "v1/customers/Get_insuranceplans_statewise?state="+obj.state+"&dob="+obj.dob+"&insurance_partner="+obj.partner
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function enrollInsurace(obj) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Post(),
        Resource: obj,
        ResourceUrl: "v1/customers/update_insurance",
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function getInsurancePlanValue(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Get(),
            Resource: obj,
            ResourceUrl:
                "v1/customers/Get_insuranceplan_value?customer_id="+obj.cust_Id+"&financial_partner="+obj.partner_type,
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }

  export function updateInsurancePlans(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl:
                "v1/customers/update_insurance-value",
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }
  
  export function createDanielsAccountNumber(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl:
                "v1/customers/CreateDanielAccountNumber?storeCode="+obj.storeCode+"&CustomerId="+obj.customerId+"&applicationId="+obj.applicationId,
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }
  
  export function getCreditDirectorData(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl:"v1/magix/CreditDirector_GetData?storeCode",
            isMagix:true
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }
  
  export function getDanielsAccountInfo(obj) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: obj,
        ResourceUrl:
        obj.applicationId === null ?
        "v1/customers/get-DanielsData?customer_id="+obj.customerId
        :
        "v1/customers/get-DanielsData?customer_id="+obj.customerId+"&application_id="+obj.applicationId,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function CreditDirectorSaveData(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl:"v1/customers/CreditDirector_SaveData?application_Id="+obj.application_Id+"&customer_Id="+obj.customer_Id,
           
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }

  export function createCSSAccount(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl:"v1/customers/CSS_Account_Creation",
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }

  export function enableDisableInsurancestate(obj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: obj,
            ResourceUrl:"v1/customers/isEnabledInsuranceState?state="+obj.state+"&insurance_Partner="+obj.partnerType+"&isEnabled="+obj.isActive,
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
  }