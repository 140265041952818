import React, { Component } from "react";
import { Card, Toast, ToastContainer } from "react-bootstrap";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import './component.adminUserRights.css';
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleCheck } from "@fortawesome/free-solid-svg-icons";

class AdminUserRights extends Component {
    constructor(){
        super();
        this.state={
            toggled: false,
            roles : [],
            sitePageDetails: [],
            sitePages: [],
            userRights: [],
            toastModal: false,
            obj : [],
            changed: false,
            permission_status: null
        };
    }

    componentDidMount() {
        if(sessionStorage.getItem("isLoggedIn") === "true") {
        trackPromise(
            this.props.actions.getUserRights().then(
                response => {
                    if(response) {
                        this.setState({
                            roles: response.roles, 
                            sitePageDetails: response.sitePageDetails, 
                            sitePages : response.sitePages, 
                            userRights : response.userRights
                        });
                        this.getPermissions()
                    }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
        )    
    }
    else 
      this.props.history.push( "/");    
    }

    getPermissions() {
        let role_name = localStorage.getItem('role_name'), page_name = 'User Rights'
        const getsitepages = this.state.sitePages.filter((site_pages) => { return site_pages.name === 'Admin Page' })
        const getSitepagedetails = this.state.sitePageDetails.filter((sitePageDetails) => { return sitePageDetails.sitePage_id ===getsitepages[0].id && sitePageDetails.name === page_name})
        const getRole = this.state.roles.filter((roles) => { return roles.name.toLowerCase() === role_name.toLowerCase() });
        let role_id = getRole[0].id;
        const role_permission_check = this.state.userRights.filter((user_rights) => { return user_rights.sitePageDetail_id === getSitepagedetails[0].id && user_rights.role_id === role_id});
        this.setState({ permission_status: role_permission_check[0] })
    }

    onChange(sitePageDetail_id,role_id,checkedBox,updatedBy) {
        var indexId = 0 ;
        var flag = 0;
        let view=false;
        let add=false;
        let edit=false;
        let del = false;
        let createdBy="";
        this.state.userRights.map((val, index) => {
            if(val.sitePageDetail_id === sitePageDetail_id && val.role_id === role_id) {
                let temp_state = [...this.state.userRights];
                let temp_element = { ...temp_state[index] };
                if(checkedBox === "canView") {
                    temp_element.canView=!temp_element.canView;
                }
                else if(checkedBox === "canAdd") {
                    temp_element.canAdd=!temp_element.canAdd;
                }
                else if(checkedBox === "canEdit") {
                    temp_element.canEdit=!temp_element.canEdit;
                }
                else if(checkedBox === "canDelete") {
                    temp_element.canDelete=!temp_element.canDelete;
                }
                view=temp_element.canView;
                add=temp_element.canAdd;
                edit=temp_element.canEdit;
                del=temp_element.canDelete;
                temp_element.updatedBy = updatedBy;
                temp_state[index] = temp_element;
                this.setState({
                    userRights : temp_state
                });
                flag = 1 ;
                indexId= temp_element.id; 
            }
        });
        if(flag === 0) {
            if(checkedBox === "canView") {
                view=true;
            }
            else if(checkedBox === "canAdd") {
                add=true;
            }
            else if(checkedBox === "canEdit") {
                edit=true;
            }
            else if(checkedBox === "canDelete") {
                del=true;
            }
            this.setState({
                userRights:[...this.state.userRights, {
                    id: 0, sitePageDetail_id : sitePageDetail_id, role_id : role_id, canView : view, canAdd : add, canEdit : edit, canDelete : del, createdBy : createdBy, updatedBy : updatedBy 
                }]
            });
        }        
        if(this.state.obj.length === 0 ) {
            this.setState({
                obj:[...this.state.obj, {
                    id: indexId, sitePageDetail_id : sitePageDetail_id, role_id : role_id, canView : view, canAdd : add, canEdit : edit, canDelete : del, createdBy : createdBy, updatedBy : updatedBy 
                }]
            });
        }
        else {
            var flaged = 0;
            this.state.obj.map((val, index) => {
                if(val.sitePageDetail_id === sitePageDetail_id && val.role_id === role_id) {
                    let temp_state = [...this.state.obj];
                    let temp_element = { ...temp_state[index] };
                    if(checkedBox === "canView") {
                        temp_element.canView=!temp_element.canView;
                    }
                    else if(checkedBox === "canAdd") {
                        temp_element.canAdd=!temp_element.canAdd;
                    }
                    else if(checkedBox === "canEdit") {
                        temp_element.canEdit=!temp_element.canEdit;
                    }
                    else if(checkedBox === "canDelete") {
                        temp_element.canDelete=!temp_element.canDelete;
                    }
                    temp_state[index] = temp_element;
                    this.setState({
                        obj : temp_state
                    });
                    flaged = 1; 
                }
            });
            if(flaged === 0) {
                this.setState({
                    obj:[...this.state.obj, {
                        id: indexId, sitePageDetail_id : sitePageDetail_id, role_id : role_id, canView : view, canAdd : add, canEdit : edit, canDelete : del, createdBy : createdBy, updatedBy : updatedBy 
                    }]
                });
            }
        }
        this.setState({
            changed : true
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let obj = {
            userRights : this.state.obj
        }
        trackPromise(
            this.props.actions.saveUserRights(obj).then(
                response => {
                    if(response) {
                        this.setState({userRights : response,  toastModal: true, obj :[] });
                        window.location.reload();
                    }
                }).catch(err => {
                    APIFailedMessage.displayError(err);
            })
        )
    }

    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
      };

    render()
    {
        let adminMenus = [];
        adminMenus = localStorage.getItem("sitePageDetails");
        adminMenus = JSON.parse(adminMenus);
        
        var startIndexOfRow=[1,4,7,10,13,16,19,22,25,28,31]; 
        const updatedBy = localStorage.getItem('loggedUserEmail');
        var count = 0;
        let canView=false;
        let canAdd=false;
        let canEdit=false;
        let canDelete = false;
        return(
            <>
            <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                <Sidebar
                    toggled={this.state.toggled}
                    handleToggleSidebar={() => {
                    this.handleToggleSidebar();
                    }}
                    isAdmin={true}
                    adminMenus={adminMenus}
                />
                <main>
                    <CustomNavbar
                        handleToggleSidebar={() => {
                            this.handleToggleSidebar();
                        }}
                        isAdmin={true}
                    />
                    <ToastContainer className="p-3" position="top-end">
                        <Toast bg="success" onClose={() => {this.setState({toastModal : !this.state.toastModal})}} show={this.state.toastModal} delay={3000} animation autohide>
                            <Toast.Body className="text-white ml-2 mr-2"><FontAwesomeIcon icon={faCircleCheck} className="pr-2"/>Updated successfully!</Toast.Body>
                        </Toast>
                    </ToastContainer>
                    <div className="row m-2 mt-4 mb-4">
                        <div className="col-12">
                            <p className="h4"><b>User Rights</b>
                            {!this.state.permission_status?.canAdd && !this.state.permission_status?.canEdit ?
                                null :
                                <button
                                    className="btn bg-secondary float-right text-primary pl-4 pr-4"
                                    disabled={this.state.changed === false} onClick={(event) => this.handleSubmit(event)}>
                                        SAVE
                                </button>
                                }
                            </p>
                        </div>
                        <div className="w-100">
                            <div className="col-12 mt-3">
                                {this.state.sitePages.map((page) => (
                                    <Card className={`cardBorder ${page.id !== 1 ? "mt-4" : ""}`}>
                                        <span className="d-none">{count=0}</span>
                                        <Card.Header className="bg-secondary text-primary">
                                            <p className="h5 mb-1 mt-1"><b>{page.name}</b></p>
                                        </Card.Header>
                                        <Card.Body className="p-0">
                                                <div className="wrapper">
                                                {this.state.sitePageDetails.map((pageDetails) => (
                                                    pageDetails.sitePage_id === page.id ?
                                                        (++count , <div className={`col-12 ${count == 1 ? "col-lg-6" : "col-lg-3"}`}>
                                                        <div className="row pt-4 bg-primary pb-3">
                                                            <div className={`${count == 1 ? "col-3 col-lg-6 pr-0 border-right" : "d-none"}`}>
                                                                <b className="text-secondary">Role</b>
                                                            </div>
                                                            <div className={`${count ==1  ? "col-9 col-lg-6 border-right" : "col-12 offset-lg-0 col-lg-12 border-right"}`}>
                                                                <p className="text-center text-secondary"><b>{pageDetails.name}</b></p>
                                                                <div className="row">
                                                                    <div className={`pr-1 ${ page.name !== "Admin Page" ? " pl-1 col-4" : " pl-0 col-3" }`}>
                                                                        <p className="customBorder w-100 pl-0 pr-0 pt-2 pb-2 text-center">View</p>
                                                                    </div>
                                                                    <div className={`pr-1 ${ page.name !== "Admin Page" ? " pl-1 col-4" : " pl-0 col-3" }`}>
                                                                        <p className="customBorder w-100 pl-0 pr-0 pt-2 pb-2 text-center">Add</p>
                                                                    </div>
                                                                    <div className={`pr-1 ${ page.name !== "Admin Page" ? " pl-1 col-4" : " pl-0 col-3" }`}>
                                                                        <p className="customBorder w-100 pl-0 pr-0 pt-2 pb-2 text-center">Edit</p>
                                                                    </div>
                                                                    <div className={`pr-1 ${ page.name !== "Admin Page" ? " d-none" :  " pl-0 col-3" }`}>
                                                                        <p className="customBorder w-100 pl-0 pr-0 pt-2 pb-2 text-center">Delete</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.roles.map((role) => (
                                                            <>
                                                            <div className={`row colorSwift pt-4 border-bottom pb-4 ${count == 1 ? "" : "pb-md-5"}`}>
                                                                    <div className={`col-3 pr-0 border-right ${count == 1 ? "col-lg-6" : " d-none"}`}>
                                                                        <p>{role.name}</p>
                                                                    </div>
                                                                    <div className={`border-right  ${count == 1 ? "col-9 col-lg-6" : "col-12 col-lg-12"}`}>
                                                                        <div className="row">
                                                                        {
                                                                            (canView=false,canAdd=false,canEdit=false,canDelete = false)
                                                                        }
                                                                        {this.state.userRights.map((val) => {
                                                                            if(val.sitePageDetail_id === pageDetails.id && val.role_id === role.id) {
                                                                                canView=val.canView;
                                                                                canAdd=val.canAdd;
                                                                                canEdit=val.canEdit;
                                                                                canDelete = val.canDelete;
                                                                            }
                                                                        })}
                                                                        <div className={`text-center ${ page.name !== "Admin Page" ? " col-4" : " col-3" }`}>
                                                                                        <div className="form-group form-check">
                                                                                            <input type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id=""
                                                                                            onChange={() => this.onChange(pageDetails.id, role.id, "canView", updatedBy)}
                                                                                            checked={canView}
                                                                                            disabled = { 
                                                                                                !this.state.permission_status?.canAdd && !this.state.permission_status?.canEdit ? true : false
                                                                                             }
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`text-center ${ page.name !== "Admin Page" ? " col-4" : " col-3" }`}>
                                                                                        <div className="form-group form-check">
                                                                                            <input type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id=""
                                                                                            onChange={() => this.onChange(pageDetails.id, role.id, "canAdd", updatedBy)}
                                                                                            checked={canAdd}
                                                                                            disabled = { 
                                                                                                !this.state.permission_status?.canAdd && !this.state.permission_status?.canEdit ? true : false
                                                                                             }
                                                                                             />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`text-center ${ page.name !== "Admin Page" ? " col-4" : " col-3" }`}>
                                                                                        <div className="form-group form-check">
                                                                                            <input type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id=""
                                                                                            onChange={() => this.onChange(pageDetails.id, role.id, "canEdit", updatedBy)}
                                                                                            checked={canEdit}
                                                                                            disabled = { 
                                                                                                !this.state.permission_status?.canAdd && !this.state.permission_status?.canEdit ? true : false
                                                                                             }
                                                                                             />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={`text-center ${ page.name !== "Admin Page" ? " d-none" : " col-3" }`}>
                                                                                        <div className="form-group form-check">
                                                                                            <input type="checkbox"
                                                                                            className="form-check-input"
                                                                                            id=""
                                                                                            onChange={() => this.onChange(pageDetails.id, role.id, "canDelete", updatedBy)}
                                                                                            checked={canDelete}
                                                                                            disabled = { 
                                                                                                !this.state.permission_status?.canAdd && !this.state.permission_status?.canEdit ? true : false
                                                                                             }
                                                                                             />
                                                                                        </div>
                                                                                    </div>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            </>
                                                        ))}
                                                        </div>)
                                                : 
                                                    null
                                                ))}
                                                </div>
                                        </Card.Body>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            </>
        );
    }
}
const mapDispatchToProps=(dispatch)=>{
 return {
  actions:bindActionCreators(userRightsActions,dispatch)
 } 
}
export default connect(null,mapDispatchToProps)(AdminUserRights);