import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/react-pro-sidebar/dist/css/styles.css";
import Routes from "./router/routes";
import Loader from "react-loader-spinner";
import { usePromiseTracker } from "react-promise-tracker";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CacheBuster from 'react-cache-buster';
import data from '../package.json';

const LoadingIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();
    return (
        promiseInProgress && (
            <div
                style={{
                    position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Loader type="ThreeDots" color="#5C068C" height="100" width="100" />
            </div>
        )
    );
};

function App(props) {
    const {promiseInProgress}=usePromiseTracker();
  return (
    <CacheBuster
      currentVersion={data.version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<LoadingIndicator />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
        <div id="App" className={`${promiseInProgress ? 'overlay':'without_overlay'}`}>
            <Routes />
            <LoadingIndicator />
            <ToastContainer  limit={1} theme="colored"/>
        </div>
    </CacheBuster>
  );
}

export default App;


