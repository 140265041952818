import { Component } from "react";
import { bindActionCreators } from "redux";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import { connect } from "react-redux";
import Sidebar from "../../components/component.customSideBar";
import CustomNavbar from "../../components/component.customNavbar";
import { Card } from "react-bootstrap";
import "./component.applicationReqResponse.css";
import * as creditApplication from "../../redux/actions/creditApplication.actions";
import BootstrapTable from "react-bootstrap-table-next";
import { trackPromise } from "react-promise-tracker";



class CreditApplicationReqResponse extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      applicationIdList:[],
      searchObj: {
        phoneNo: "",
        customerID: "",
        applicationId: "",
      },   
      noDataFound: false,
      selctedAppId:"",
      reqResponse:[]
    };
  }
 
  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }

  onChange(event) {
    event.preventDefault();
    if (event.target.name === "phoneNo") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, phoneNo: event.target.value },
        });
      }
    } else if (event.target.name === "customerID") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, customerID: event.target.value },
        });
      }
    } else if (event.target.name === "applicationId") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, applicationId: event.target.value },
        });
      }
    }else if (event.target.name === "cmbAppId") {
      this.setState({selctedAppId: event.target.value });
    }
  }

  onSubmit() {
   let Ids=[];
   let obj={
    customer_id:this.state.searchObj.customerID,
    application_id:this.state.searchObj.applicationId,
    phone:this.state.searchObj.phoneNo
   }
   this.setState({selctedAppId:"",reqResponse:[],applicationIdList:[]});
   trackPromise(
    this.props.creditApplication.getApplicationIds(obj).then(
    response=>{
          if(response){
            if(response.length>0){
              Ids=response.map(item=>{return item});
              this.setState({applicationIdList:Ids,noDataFound:false});
            }
            else{
              this.setState({noDataFound:true});
            }
        }
      })
    )
  }

  GetreqResp() {
    trackPromise(
      this.props.creditApplication.getApplicationReqResp(this.state.selctedAppId).then(
      response=>{
          if(response){
            if(response.length>0)
              this.setState({reqResponse:response,noDataFound:false});
            else
              this.setState({noDataFound:true});
          }
      })
    )
   }

  render() {
    const columns = [
      {
        dataField: "financialPartner",
        text: "Financial Partners",
        headerClasses: "partnerCol",
      },
      {
        dataField: "request",
        text: "Request",
        sort: false,
      },
      {
        dataField: "response",
        text: "Response",
      }
      
    ];
    return (
      <>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={this.state.sitePageDetails}
          />
          <main>
            <CustomNavbar
              handleToggleSidebar={() => {
                this.handleToggleSidebar();
              }}
              isAdmin={true}
            />
            <div id="main" className="container-fluid">
              <div className="row pl-1 pr-1 mt-4 mb-4">
                <div className="col-12 mb-2">
                  <h4 className="ml-1"><b>Credit Application Req/Resp</b></h4>
                </div>
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="row">
                          <div className="col-12 col-md-3 searchDiv">
                            <div className="form-group ml-2 mr-2">
                              <label htmlFor="phoneNumber" className="text-secondary font-weight-bold">Phone Number</label>
                              <input
                                type="text"
                                className="form-control border-dark"
                                placeholder="Phone Number"
                                name="phoneNo"
                                value={this.state.searchObj.phoneNo}
                                onChange={(event) => this.onChange(event)}
                               
                              />
                            </div>
                            <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                          </div>
                          <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">
                            <div className="form-group w-100 ml-2 mr-2">
                              <label htmlFor="ssn" className="text-secondary font-weight-bold">Application ID</label>
                              <input
                                type="text"
                                className="form-control border-dark"
                                placeholder="Application ID"
                                name="applicationId"
                                value={this.state.searchObj.applicationId}
                                onChange={(event) => this.onChange(event)}
                                
                              />
                            </div>
                            <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                          </div>
                          <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">

                            <div className="form-group w-100 ml-2 mr-2">
                              <label htmlFor="ssn" className="text-secondary font-weight-bold">Customer ID</label>
                              <input
                                type="text"
                                className="form-control border-dark"
                                placeholder="Customer ID"
                                name="customerID"
                                value={this.state.searchObj.customerID}
                                onChange={(event) => this.onChange(event)}
                              
                              />
                            </div>
                        
                          </div>
                          <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                            <button
                              className="btn bg-secondary text-primary w-75 mt-4"
                              disabled={
                                this.state.searchObj.phoneNo?.length > 9 ||
                                  this.state.searchObj.applicationId?.length > 0 ||
                                  this.state.searchObj.customerID?.length > 0
                                  ? false
                                  : true
                              }
                              onClick={() => this.onSubmit()}
                            >
                              Search
                            </button>
                          </div>
                      </div>
                      {this.state.applicationIdList.length>0?
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-3">
                              <div className="form-group text-left mt-md-2">
                                  <label className="text-secondary font-weight-bold">Application ID</label>
                                  <select id="cmbAppId" name="cmbAppId" className="form-select p-2" onChange={(event) => this.onChange(event)}>
                                      <option value="0">-- Select --</option>
                                      {this.state.applicationIdList?.map((item) => <option value={item.application_Id} selected={item.application_Id == this.state.selctedAppId ? "selected" : ""}>{item.application_Id}</option>)}
                                  </select>
                              </div>
                          </div>
                          <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">
                            <button
                              className="btn bg-secondary text-primary w-75 mt-4"
                              disabled={
                                  (this.state.selctedAppId?.length > 0 && this.state.selctedAppId!='0')
                                  ? false
                                  : true
                              }
                              onClick={() => this.GetreqResp()}
                            >
                              Get Req/Resp
                            </button>
                          </div>
                      </div>:""}
                    </Card.Body>
                  </Card>
                </div>
              </div>
                <div className="col-12 mt-4">
                  <>
                    <div id="cardrow" className="row">
                      {this.state.reqResponse.length>0 &&
                        this.state.reqResponse?.map((val) => (
                          <div
                            className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                            key={val.customer_Id}
                          >
                            <Card className="text-left shadow">
                              <Card.Body>
                                <p className="h3 ml-0 mt-2 mb-0">
                                {val.financialPartner}
                                </p>
                                <hr ></hr>
                                <div className="row">
                                  <div className="col-12 mb-1 font-weight-bold">Request :</div>
                                  <div className="col-12 mb-2"> {val.request}</div>
                                  <div className="col-12 mb-1 font-weight-bold">Response :</div>
                                  <div className="col-12">{val.response}</div>
                                </div>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                    </div>
                        {this.state.reqResponse.length>0?
                          <div className="masterCustomerTable reqRespTable">
                            <BootstrapTable
                              className="pagination"
                              bootstrap4
                              hover
                              bordered={false}
                              condensed={true}
                              keyField="customer_Id"
                              data={this.state.reqResponse}
                              columns={columns}
                            />
                        </div>:
                        (this.state.reqResponse.length==0 && this.state.noDataFound==true)&&
                          <NoDataIndication/>
                        }
                  </>
              </div>
          </div>
          </main>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    userRightAction: bindActionCreators(userRightsActions, dispatch),
    creditApplication: bindActionCreators(creditApplication, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CreditApplicationReqResponse);


const NoDataIndication = () => {
  return(
    <div className="row mt-4 ml-2 mr-2">
        <div className="col-12">
          <Card>
            <Card.Body>
                <p className="text-secondary font-weight-bold text-center">No Data found </p>
            </Card.Body>
          </Card>
        </div>
    </div>
  ) 
};