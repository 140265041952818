export const AZDisclosure = (partnerType, toPrint) => {
    return (
        "<table class='print-component'>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        "<div class='mt-4'>" +
        "<h4 class='text-center mb-1' style='text-align:center !important'><b>CONSUMER INSURANCE SUMMARY</b></h4>" +
        "<p class='text-center' style='text-align:center !important'>(LIFE, DISABILITY, INVOLUNTARY UNEMPLOYMENT, PROPERTY INSURANCE)</p>" +
        "<p style='text-align:justify'>This is a summary of the coverages available <b>not an application for insurance</b>. A separate application for insurance must be completed and signed to obtain coverage. The purchase of this coverage is <b>optional and is not required to obtain credit approval</b>.</p>" +
        "<p style='text-align:justify'>An application for coverage will be provided dependent upon the product indicated on this summary. Customers must complete the application(s) and meet the eligibility requirements to obtain coverage.</p>" +
        "<p class='pl-5 pr-5'><b><u>Gold Plan includes: Life, Disability, Involuntary Unemployment and Property Insurance</u><u> Silver Plan includes: Life, Disability and Property Insurance</u><u> Bronze Plan includes: Property Insurance</u></b></p>" +
        "<div class='pl-5 pr-5'>" +
        "<p class='mb-0'><b>Insurers:</b> Minnesota Life Insurance Company, Securian Casualty Company</p>" +
        "<p class='mb-0'><b>Administrator:</b> Summit Administrators 800-275-3414</p>" +
        (partnerType === 'Fortiva' ? "<p class='mb-0'><b>Creditor:</b> Bank of Missouri as issuer of credit arranged by Fortiva Retail Credit on behalf of Daniel’s Jewelers</p>" : "<p class='mb-0'><b>Creditor: Daniel’s Jewelers</b></p>") +
        "<p><b>Agent License Number: 0F23531</b></p>" +
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Eligibility – Gold, Silver, and Bronze</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Life and Disability Eligibility Conditions.</b> You are eligible for insurance if you have not reached your 70th birthday for life, 65th birthday for disability. You are eligible for insurance as to each advance only if you, on the date you sign for the advance, are working for wages or profit 30 hours or more per week and have been so working for 30 days or more immediately prior to the date of the advance. If you are not, that particular advance will not be insured until you meet this requirement.</li>" +
        "<li style='text-align:justify'><b>Unemployment Eligibility Conditions.</b> To be eligible for loss of employment benefits, as of the effective date of the Certificate, You must be insured under the Plan and gainfully employed on a full-time basis and in a nonseasonal occupation. Loss of income due to retirement is not covered.</li>" +
        "<li style='text-align:justify'><b>Property Eligibility Conditions.</b> Property coverage covers the interest of the Creditor and/or the Debtor as outlined in the Certificate Declarations page, master policy and specific endorsements.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Life Benefit – Gold and Silver</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Single Life Insurance Benefit.</b> If you die while you are insured for single life coverage, we will pay the amount of insurance in force at the time of your death after we receive proof of your death.</li>" +
        "<li style='text-align:justify'><b>Joint Life Insurance Benefit.</b> If you or your co-borrower die while insured for joint life coverage, we will pay the amount of insurance in force at the time you or your co-borrower dies after we receive proof of the death. Only one death benefit is payable under the policy.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Disability Benefit – Gold and Silver</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Total Disability Insurance Benefit.</b> If you are insured for total disability insurance, we will pay a benefit if you file written proof that you became totally disabled while insured and continue to be totally disabled for more than 14 days. Payment will be calculated from the 1st day of disability. Payments will stop when you are not totally disabled anymore, when your loan with the creditor is paid off or you reach age 66, whichever comes first. The monthly disability benefit will be your required monthly loan payment or the Maximum Monthly Disability Benefit, whichever is less. For a period of continuous total disability less than one month in duration, a benefit in the amount of 1/30th of the monthly disability benefit for each day of continuous total disability.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Unemployment Benefit – Gold</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Unemployment Benefit.</b> We will pay the minimum monthly payment on Your account. After the 30-day waiting period, benefits will be paid retroactively to the first day; and while the unemployment continues.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Property Benefit – Gold, Silver, and Bronze</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Property Benefit.</b> In the event of a loss, our liability will be the lesser of the cost to repair or replace the property or the original sales price. A deductible may apply if indicated on the Declarations Page and, if applicable, will apply on a per occurrence basis. At an additional premium, coverage for loss or damage may be extended to include: Mechanical Breakdown, Worldwide coverage, Theft, Burglary and Robbery, Waiver of Outstanding Balance, Additional Living Expenses for temporary living quarters, Rental Expense Reimbursement for rental of replacement property resulting from loss or damage to insured property because of a Peril Insured Against and other additional perils. Our liability will not exceed for the first 60 days after enrollment for insurance, an amount up to the highest balance in the debtor Revolving Agreement during that 60-day period. After the first 60 days after enrollment, an amount up to the highest balance on which a premium has been assessed during the 36 months before the date of loss. At an additional premium, the settlement options may be amended to include replacement cost rather than actual cash value.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Amount of Life Insurance – Gold and Silver</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'>The amount of life insurance is the total amount due on the loan (excluding unearned finance charges), outstanding on the date of the insured borrower’s death. But we will not pay more than the Maximum Amount of Life Insurance.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>When Insurance Stops or Termination – Gold, Silver, and Bronze</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Single or Joint Life and Disability – When Insurance Stops.</b> This insurance stops: on the first billing date after the creditor receives your written request to stop the insurance; or on the first billing date after you withdraw your authorization for the addition of charges for the insurance to your loan; or on the first billing date after you reach your 70th birthday for life, 66th birthday for disability; or on the date your loan stops; or on the billing date upon which you are more than 90 days delinquent in making a minimum monthly payment on your loan; or, upon at least 30 days written to you, on the date the Group Policy stops.</li>" +
        "<li style='text-align:justify'><b>Chargegard IUI – Termination.</b> Your individual Certificate will be automatically cancelled on the first billing date after the Creditor receives Your written request to stop the insurance or if earlier; or billing date upon which You are more than 90 days past due in making the required account payment; or date Your account is terminated; or date the Master Policy is cancelled.</li>" +
        "<li style='text-align:justify'><b>CP2000 – Termination.</b> Coverage ends when the first of the following occurs: the expiration date shown on the Declarations Page or the loan is paid off. Coverage will automatically terminate on the date you are more than 90 days late making the required account payment; or your account is terminated.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Exclusions – Gold, Silver, and Bronze</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Life Exclusions.</b> We will not pay a claim for an advance on your loan if you commit suicide within 6 months after the date of the advance, but we will return the life insurance charge on that advance; or as to each advance on your loan, if you die within 6 months after the date of the advance as a result of a preexisting medical condition. A preexisting medical condition is one for which you received or had medical treatment, advice or diagnostic tests either for that same condition or a related condition within the six-month period immediately prior to the date of the advance. This also applies to your co-borrower, if you applied for joint life coverage.</li>" +
        "<li style='text-align:justify'><b>Total Disability Exclusions.</b> We will not pay a claim or refund the disability insurance charge if your disability is a result of normal pregnancy; or intentionally self-inflicted injury; or as to each advance on your loan, begins within 6 months after the date of the advance as a result of a preexisting medical condition. A preexisting medical condition is one for which you received or had medical treatment, advice or diagnostic tests either for that same condition or a related condition within the six-month period immediately prior to the date of the advance.</li>" +
        "<li style='text-align:justify'><b>Property Exclusions.</b> Coverage will not be provided against loss or damage: due to frost, cold weather, ice, snow or sleet; due to deterioration, unless it results from insured perils; to motor vehicles, land conveyances, farm equipment, aircrafts or boats and their equipment; due to mechanical or electrical breakdown; caused by your neglect to protect property during and after any covered loss to the extent of your neglect; caused by or resulting from: a) hostile or warlike action in time of peace or war, by any government or sovereign power, their military authority or agent, or by any armed forces; b) action taken by governmental authority in hindering, combating or defending against such an occurrence; c) seizure or destruction under quarantine or Customs regulations; or d) contraband or illegal transportation or trade; by nuclear reaction, nuclear radiation, or radioactive contamination; to antiques or any unique articles. We do not insure the antiquity, uniqueness, aesthetic or sentimental value of such article(s); to electronic apparatus accessories, television and radio antennas and outside wiring; or to accounts, currency, securities, deeds, evidence of debt, records, books of record, manuscripts, bullion or stamp and coin collections.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Conditions or Other Limitations – Gold and Silver</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Single or Joint Life and Disability Conditions or Other Limitations.</b> You are insured only for advances actually received by you. You are not insured for any unused credit which may be available to you. Only the Primary Borrower is eligible for disability insurance.</li>" +
        "<li style='text-align:justify'><b>IUI Conditions or Other Limitations.</b> Coverage will not apply to purchases made while You are receiving benefits under the Certificate.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Property Perils – Gold and Bronze</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Property Perils.</b> We will insure against sudden and direct accidental loss or damage to property caused by: fire or lightning; collision, derailment or overturning of vehicle, collapse of bridges, while being transported by land conveyance; and marine perils while on ferries and/or in cars or transfers in connection with land conveyances. At an additional premium, coverage will be extended to include: windstorm; cyclone; tornado; hail or explosion; riot; riot attending a strike; and civil commotion; aircraft or private passenger type vehicles; smoke; flood; collapse of buildings; rupture, cracking, burning or freezing of plumbing, heating or air-conditioning systems and domestic appliances; and vandalism and malicious mischief.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Options for All Programs – Gold, Silver, and Bronze</b></h5>" +
        "<ul>" +
        "<li style='text-align:justify'><b>Available coverage options.</b> If you qualify, you may purchase life coverage with disability; and/or unemployment and/or property coverage together as a package.</li>" +
        "<li style='text-align:justify'><b>Cancellation and refunds or credits.</b> You may cancel this insurance and receive a full refund within the first 30 days after receiving your policy or certificate. If you cancel after the 30 days, any unearned premium will be refunded in accordance with applicable law.</li>" +
        "<li style='text-align:justify'><b>Other Insurance.</b> If you have other life, disability, unemployment or property insurance that covers the same risk insured by the credit insurance being offered, you may not want or need to purchase this insurance.</li>" +
        "<li style='text-align:justify'><b>Insurance Evaluation.</b> The endorsee is not qualified or authorized to evaluate the adequacy of your existing coverages, unless the individual is licensed as an agent.</li>" +
        "<li style='text-align:justify'><b>Finance charges.</b> If any portion of the premium for this insurance is financed, it will be subject to finance charges at the rate applicable to your loan.</li>" +
        "<li style='text-align:justify'><b>Costs.</b> The insurance premium costs are shown below for each type of coverage available:</li>" +
        "</ul>" +
        "</div>" +
        (
            toPrint === true ? propertyValueToPrint() : propertyValueToEmail()
        ) +
        "</td>" +
        "</tr>" +
        "</tbody>" +
        "<tfoot class='table-footer'>" +
        "<tr>" +
        "<td>" +
        "<span>F.88728.2 Rev 9-2023</span>" +
        "</td>" +
        "</tr>" +
        "</tfoot>" +
        "</table>"
    );
}

const propertyValueToPrint = () => {
    return (
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Price – Gold, Silver, and Bronze</b></h5>" +
        "<div class='container pl-5 pr-5 mt-3'>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Life Single:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.083 per $100</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Life Joint:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.137 per $100</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Disability:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.112 per $100</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Involuntary Unemployment:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$3.50 per $100</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Property:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.08 per $100</b></p></div>" +
        "</div>" +
        "</div>" +
        " </div>"
    )
}

const propertyValueToEmail = () => {
    return (
        "<div class='mt-3 mb-4'>" +
        "<h5 class='border p-1'><b>Price – Gold, Silver, and Bronze</b></h5>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        "<td><b>Life Single:</b></td>" +
        "<td><b><u>$0.083 per $100</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Life Joint:</b></td>" +
        "<td><b><u>$0.137 per $100</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Disability:</b></td>" +
        "<td><b><u>$0.112 per $100</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Involuntary Unemployment:</b></td>" +
        "<td><b><u>$3.50 per $100</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Property:</b></td>" +
        "<td><b><u>$0.08 per $100</u></b></td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>"
    )
}

