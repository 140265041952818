import React, {useRef}  from "react";
import Moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleUp,faCircleXmark,faAngleDown } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from 'react-to-print';
import { DanielsOffersCard } from "./component.applicationStatus";

const CreditAppView=(props)=>{
    const {show,customerId,applicationId,mobileVerificationExpand,identificationExpand ,uownExpand,employmentExpand,autopayRollExpand,residenceExpand,synchronyExpand,progressiveExpand,formObj,psInformationExpand,expand,creditId,
      customerPreApprove,loadSynchronyOffers,loadProgressiveOffers,loadUOwnOffers, loadFortivaOffers, estatementExpand}=props;
    const componentRef = useRef(null);
    return(
        <div
        className={` ${
          show ? "printSummaryPanelShow" : "printSummaryPanelHide"
        }`}
      >
        <div className="printSummaryPanel">
          <div className="container-fluid pt-4">
            <div className="row">
              <div className="col-12 pl-4 pr-4">
                <p>
                  <span className="h4 text-secondary m-0">
                    <b>{creditId? "Credit Application":"Print Summary"}</b>
                  </span>
                  <span
                    className="float-right btn p-0 mr-0"
                   onClick={() => expand("cancel")}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                  </span>
                </p>
                <p className="mb-0">
                  <b>Customer ID : </b>{" "}
                  {customerId ? customerId : ""}
                </p>
                <p className="mb-0">
                  <b>Application ID : </b>{" "}
                  {applicationId === "null"
                    ? "-"
                    : applicationId}
                </p>

                <p className="h5 font-weight-bold mt-4">
                  Mobile Verification
                  {mobileVerificationExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("mobileVerification")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("mobileVerification")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    mobileVerificationExpand === true
                      ? ""
                      : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Mobile Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {formObj.mobileNo}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Is MobileNo Verified
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {typeof formObj.mobileNoVerified ===
                          "boolean"
                            ? formObj.mobileNoVerified
                              ? "true"
                              : "false"
                            : formObj.mobileNoVerified}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Is SMS Code Sent
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {typeof formObj.smsCodeSent === "boolean"
                            ? formObj.smsCodeSent
                              ? "true"
                              : "false"
                            : formObj.smsCodeSent}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Is SMS Override
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {typeof formObj.smsOverride === "boolean"
                            ? formObj.smsOverride
                              ? "true"
                              : "false"
                            : formObj.smsOverride}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Personal Information
                  {psInformationExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("personalInformation")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("personalInformation")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    psInformationExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    {/* <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Mobile Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.mobileNo}
                        </p>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Store Code
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.storeCode}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Social Security Number/ITIN
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.ssn}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Date of Birth(MM/DD/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.dob}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          First Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.firstName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Middle Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.middleName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Last Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.lastName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Suffix
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.suffix:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Mailing Address
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.mailingAdr}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Apt/Unit
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.apt}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          City
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.city}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          State
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.state}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Zip Code
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.zipCode}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Housing Information
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.housingInfo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Secondary Phone(Optional)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.secPhone:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Email Address
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.emailAdr}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                {/* <p className="h5 font-weight-bold mt-4">
                  Residence
                  {residenceExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("residence")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("residence")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    residenceExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                  </form>
                </div> */}
                <p className="h5 font-weight-bold mt-4">
                  Employment
                  {employmentExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("employment")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("employment")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    employmentExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Employer Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(formObj.danielsStatus || formObj.uownStatus)? formObj.empName:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Type of Work/Job Duties
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.danielsStatus? formObj.duties:""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Start Date(MM/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.danielsStatus? formObj.empStartDate:""}
                         
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Employer's Phone Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.empPhoneNo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Monthly Net Income(from all sources)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.monthlyIncome}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Identification
                  {identificationExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("identification")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("identification")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    identificationExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID Type
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.primaryIdType:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {(formObj.danielsStatus || formObj.synchronyStatus || formObj.progressiveStatus)? formObj.primaryIdNumber:""}
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID State
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.primaryIdState:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Daniel's Required Field
                  {synchronyExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("synchrony")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("synchrony")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    synchronyExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    {/* <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Primary ID Expirtaion Date(MM/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.primaryIdExpiryDate}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Second ID Type
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.synchronyStatus? formObj.secondIdType:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Second ID Issued By
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {formObj.synchronyStatus? formObj.secondIdIssuedBy:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Second ID Expiration Date(MM/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.synchronyStatus? formObj.secondIdExpiryDate:""}
                        </p>
                      </div>
                    </div> */}
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Nearest Relative Name
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.danielsStatus? formObj.relativeName:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Nearest Relative Phone
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.danielsStatus? formObj.relativePhone:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Nearest Relative Relationship
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.danielsStatus? formObj.relativeRelation:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Progressive Required Field
                  {progressiveExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("progressive")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("progressive")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    progressiveExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Bank routing number(9 digits)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.progressiveStatus? formObj.bankRouteNo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Checking Account Number
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.progressiveStatus? formObj.checkAccNo:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          How often are you paid?
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.progressiveStatus? formObj.oftenPaid:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Last pay day(MM/DD/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.progressiveStatus? formObj.lastPayDay:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Next pay day(MM/DD/YYYY)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.progressiveStatus? formObj.nextPayDay:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Name on Credit Card
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.ccName}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Credit Card Number(first 6 digits)
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.cc}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Credit Card Expiration Date(MM/YYYY){" "}
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.ccExpiryDate}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Credit Card Billing Address{" "}
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.ccAddress}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Apt/Unit
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.progressiveStatus? formObj.ccaptUnit:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          City
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.progressiveStatus? formObj.cccity:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          State
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.progressiveStatus? formObj.ccstate==0?"":formObj.ccstate:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Zip Code
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.progressiveStatus? formObj.cczipCode:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                  Uown Required Field
                  {uownExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("uown")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("uown")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    uownExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          How often are you paid?
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          {formObj.uownStatus? formObj.oftenPaid:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          // style={{ wordBreak: "break-all" }}
                        >
                          Last Day (MM/DD/YYYY) Pay $ Was Available
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.uownStatus? formObj.lastPayDay:""}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          // style={{ wordBreak: "break-all" }}
                        >
                          Next Day (MM/DD/YYYY) Pay $ Will Be Available
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                           {formObj.uownStatus? formObj.nextPayDay:""}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                Auto-Pay Enroll(Daniels Credit only)
                  {autopayRollExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("autopay")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("autopay")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    autopayRollExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Status
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {formObj.autopay?.toLowerCase() === "enrolled" ? "ENROLLED" : "NOT ENROLLED"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
                <p className="h5 font-weight-bold mt-4">
                E-Statement Enroll(Daniels Credit only)
                  {estatementExpand === false ? (
                    <FontAwesomeIcon
                      icon={faAngleDown}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("estatement")}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faAngleUp}
                      className="float-right"
                      size="lg"
                      onClick={() => expand("estatement")}
                    />
                  )}
                </p>
                <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                <div
                  className={` ${
                    estatementExpand === true ? "" : " d-none"
                  }`}
                >
                  <form>
                    <div className="row">
                      <div className="col-6 col-md-5 col-lg-5 font-weight-bold text-secondary">
                        <label
                          className="mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                          Status
                        </label>
                      </div>
                      <div className="col-6 col-md-5 col-lg-5">
                        <p
                          className="pl-2 mb-1"
                          style={{ wordBreak: "break-all" }}
                        >
                         {formObj.isDanielEStatementDisclosure ? "ENROLLED" : "NOT ENROLLED"}
                        </p>
                      </div>
                    </div>
                  </form>
                </div>

                <p className="h5 font-weight-bold mt-4">Application Status
                            {customerPreApprove === false ?
                                <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => expand("customerPreApprove")} />
                                :
                                <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => expand("customerPreApprove")}  />
                            }
                        </p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        <div className={` ${customerPreApprove === true ? "" : " d-none"}`}>
                            <form>
                            <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Daniel’s Credit</label>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pt-2 pl-1 pr-1">
                                    <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: DanielsOffersCard(formObj.isDaniel,Moment(formObj?.createdDate).format("MM/DD/YYYY"))}}></div></div>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Fortiva</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadFortivaOffers() }}></div></div>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Synchrony Bank Credit</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadSynchronyOffers() }}></div></div>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Progressive Leasing</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadProgressiveOffers() }}></div></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Uown Leasing</label>
                                    </div>
                                    <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadUOwnOffers() }}></div></div>
                                    </div>
                                </div>
                                <hr></hr>
                            </form>
                        </div>
                        <p className="h5 font-weight-bold mt-4"> Created Date</p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                            <div className="row">
                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                    <label>Created Date</label>
                                </div>
                                <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                    <p className="pl-2">{Moment(formObj?.createdDate).format("MM/DD/YYYY, h:mm:ss a")}</p>
                                </div>
                            </div>

                <div className="row">
                  <div className="col-12 mt-4 mb-3">
                    <div className="row">
                      <div className="col-6 col-md-6 col-lg-6">
                        <p
                          className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                          onClick={() => expand("cancel") }
                        >
                          Cancel
                        </p>
                      </div>
                      <div className="col-6 col-md-6 col-lg-6">
                        <ReactToPrint
                          trigger={() => (
                            <p className="btn bg-secondary text-primary w-100">
                              Print
                            </p>
                          )}
                          content={() => componentRef.current}
                          pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                          documentTitle={`${formObj.firstName}-Information`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div id="customerInfo" ref={componentRef}>
                  <div className="ml-5 mb-3 mr-5 mt-4">
                    <h2 className="text-secondary">Customer Information</h2>
                    <h5 className="font-weight-bold">
                      For store use only. Not for customer distribution.
                      Please dispose of this form properly.
                    </h5>
                    <div className="mt-4 printDatas">
                      <p className="mb-0">
                        <b>Customer ID : </b>{" "}
                        {customerId ? customerId : "-"}
                      </p>
                      <p className="mb-0">
                        <b>Application ID : </b>{" "}
                        {applicationId === "null"
                          ? "-"
                          : applicationId}
                      </p>
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Personal Information</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Mobile Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.mobileNo}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Store Code
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.storeCode}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Social Security Number/ITIN
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.ssn}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Date of Birth(MM/DD/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.dob}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                First Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.firstName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Middle Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.middleName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Last Name</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.lastName}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Suffix</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.suffix:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Mailing Address
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.mailingAdr}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Apt/Unit</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.apt}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">City</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.city}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">State</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.state}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Zip Code</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.zipCode}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Housing Information
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.housingInfo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Secondary Phone(Optional)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.secPhone:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Email Address
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.emailAdr}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Residence</h4>
                          <hr className="mt-0"></hr>
                        </div>
                      </div>
                    </div> */}
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Employment</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Employer Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.danielsStatus || formObj.uownStatus)? formObj.empName:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Type of Work/Job Duties
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.danielsStatus? formObj.duties:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Start Date(MM/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.danielsStatus? formObj.empStartDate:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Employer's Phone Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.empPhoneNo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Monthly Net Income(from all sources)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.monthlyIncome}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Identification</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID Type
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                              {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.primaryIdType:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.danielsStatus || formObj.synchronyStatus || formObj.progressiveStatus)? formObj.primaryIdNumber:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID State
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {(formObj.danielsStatus || formObj.synchronyStatus)? formObj.primaryIdState:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Daniel's Required Field</h4>
                          <hr className="mt-0"></hr>
                        </div>

                        {/* <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Primary ID Expirtaion Date(MM/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.primaryIdExpiryDate}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Second ID Type
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {formObj.synchronyStatus? formObj.secondIdType:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Second ID Issued By
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.synchronyStatus? formObj.secondIdIssuedBy:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Second ID Expiration Date(MM/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {formObj.synchronyStatus? formObj.secondIdExpiryDate:""}
                              </p>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Nearest Relative Name
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.danielsStatus? formObj.relativeName:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Nearest Relative Phone
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.danielsStatus? formObj.relativePhone:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Nearest Relative Relationship
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.danielsStatus? formObj.relativeRelation:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Progressive Required Field</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Bank routing number(9 digits)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.progressiveStatus? formObj.bankRouteNo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Checking Account Number
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {formObj.progressiveStatus? formObj.checkAccNo:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                How often are you paid?
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.progressiveStatus? formObj.oftenPaid:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Last pay day(MM/DD/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.progressiveStatus? formObj.lastPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Next pay day(MM/DD/YYYY)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.progressiveStatus? formObj.nextPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Name on Credit Card
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.progressiveStatus? formObj.ccName:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Credit Card Number(first 6 digits)
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.progressiveStatus? formObj.cc:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Credit Card Expiration Date(MM/YYYY){" "}
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                              {formObj.progressiveStatus? formObj.ccExpiryDate:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Credit Card Billing Address
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.progressiveStatus && !formObj.creditCardBillingAddress)? formObj.ccAddress:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Apt/Unit
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {(formObj.progressiveStatus && !formObj.creditCardBillingAddress)? formObj.ccaptUnit:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">City</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {(formObj.progressiveStatus && !formObj.creditCardBillingAddress)? formObj.cccity:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">State</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.progressiveStatus && !formObj.creditCardBillingAddress)? formObj.ccstate:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Zip Code</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {(formObj.progressiveStatus && !formObj.creditCardBillingAddress)? formObj.cczipCode:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Uown Required Field</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                How often are you paid?
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.uownStatus? formObj.oftenPaid:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                              Last Day (MM/DD/YYYY) Pay $ Was Available
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                 {formObj.uownStatus? formObj.lastPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">
                                Next Day (MM/DD/YYYY) Pay $ Will Be Available
                              </p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.uownStatus? formObj.nextPayDay:""}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>Auto-Pay Enroll(Daniels Credit only)</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Status</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                                {formObj.autopay?.toLowerCase() === "enrolled" ? "ENROLLED" : "NOT ENROLLED"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                      <div
                        className="row bg-white"
                        style={{ borderRadius: "10px" }}
                      >
                        <div className="col-12 mt-3 mb-2">
                          <h4>E-Statement Enroll(Daniels Credit only)</h4>
                          <hr className="mt-0"></hr>
                        </div>
                        <div className="col-6">
                          <div className="row">
                            <div className="col-6">
                              <p className="text-secondary mb-1">Status</p>
                            </div>
                            <div className="col-6">
                              <p
                                className="mb-1"
                                style={{ wordBreak: "break-all" }}
                              >
                               {formObj.isDanielEStatementDisclosure ? "ENROLLED" : "NOT ENROLLED"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 printDatas">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12 mt-4 mb-2">
                                        <h4>Application Status</h4>
                                        <hr className="mt-0"></hr>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Daniel’s Credit</p>
                                            </div>
                                            <div className="col-7">
                                              <div dangerouslySetInnerHTML={{ __html: DanielsOffersCard(formObj.isDaniel,Moment(formObj?.createdDate).format("MM/DD/YYYY")) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Fortiva</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadFortivaOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Synchrony Bank Credit</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadSynchronyOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Progressive Leasing</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadProgressiveOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">UOWN Leasing</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadUOwnOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div className="mt-5">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12">
                                        <span className="mb-1 float-right" >{Moment(formObj?.createdDate).format("MM/DD/YYYY, h:mm:ss a")}</span>
                                    </div>
                                </div>
                        </div>
                       
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}
export default CreditAppView;