import React, { Component } from "react";
import { Card,Modal } from "react-bootstrap";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import './component.accountSummary.css';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
import 'react-datepicker/dist/react-datepicker.css';
import IdleTimeModal from "../../components/component.idleTimeModal";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import TitleCard from "../../components/component.titleCard";
import * as synchronyActions from "../../redux/actions/synchronyLookup.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../components/component.confirmDialog";
import Moment from 'moment';
import TenderModal from '../../components/component.tenderModal';
import FortivaErrorDialog from "../../components/component.fortivaErrorDialog";
import GetInfo from "../../components/component.getInfo";
class SynchronyActSummary extends Component {
    constructor() {
        super();
        this.state = {
            summaryDetails: {
                accountNumber:"",
                creditLine:"",
                balance:"",
                opentobuy:"",
                statusMessage:null,
                failedCredit:""
            },
            showAlert:false,
            toggled: false,
            showincrLimit:false,
            formObj:{
                income: localStorage.getItem("monthlyIncome")!=="null"?localStorage.getItem("monthlyIncome"):"",
                requestedLmt:""
            },
            increaseLimitmsg:"",
            increaseLmtErr:"",
            showTender : false,
            currentTender : "",
            isSummary:false
        };

    }

    componentDidMount() {
        if (JSON.parse(sessionStorage.getItem('isLoggedIn'))) {
            let synchronyAppId = localStorage.getItem("synchronyAppId");
            if (synchronyAppId!="null" && synchronyAppId!="undefined" && synchronyAppId!=="") {
                    this.getActSummaryData(synchronyAppId);
                }
                else{
                    // this.setState({isSummary:!this.state.isSummary});
                }
        }
        else
            this.props.history.push("/");
    }

    getActSummaryData(synchronyAppId) {
        // let phoneNo=sessionStorage.getItem("selectedUserPhone")!=="null"?sessionStorage.getItem("selectedUserPhone"):"";
        // let homeStore = localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        // if(phoneNo.includes("-")){
        //     phoneNo = phoneNo.slice(0,3)+phoneNo.slice(4,7)+phoneNo.slice(8,12);
        // }
        let ssnandzipcode = sessionStorage.getItem("ssn&zipcode");
        let filterObj = {
            ssn:ssnandzipcode?.split(",")[0]?.replace(/-/g,""),
            zipCode:ssnandzipcode?.split(",")[1],
            phoneNumber:"",
            accountNumber:"",
            homeStore : sessionStorage.getItem("synchronyStoreCode")
        }
        trackPromise(
            this.props.actions.getsynchronyLookup(filterObj).then(
                response => {
                    if(response.applicationId || response.accountNumber)
                    this.setState({ summaryDetails: response });
                    else{
                        // this.setState({showAlert:true,increaseLimitmsg:response.errorMessage});
                    }
                }
            ).catch(err => {
                APIFailedMessage.displayError(err);
            })
        );
    }

    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
    };

    onIncreaseLimit = ()=>{
        this.setState({showincrLimit:!this.state.showincrLimit,increaseLmtErr:"",
           formObj:{...this.state.formObj,requestedLmt:""}
        });
    }
    onChange(event){
    //    let reg=/^[0-9]+$/;
    //    if(reg.test(event.target.value)){
            this.setState({formObj:{...this.state.formObj,[event.target.name]:event.target.value}})
        //}
    }

    submitIncCreditLimit(){
        if(Number(this.state.formObj.requestedLmt)<Number(this.state.summaryDetails.opentobuy)){
            this.setState({increaseLmtErr:"Requested amount cannot be less than the open to buy amount."});  
           return 
          }
        let synchronyAppId = localStorage.getItem("synchronyAppId") == "null" ? "" : localStorage.getItem("synchronyAppId");
        let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        let obj={
            customerId: GetInfo.getCustomerId(),
            applicationId : synchronyAppId,
            requestedLimit:this.state.formObj.requestedLmt,
            income:this.state.formObj.income,
            homeStore:homeStore
        }
        this.setState({showincrLimit:false,increaseLmtErr:""});
        trackPromise(
            this.props.actions.increaseSynLmt(obj)
            .then(response=>{
                if(response.fullApproval){
                    let currentDate=Moment(new Date()).format("MM/DD/YYYY");
                    let msg=`Your request for credit line increase ${'$'+response.creditLimit} was approved on ${currentDate}`;
                    this.setState({showAlert:true,increaseLimitmsg:msg,
                        summaryDetails:{...this.state.summaryDetails,creditLine:response.creditLimit}});
                        this.getActSummaryData(synchronyAppId);
                }
                else{
                    this.setState({showAlert:true,increaseLimitmsg:response.errorMessage});
                }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
        );
    }

    handleAlertModal = () => {
        let showAlert=!this.state.showAlert;
        this.setState({showAlert:showAlert});
    } 

    triggerTender = (curTen) => {
        this.setState({showTender : !this.state.showTender, currentTender : curTen});
    }

    render() {
        return (
            <>
                <IdleTimeModal />
                <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                    <Sidebar
                        toggled={this.state.toggled}
                        handleToggleSidebar={() => this.handleToggleSidebar()}
                    />
                    <main>
                        <CustomNavbar handleToggleSidebar={() => this.handleToggleSidebar()} isActSummary={true} />
                        <div id="main" className="container-fluid pl-4 pr-4">
                            <div className="row mt-4">
                                <TitleCard accType={"Synchrony"}/>
                                <div className="col-12">
                                        <Card>
                                            <Card.Body>
                                            <div>
                                                <div className="row">
                                                <div className="col-md-9 col-12">
                                                        <p className="h5 mt-2 mb-4 pl-2"><b>Account Info</b></p>
                                                    </div>
                                                    <div className="col-md-3 col-12">
                                                        <button className={"btn text-primary w-100"+((this.state.summaryDetails.accountNumber=="" || this.state.summaryDetails.accountNumber==null)? " creditBtn":" bg-secondary")} onClick={(event) => this.onIncreaseLimit(event)}
                                                            disabled={(this.state.summaryDetails.accountNumber=="" || this.state.summaryDetails.accountNumber==null || this.state.summaryDetails.opentobuy=="" ||  this.state.summaryDetails.opentobuy==null) ?true:false}>Request Credit Line Increase</button>
                                                    </div>
                                                </div>
                                                    <div className="row">
                                                        <div className=" col-6 col-md-3 border-right pr-md-3 pt-2 pb-2 pl-md-4">
                                                            <p><b>Account ID</b></p>
                                                            <p>{this.state.summaryDetails.accountNumber}</p>
                                                        </div>
                                                        <div className=" col-6 col-md-3 border-right pr-md-3 pl-md-4 pt-2 pb-2">
                                                            <p><b>Credit Limit</b></p>
                                                            <p>{"$"+this.state.summaryDetails.creditLine}</p>
                                                        </div>
                                                        <div className=" col-6 col-md-3 pr-md-3 pl-md-4 pt-2 pb-2">
                                                            <p><b>Open to Buy</b></p>
                                                            <p>{"$"+this.state.summaryDetails.opentobuy}</p>
                                                        </div>
                                                        <div className=" col-6 col-md-3 pr-md-3 pl-md-4 pt-2 pb-2">
                                                            <p><b>Account Balance</b></p>
                                                            <p>{"$"+this.state.summaryDetails.balance}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>

                                        <Card className="mt-4 mb-4">
                                            <Card.Body>
                                            <div>
                                                <div className="row">
                                                <div className="col-7">
                                                        <p className="h5 mt-2 pl-2"><b>Tender Synchrony</b></p>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                        {sessionStorage.getItem("tenderSynchronyAccess") == "true" ?
                                                        <button className={(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "btn text-primary bg-secondary" : "btn text-muted border"} onClick={(sessionStorage.getItem("tenderSynchronyEnable") == "true") ? () => this.triggerTender("Synchrony") : null }>Tender Synchrony</button>
                                                        : null }
                                                    </div>
                                                </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </div>
                            </div>
                            <Modal show={this.state.showincrLimit} onHide={this.state.showincrLimit}>  
                                <Modal.Header className="pb-0"><b className="text-secondary h4">Credit Limit Increase</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onIncreaseLimit()} /></Modal.Header>
                                <Modal.Body className="pb-0">
                                    <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2">
                                        <label>Requested Total Purchase Amount</label>
                                    </div>
                                    <div
                                        className="col-12 col-md-6 col-lg-6 pt-2"
                                        style={{ marginRight: "-30px" }}
                                    >
                                        <input
                                        type="number"
                                        className="form-control border-dark "
                                        name="requestedLmt"
                                        id="requestedLmt"
                                        placeholder="Requested Limit"
                                        value={this.state.formObj.requestedLmt}
                                        onChange={(event) => this.onChange(event)}
                                        ></input>
                                          <span className="text-danger ml-0">{this.state.increaseLmtErr}</span>
                                    </div>
                                    <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                                    
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2">
                                        <label>Monthly Income</label>
                                    </div>
                                    <div
                                        className="col-12 col-md-6 col-lg-6 pt-2"
                                        style={{ marginRight: "-30px" }}
                                    >
                                        <input
                                        type="number"
                                        className="form-control border-dark "
                                        name="income"
                                        id="income"
                                        placeholder="Income"
                                        value={this.state.formObj.income}
                                        onChange={(event) => this.onChange(event)}
                                        ></input>
                                    </div>
                                    <div className="col-12 offset-md-4 col-md-6 offset-lg-4 col-lg-6">
                                    </div>
                                    </div>

                                </Modal.Body> 
                                <Modal.Footer className="border-top-0 mt-1">
                                    <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onIncreaseLimit()}>Cancel</p>
                                     <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary" disabled={(this.state.formObj.requestedLmt!=="" && this.state.formObj.income!=="")?false:true} onClick={()=>{this.submitIncCreditLimit()}}>Increase</button>
                                </Modal.Footer>
                            </Modal>

                            <ConfirmDialog
                                show={this.state.showAlert}
                                handleModalPopup={this.handleAlertModal}
                                message={`<span class=\"mb-0 \">${this.state.increaseLimitmsg}</span>`}
                                header_name = "Alert!!"
                                button2_name="OK"
                                button2={this.handleAlertModal}
                            />
                        </div>
                        <FortivaErrorDialog
                            show={this.state.isSummary}
                            handleModalPopup={(event)=>this.setState({isSummary:!this.state.isSummary})}
                            message={`<span class=\"mb-0 \">No Account found</span>`}
                            submitFortiva={(event)=>this.setState({isSummary:!this.state.isSummary})}
                        />
                    </main>
                </div>

                <TenderModal 
                    showTender = {this.state.showTender}
                    triggerTender = {this.triggerTender}
                    currentTender = {this.state.currentTender}
                />
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(synchronyActions, dispatch),
    }
}
export default connect(null, mapDispatchToProps)(SynchronyActSummary);