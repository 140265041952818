import { ApiWrapper } from "../../api/thunk.helper"; //storeNumber.actions.js
import HttpMethods from "../../redux/_http.methods";

export function getUserRole(roleList) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: roleList,
      ResourceUrl: "v1/associates/search-roles",
      isAssociate:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function addAndEditUserRole(updatedRole) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: updatedRole,
            ResourceUrl: "v1/associates/roles",
            isAssociate: true
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function deleteRole(deleteRole) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Delete(),
          Resource: deleteRole,
          ResourceUrl: `v1/associates/role/${deleteRole.role_id}`,
          isAssociate: true
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}