import React, {useRef}  from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from 'react-to-print';

const FortivaDisclosures=(props)=>{
    const {title,onDisclosure,onClose,show,disclosuresMsg}=props;
    const componentRef = useRef(null);

    return(
        <Modal id="customModel" show={show} onHide={(event) => onDisclosure(event)}>
                            <Modal.Header className="pb-0"><b className="text-secondary h4"><label className="d-flex justify-content-center align-items-center">{title}</label></b><FontAwesomeIcon icon={faClose} className="btn" onClick={(event) => { onClose(event) }} /></Modal.Header>
                            <Modal.Body className="pb-0">
                          
                                <div ref={componentRef}>
                                {/* <h2 className="text-secondary" id="disclosureHeading"><b>{title}</b></h2> */}
                                <p className="printThisPage">
                                        <span>Please</span>
                                        <ReactToPrint
                                            trigger={() =>
                                                <span className="ml-0" style={{ color: "rgb(0, 174, 239)", cursor: "pointer" }}><u>print this page</u></span>
                                            }
                                            content={() => componentRef.current}
                                            pageStyle="@page { size: 210mm 297mm; margin: 20mm 20mm; }"
                                            documentTitle="Daniel’s Jewelers disclosures"
                                        />
                                        <span className="ml-0"> for your record.</span>
                                    </p>
                                    <div id="fortivaDisclosure" dangerouslySetInnerHTML={{ __html : disclosuresMsg?.split("</head>")[1]
                                                                                             ?.replaceAll("h1","h4")
                                                                                             ?.replaceAll(/Â/g,"")
                                                                                             ?.replaceAll("text-indent:-24.0","text-indent:4")
                                                                                             ?.replaceAll("<span style=\"font-family:&apos;Arial Narrow&apos;,sans;font-size:13.333334px;\"><br /></span>","  ")
                                                                                             ?.replace(/APPLICATION DISCLOSURES|margin|br/g,"")
                                                                                             ?.replaceAll("color:#0076a9","color:#5C068C")}}></div>

                                </div>
                            </Modal.Body>
                           
                        </Modal>

     )
}
export default FortivaDisclosures;