import { ApiWrapper } from "../../api/thunk.helper"; //storeNumber.actions.js
import HttpMethods from "../../redux/_http.methods";

export function getUserList(userObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: userObj,
      ResourceUrl:
        "v1/associates/v1/users-search",
      isAssociate: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getDistricts() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl:
        "v1/associates/districts",
      isAssociate: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function getUserRoles() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl:
        "v1/associates/roles",
      isAssociate: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function getStoresByDistrictId(districtId) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl:
        "v1/associates/stores-by-district?DistrictId=" +
        districtId,
      isAssociate: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getStoresByMultipleDistrictId(districtIds) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl:
        "v1/associates/stores-by-multipledistrict?DistrictIds=" +
        districtIds,
      isAssociate: true,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function editUser(userObj, isEdit) {
  if (isEdit) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Put(),
        Resource: userObj,
        ResourceUrl:
          "v1/associates/user",
        isAssociate: true,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  } else {
    return function (dispatch) {
        const data = {
          MethodType: HttpMethods.Post(),
          Resource: userObj,
          ResourceUrl:
            "v1/associates/user",
          isAssociate: true,
        };
        return ApiWrapper.LoadData(data)
          .then((response) => {
            if (response) {
              return response;
            } else {
              return response;
            }
          })
          .catch((error) => {
            throw error;
          });
      };
  }
}

export function validateUserExists(email) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: "",
        ResourceUrl:
          "v1/associates/user-exists?AssociateEmail=" +
          email,
        isAssociate: true,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
  export function deleteUser(emailId) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Delete(),
        Resource: "",
        ResourceUrl:
          "v1/associates/user?AssociateEmail=" +
          emailId,
        isAssociate: true,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }
  export function getUser(email) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: "",
        ResourceUrl:
          "v1/associates/user?AssociateEmail="+email,
        isAssociate: true,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }

  export function getPortalList(type) {
    return function (dispatch) {
      const data = {
        MethodType: HttpMethods.Get(),
        Resource: "",
        ResourceUrl:
          "v1/customers/apply-now/code-master/" +
          type,
      };
      return ApiWrapper.LoadData(data)
        .then((response) => {
          if (response) {
            return response;
          } else {
            return response;
          }
        })
        .catch((error) => {
          throw error;
        });
    };
  }