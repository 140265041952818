import { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../components/component.customSideBar";
import CustomNavbar from "../../components/component.customNavbar";
import { trackPromise } from "react-promise-tracker";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { Card } from "react-bootstrap";
import * as adminActions from "../../redux/actions/admin.actions";
import "./component.features.css";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";

class Features extends Component {
  constructor() {
    super();
    this.state = {
      //state for toogle sidebar in mobile view
      toggled: false,
      //state for validate user permissions
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: {
        canAdd: false,
        canEdit: false,
      },
      //state for passing admin menu to sidebar
      sitePageDetails: [],
      //state for post api call parameters
      signOutTime: 1,
      augmentesSignOutTime: 1,
      isAutoPayEnabled: false,
      isSynchronySendEmail: false,
      isAddressAutoComplete: false,
      isCoBorrowerEnable: false,
      isMasterAddressUpdationEnable: false,
      isInsuranceEnable : false,
      isCSSEnable:false,
      isWaterFallEnable:false,
      isEstatementEnable : false,
      isInsurance_FortivaEnabled : false,
      isInsurance_DanielsEnabled: false,
      isDanielsFileAClaim:false,
      isDanielsCancelEnrollment:false,
      isFortivaFileAClaim:false,
      isFortivaCancelEnrollment:false,
      isDanielsApplicationFlowChanges:false,
      adminSettingRes : {}
    };
  }

  //Hide/Show sidebar in mobile/table views.
  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }

  componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      let adminMenus = [];
      adminMenus = localStorage.getItem("sitePageDetails");
      adminMenus = JSON.parse(adminMenus);
      if (adminMenus) {
        this.setState({
          sitePageDetails: adminMenus,
        });
      } else {
        trackPromise(
          this.props.userRightsactions
            .getUserRights()
            .then((response) => {
              if (response) {
                let adminMenus = response.sitePageDetails.filter(
                  (item) => item.sitePage_id === 2
                );
                this.setState({
                  sitePageDetails: adminMenus,
                });
                localStorage.setItem(
                  "sitePageDetails",
                  JSON.stringify(adminMenus)
                );
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
      trackPromise(
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      trackPromise(
        this.props.adminActions
          .getSignOutTime()
          .then((response) => {
            if (response) {
              this.setState({
                signOutTime: response.signOutInMins,
                augmentesSignOutTime: response.sessionOutMins,
                isAutoPayEnabled: response.isAutoPayEnabled,
                isSynchronySendEmail: response.isSendEmail_Synchrony,
                isAddressAutoComplete: response.isAddressAutoComplete,
                isCoBorrowerEnable: response.isCoBorrowerEnable,
                isMasterAddressUpdationEnable: true,
                isCSSEnable:response.isCSSEnable,
                isWaterFallEnable:response.isWaterFallEnable,
                isEstatementEnable : response.isEstatementEnable,
                isInsurance_FortivaEnabled : response.isInsurance_FortivaEnabled,
                isInsurance_DanielsEnabled: response.isInsurance_DanielsEnabled,
                isDanielsFileAClaim:response.isDanielsFileAClaim,
                isDanielsCancelEnrollment:response.isDanielsCancelEnrollment,
                isFortivaFileAClaim:response.isFortivaFileAClaim,
                isFortivaCancelEnrollment:response.isFortivaCancelEnrollment,
                isDanielsApplicationFlowChanges:response.isDanielsApplicationFlowChanges,
                adminSettingRes : response
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  //To verify permissions of logged user.
  getPermissions() {
    let role_name = localStorage.getItem("role_name"),page_name = "Features";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return site_page_details.sitePage_id === getsitepages[0].id
        && site_page_details.name === page_name
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({
      permission_status: {
        ...this.state.permission_status,
        canAdd: role_permission_check[0]?.canAdd,
        canEdit: role_permission_check[0]?.canEdit,
      },
    });
  }

  //Making api call on changes
  setToogle(field) {
    if((this.state.permission_status.canAdd || this.state.permission_status.canEdit) === true) {
      let obj = {
        signOutInMins: this.state.signOutTime,
        sessionOutMins: this.state.augmentesSignOutTime,
        isAutoPayEnabled: this.state.isAutoPayEnabled,
        isSendEmail_Synchrony: this.state.isSynchronySendEmail,
        isAddressAutoComplete: this.state.isAddressAutoComplete,
        isCoBorrowerEnable: this.state.isCoBorrowerEnable,
        isCSSEnable:this.state.isCSSEnable,
        isWaterFallEnable:this.state.isWaterFallEnable,
        isEstatementEnable:this.state.isEstatementEnable,
        // isMasterAddressUpdationEnable : this.state.isMasterAddressUpdationEnable
        isInsurance_FortivaEnabled: this.state.isInsurance_FortivaEnabled,
        isInsurance_DanielsEnabled: this.state.isInsurance_DanielsEnabled,
        isDanielsFileAClaim:this.state.isDanielsFileAClaim,
        isDanielsCancelEnrollment:this.state.isDanielsCancelEnrollment,
        isFortivaFileAClaim:this.state.isFortivaFileAClaim,
        isFortivaCancelEnrollment:this.state.isFortivaCancelEnrollment,
        isDanielsApplicationFlowChanges:this.state.isDanielsApplicationFlowChanges,
        isAssociateStore_IP: this.state.adminSettingRes.isAssociateStore_IP,
        timervalue: this.state.adminSettingRes.timervalue       
      };
      if (field === "toogleAutoPay") {
        obj.isAutoPayEnabled = !this.state.isAutoPayEnabled;
      } else if (field === "toogleIsSynchronySendEmail") {
        obj.isSendEmail_Synchrony = !this.state.isSynchronySendEmail;
      } else if (field === "toogleAddressAutoComplete") {
        obj.isAddressAutoComplete = !this.state.isAddressAutoComplete;
      } else if (field === "toogleCoBorrower") {
        obj.isCoBorrowerEnable = !this.state.isCoBorrowerEnable;
        // } else if (field === "toogleMasterAddressUpdation") {
        //   obj.isMasterAddressUpdationEnable = !this.state.isMasterAddressUpdationEnable;
      }else if (field === "toogleCssAcctCreation") {
        obj.isCSSEnable = !this.state.isCSSEnable;
      }else if (field === "toogleisWaterFallEnable") {
        obj.isWaterFallEnable = !this.state.isWaterFallEnable;
      }else if (field === "toogleisEstatementEnable") {
        obj.isEstatementEnable = !this.state.isEstatementEnable;
      }else if (field === "toogleFortivaInsurance") {
        obj.isInsurance_FortivaEnabled = !this.state.isInsurance_FortivaEnabled;
      }
      else if (field === "toogleDanielsInsurance") {
        obj.isInsurance_DanielsEnabled = !this.state.isInsurance_DanielsEnabled;
      }
      else if(field==="toogleDanielsFileaClaim"){
        obj.isDanielsFileAClaim = !this.state.isDanielsFileAClaim;
      }
      else if(field==="toogleDanielsCancelEnrollment"){
        obj.isDanielsCancelEnrollment = !this.state.isDanielsCancelEnrollment;
      }
      else if(field==="toogleFortivaFileaClaim"){
        obj.isFortivaFileAClaim = !this.state.isFortivaFileAClaim;
      }
      else if(field==="toogleFortivaCancelEnrollment"){
        obj.isFortivaCancelEnrollment = !this.state.isFortivaCancelEnrollment;
      }
      else if(field==="toogleisDanielsApplicationFlowChanges"){
        obj.isDanielsApplicationFlowChanges = !this.state.isDanielsApplicationFlowChanges;
      }
      trackPromise(
        this.props.adminActions
          .saveSignOutTime(obj)
          .then((res) => {
            if (res) {
              if (field === "toogleAutoPay") {
                this.setState({
                  isAutoPayEnabled: res.isAutoPayEnabled,
                });
              } else if (field === "toogleIsSynchronySendEmail") {
                this.setState({
                  isSynchronySendEmail: res.isSendEmail_Synchrony,
                });
              } else if (field === "toogleAddressAutoComplete") {
                this.setState({
                  isAddressAutoComplete: res.isAddressAutoComplete,
                });
              } else if (field === "toogleCoBorrower") {
                this.setState({
                  isCoBorrowerEnable: res.isCoBorrowerEnable,
                });
                // } else if (field === "toogleMasterAddressUpdation") {
                //   this.setState({
                //     isMasterAddressUpdationEnable: res.isMasterAddressUpdationEnable,
                //   });
              }else if (field === "toogleCssAcctCreation") {
                this.setState({
                  isCSSEnable: res.isCSSEnable,
                });
              }else if (field === "toogleisWaterFallEnable") {
                this.setState({
                  isWaterFallEnable: res.isWaterFallEnable,
                });
              }else if (field === "toogleisEstatementEnable") {
                this.setState({
                  isEstatementEnable: res.isEstatementEnable,
                });
              }else if (field === "toogleFortivaInsurance") {
                this.setState({
                  isInsurance_FortivaEnabled: res.isInsurance_FortivaEnabled,
                });
              }else if (field === "toogleDanielsInsurance") {
                this.setState({
                  isInsurance_DanielsEnabled: res.isInsurance_DanielsEnabled,
                });
              }else if (field === "toogleDanielsFileaClaim") {
                this.setState({
                  isDanielsFileAClaim: res.isDanielsFileAClaim,
                });
              }else if (field === "toogleDanielsCancelEnrollment") {
                this.setState({
                  isDanielsCancelEnrollment: res.isDanielsCancelEnrollment,
                });
              }else if(field === "toogleFortivaFileaClaim"){
                this.setState({
                  isFortivaFileAClaim: res.isFortivaFileAClaim,
                });
              }else if(field === "toogleFortivaCancelEnrollment"){
                this.setState({
                  isFortivaCancelEnrollment: res.isFortivaCancelEnrollment,
                });
              }else if(field === "toogleisDanielsApplicationFlowChanges"){
                this.setState({
                  isDanielsApplicationFlowChanges: res.isDanielsApplicationFlowChanges,
                });
               }
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  render() {
    return (
      <>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          {/* Side Panel/Menu Bar */}
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={this.state.sitePageDetails}
          />
          <main>
            {/* Navigation Bar */}
            <CustomNavbar
              handleToggleSidebar={() => {
                this.handleToggleSidebar();
              }}
              isAdmin={true}
            />
            <div id="main" className="container-fluid">
              <div className="row mt-4 mb-4">
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body>
                    <div className="row">
                      <div className="col-8">
                        <h3 className="mb-0">
                          <b>FEATURES</b>
                        </h3>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body>
                    <div className="row mb-4">
                      <div className="col-8">
                        <h4 className="mb-0">
                          <b>Customer Details Page</b>
                        </h4>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Disable</b>
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Enable</b>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Co-Borrower Section</b>
                        </span>
                        <p>
                          If disabled, it will remove the Co-Borrower section
                          (Includes printouts).
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isCoBorrowerEnable"
                            name="isCoBorrowerEnable"
                            checked={!this.state.isCoBorrowerEnable}
                            onClick={() => this.setToogle("toogleCoBorrower")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isCoBorrowerEnable"
                            name="isCoBorrowerEnable"
                            checked={this.state.isCoBorrowerEnable}
                            onClick={() => this.setToogle("toogleCoBorrower")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Daniel's Application Status</b>
                        </span>
                        
                      </div>
                      <div className="col-8 border-right">
                        <p>If disabled, it will hide the Add-On , Re-Open and Apply for New Account.</p>
                        </div>
                        <div className="col-2 d-flex justify-content-center border-right align-items-center">
                        <div className="form-group mb-0">
                            <input
                              type="radio"
                              className="form-control"
                              id="isDanielsApplicationFlowChanges"
                              name="isDanielsApplicationFlowChanges"
                              checked={!this.state.isDanielsApplicationFlowChanges}
                              onClick={() => this.setToogle("toogleisDanielsApplicationFlowChanges")}
                              disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                            />
                          </div>
                        </div>
                        <div className="col-2 d-flex justify-content-center align-items-center">
                          <div className="form-group mb-0">
                            <input
                              type="radio"
                              className="form-control"
                              id="isDanielsApplicationFlowChanges"
                              name="isDanielsApplicationFlowChanges"
                              checked={this.state.isDanielsApplicationFlowChanges}
                              onClick={() => this.setToogle("toogleisDanielsApplicationFlowChanges")}
                              disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                            />
                          </div>
                        </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body>
                    <div className="row mb-4">
                      <div className="col-8">
                        <h4 className="mb-0">
                          <b>Credit Application/ Apply Again Page</b>
                        </h4>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Disable</b>
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Enable</b>
                        </p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Auto Pay</b>
                        </span>
                        <p className="mb-0">
                          If disabled, it will hide the auto pay enrollment
                          section in credit application page (Across all
                          financial partners).
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isAutoPayEnabled"
                            name="isAutoPayEnabled"
                            checked={!this.state.isAutoPayEnabled}
                            onClick={() => this.setToogle("toogleAutoPay")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isAutoPayEnabled"
                            name="isAutoPayEnabled"
                            checked={this.state.isAutoPayEnabled}
                            onClick={() => this.setToogle("toogleAutoPay")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>E-Statement Enrollment</b>
                        </span>
                        <p className="mb-0">
                          If disabled, it will hide the E-Statement enrollment
                          section in credit application page (Across all
                          financial partners).
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isEstatementEnable"
                            name="isEstatementEnable"
                            checked={!this.state.isEstatementEnable}
                            onClick={() =>
                              this.setToogle("toogleisEstatementEnable")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isEstatementEnable"
                            name="isEstatementEnable"
                            checked={this.state.isEstatementEnable}
                            onClick={() =>
                              this.setToogle("toogleisEstatementEnable")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Synchrony Email Sending</b>
                        </span>
                        <p className="mb-0">
                          If enabled, an email will be sent to District Manager after Synchrony
                          approves any credit application.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isSynchronySendEmail"
                            name="isSynchronySendEmail"
                            checked={!this.state.isSynchronySendEmail}
                            onClick={() =>
                              this.setToogle("toogleIsSynchronySendEmail")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isSynchronySendEmail"
                            name="isSynchronySendEmail"
                            checked={this.state.isSynchronySendEmail}
                            onClick={() =>
                              this.setToogle("toogleIsSynchronySendEmail")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Address Auto Fill</b>
                        </span>
                        <p className="mb-0">
                          If enabled, Melissa address verification will show suggestions (Only on Instore application).
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isAddressAutoComplete"
                            name="isAddressAutoComplete"
                            checked={!this.state.isAddressAutoComplete}
                            onClick={() =>
                              this.setToogle("toogleAddressAutoComplete")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isAddressAutoComplete"
                            name="isAddressAutoComplete"
                            checked={this.state.isAddressAutoComplete}
                            onClick={() =>
                              this.setToogle("toogleAddressAutoComplete")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>WaterFall</b>
                        </span>
                        <p className="mb-0">
                          If enabled, Customer can apply to Daniel's, Synchrony, and Fortiva but Leasing will have to be part of a separate application.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isWaterFallEnable"
                            name="isWaterFallEnable"
                            checked={!this.state.isWaterFallEnable}
                            onClick={() =>
                              this.setToogle("toogleisWaterFallEnable")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isWaterFallEnable"
                            name="isWaterFallEnable"
                            checked={this.state.isWaterFallEnable}
                            onClick={() =>
                              this.setToogle("toogleisWaterFallEnable")
                            }
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body>
                    <div className="row mb-4">
                      <div className="col-8">
                        <h4 className="mb-0">
                          <b>View Offers Page</b>
                        </h4>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Disable</b>
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Enable</b>
                        </p>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Daniels Offers</b>
                        </span>
                        <p className="mb-0">
                          If enabled, it will create a css account for daniels applications(Instore, djo applications only).
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isCssAcctCreation"
                            name="isCssAcctCreation"
                            checked={!this.state.isCSSEnable}
                            onClick={() => this.setToogle("toogleCssAcctCreation")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isCssAcctCreation"
                            name="isCssAcctCreation"
                            checked={this.state.isCSSEnable}
                            onClick={() => this.setToogle("toogleCssAcctCreation")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Fortiva Insurance</b>
                        </span>
                        <p className="mb-0">
                          If enabled, customers have the option to opt in for fortiva credit insurance.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isFortivaInsuranceCheckBox"
                            name="isFortivaInsuranceCheckBox"
                            checked={!this.state.isInsurance_FortivaEnabled}
                            onClick={() => this.setToogle("toogleFortivaInsurance")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isFortivaInsuranceCheckBox"
                            name="isFortivaInsuranceCheckBox"
                            checked={this.state.isInsurance_FortivaEnabled}
                            onClick={() => this.setToogle("toogleFortivaInsurance")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                          <b>Daniels Insurance</b>
                        </span>
                        <p className="mb-0">
                          If enabled, customers have the option to opt in for Daniels credit insurance.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isDanielsInsuranceCheckBox"
                            name="isDanielsInsuranceCheckBox"
                            checked={!this.state.isInsurance_DanielsEnabled}
                            onClick={() => this.setToogle("toogleDanielsInsurance")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="isDanielsInsuranceCheckBox"
                            name="isDanielsInsuranceCheckBox"
                            checked={this.state.isInsurance_DanielsEnabled}
                            onClick={() => this.setToogle("toogleDanielsInsurance")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body>
                    <div className="row mb-4">
                      <div className="col-8">
                        <h4 className="mb-0">
                          <b>Daniels Account Summary</b>
                        </h4>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Disable</b>
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Enable</b>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                        <b>File a Claim</b>
                        </span>
                        <p>
                          If disabled, File a claim will be removed under Credit Insurance section.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="danielsFileAClaim"
                            name="danielsFileAClaim"
                            checked={!this.state.isDanielsFileAClaim}
                            onClick={() => this.setToogle("toogleDanielsFileaClaim")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="danielsFileAClaim"
                            name="danielsFileAClaim"
                            checked={this.state.isDanielsFileAClaim}
                            onClick={() => this.setToogle("toogleDanielsFileaClaim")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                        <b>Cancel Enrollment</b>
                        </span>
                        <p>
                          If disabled, Cancel enorllment will be removed under Credit Insurance section.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="danielsCancelEnrollment"
                            name="danielsCancelEnrollment"
                            checked={!this.state.isDanielsCancelEnrollment}
                            onClick={() => this.setToogle("toogleDanielsCancelEnrollment")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="danielsCancelEnrollment"
                            name="danielsCancelEnrollment"
                            checked={this.state.isDanielsCancelEnrollment}
                            onClick={() => this.setToogle("toogleDanielsCancelEnrollment")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body>
                    <div className="row mb-4">
                      <div className="col-8">
                        <h4 className="mb-0">
                          <b>Fortiva Account Summary</b>
                        </h4>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Disable</b>
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <p className="mb-0">
                          <b>Enable</b>
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                        <b>File a Claim</b>
                        </span>
                        <p>
                          If disabled, File a claim will be removed under Credit Insurance section.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="fortivaFileaClaim"
                            name="fortivaFileaClaim"
                            checked={!this.state.isFortivaFileAClaim}
                            onClick={() => this.setToogle("toogleFortivaFileaClaim")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="fortivaFileaClaim"
                            name="fortivaFileaClaim"
                            checked={this.state.isFortivaFileAClaim}
                            onClick={() => this.setToogle("toogleFortivaFileaClaim")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 border-right">
                        <span className="h5 m-0">
                        <b>Cancel Enrollment</b>
                        </span>
                        <p>
                          If disabled, Cancel enrollment will be removed under Credit Insurance section.
                        </p>
                      </div>
                      <div className="col-2 d-flex justify-content-center border-right align-items-center">
                      <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="fortivaCancelEnrollment"
                            name="fortivaCancelEnrollment"
                            checked={!this.state.isFortivaCancelEnrollment}
                            onClick={() => this.setToogle("toogleFortivaCancelEnrollment")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                      <div className="col-2 d-flex justify-content-center align-items-center">
                        <div className="form-group mb-0">
                          <input
                            type="radio"
                            className="form-control"
                            id="fortivaCancelEnrollment"
                            name="fortivaCancelEnrollment"
                            checked={this.state.isFortivaCancelEnrollment}
                            onClick={() => this.setToogle("toogleFortivaCancelEnrollment")}
                            disabled={!(this.state.permission_status.canAdd || this.state.permission_status.canEdit)}
                          />
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </main>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Features);
