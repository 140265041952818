import React from "react";
import { Modal, Card } from "react-bootstrap";
import "./component.insurance.css";
import {
  faArrowLeft,
  faBuilding,
  faHeartbeat,
  faSuitcase,
  faTimesCircle,
  faWheelchair,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Component } from "react";
import { bindActionCreators } from "redux";
import * as InsuranceActions from "../../redux/actions/insurance.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import Moment from "moment";
import { trackPromise } from "react-promise-tracker";
import ReactToPrint from 'react-to-print';
import {AZDisclosure} from "./insuranceDisclosure/component.AZdisclosure";
import {CADisclosure} from "./insuranceDisclosure/component.CAdisclosure";
import {GADisclosure} from "./insuranceDisclosure/component.GAdisclosure";
import {NVDisclosure} from "./insuranceDisclosure/component.NVdisclosure";
import {NMDisclosure} from "./insuranceDisclosure/component.NMdisclosure";
import {TXDisclosure} from "./insuranceDisclosure/component.TXdisclosure";
import {FLDisclosure} from "./insuranceDisclosure/component.FLdisclosure";
import * as customerLookupActions from "../../redux/actions/customerLookup.actions";
import Loader from 'react-loader-spinner';

class CongrulationPopup extends Component {
  constructor() {
    super();
    this.state = {
      status: "",
      acknowledgeCheckBox: false,
      paperCheckBox: false,
      electroniccopyCheckBox: false,
      stateTexas: false,
      step1: false,
      step2: false,
      goldInsurancePlan: [],
      silverInsurancePlan: [],
      bronzeInsurancePlan: [],
      allPlans: [],
      selectedPlan: "",
      coverage: [],
      insurance: [],
      goldPlan: false,
      silverPlan: false,
      bronzePlan: false,
      disclosureOne: false,
      disclosureTwo: false,
      disclosureThree: false,
      anyError: false,
      showPrePurchaseSummary : false,
      showSpinner : true,
      danielAppId:localStorage.getItem("danielAppId")
    };
  }

  componentDidMount() {
    let dob = sessionStorage.getItem("ssn&dob");
    let obj = {
      state : this.props.offerAcceptedState,
      dob: Moment(dob?.split("&")[1]).format("YYYY-MM-DD"),
      partner: this.props.partnerType
    }
    this.props.actions.getInsurancePlans(obj).then((response) => {
      if (response) {
        response.getInsuranceResponses?.map((val) => {
          let total = 0;
          if (val.insurance_life !== "no")
            total += parseFloat(val.insurance_life?.replace('$', ""))
          if (val.insurance_disability !== "no")
            total += parseFloat(val.insurance_disability?.replace('$', ""))
          if (val.insurance_unemployment !== "no")
            total += parseFloat(val.insurance_unemployment?.replace('$', ""))
          if (val.insurance_property !== "no")
            total += parseFloat(val.insurance_property?.replace('$', ""))
          val.totalCost = "$" + total?.toFixed(3);
        });
        let gold = response.getInsuranceResponses?.find((val) => val.insurance_Plan === "gold_plan");
        let silver = response.getInsuranceResponses?.find((val) => val.insurance_Plan === "silver_plan");
        let bronze = response.getInsuranceResponses?.find((val) => val.insurance_Plan === "bronze_plan");
        this.setState({ goldInsurancePlan: gold, silverInsurancePlan: silver, bronzeInsurancePlan: bronze, allPlans: response });
        if (obj.state === "TX") {
          this.setState({ stateTexas: true });
          let insurance = response?.getInsuranceResponses?.filter((item) => {
            if (item.insurance_Plan !== "bronze_plan") {
              return item;
            }
          });
          if (insurance?.length === 0) {
            let insurance = response?.getInsuranceResponses?.filter((item) => {
              if (item.insurance_Plan === "bronze_plan") {
                return item;
              }
            });
            this.setState({ insurance: insurance });
          }
          else {
            this.setState({ insurance: insurance });
          }
          this.setState({showSpinner : false});
        }
        else {
          this.setState({ insurance: response?.getInsuranceResponses, showSpinner : false });
        }
      }
    }).catch((err) => {
      APIFailedMessage.displayError(err);
    })
  }

  onChange(event) {
    const name = event.target.name;
    if (name === "acknowledge")
      this.setState({ acknowledgeCheckBox: !this.state.acknowledgeCheckBox });
    else if (name === "paper")
      this.setState({ paperCheckBox: !this.state.paperCheckBox });
    else
      this.setState({
        electroniccopyCheckBox: !this.state.electroniccopyCheckBox,
      });
  }

  onPlanChange(plan) {
    if (plan === "gold") {
      this.setState({ goldPlan: true, silverPlan: false, bronzePlan: false, selectedPlan: plan, coverage: this.state.goldInsurancePlan });
    } else if (plan === "silver") {
      this.setState({ goldPlan: false, silverPlan: true, bronzePlan: false, selectedPlan: plan, coverage: this.state.silverInsurancePlan });
    } else if (plan === "bronze") {
      this.setState({ goldPlan: false, silverPlan: false, bronzePlan: true, selectedPlan: plan, coverage: this.state.bronzeInsurancePlan });
    } else if (plan === "DisOne") {
      this.setState({ disclosureOne: !this.state.disclosureOne });
    } else if (plan === "DisTwo") {
      this.setState({ disclosureTwo: !this.state.disclosureTwo });
    } else if (plan === "DisThree") {
      this.setState({ disclosureThree: !this.state.disclosureThree });
    }
  }

  handleOnClick(status) {
    this.setState({ status: status });
  }

  handleBackClick() {
    this.setState({
      status: "",
      // acknowledgeCheckBox : false, paperCheckBox:false, electroniccopyCheckBox:false
      goldPlan: false,
      silverPlan: false,
      bronzePlan: false,
      disclosureOne: false,
      disclosureTwo: false,
      disclosureThree: false,
      selectedPlan: "",
      coverage: [],
      showPrePurchaseSummary : false,
    });
  }

  onInsuraceEnroll() {
    if (
      (this.state.disclosureOne &&
        this.state.disclosureTwo &&
        this.state.disclosureThree) || this.state.status === "Decline"
    ) {
      let obj = {
        "application_id": this.props.partnerType === "Daniels"?this.state.danielAppId:localStorage.getItem("fortivaAppId"),
        "isInsurance_Status": this.state.status,
        "state": this.props.offerAcceptedState,
        "plan_type": this.state.goldPlan ? "Gold" : this.state.silverPlan ? "Silver" : this.state.bronzePlan ? "Bronze" : "None selected",
        "plan_value": this.state.goldPlan ? this.state.goldInsurancePlan?.totalCost : this.state.silverPlan ? this.state.silverInsurancePlan?.totalCost : this.state.bronzePlan ? this.state.bronzeInsurancePlan?.totalCost : "",
        "createdBy": localStorage.getItem("loggedUserEmail"),
        "modifiedBy": localStorage.getItem("loggedUserEmail"),
        "insurance_life": this.state.status === "Decline" ? "None selected" : this.state.coverage.insurance_life === "no" ? this.state.coverage.insurance_life : "Yes",
        "insurance_unemployment": this.state.status === "Decline" ? "None selected" : this.state.coverage.insurance_unemployment === "no" ? this.state.coverage.insurance_unemployment : "Yes",
        "insurance_disability": this.state.status === "Decline" ? "None selected" : this.state.coverage.insurance_disability === "no" ? this.state.coverage.insurance_disability : "Yes",
        "insurance_property": this.state.status === "Decline" ? "None selected" : this.state.coverage.insurance_property === "no" ? this.state.coverage.insurance_property : "Yes",
        "financial_partner": this.props.partnerType,
        "account_number": this.props.partnerType === "Fortiva" ? sessionStorage.getItem("fortivaAccountNo") : "",
        "customer_id": localStorage.getItem("customerId"),
        "finalize_cancellationDate": ""
      };
      if(this.props.partnerType=="Daniels")
        this.props.triggerInsurance();
      trackPromise(
        this.props.actions.enrollInsurace(obj).then(async (res) => {
          if (res === true) {
            //Customer declined the insurance
            if (this.state.status === "Decline") {
              if(this.props.partnerType === "Daniels" && this.props.from ==="offers")
                await this.createCSSAccount();
              else
                //Fortiva
                this.props.triggerInsurance("Completed");
            } else if (this.state.stateTexas && !this.state.step2 && this.state.allPlans?.getInsuranceResponses?.length !== 1) {
              if(this.state.electroniccopyCheckBox)
                this.sendDisclosureEmail();
              let insurance = this.state.allPlans?.getInsuranceResponses?.filter((item) => {
                if (item.insurance_Plan === "bronze_plan") {
                  return item;
                }
              });
              this.setState({
                insurance: insurance,
                acknowledgeCheckBox: false,
                paperCheckBox: false,
                electroniccopyCheckBox: false,
                goldPlan: false,
                silverPlan: false,
                bronzePlan: false,
                disclosureOne: false,
                disclosureTwo: false,
                disclosureThree: false,
                status: "",
                step1: true,
                step2: true,
              });
              //Triggering insurance again for texas functionality
              if(this.props.partnerType === "Daniels")
                this.props.triggerInsurance();
            } else {
              //Insurance is enrolled
              if(this.props.partnerType=="Daniels" && this.props.from ==="offers"){
                await this.createCSSAccount();
            }else if(this.props.partnerType==="Daniels" && this.props.from ==="summary"){
              this.props.triggerInsurance("Completed");
            }
              else
                this.setState({ status: "Completed" });
              if(this.state.electroniccopyCheckBox)
                this.sendDisclosureEmail();
            }
          }
          else {
            if(this.props.partnerType === "Daniels")
              this.props.triggerInsurance();
            this.setState({ anyError: true });
          }
        }).catch((err) => {
          APIFailedMessage.displayError(err);
        })
      );
    }
  }

  sendDisclosureEmail() {
    let subject = "";
    if(this.props.offerAcceptedState === "CA") 
      subject = this.props.partnerType+" - Credit Insurance Pre-Purchase and Summary of Coverage Disclosure";
    else if(this.props.offerAcceptedState === "FL") 
      subject = this.props.partnerType+" - Credit Insurance Eligibility Disclosure";
    else
      subject = this.props.partnerType+" - Consumer Insurance Summary"+(this.state.step2 || (this.state.stateTexas && this.state.allPlans?.getInsuranceResponses?.length === 1) ? " and Personal Property Insurance Application and Certificate" : "");
      let obj = {
        toEmail: sessionStorage.getItem("selectedUserEmail"),
        cc: "",
        subject: subject,
        emailContent: " ",                
        attachmentContent: "",
        is_insurance: "Enrolled_"+this.props.offerAcceptedState,
        financial_partner : this.props.partnerType,
      }
      trackPromise(
        this.props.customerLookUpactions
        .sendApplyEmailLink(obj)
        .then((response) => {
          if (response.requestStatus?.toLowerCase() === "success") {
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
      )
  }

  createCSSAccount() {
    let obj = {
      storeCode: JSON.parse(localStorage.getItem("associateStores")),
      customerId: localStorage.getItem("customerId"),
      applicationId: this.state.danielAppId
    };
    trackPromise(
    this.props.actions.createDanielsAccountNumber(obj)
      .then(async resp => {
        if (resp) {
          this.setState({ danielsAcctNumber: resp });
          let cssObj = {
            "customerId": JSON.parse(localStorage.getItem("customerId")),
            "application_id": this.state.danielAppId,
            "danielAccountNo": resp,
            "credit_director": false,
            "isInsurance":true
          };
          let creditData=await trackPromise(this.props.actions.getCreditDirectorData(cssObj));
          if(creditData){
                let creditSaveObj={
                "crediT_LIMIT": creditData.crediT_LIMIT,
                "experiaN_SCORE": creditData.experiaN_SCORE,
                "tranS_UNION_SCORE": creditData.tranS_UNION_SCORE,
                "equifaX_SCORE": creditData.equifaX_SCORE,
                "tranS_UNION_SCORE_1": creditData.tranS_UNION_SCORE_1,
                "id": creditData.id,
                "crediT_BUREAU_USED": creditData.crediT_BUREAU_USED,
                "totaL_RATIO": creditData.totaL_RATIO,
                "trades": creditData.trades,
                "neT_INCOME": creditData.neT_INCOME,
                "generiC_STRING_FIELD_8": creditData.generiC_STRING_FIELD_8,
                "applicatioN_TYPE": creditData.applicatioN_TYPE,
                "totaL_SCORE": creditData.totaL_SCORE,
                "applicatioN_SCORE": creditData.applicatioN_SCORE,
                "reV_RATIO": creditData.reV_RATIO,
                "tradeS_DEROGS": creditData.tradeS_DEROGS,
                "in_File_YYMM": creditData.in_File_YYMM,
                "experiaN_SCORE_1": creditData.experiaN_SCORE_1,
                "experiaN_SCORE_2": creditData.experiaN_SCORE_2,
                "tranS_UNION_SCORE_2":  creditData.tranS_UNION_SCORE_2,
                "equifaX_SCORE_1": creditData.equifaX_SCORE_1,
                "equifaX_SCORE_2": creditData.equifaX_SCORE_2,
                "crediT_RATING": creditData.crediT_RATING,
                "ratinG_CODE": creditData.ratinG_CODE,
                "lasT_BUREAU_DATE": creditData.lasT_BUREAU_DATE,
                "maintaineD_DATE_TIME": creditData.maintaineD_DATE_TIME,
                "client_acnt_number2": creditData.client_acnt_number2,
                "fnd_rcpt_id": creditData.fnd_rcpt_id,
                application_Id:this.state.danielAppId,
                customer_Id:JSON.parse(localStorage.getItem("customerId"))
              }
              await this.props.actions.CreditDirectorSaveData(creditSaveObj)
              .then(async resp => {
                if (resp==true) {
                  let response=await trackPromise(this.props.actions.createCSSAccount(cssObj));
                  if (response.success == "true") {
                  if (this.state.status === "Decline") {
                    this.props.triggerInsurance("Declined");
                  }
                  else{
                    this.setState({ status: "Completed" });
                    this.props.triggerInsurance();
                  }
                }
              }
              });
            }
        }
      }).catch((err) => {
        APIFailedMessage.displayError(err);
      })
    )
  }

  render() {
    return (
      <>
        {this.state.showPrePurchaseSummary ? 
          <Modal show={this.props.insurance} size="lg">
            <Modal.Header className="align-items-center">
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="pr-2 pl-1"
                id="insuranceBackArrowBtn"
                onClick={() => this.handleBackClick()}
              />
              <span className="mr-auto h4 mb-0">Credit Insurance</span>
            </Modal.Header>
            <Modal.Body>
              <div>
                <div>
                  <span>Please</span>
                  <ReactToPrint
                    trigger={() =>
                      <span className="ml-0" style={{ color: "rgb(0, 174, 239)", cursor: "pointer" }}><u>print this page</u></span>
                    }
                    content={() => this.componentRef}
                    pageStyle={this.props.offerAcceptedState === "NV" ? "@page { size: 210mm 297mm; margin: 20mm 20mm; font-size : 16px }" : "@page { size: 210mm 297mm; margin: 10mm 10mm; }"}
                    documentTitle="Daniel’s Jewelers disclosures"
                  />
                  <span className="ml-0"> for your record.</span>
                </div>
                {this.props.offerAcceptedState === "AZ" ?
                    <div dangerouslySetInnerHTML={{ __html: AZDisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} />
                  : this.props.offerAcceptedState === "CA" ?
                    <div dangerouslySetInnerHTML={{ __html: CADisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} />
                  : this.props.offerAcceptedState === "GA" ?
                    <div dangerouslySetInnerHTML={{ __html: GADisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} />
                  : this.props.offerAcceptedState === "NV" ?
                    <div dangerouslySetInnerHTML={{ __html: NVDisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} />
                  : this.props.offerAcceptedState === "NM" ?
                    <div dangerouslySetInnerHTML={{ __html: NMDisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} />
                  : this.props.offerAcceptedState === "TX" ?
                    <div dangerouslySetInnerHTML={{ __html: TXDisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} />
                  : <div dangerouslySetInnerHTML={{ __html: FLDisclosure(this.props.partnerType, true) }} ref={el => (this.componentRef = el)} /> }                
              </div>
            </Modal.Body>
          </Modal>
          :
        !this.state.anyError ?
          <Modal
            show={this.props.insurance}
            size={this.state.status === "" || this.state.status === "Completed" ? "" : "lg"}
          >
            {this.state.status === "" || this.state.status === "Completed" ? (
              <Modal.Header>
                <h3 className="mb-0">
                  <b>Congratulations</b>
                </h3>
                {this.state.status === "Completed" || (this.props.from === "summary" && !this.state.step1) ? <FontAwesomeIcon icon={faTimesCircle} size="2x" id="insuranceCloseBtn" onClick={() => this.props.triggerInsurance(this.state.status)} /> : null}
              </Modal.Header>
            ) : (
              <Modal.Header className="align-items-center">
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="pr-2 pl-1"
                  id="insuranceBackArrowBtn"
                  onClick={() => this.handleBackClick()}
                />
                <span className="mr-auto h4 mb-0">Credit Insurance</span>
                {/* <FontAwesomeIcon icon={faTimesCircle} size="2x" className="ml-auto" style={{cursor : "pointer"}} onClick={() => this.toogleInsuranceModal()}/> */}
              </Modal.Header>
            )}
            <Modal.Body>
              {this.state.status === "" ? (
                <>
                {this.state.showSpinner ? (
                  <div
                    style={{
                      flexDirection: "column",
                    }}
                    className="d-flex justify-content-center align-items-center w-100"
                  >
                    <Loader
                      type="ThreeDots"
                      color="#5C068C"
                      height="100"
                      width="100"
                    />
                  </div>
                ) : 
                <>
                  <div>
                    {(this.props.from === "summary" && this.state.step1 === true) || (this.props.from === "offers" && this.state.step1 === true) ?
                      <>
                        <p className="mb-1">
                          In addition to the current plan option, we also offer property <b>Credit Insurance</b> to protect your purchase.
                        </p>
                        <hr></hr>
                      </>
                      : null}
                  </div>
                  {this.state.step1 !== true ?
                    <p>
                      Your current credit approval has an option to add{" "}
                      <b>Credit Insurance</b> to protect your purchase. You may
                      request to see a paper copy of our {this.props.offerAcceptedState === "CA" ?
                        "Credit Insurance Pre-Purchase and Summary of Coverage Disclosure."
                      : this.props.offerAcceptedState === "FL" ? "Credit Insurance Eligibility Disclosure."
                      : "Consumer Insurance Summary"+((this.state.step2 || (this.state.stateTexas && this.state.allPlans?.getInsuranceResponses?.length === 1)) ? " and Personal Property Insurance Application and Certificate" : "")+"."}
                    </p>
                    : null}
                  <div className="d-flex">
                    <input
                      name="acknowledge"
                      id="acknowledge"
                      type="checkbox"
                      className="mb-auto"
                      value={this.state.acknowledgeCheckBox}
                      onClick={(e) => this.onChange(e)}
                      checked={
                        this.state.acknowledgeCheckBox === true ? "checked" : ""
                      }
                    />
                    <div>
                      <label htmlFor="acknowledge" id="acknowledgeLabel">
                        I acknowledge that I have received a{" "}
                      </label>
                      <input
                        name="paper"
                        id="paper"
                        type="checkbox"
                        className=""
                        value={this.state.paperCheckBox}
                        onClick={(e) => this.onChange(e)}
                        checked={this.state.paperCheckBox === true ? "checked" : ""}
                      />
                      <label htmlFor="paper" id="paperLabel">
                        paper
                      </label>
                      <input
                        name="electroniccopy"
                        id="electroniccopy"
                        type="checkbox"
                        value={this.state.electroniccopyCheckBox}
                        onClick={(e) => this.onChange(e)}
                        checked={
                          this.state.electroniccopyCheckBox === true
                            ? "checked"
                            : ""
                        }
                      />
                      <label htmlFor="electroniccopy" id="electroniccopyLabel">
                        electronic copy of the
                      </label>
                      <span onClick={() => this.setState({showPrePurchaseSummary : !this.state.showPrePurchaseSummary})} className="ml-0" style={{ color: "rgb(0, 174, 239)", cursor: "pointer", position: "relative", top:"-4px" }}>
                      {this.props.offerAcceptedState === "CA" ?
                        <u>CONSUMER CREDIT INSURANCE PRE-PURCHASE AND SUMMARY OF
                        COVERAGES DISCLOSURE.</u>
                      : this.props.offerAcceptedState === "FL" ? <u>CREDIT INSURANCE ELIGIBILITY DISCLOSURE.</u>
                      : 
                        <u>CONSUMER INSURANCE SUMMARY{(this.state.step2 || (this.state.stateTexas && this.state.allPlans?.getInsuranceResponses?.length === 1)) ? " AND PERSONAL PROPERTY INSURANCE APPLICATION AND CERTIFICATE" : ""}.</u> }
                      </span>  
                    </div>
                  </div>
                  <div className="row mt-3 mb-2">
                    <div className="col-12 col-md-6 col-lg-6">
                      <button
                        className="btn p-lg-3 bg-secondary text-primary w-100"
                        onClick={() => this.handleOnClick("Accept")}
                        disabled={
                          !(
                            this.state.acknowledgeCheckBox &&
                            (this.state.paperCheckBox ||
                              this.state.electroniccopyCheckBox)
                          )
                        }
                      >
                        Yes, I’d like to enroll in credit Insurance.
                      </button>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-0 mt-lg-0">
                      <button
                        className="btn p-lg-3 bg-primary text-secondary w-100"
                        onClick={() => this.handleOnClick("Decline")}
                         // disabled={
                        //   !(
                        //     this.state.acknowledgeCheckBox &&
                        //     (this.state.paperCheckBox ||
                        //       this.state.electroniccopyCheckBox)
                        //   )
                        // }
                      >
                        I am declining credit insurance.
                      </button>
                    </div>
                  </div>
                </>
                }
                </>
              ) : this.state.status === "Completed" ? (
                //Insurance flow completed scenario
                <>
                  <p>Your current offer is protected by <b>Credit Insurance</b>.</p>
                  <button className="btn bg-secondary text-primary pl-5 pr-5 pt-2 pb-2 float-right" onClick={() => this.props.triggerInsurance(this.state.status)}>OK</button>
                </>
              ) :
                <>
                  {this.state.status === "Accept" ?
                    //Accept scenario
                    <>
                      <p className="mt-3 mb-4 text-center h4">
                        <b>PLANS</b>
                      </p>
                      <div className="container">
                        <div className="row justify-content-center">
                          {this.state.insurance?.map((item) => {
                            return (
                              <div className="col-12 col-md-4 col-lg-4">
                                <Card
                                  className={
                                    item.insurance_Plan?.split("_")[0] === this.state.selectedPlan
                                      ? " border-secondary rounded-0"
                                      : " border-0"
                                  }
                                  onClick={() => this.onPlanChange(item.insurance_Plan?.split("_")[0])}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Card.Body>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                      <p className="h4 mb-0 mt-3">
                                        <u>
                                          <b>{item.insurance_Plan?.split("_")[0]?.toUpperCase()}</b>
                                        </u>
                                      </p>
                                      <div className="d-flex mt-4">
                                        <span className="mb-0 h1">
                                          <b>{item?.totalCost?.split(".")[0]}.</b>
                                        </span>
                                        <div className="d-flex flex-column">
                                          <p className="mb-0">
                                            <b>{item?.totalCost?.split(".")[1]}</b>
                                          </p>
                                          <p className="mb-0">Per Month</p>
                                        </div>
                                      </div>
                                      <p className="mb-3 text-center">
                                        Per $100 of outstanding amount
                                      </p>
                                    </div>
                                  </Card.Body>
                                </Card>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <hr></hr>
                      <div className="row justify-content-center">
                        {this.state.goldPlan || this.state.silverPlan || this.state.bronzePlan ?
                          <>
                            {this.state.coverage.insurance_life != "no" && this.state.coverage.insurance_life != "" ?
                              <div className="col-6 col-md-3 text-center mt-4 mb-4">
                                <FontAwesomeIcon
                                  icon={faHeartbeat}
                                  className="mb-3"
                                  size="3x"
                                />
                                <p className="mb-0">Life</p>
                                <p className="mb-0">{this.state.coverage.insurance_life}</p>
                              </div>
                              : null}
                            {this.state.coverage.insurance_disability != "no" && this.state.coverage.insurance_disability != "" ?
                              <div className="col-6 col-md-3 text-center mt-4 mb-4">
                                <FontAwesomeIcon
                                  icon={faWheelchair}
                                  className="mb-3"
                                  size="3x"
                                />
                                <p className="mb-0">Total Disability</p>
                                <p className="mb-0">{this.state.coverage?.insurance_disability}</p>
                              </div>
                              : null}
                            {this.state.coverage.insurance_unemployment != "no" && this.state.coverage.insurance_unemployment != "" ?
                              <div className="col-6 col-md-3 text-center mt-4 mb-4">
                                <FontAwesomeIcon
                                  icon={faSuitcase}
                                  className="mb-3"
                                  size="3x"
                                />
                                <p className="mb-0">Involuntary Unemployment</p>
                                <p className="mb-0">{this.state.coverage?.insurance_unemployment}</p>
                              </div>
                              : null}
                            {this.state.coverage.insurance_property != "no" && this.state.coverage.insurance_property != "" ?
                              <div className="col-6 col-md-3 text-center mt-4 mb-4">
                                <FontAwesomeIcon
                                  icon={faBuilding}
                                  className="mb-3"
                                  size="3x"
                                />
                                <p className="mb-0">Property</p>
                                <p className="mb-0">{this.state.coverage?.insurance_property}</p>
                              </div>
                              : null}
                          </>
                          : null}
                      </div>
                      {this.state.goldPlan ||
                        this.state.silverPlan ||
                        this.state.bronzePlan ? (
                        <>
                          <hr></hr>
                          <Card className="rounded-0 shadow mb-3">
                            <Card.Body>
                              <div className="form-group d-flex text-justify">
                                <input
                                  type="checkbox"
                                  id="disclosureOne"
                                  className="mr-2 mb-auto"
                                  value={this.state.disclosureOne}
                                  onChange={() => this.onPlanChange("DisOne")}
                                />
                                <label htmlFor="disclosureOne">
                                  Yes! I elect to purchase optional credit insurance at
                                  the cost disclosed above.
                                </label>
                              </div>
                              <div className="form-group d-flex text-justify">
                                <input
                                  type="checkbox"
                                  id="disclosureTwo"
                                  className="mr-2 mb-auto"
                                  value={this.state.disclosureTwo}
                                  onChange={() => this.onPlanChange("DisTwo")}
                                />
                                <label htmlFor="disclosureTwo">
                                  By submitting this application, I acknowledge that my
                                  electronic signature below indicates that I have: 1)
                                  read and received a copy of the {this.props.offerAcceptedState === "CA" ?
                                    "Credit Insurance Pre-Purchase and Summary of Coverages Disclosure " 
                                    : this.props.offerAcceptedState === "FL" ? "Credit Insurance Eligibility Disclosure "
                                    : "Consumer Insurance Summary "+(this.state.step2 || (this.state.stateTexas && this.state.allPlans?.getInsuranceResponses?.length === 1) ? "and, if purchasing optional property insurance, a copy of the Personal Property Insurance Application and Certificate " : "") }
                                  mentioned above, 2) elected the plan described above,
                                  and 3) agree to receive an electronic copy of the
                                  contents of this document and my insurance
                                  certificates.
                                </label>
                              </div>
                              <div className="form-group d-flex text-justify">
                                <input
                                  type="checkbox"
                                  id="disclosureThree"
                                  className="mr-2 mb-auto"
                                  value={this.state.disclosureThree}
                                  onChange={() => this.onPlanChange("DisThree")}
                                />
                                <label htmlFor="disclosureThree">
                                  By electing optional credit insurance, I acknowledge
                                  that: I do not need to purchase this insurance to get
                                  credit and I can get similar coverage including
                                  property coverage, if included, from any insurer I
                                  choose. The credit insurance includes, depending on
                                  the Plan selected, credit life, total disability,
                                  involuntary unemployment, and/or property to the
                                  extent available in my state as described in the
                                  {this.props.offerAcceptedState === "CA" ?
                                    " Credit Insurance Pre-Purchase and Summary of Coverages Disclosure" 
                                    : this.props.offerAcceptedState === "FL" ? " Credit Insurance Eligibility Disclosure"
                                    : " Consumer Insurance Summary"+(this.state.step2 || (this.state.stateTexas && this.state.allPlans?.getInsuranceResponses?.length === 1) ? " and, if purchasing optional property insurance, a copy of the Personal Property Insurance Application and Certificate" : "") }. I read and I meet the age and/or
                                  employment eligibility requirements shown in the
                                  {this.props.offerAcceptedState === "CA" ?
                                    " Credit Insurance Pre-Purchase and Summary of Coverages Disclosure"
                                    : this.props.offerAcceptedState === "FL" ? " Credit Insurance Eligibility Disclosure"
                                    : " Consumer Insurance Summary"+(this.state.step2 || (this.state.stateTexas && this.state.allPlans?.getInsuranceResponses?.length === 1) ? " and, if purchasing optional property insurance, a copy of the Personal Property Insurance Application and Certificate" : "") }. Monthly premium charges are based on the
                                  account balance and the rate shown. I will receive
                                  notice of any rate increase. I may cancel anytime.
                                </label>
                              </div>
                            </Card.Body>
                          </Card>
                        </>
                      ) : null}
                    </>
                    :
                    //Decline scenario
                    <>
                      <div className="form-group d-flex">
                        <input
                          type="checkbox"
                          id="declineCheckBox"
                          value={true}
                          className="mr-2 mb-auto"
                          checked={true}
                        />
                        <label htmlFor="declineCheckBox">
                          By submitting this application, I acknowledge that my
                          electronic signature below indicates that I am NOT enrolling
                          in any of the available insurance programs and decline all
                          coverage benefits offered at this time.
                        </label>
                      </div>
                    </>
                  }

                  <div className="row">
                    <div className={"col-12 " + (this.state.goldPlan || this.state.silverPlan || this.state.bronzePlan || this.state.status === "Decline" ? "offset-md-4 col-md-4 offset-lg-6 col-lg-3" : "offset-md-8 col-md-4 offset-lg-9 col-lg-3")}>
                      <button
                        className="btn pt-2 pb-2 border customtext-secondary w-100"
                        style={{ backgroundColor: "#E5D7EC" }}
                        onClick={() => this.handleBackClick()}
                      >
                        CANCEL
                      </button>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 mt-3 mt-md-0 mt-lg-0">
                      {this.state.goldPlan ||
                        this.state.silverPlan ||
                        this.state.bronzePlan ||
                        this.state.status === "Decline" ? (
                        <button
                          className="btn pt-2 pb-2 bg-secondary text-primary w-100"
                          onClick={() => this.onInsuraceEnroll()}
                          disabled={
                            this.state.status === "Decline" ? false :
                              !(
                                this.state.disclosureOne &&
                                this.state.disclosureTwo &&
                                this.state.disclosureThree
                              )
                          }
                        >
                          {this.state.status === "Decline" ? "CONFIRM" : "ENROLL"}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </>}
            </Modal.Body>
          </Modal>
          :
          <Modal show={this.props.insurance}>
            <Modal.Header className="align-items-center">
                <span className="mr-auto h4 mb-0">Credit Insurance</span>
                <FontAwesomeIcon icon={faTimesCircle} size="2x" className="ml-auto" style={{cursor : "pointer"}} onClick={() => this.props.triggerInsurance(this.state.status)} />
              </Modal.Header>
            <Modal.Body className="text-center">
              <h4 className="text-danger">Error!</h4>
              <p>Please close the insurance and try again</p>
              <button className="btn bg-secondary text-primary float-right pl-4 pr-4" onClick={() => this.props.triggerInsurance(this.state.status)}>Close</button>
            </Modal.Body>
          </Modal>
        }
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(InsuranceActions, dispatch),
    customerLookUpactions: bindActionCreators(customerLookupActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(CongrulationPopup);
