import React, { Component } from "react";
import { Card, ToastContainer, Toast } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faCircleChevronRight, faCircleInfo, faPrint, faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import './component.customerDetails.css';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import * as authActions from '../../redux/actions/authActions';
import { trackPromise } from 'react-promise-tracker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Moment from 'moment';
import * as applyNowActions from '../../redux/actions/applyNow.actions';
import CurrencyInput from 'react-currency-input-field';
import IdleTimeModal from "../../components/component.idleTimeModal";
import ReactToPrint from 'react-to-print';
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import { Link } from "react-router-dom";
import { IMaskInput } from 'react-imask';
import APIFailedMessage from "../../components/component.apiFailedMessage";
import ConfirmDialog from "../../components/component.confirmDialog";
import formatPhoneNumber from "../../components/component.formatPhoneNumber";
import GetDateFromMMYYYY from "../../components/component.GetDateFromMMYYYY";
import * as synchronyActions from "../../redux/actions/synchronyLookup.actions";
import { DanielsOffersStatus, FortivaOffersStatus, SynchronyOffersStatus, ProgressiveOffersStatus, UOwnOffersStatus } from "../../components/component.applicationStatus";
import * as customerLookupActions from "../../redux/actions/customerLookup.actions";
import TenderModal from '../../components/component.tenderModal';
import EmailApplyLink from "../../components/component.emailApplyLink";
import * as creditApplication from "../../redux/actions/creditApplication.actions";
import GetInfo from "../../components/component.getInfo";
// import ProgUownDialog from "../../components/component.noFortivaDialog";
// import FortivaDialog from "../../components/component.noFortivaDialog";
// import FortivaErrorDialog from "../../components/component.fortivaErrorDialog";
import * as AdminActions from "../../redux/actions/admin.actions";
import * as accountSummaryAction from "../../redux/actions/accountSummary.actions";
import { toast } from "react-toastify";
import * as customerUpdationActions from "../../redux/actions/customerUpdation.actions";
import Autocomplete from 'react-autocomplete';

class CustomerDetails extends Component {
    constructor() {
        super();
        this.state = {
            toggled: false,
            show: false,
            creditModal: false,
            borrowerExpand: true,
            Co_BorrowerExpand: false,
            psBorrowerExpand: true,
            psCo_BorrowerExpand: false,
            customerPreApprove: false,
            custBorrowerInfo: {
                account: "",
                firstName: "",
                middleName: "",
                lastName: "",
                mailingAdr: "",
                city: "",
                state: "0",
                zipCode: "",
                mobileNo: "",
                emailAdr: "",
                empName: "",
                empPhone: "",
                duties: "",
                empStartDate: "",
                netIncome: "",
                ssn: "",
                dob: "",
                primaryIdNo: "",
            },
            custCoBorrowerInfo: {
                title_code: "",
                secondaryPhone: "",
                birthDate: "",
                suffix_Id: "0",
                masked_ssn: "",
                masked_idNumber: "",
                emailAddress: "",
                workJob: "",
                monthlyIncome: "",
                howLong: "",
                account: "",
                firstName: "",
                middleName: "",
                lastName: "",
                mailingAdr: "",
                city: "",
                state: "0",
                zipCode: "",
                mobileNo: "",
                emailAdr: "",
                empName: "",
                empPhone: "",
                duties: "",
                empStartDate: "",
                netIncome: "",
                ssn: "",
                dob: "",
                primaryIdNo: "",
            },
            appBorrowerInfo: {
                account: "",
                firstName: "",
                middleName: "",
                lastName: "",
                mailingAdr: "",
                city: "",
                state: "0",
                zipCode: "",
                mobileNo: "",
                emailAdr: "",
                empName: "",
                empPhone: "",
                duties: "",
                empStartDate: "",
                netIncome: "",
                ssn: "",
                dob: "",
                primaryIdNo: "",
            },
            appCoBorrowerInfo: {
                title_code: "",
                secondaryPhone: "",
                birthDate: "",
                suffix_Id: "0",
                masked_ssn: "",
                masked_idNumber: "",
                emailAddress: "",
                workJob: "",
                monthlyIncome: "",
                howLong: "",
                account: "",
                firstName: "",
                middleName: "",
                lastName: "",
                mailingAdr: "",
                city: "",
                state: "0",
                zipCode: "",
                mobileNo: "",
                emailAdr: "",
                empName: "",
                empPhone: "",
                duties: "",
                empStartDate: "",
                netIncome: "",
                ssn: "",
                dob: "",
                primaryIdNo: "",
            },
            printAtStore : false,
            sentToMagix : false,
            emailCredit: false,
            printError : "",
            emailCustomerService: false,
            lstSuffix: [],
            lstState: [],
            lstHousingInfo: [],
            lstOftenPaid: [],
            lstPrimaryIdType: [],
            lstSecondIdType: [],
            customerId: "",
            store: "",
            formObj: {
                title: "",
                borrowerName: "",
                suffix: "0",
                address: "",
                apt: "",
                city: "",
                state: "0",
                zipCode: "",
                mobileNo: "",
                emailAdr: "",
                mobileNo2: "",
                dob: "",
                maskedSsn: "",
                primaryIdNo: "",
                empName: "",
                empPhoneNo: "",
                duties: "",
                netIncome: "",
                empStartDate: "",
            },
            coFormObj: {
                title: "",
                coBorrowerName: "  ",
                suffix: "0",
                mobileNo: "",
                emailAdr: "",
                mobileNo2: "",
                dob: "",
                ssn: "",
                primaryIdNo: "",
                empName: "",
                empPhoneNo: "",
                duties: "",
                netIncome: "",
                empStartDate: "",
            },
            offerStatus: {
                danielsStatus: "",
                synchronyStatus: "",
                progressiveStatus: "",
                uownStatus: "",
            },
            cusPreApproval: [],
            applicationOffers:[],
            comments: "",
            roles: [],
            site_page_details: [],
            sitePages: [],
            userRights: [],
            permission_status: '',
            admin_permission_status : '',
            toastModal: false,
            smsMobileNumber: "",
            verificationCode: "",
            phoneNumErr: "",
            verificationCodeErr: "",
            isSMSVerified: false,
            isSMSOverride: false,
            smsModal: false,
            isSMSError: false,
            SMSCodeSent: false,
            stateList_credit: [],
            dataSavedError : false,
            dataSavedSuccess : false,
            synchronyOffers:{
                applicationId:"",
                status:"",
                createdDate:"",
                errorMessage:"",
                openToBuy:null
            },
            showTender : false,
            currentTender : "",
            // synchronyPending:false,
            // synchronyDeclined:false,
            // showProgUown:false,
            // showFortiva:false,
            // successMsg:"",
            // offerModal: false,
            // associateId:localStorage.getItem('loggedUserEmail')!="null"?localStorage.getItem('loggedUserEmail'):"",
            // store_Id:localStorage.getItem("associateStores")!="null"?JSON.parse(localStorage.getItem("associateStores")):"",
            // fortivaAppError:false,
            // fortivaAppErrMsg:"",
            dpay_offers : [],
            financialPartner:"",
            selectedMagixAcct:undefined,
            addOn : false,
            reOpen : false, 
            newApplication : false,
            fortivaApp : false,
            danielsApp : false,
            progressiveApp : false,
            uownApp : false,
            fortivaOffer: [],
            isDanielsApplicationFlowChanges:false,
            editMasterCustomer : false,
            masterCustomerInfo : {},
            formErrors : {},
            addressAutoFillEnable : false,
            allAddress : [],
            allAptUnit : [],
        };
    }

    loadApplicationData(applicationId) {
        if(applicationId){
            trackPromise(
                this.props.actions.getApplicationDetails(applicationId).then(
                    response => {
                        if(response){
                            this.applicationData(response);
                            // if (JSON.parse(sessionStorage.getItem("firstTimeFromApplyNow")) === true && response[0].isDaniel) {
                            //     sessionStorage.setItem("firstTimeFromApplyNow", false);
                            //     document.getElementById("printToStore").click();
                            // }
                            //dipsplay prog uown popup except synchrony and fortiva combination.
                            // let submitAppFirst=sessionStorage.getItem("submitAppFirst");
                            //     if(submitAppFirst){
                            //         let submitforFortiva= localStorage.getItem('submitforFortiva');
                            //         if(submitforFortiva){
                            //             if(submitforFortiva=="false"){
                            //                 submitAppFirst=JSON.parse(submitAppFirst);
                            //                 if(submitAppFirst.customerId==response[0].customer_Id){
                            //                     if(submitAppFirst.applicationId==response[0].application_id){
                            //                         if((response[0].isSynchrony===true  || response[0].isFortiva===true ||  response[0].isDaniel===true) && (response[0].isProgressive===false && response[0].isUown===false) && submitAppFirst.isSubmitAppFirst==true ) {
                            //                             this.setState({showProgUown:!this.state.showProgUown});
                            //                         }
                            //                     }
                            //                 }
                            //             }
                            //         }
                            //    }
                            }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
            trackPromise(
                this.props.creditApplication.getApplicationViewOffers(GetInfo.getCustomerId(),applicationId).then(
                    response => {
                        this.setState({ applicationOffers: response.data });
                    }).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
            )
        }   
        this.loadViewOffers();
    }

    applicationData(response) {
        sessionStorage.setItem("selectedUserPhone",response[0].mobileNo == null ? "" : response[0].mobileNo);
        sessionStorage.setItem("selectedUserEmail",response[0].emailAddress == null ? "" : response[0].emailAddress);
        localStorage.setItem("selectedCustomer", response[0].customer_Id);
        sessionStorage.setItem("createdDate",response[0].createdDate);
        sessionStorage.setItem("isSynchrony",response[0].isSynchrony);
        localStorage.setItem('lastName', response[0].lastName);
        localStorage.setItem("creditappEmailAddr",response[0].emailAddress);
        this.setState({appBorrowerInfo:response[0]});
        this.updateState("txtCustomer", (response[0].customer_Id == null ? "" : response[0].customer_Id));
        this.updateState("txtStore", (response[0].store_code == null ? "" : response[0].store_code));
        this.updateState("txtTitle", (response[0].title_code == null ? "" : response[0].title_code));
        let borrowerName="";
        if(response[0].firstName){
            borrowerName= response[0].firstName;
        }
        if(response[0].middleName){
            borrowerName= borrowerName+" "+response[0].middleName;
        }
        if(response[0].lastName){
            borrowerName= borrowerName+" "+response[0].lastName;
        }
        this.updateState("txtBorrowerName",borrowerName);
       
        this.updateState("txtSuffix", (response[0].suffix_Id == null ? "" : response[0].suffix_Id));
        this.updateState("txtAddress", (response[0].address == null ? "" : response[0].address));
        this.updateState("txtApt", (response[0].apt == null ? "" : response[0].apt));
        this.updateState("txtCity", (response[0].city == null ? "" : response[0].city));
        this.updateState("txtMobileNo", (response[0].mobileNo == null ? "" : response[0].mobileNo));
        if(response[0].state_Id) {
            let state=this.state.stateList_credit?.find((val) => val.id === response[0].state_Id);
            if(state) {
                state=this.state.lstState?.find((val) => val.description?.toLowerCase() === state.description?.toLowerCase());
                this.updateState("cmbState", (state?.id === null || state?.id === undefined ? "0" : state?.id));
                this.setState({appBorrowerInfo : {
                        ...this.state.appBorrowerInfo, state_Id : (state?.id === null || state?.id === undefined ? "0" : state?.id)}
                });
            }
            else {
                this.updateState("cmbState", (response[0].state_Id == null ? "0" : response[0].state_Id));
            }
        }
        this.updateState("txtZipCode", (response[0].zipCode == null ? "" : response[0].zipCode));
        this.updateState("txtMobileNo2", (response[0].secondaryPhone == null ? "" : response[0].secondaryPhone));
        this.updateState("txtEmailAdr", (response[0].emailAddress == null ? "" : response[0].emailAddress));
        this.setState({
            formObj: { ...this.state.formObj, dob: (response[0].birthDate == null ? "" : Moment(response[0].birthDate).format("MM/DD/YYYY")) },
        });
        this.updateState("txtSSN", (response[0].ssn == null ? "" : response[0].ssn));
        // this.updateState("txtSSN", (response[0].masked_ssn == null ? "" : response[0].masked_ssn));
        this.updateState("txtIDNo", (response[0].idNumber == null ? "" : response[0].idNumber));
        this.updateState("txtEmpName", (response[0].empName == null ? "" : response[0].empName));
        this.updateState("txtEmpPhoneNo", (response[0].empPhone == null ? "" : response[0].empPhone));
        this.updateState("txtDuties", (response[0].workJob == null ? "" : response[0].workJob));
        this.updateState("txtNetIncome", (response[0].monthlyIncome == null ? "" : response[0].monthlyIncome));
        localStorage.setItem("monthlyIncome",response[0].monthlyIncome);
        this.setState({
            formObj: { ...this.state.formObj, empStartDate: (response[0].howLong == null ? "" : Moment(response[0].howLong).format("MM/YYYY")) },
        });
        this.setState({
            formObj: { ...this.state.formObj, createdDate: (response[0].createdDate == null ? "" : Moment(response[0].createdDate).format("MM/DD/YYYY")) },
        });
        let obj={
            isSynchrony:response[0].isSynchrony,
            customerId:response[0].id
        }
        sessionStorage.getItem("isLastAppDaniel", response[0].isDaniel);
        sessionStorage.setItem("credit_Id",response[0]?.credit_Id);
        //localStorage.setItem('synchronyObj', JSON.stringify(obj));
        if (response[1] !== undefined) {
            this.setState({ appCoBorrowerInfo: response[1] });
            this.updateState("coTxtTitle", (response[1].title_code == null ? "" : response[1].title_code));
            this.updateState("coTxtCoBorrowerName", (response[1].firstName == null ? "" : response[1].firstName) + " " + (response[1].middleName == null ? "" : response[1].middleName) + " " + (response[1].lastName == null ? "" : response[1].lastName));
            this.updateState("coTxtSuffix", (response[1].suffix_Id == null ? "" : response[1].suffix_Id));
            this.updateState("coTxtMobileNo", (response[1].mobileNo == null ? "" : response[1].mobileNo));
            this.updateState("coTxtEmailAdr", (response[1].emailAddress == null ? "" : response[1].emailAddress));
            this.updateState("coTxtMobileNo2", (response[1].secondaryPhone == null ? "" : response[1].secondaryPhone));
            this.setState({
                coFormObj: { ...this.state.coFormObj, dob: (response[1].birthDate == null ? "" : Moment(response[1].birthDate).format("MM/DD/YYYY")) },
            });
            this.updateState("coTxtSSN", (response[1].ssn == null ? "" : response[1].ssn));
            // this.updateState("coTxtSSN", (response[1].masked_ssn == null ? "" : response[1].masked_ssn));
            this.updateState("coTxtIDNo", (response[1].idNumber == null ? "" : response[1].idNumber));
            this.updateState("coTxtEmpName", (response[1].empName == null ? "" : response[1].empName));
            this.updateState("coTxtEmpPhoneNo", (response[1].empPhone == null ? "" : response[1].empPhone));
            this.updateState("coTxtDuties", (response[1].workJob == null ? "" : response[1].workJob));
            this.updateState("coTxtNetIncome", (response[1].monthlyIncome == null ? "" : response[1].monthlyIncome));
            this.setState({
                coFormObj: { ...this.state.coFormObj, empStartDate: (response[1].howLong == null ? "" : Moment(response[1].howLong).format("MM/YYYY")) },
            });
        }
    }

    revertChanges() {
        document.getElementById('printSummaryDiv').scrollIntoView();
        this.setState({ show: !this.state.show, isSMSOverride: false, isSMSVerified: false, printAtStore : false, sentToMagix: false, emailCredit: false, psCo_BorrowerExpand: false, customerPreApprove: false, SMSCodeSent : false, isSMSError: false, verificationCode : "",phoneNumErr:"" });
        let response = this.state.appBorrowerInfo;
        let co_response = this.state.appCoBorrowerInfo;
        let borrowerName="";
        if(response.firstName){
            borrowerName= response.firstName;
        }
        if(response.middleName){
            borrowerName= borrowerName+" "+response.middleName;
        }
        if(response.lastName){
            borrowerName= borrowerName+" "+response.lastName;
        }
       
        this.setState({
            formObj: {
                ...this.state.formObj,
                title: (response.title_code == null ? "" : response.title_code),
                borrowerName: borrowerName,
                suffix: (response.suffix_Id == null ? "0" : response.suffix_Id),
                address: (response.address == null ? "" : response.address),
                apt: (response.apt == null ? "" : response.apt),
                city: (response.city == null ? "" : response.city),
                state: (response.state_Id == null ? "0" : response.state_Id),
                zipCode: (response.zipCode == null ? "" : response.zipCode),
                mobileNo: (response.mobileNo == null ? "" : response.mobileNo),
                emailAdr: (response.emailAddress == null ? "" : response.emailAddress),
                mobileNo2: (response.secondaryPhone == null ? "" : response.secondaryPhone),
                dob: (response.birthDate == null ? "" : Moment(response.birthDate).format("MM/DD/YYYY")),
                ssn: (response.masked_ssn == null ? "" : response.masked_ssn),
                primaryIdNo: (response.idNumber == null ? "" : response.idNumber),
                empName: (response.empName == null ? "" : response.empName),
                empPhoneNo: (response.empPhone == null ? "" : response.empPhone),
                duties: (response.workJob == null ? "" : response.workJob),
                netIncome: (response.monthlyIncome == null ? "" : response.monthlyIncome),
                empStartDate: (response.howLong == null ? "" : Moment(response.howLong).format("MM/YYYY")),
            },
            coFormObj: {
                ...this.state.coFormObj,
                title: (co_response.title_code == "" || co_response.title_code == null ? "" : co_response.title_code),
                coBorrowerName: (co_response.firstName == "" || co_response.firstName == null ? "" : co_response.firstName) + " " + (co_response.middleName == "" || co_response.middleName == null ? "" : co_response.middleName) + " " + (co_response.lastName == "" || co_response.middleName == null ? "" : co_response.lastName),
                suffix: ((co_response.suffix_Id == "" || co_response.suffix_Id === null) ? "0" : co_response.suffix_Id),
                mobileNo: (co_response.mobileNo == null || co_response.mobileNo == "" ? "" : co_response.mobileNo),
                emailAdr: (co_response.emailAddress == null || co_response.emailAddress == "" ? "" : co_response.emailAddress),
                mobileNo2: (co_response.secondaryPhone == null || co_response.secondaryPhone == "" ? "" : co_response.secondaryPhone),
                dob: (co_response.birthDate == null || co_response.birthDate == "" ? "" : Moment(co_response.birthDate).format("MM/DD/YYYY")),
                ssn: (co_response.ssn == null || co_response.ssn == "" ? "" : co_response.ssn),
                primaryIdNo: (co_response.idNumber == null || co_response.idNumber == "" ? "" : co_response.idNumber),
                empName: (co_response.empName == null || co_response.empName == "" ? "" : co_response.empName),
                empPhoneNo: (co_response.empPhone == null || co_response.empPhone == "" ? "" : co_response.empPhone),
                duties: (co_response.workJob == null || co_response.workJob == "" ? "" : co_response.workJob),
                netIncome: (co_response.monthlyIncome == null || co_response.monthlyIncome == "" ? "" : co_response.monthlyIncome),
                empStartDate: (co_response.howLong == null || co_response.howLong == "" ? "" : Moment(co_response.howLong).format("MM/YYYY")),
            },
            comments: ""
        });
    }

    async loadMultipleMagixAccountID(res) {
        let obj={
            ssn:res[0]?.ssn,
            dob:Moment(res[0]?.birthDate).format("MM/DD/YYYY")
        }
        let magix_account_no="";
        sessionStorage.setItem("ssn&dob",res[0]?.ssn+"&"+res[0]?.birthDate);
        let lastChargedMagixAcc = undefined;
        if(this.state.isDanielsApplicationFlowChanges){
            await trackPromise(
                this.props.actions.getAllMagixTransactionDates(obj).then((response) => {
                    if(response) {
                        lastChargedMagixAcc = response[0]?.magix_Id;
                    }
                })
            )
        }
        await trackPromise(
        this.props.actions.getAllMagixAccountNumbers(obj).then( async response => {
            if(response) {
                  if(response?.length > 0) {
                    let data = [];
                    let magixAccNo = localStorage.getItem("magix_account_no");
                    let magixID = response?.map((val) => {
                        return (val.magix_Id+"-"+val.magixStoreCode+"-"+val.currentBalance);
                    });
                    if(this.state.isDanielsApplicationFlowChanges){
                        data = response?.find(item => {
                            return item.magix_Id == lastChargedMagixAcc;
                        });
                        let acc_stat_code=response?.find(item => {return item.magix_Id == lastChargedMagixAcc}).creditRating;
                            if(acc_stat_code){
                                await trackPromise;(
                                    this.props.actions.isNewAccoutRequired(acc_stat_code).then( response => {
                                        if(response?.toLowerCase()==="y"){
                                            this.setState({newApplication:true})
                                        }
                                    }
                                ));
                            }
                        localStorage.setItem("magix_account_no", lastChargedMagixAcc);
                        localStorage.setItem("storeCode", data?.magixStoreCode);
                        localStorage.setItem("magixassociateId", lastChargedMagixAcc);
                    }
                    else {
                        data = response?.find(item => {
                            return item.magix_Id == magixAccNo;
                        });
                        localStorage.setItem("magixassociateId", magixAccNo);
                    }
                    this.setState({selectedMagixAcct:data});
                    magix_account_no=magixID;
                    sessionStorage.setItem("multipleMagixId",magixID);
                    sessionStorage.setItem("magixInstoreData", magixID[0]?.split("-")[1]);
                  }
                  else {
                    localStorage.setItem("magix_account_no", "");
                    sessionStorage.setItem("multipleMagixId","");
                    sessionStorage.setItem("magixInstoreData", "");
                  }
            }
        }));
        if(magix_account_no !== "")
            magix_account_no?.map(item => {
            let magixID = item?.split("-")[0];
            trackPromise(
                this.props.actions.getDPayOffes(magixID).then(response => {
                    if(response) {
                        if(response?.data[0] !== undefined)
                        this.setState({dpay_offers : [...this.state.dpay_offers , response?.data[0]]})}
                })
            );
        })            
    }

    componentDidMount() {
        if (JSON.parse(sessionStorage.getItem('isLoggedIn'))) {
            this.loadDropDowns("State");
            this.loadDropDowns("State_Credit");
            this.loadDropDowns("Suffix");
            this.loadDropDowns("HousingInfo");
            this.loadDropDowns("OftenPaid");
            this.loadDropDowns("PrimaryIdType");
            this.loadDropDowns("secondIdType");
            if (localStorage.getItem("customerId") !== "null") {
                let obj = localStorage.getItem("customerId");
                trackPromise(
                    this.props.actions.getCustomerDetails_new(obj).then(
                        async response => {
                            if(response){
                                this.loadMultipleMagixAccountID(response);
                                if(sessionStorage.getItem("fromInstoreDuplicatePopup") == "true") {
                                    localStorage.setItem("magix_account_no", response[0].magix_Id);
                                    localStorage.setItem("storeCode", response[0].magixStoreCode);
                                    sessionStorage.removeItem("fromInstoreDuplicatePopup");
                                }
                                sessionStorage.setItem("magixInstoreData", response[0].magixStoreCode);
                                for(const item in response[0]) {
                                    if(response[0][item] === "(null)") {
                                        response[0][item] = null;
                                    } 
                                }
                                this.setState({ custBorrowerInfo: response[0], masterCustomerInfo : response[0] });
                                localStorage.setItem("selectedUserName", (response[0].firstName === null ? "" : response[0].firstName + " ") +(response[0].middleName === null ? "" : response[0].middleName + " ")+ (response[0].lastName === null ? "" : response[0].lastName ));
                                if(response[0].state_Id) {
                                    let state=this.state.stateList_credit?.find((val) => val.id === response[0].state_Id);
                                        if(state) {
                                            state=this.state.lstState?.find((val) => val.description?.toLowerCase() === state.description?.toLowerCase());
                                            this.setState({custBorrowerInfo : {
                                                    ...this.state.custBorrowerInfo, state_Id : (state?.id === null || state?.id === undefined ? "0" : state?.id)}
                                            });
                                        }
                                }
                                    localStorage.setItem("danielAppId",response[0].danielAppId);
                                    localStorage.setItem("progressiveAppId",response[0].progressiveAppId);
                                    localStorage.setItem("uownAppId",response[0].uownAppId);
                                    localStorage.setItem("synchronyAppId",response[0].synchronyAppId);
                                    localStorage.setItem("fortivaAppId",response[0].fortivaAppId);
                                    this.setState({applicationId:response[0].application_id});
                                    this.setState({
                                        offerStatus: { ...this.state.offerStatus, danielsStatus: response[0].danielsStatus, synchronyStatus: response[0].isSynchrony, progressiveStatus: response[0].progressiveStatus, uownStatus: response[0].uownStatus },
                                    });
                                    localStorage.setItem("synchronyStatus",response[0].synchronyStatus);
                                    localStorage.setItem("applicationId",response[0].application_id);
                                    this.loadApplicationData(response[0].application_id);
                                    if(response[0]?.isFortiva) {
                                        this.fortivaOffer();
                                    }
                                    if(response[0]?.isSynchrony) {
                                        await this.synchronyOffers();
                                    }
                                    await this.getAccountSummary();
                            }
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
            else {
                let obj = { associateId: localStorage.getItem("magixassociateId"), storecode: localStorage.getItem('storeCode') }
                trackPromise(
                    this.props.actions.getMagixCustomerDetails(obj).then(
                        response => {
                            this.loadApplicationData(response);
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
            trackPromise(
                this.props.userRightsactions.getUserRights().then(
                    response => {
                        if (response) {
                            this.setState({
                                roles: response.roles,
                                site_page_details: response.sitePageDetails,
                                sitePages: response.sitePages,
                                userRights: response.userRights
                            });
                            this.getPermissions()
                        }
                    }).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
            )
            trackPromise(
                this.props.adminActions.getallstoredetails()
                  .then((res) => {
                    let associatedSore=res?.find(item=>item.code===JSON.parse(localStorage.getItem("associateStores")));
                    if(associatedSore?.isFortiva) this.setState({fortivaApp:true});
                    if(associatedSore?.isDaniels) this.setState({danielsApp : true})
                    if(associatedSore?.isProgressive) this.setState({progressiveApp : true})
                    if(associatedSore?.isUown) this.setState({uownApp : true})
                  })
                  .catch((err) => {
                    APIFailedMessage.displayError(err);
                  })
            )
            trackPromise(
                this.props.adminActions.getSignOutTime()
                  .then((response) => {
                    if (response) {
                      this.setState({
                        isDanielsApplicationFlowChanges:response.isDanielsApplicationFlowChanges,
                        addressAutoFillEnable : response.isAddressAutoComplete,
                      });
                    }
                  })
                  .catch((err) => {
                    APIFailedMessage.displayError(err);
                  })
              );
        }
        else
            this.props.history.push("/");
    }

    getPermissions() {
        let role_name = localStorage.getItem('role_name'), curr_page_name = 'Customer Details', middle_page_name = 'View Offers', next_page_name = 'Add-On/Re-Open/Apply', admin_page_name = "customer update option in customer details page";
        const getsitepages = this.state.sitePages.filter((site_pages) => { return site_pages.name === 'Portal Page' })
        const getSitepagedetails = this.state.site_page_details.filter((site_page_details) => { return site_page_details.sitePage_id === getsitepages[0].id && (site_page_details.name === curr_page_name || site_page_details.name === middle_page_name || site_page_details.name === next_page_name) })
        const getRole = this.state.roles.filter((roles) => { return roles.name.toLowerCase() === role_name.toLowerCase() });
        let role_id = getRole[0].id;
        const role_permission_check = this.state.userRights.filter((user_rights) => { return (user_rights.sitePageDetail_id === getSitepagedetails[0].id || user_rights.sitePageDetail_id === getSitepagedetails[1].id || user_rights.sitePageDetail_id === getSitepagedetails[2].id) && user_rights.role_id === role_id });
        this.setState({ permission_status: role_permission_check })
        const getadminpagedetails = this.state.site_page_details.filter((site_page_details) => { return site_page_details.sitePage_id ===getsitepages[0].id && site_page_details.name?.toLowerCase() === admin_page_name})
        const admin_permission_check = this.state.userRights.filter((user_rights) => { return user_rights.sitePageDetail_id === getadminpagedetails[0].id && user_rights.role_id === role_id });
        this.setState({ admin_permission_status: admin_permission_check });
    }

    loadViewOffers() {
        let obj = JSON.parse(localStorage.getItem("customerId")) === null ? localStorage.getItem("magixassociateId") : localStorage.getItem("customerId");
        if(obj!="null"){
        trackPromise(
            this.props.actions.getCustomerViewOffers(GetInfo.getCustomerId(),obj).then(
                response => {
                    this.setState({ cusPreApproval: response.data });
                }).catch(err => {
                    APIFailedMessage.displayError(err);
                })
        )
    }
}

fortivaOffer() {
    let obj = JSON.parse(localStorage.getItem("customerId")) === null ? localStorage.getItem("magixassociateId") : localStorage.getItem("customerId");
    if(obj!="null"){
        trackPromise(
            this.props.actions.getViewOfferFortiva(obj).then((res) => {
                if(res) {
                    this.setState({fortivaOffer : res?.data});
                    let fortivaAppId = localStorage.getItem("fortivaAppId");
                    if(fortivaAppId != "null" && fortivaAppId != "undefined" && fortivaAppId !== "") {
                        trackPromise(
                            this.props.accountSummaryAction.getFortivaActSummmary(GetInfo.getCustomerId(), fortivaAppId).then(
                                response => {
                                    let fortivaOffer=this.state.fortivaOffer;
                                    fortivaOffer[0].openToBuy = response.openToBuy;
                                    this.setState({fortivaOffer : fortivaOffer});
                                }
                            ).catch(err => {
                                APIFailedMessage.displayError(err);
                            })
                        )
                    }
                }
            })
        )
        }
    }
    
    synchronyOffers(customerId,applicationId) {
        let synchronyAppId = localStorage.getItem("synchronyAppId") == null ? localStorage.getItem("magixassociateId") : localStorage.getItem("synchronyAppId");
        let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        if (synchronyAppId!="null" && synchronyAppId!="undefined") {
            trackPromise(
                this.props.actions.getSynchronyViewOffers(GetInfo.getCustomerId(),synchronyAppId,homeStore).then(
                    response => {
                        if(response){
                            // let isSynchrony=sessionStorage.getItem("isSynchrony");
                            // let isFortiva=sessionStorage.getItem("isFortiva");
                            // let submitAppFirst=sessionStorage.getItem("submitAppFirst"); 
                            this.setState({
                                synchronyOffers: { ...this.state.synchronyOffers, applicationId: response.applicationId,
                                    status:response.status,createdDate:response.createdDate,errorMessage:response.errorMessage},
                              });
                                // if(submitAppFirst){
                                //     let submitforFortiva= localStorage.getItem('submitforFortiva');
                                //     if(submitforFortiva=="true"){
                                //         submitAppFirst=JSON.parse(submitAppFirst);
                                //         if(submitAppFirst.customerId==customerId){
                                //             if(submitAppFirst.applicationId==applicationId){
                                //                 if(response.status?.toLowerCase() === "approved" && submitforFortiva=="true") {
                                //                     localStorage.setItem('submitforFortiva', false);
                                //                     this.setState({ showFortiva: true });
                                //                 }
                                //                 else  if(response.status?.toLowerCase() === "declined" && submitforFortiva=="true" && isSynchrony=="true" && isFortiva=="true") {
                                //                     //localStorage.setItem('submitforFortiva', false);
                                //                     //let declined=true;
                                //                     this.setState({synchronyDeclined:!this.state.synchronyDeclined});
                                //                     //this.submitFortiva("",declined);
                                //                 }
                                //                 else  if(response.status?.toLowerCase() === "pending" && submitforFortiva=="true" && isSynchrony=="true" && isFortiva=="true") {
                                //                     //localStorage.setItem('submitforFortiva', false);
                                //                     let pending=true;
                                //                     this.rejectFortiva(pending);
                                //                     this.setState({synchronyPending:!this.state.synchronyPending});
                                //                 }
                                //             }
                                //         }
                                //     }
                                // }
                        }

                    }).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
            )
        }
    }
     getAccountSummary() {
        // let phoneNo=sessionStorage.getItem("selectedUserPhone")!=="null"?sessionStorage.getItem("selectedUserPhone"):"";
        // let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
        // if(phoneNo.includes("-")){
        //     phoneNo = phoneNo.slice(0,3)+phoneNo.slice(4,7)+phoneNo.slice(8,12);
        // }
        //if(obj!=="null"){
            let ssnandzipcode = sessionStorage.getItem("ssn&zipcode");
            let filterObj = {
                ssn:ssnandzipcode?.split(",")[0]?.replace(/-/g,""),
                zipCode:ssnandzipcode?.split(",")[1],
                phoneNumber:"",
                accountNumber:"",
                homeStore : sessionStorage.getItem("synchronyStoreCode")
            }
            trackPromise(
                this.props.syncActions.getsynchronyLookup(filterObj).then(
                    response => {
                        if(response.accountNumber){
                            let offersResponse= this.state.synchronyOffers;
                            offersResponse.openToBuy = response.opentobuy;
                            this.setState({ synchronyOffers: offersResponse});
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            );
       // }
    }

    formatSSN(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, "");
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 6) {
            return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 5)}`;
        }
        return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
            3,
            5
        )}-${phoneNumber.slice(5, 9)}`;
    }

    onPrintOptionsChecked(event) {
        this.setState({printError : ""});
        if(event.target.id == "chkPrintAtStore") {
            this.setState({ printAtStore: !this.state.printAtStore });
        }
        else if(event.target.id == "chkSentToMagix") {
            this.setState({ sentToMagix: !this.state.sentToMagix });
        }
        // else if(event.target.id == "chkEmailCredit") {
        //     this.setState({ emailCredit: !this.state.emailCredit });
        // }
    }

    submitPrint(event) {
        event.preventDefault();
        if(!this.state.printAtStore && !this.state.sentToMagix) {
            this.setState({printError : "Please select atleast one option to submit"});
        }
        else {
            if(localStorage.getItem("customerId") != "null" || localStorage.getItem("magixassociateId")!=="null") {
                this.submitPrintSummaryData();
            }
            if(this.state.printAtStore) {
                document.getElementById("onScreenPrintTab").click();
            }
            if(this.state.sentToMagix) {
                let userStoreCode= localStorage.getItem("storeCode");
                let to="9"+userStoreCode+"@rfax.danielsjewelers.com";
                this.printToCreditDepartment(to);
            }
            // if(this.state.emailCredit) {
            //     this.printToCreditDepartment("");
            // }
        }
    }

    onEmailCustomerServiceChecked() {
        this.setState({ emailCustomerService: !this.state.emailCustomerService });
    }

    onStartDateChange(event) {
        if (event === null) {
            this.setState({
                formObj: { ...this.state.formObj, empStartDate: "" },
            });
        }
        else {
            this.setState({
                formObj: { ...this.state.formObj, empStartDate: Moment(event).format("MM/YYYY") },
            });
        }
    }
    coOnStartDateChange(event) {
        if (event === null) {
            this.setState({
                coFormObj: { ...this.state.coFormObj, empStartDate: "" },
            });
        }
        else {
            this.setState({
                coFormObj: { ...this.state.coFormObj, empStartDate: Moment(event).format("MM/YYYY") },
            });
        }
    }
    onDOBChange(event) {
        if (event === null) {
            this.setState({
                formObj: { ...this.state.formObj, dob: "" },
            });
        }
        else {
            this.setState({
                formObj: { ...this.state.formObj, dob: Moment(event).format("MM/DD/YYYY") },
            });
        }
    }
    coOnDOBChange(event) {
        if (event === null) {
            this.setState({
                coFormObj: { ...this.state.coFormObj, dob: "" },
            });
        }
        else {
            this.setState({
                coFormObj: { ...this.state.coFormObj, dob: Moment(event).format("MM/DD/YYYY") },
            });
        }
    }

    onCurrencyChange(value, name) {
        if (name === "txtNetIncome") {
            let income=(value!="" && value!== undefined)?Number(value):"";
            this.setState({
                formObj: { ...this.state.formObj, netIncome: income },
            });
        }
        else if (name === "coTxtNetIncome") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, netIncome: value },
            });
        }
    }

    onChange = (event) => {
        this.updateState(event.target.id, event.target.value);
    }

    creditApplication = (financial_partner) => {
        this.setState({ creditModal: !this.state.creditModal,financialPartner:financial_partner});
    }
    addOnandreOpen = (financial_partner, applicationType) => {
        this.setState({ creditModal: !this.state.creditModal,financialPartner:financial_partner});
        if(applicationType === "Add-On") {
            this.setState({addOn : true, reOpen : false, newApplication : false});
            sessionStorage.setItem("addOn", true);
            sessionStorage.setItem("reOpen", false);
            sessionStorage.setItem("newApplication", false);
        }
        else if (applicationType === "Re-Open"){
            this.setState({addOn : false, reOpen : true, newApplication : false});
            sessionStorage.setItem("addOn", false);
            sessionStorage.setItem("reOpen", true);
            sessionStorage.setItem("newApplication", false);
        }
        else if (applicationType === "Apply for New Account"){
            this.setState({addOn : false, reOpen : false, newApplication : true});
            sessionStorage.setItem("addOn", false);
            sessionStorage.setItem("reOpen", false);
            sessionStorage.setItem("newApplication", true);
        }
        else {
            this.setState({addOn : false, reOpen : false, newApplication : false});
            sessionStorage.setItem("addOn", false);
            sessionStorage.setItem("reOpen", false);
            sessionStorage.setItem("newApplication", false);
        }
    }

    stayInAndContinue = () => {
        this.setState({ creditModal: !this.state.creditModal });
        let appsettings = JSON.parse(localStorage.getItem("appsettings"));
        const CryptoJS = require('crypto-js');
        sessionStorage.setItem("logout/continue",false);
        sessionStorage.setItem("selectedPartner",this.state.financialPartner);
        var encryptedBytes = CryptoJS.AES.encrypt(appsettings.Keys.securityKey, JSON.parse(localStorage.getItem("appsettings")).Keys.securityKey);
        this.props.history.push("/apply-now?" + encryptedBytes);
    }

    logOutAndContinue = (event) => {
        event.preventDefault();
        this.setState({ creditModal: !this.state.creditModal });
        sessionStorage.clear();
        //localStorage.removeItem("searchObj");
        const CryptoJS = require('crypto-js');
        sessionStorage.setItem("logout/continue",true);
        sessionStorage.setItem("selectedPartner",this.state.financialPartner);
        if(this.state.addOn) {
            sessionStorage.setItem("addOn", true);
            sessionStorage.setItem("reOpen", false);
            sessionStorage.setItem("newApplication", false);
        }
        else if (this.state.reOpen){
            sessionStorage.setItem("addOn", false);
            sessionStorage.setItem("reOpen", true);
            sessionStorage.setItem("newApplication", false);
        }
        else if (this.state.newApplication){
            sessionStorage.setItem("addOn", false);
            sessionStorage.setItem("reOpen", false);
            sessionStorage.setItem("newApplication", true);
        }
        else{
            sessionStorage.setItem("addOn", false);
            sessionStorage.setItem("reOpen", false);
            sessionStorage.setItem("newApplication", false);
        }
        var url = "";
        if(JSON.parse(localStorage.getItem('applicationId')) > 0) {
            url = "associateEmail="+localStorage.getItem("loggedUserEmail")+"&token="+localStorage.getItem("token")+"&type=all&customerId="+localStorage.getItem('applicationId');
        }
        else {
            url = "associateEmail="+localStorage.getItem("loggedUserEmail")+"&token="+localStorage.getItem("token")+"&type=all&accountId="+localStorage.getItem('customerId');
        }
        var encryptedBytes = CryptoJS.AES.encrypt(url, JSON.parse(localStorage.getItem("appsettings")).Keys.securityKey);
        this.props.history.push("/apply-now?" + encryptedBytes);
    }

    downloadPDF(event) {
        event.preventDefault();
        window.location.href = "https://instoreportal-customers-int-svc.azurewebsites.net/v1/customers/print?"
            + "customer_ID=" + this.state.customerId
            + "&borrower_Name=" + this.state.formObj.firstName + " " + this.state.formObj.middleName + " " + this.state.formObj.lastName + ""
            + "&address=" + this.state.formObj.mailingAdr
            + "&city=" + this.state.formObj.city
            + "&state=" + this.state.lstState.find(obj => obj.id == this.state.formObj.state)?.description
            + "&zip=" + this.state.formObj.zipCode
            + "&mobile_Number=" + this.state.formObj.mobileNo
            + "&email=" + this.state.formObj.emailAdr
            + "&employer_Phone=" + this.state.formObj.empPhoneNo
            + "&employer=" + this.state.formObj.empName
            + "&type_Of_Work=" + this.state.formObj.duties
            + "&monthly_Net_Income=" + this.state.formObj.netIncome.toString()
            + "&start_Date=" + this.state.formObj.empStartDate
    }

    htmlContent() {
        let val = this.state.comments?.split("\n")?.map(val => {
            return (val +
                '<br>'
            );
        })
        let borrowerName="";
        if(this.state.appBorrowerInfo.firstName){
            borrowerName= this.state.appBorrowerInfo.firstName;
        }
        if(this.state.appBorrowerInfo.middleName){
            borrowerName= borrowerName+" "+this.state.appBorrowerInfo.middleName;
        }
        if(this.state.appBorrowerInfo.lastName){
            borrowerName= borrowerName+" "+this.state.appBorrowerInfo.lastName;
        }
        val = String(val)?.replace(/,/g, '');
        return '<!DOCTYPE html>' +
            '<html>' +
            '<head>' +
            '</head>' +
            '<body>' +
            '<h2>CUSTOMER INFORMATION - PRINT SUMMARY UPDATE</h2>' +
            '<table>' +
            '<tr>' +
            '<td>' +
            '<b>Customer ID : </b>' + (this.state.customerId) +
            '<br>' +
            '<b>Home Store : </b>' + (((localStorage.getItem("magixassociateId")) !== "null") ? this.state.appBorrowerInfo.store_code : (this.state.appBorrowerInfo?.magixStoreCode !== null ? this.state.appBorrowerInfo.magixStoreCode : "")) +
            '<br>' +
            '<b>Apply Store : </b>' + (JSON.parse(localStorage.getItem("associateStores"))) +
            '<br>' +
            '</td>' +
            // '<td>' +
            // '<b>Is SMS Code Sent : </b>' + (this.state.SMSCodeSent? "Yes" : "No") +
            // '<br>' +
            // '<b>Is MobileNo Verified : </b>' + (this.state.isSMSVerified ? "Yes" : "No") +
            // '<br>' +
            // '<b>Is SMS Override : </b>' + (this.state.isSMSOverride ? "Yes" : "No") +
            // '<br>' +
            // '</td>' +
            '</tr>' +
            '</table>' +
            '<br>' +
            '<table>' +
            '<tr>' +
            '<td>' +
            '<b><u>Borrower</u></b>' +
            '<br>' +
            '<b>Title ' + (this.state.appBorrowerInfo.title_code !== null ? (this.state.appBorrowerInfo.title_code !== this.state.formObj.title ? "*" : "") : (this.state.formObj.title !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.title) +
            '<br>' +
            '<b>Borrower Name ' + (borrowerName?.toLowerCase() !== this.state.formObj.borrowerName.toLowerCase() ? "*" : "") + ' : </b>' + (this.state.formObj.borrowerName) +
            '<br>' +
            '<b>Suffix ' + (this.state.appBorrowerInfo.suffix_Id !== null ? (this.state.appBorrowerInfo.suffix_Id != this.state.formObj.suffix ? "*" : "") : (this.state.formObj.suffix !== "" && this.state.formObj.suffix !== "0" ? "*" : "")) + ' : </b>' + (parseInt(this.state.formObj.suffix) > 0 ? this.state.lstSuffix.find((suffix) => this.state.formObj.suffix == suffix.id)?.description : "") +
            '<br>' +
            '<b>Address ' + (this.state.appBorrowerInfo.address !== null ? ((this.state.appBorrowerInfo.address + ", " + (this.state.appBorrowerInfo.apt !== null ? this.state.appBorrowerInfo.apt : "")) !== this.state.formObj.address + ", " + this.state.formObj.apt ? "*" : "") : ((this.state.formObj.address + ", " + this.state.formObj.apt) !== ", " ? "*" : "")) + ' : </b>' + (this.state.formObj.address + ", " + this.state.formObj.apt) +
            '<br>' +
            '<b>City ' + (this.state.appBorrowerInfo.city !== null ? (this.state.appBorrowerInfo.city !== this.state.formObj.city ? "*" : "") : (this.state.formObj.city !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.city) +
            '<br>' +
            '<b>State ' + (this.state.appBorrowerInfo.state_Id !== null ? (this.state.appBorrowerInfo.state_Id != this.state.formObj.state ? "*" : "") : (this.state.formObj.state !== "0" ? "*" : "")) + ' : </b>' + (parseInt(this.state.formObj.state) > 0 ? this.state.lstState.find(obj => obj.id == this.state.formObj.state)?.description : "") +
            '<br>' +
            '<b>Zip Code ' + (this.state.appBorrowerInfo.zipCode !== null ? (this.state.appBorrowerInfo.zipCode !== this.state.formObj.zipCode ? "*" : "") : (this.state.formObj.zipCode !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.zipCode) +
            '<br>' +
            '<b>Phone Number ' + (this.state.appBorrowerInfo.mobileNo !== null ? (formatPhoneNumber(this.state.appBorrowerInfo.mobileNo) !== formatPhoneNumber(this.state.formObj.mobileNo) ? "*" : "") : (this.state.formObj.mobileNo !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.mobileNo) +
            '<br>' +
            '<b>Email Address ' + (this.state.appBorrowerInfo.emailAddress !== null ? (this.state.appBorrowerInfo.emailAddress !== this.state.formObj.emailAdr ? "*" : "") : (this.state.formObj.emailAdr !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.emailAdr) +
            '<br>' +
            '<b>Secondary Number ' + (this.state.appBorrowerInfo.secondaryPhone !== null ? (formatPhoneNumber(this.state.appBorrowerInfo.secondaryPhone) !== formatPhoneNumber(this.state.formObj.mobileNo2) ? "*" : "") : (this.state.formObj.mobileNo2 !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.mobileNo2) +
            '<br>' +
            '<b>Birth date ' + (this.state.appBorrowerInfo.birthDate !== null ? (Moment(this.state.appBorrowerInfo.birthDate).format("MM/DD/YYYY") !== this.state.formObj.dob ? "*" : "") : (this.state.formObj.dob !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.dob) +
            '<br>' +
            '<b>Soc Sec No/ITIN ' + (this.state.appBorrowerInfo.masked_ssn !== null ? (this.state.appBorrowerInfo.masked_ssn !== this.state.formObj.ssn ? "*" : "") : (this.state.formObj.ssn !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.ssn) +
            '<br>' +
            '<b>ID Number ' + (this.state.appBorrowerInfo.idNumber !== null ? (this.state.appBorrowerInfo.idNumber !== this.state.formObj.primaryIdNo ? "*" : "") : (this.state.formObj.primaryIdNo !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.primaryIdNo) +
            '<br>' +
            '<b>Employer Name ' + (this.state.appBorrowerInfo.empName !== null ? (this.state.appBorrowerInfo.empName !== this.state.formObj.empName ? "*" : "") : (this.state.formObj.empName !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.empName) +
            '<br>' +
            '<b>Employer Phone Number ' + (this.state.appBorrowerInfo.empPhone !== null ? (formatPhoneNumber(this.state.appBorrowerInfo.empPhone) !== formatPhoneNumber(this.state.formObj.empPhoneNo) ? "*" : "") : (this.state.formObj.empPhoneNo !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.empPhoneNo) +
            '<br>' +
            '<b>Type of Work/Job Duties ' + (this.state.appBorrowerInfo.workJob !== null ? (this.state.appBorrowerInfo.workJob !== this.state.formObj.duties ? "*" : "") : (this.state.formObj.duties !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.duties) +
            '<br>' +
            '<b>Monthly Net Income (from all sources) ' + (this.state.appBorrowerInfo.monthlyIncome !== null ? (this.state.appBorrowerInfo.monthlyIncome !== this.state.formObj.netIncome ? "*" : "") : (this.state.formObj.netIncome !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.netIncome == undefined ? "" : this.state.formObj.netIncome) +
            '<br>' +
            '<b>Start Date ' + (this.state.appBorrowerInfo.howLong !== null ? (Moment(this.state.appBorrowerInfo.howLong).format("MM/YYYY") !== this.state.formObj.empStartDate ? "*" : "") : (this.state.formObj.empStartDate !== "" ? "*" : "")) + ' : </b>' + (this.state.formObj.empStartDate) +
            '<br>' +
            '<br>' +
            '</td>' +
            // (sessionStorage.getItem("coBorrower")=="true"?
            // '<td>' +
            // '<b><u>Co-Borrower</u></b>' +
            // '<br>' +
            // '<b>Title ' + (this.state.appCoBorrowerInfo.title_code !== null ? (this.state.appCoBorrowerInfo.title_code !== this.state.coFormObj.title ? "*" : "") : (this.state.coFormObj.title !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.title) +
            // '<br>' +
            // '<b>Co-Borrower Name ' + (((this.state.appCoBorrowerInfo.firstName == null || this.state.appCoBorrowerInfo.firstName == "" ? "" : this.state.appCoBorrowerInfo.firstName) + " " + (this.state.appCoBorrowerInfo.middleName == null || this.state.appCoBorrowerInfo.middleName == "" ? "" : this.state.appCoBorrowerInfo.middleName) + " " + (this.state.appCoBorrowerInfo.lastName == null || this.state.appCoBorrowerInfo.lastName == "" ? "" : this.state.appCoBorrowerInfo.lastName)) !== this.state.coFormObj.coBorrowerName ? "*" : "") + ': </b>' + (this.state.coFormObj.coBorrowerName) +
            // '<br>' +
            // '<b>Suffix ' + (this.state.appCoBorrowerInfo.suffix_Id !== null ? (this.state.appCoBorrowerInfo.suffix_Id !== this.state.coFormObj.suffix ? "*" : "") : (this.state.coFormObj.suffix !== "" && this.state.coFormObj.suffix !== "0" ? "*" : "")) + ' : </b>' + (parseInt(this.state.coFormObj.suffix) > 0 ? this.state.lstSuffix.find((suffix) => this.state.coFormObj.suffix == suffix.id)?.description : "") +
            // '<br>' +
            // '<b>Phone Number ' + (this.state.appCoBorrowerInfo.mobileNo !== null ? (formatPhoneNumber(this.state.appCoBorrowerInfo.mobileNo) !== formatPhoneNumber(this.state.coFormObj.mobileNo) ? "*" : "") : (this.state.coFormObj.mobileNo !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.mobileNo) +
            // '<br>' +
            // '<b>Email Address ' + (this.state.appCoBorrowerInfo.emailAddress !== null ? (this.state.appCoBorrowerInfo.emailAddress !== this.state.coFormObj.emailAdr ? "*" : "") : (this.state.coFormObj.emailAdr !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.emailAdr) +
            // '<br>' +
            // '<b>Secondary Number ' + (this.state.appCoBorrowerInfo.secondaryPhone !== null ? (formatPhoneNumber(this.state.appCoBorrowerInfo.secondaryPhone) !== formatPhoneNumber(this.state.coFormObj.mobileNo2) ? "*" : "") : (this.state.coFormObj.mobileNo2 !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.mobileNo2) +
            // '<br>' +
            // '<b>Birth date ' + (this.state.appCoBorrowerInfo.birthDate !== null && this.state.appCoBorrowerInfo.birthDate !== "" ? (Moment(this.state.appCoBorrowerInfo.birthDate).format("MM/DD/YYYY") !== this.state.coFormObj.dob ? "*" : "") : (this.state.coFormObj.dob !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.dob) +
            // '<br>' +
            // '<b>Soc Sec No/ITIN ' + (this.state.appCoBorrowerInfo.masked_ssn !== null ? (this.state.appCoBorrowerInfo.masked_ssn !== this.state.coFormObj.ssn ? "*" : "") : (this.state.coFormObj.ssn !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.ssn) +
            // '<br>' +
            // '<b>ID Number ' + (this.state.appCoBorrowerInfo.idNumber !== null && this.state.appCoBorrowerInfo.idNumber !== undefined ? (this.state.appCoBorrowerInfo.idNumber !== this.state.coFormObj.primaryIdNo ? "*" : "") : (this.state.coFormObj.primaryIdNo !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.primaryIdNo) +
            // '<br>' +
            // '<b>Employer Name ' + (this.state.appCoBorrowerInfo.empName !== null ? (this.state.appCoBorrowerInfo.empName !== this.state.coFormObj.empName ? "*" : "") : (this.state.coFormObj.empName !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.empName) +
            // '<br>' +
            // '<b>Employer Phone Number ' + (this.state.appCoBorrowerInfo.empPhone !== null ? (formatPhoneNumber(this.state.appCoBorrowerInfo.empPhone) !== formatPhoneNumber(this.state.coFormObj.empPhoneNo) ? "*" : "") : (this.state.coFormObj.empPhoneNo !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.empPhoneNo) +
            // '<br>' +
            // '<b>Type of Work/Job Duties ' + (this.state.appCoBorrowerInfo.workJob !== null ? (this.state.appCoBorrowerInfo.workJob !== this.state.coFormObj.duties ? "*" : "") : (this.state.coFormObj.duties !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.duties) +
            // '<br>' +
            // '<b>Monthly Net Income (from all sources) ' + (this.state.appCoBorrowerInfo.monthlyIncome !== null ? (this.state.appCoBorrowerInfo.monthlyIncome !== this.state.coFormObj.netIncome ? "*" : "") : (this.state.coFormObj.netIncome !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.netIncome == undefined ? "" : this.state.coFormObj.netIncome) +
            // '<br>' +
            // '<b>Start Date ' + (this.state.appCoBorrowerInfo.howLong !== null && this.state.appCoBorrowerInfo.howLong !== "" ? (Moment(this.state.appCoBorrowerInfo.howLong).format("MM/YYYY") !== this.state.coFormObj.empStartDate ? "*" : "") : (this.state.coFormObj.empStartDate !== "" ? "*" : "")) + ' : </b>' + (this.state.coFormObj.empStartDate) +
            // '<br>' +
            // '<br>' +
            // '</td>':"") +
            '</tr>' +
            '</table>' +
            // '<b><u>Application Status</u></b>' +
            // '<br>' +
            // '<br>' +
            // '<b>Daniel’s Credit : </b>' +
            // '<br>' +
            // (DanielsOffersStatus(this.state.appBorrowerInfo.isDaniel,this.state.formObj?.createdDate)) +
            // '<br>' +
            // '<table>' +
            // '<tr>' +
            // '<td>' +
            // '<b>Fortiva : </b>' +
            // '<br>' +
            // (this.loadFortivaAppOffers()) +
            // '<br>' +
            // '</td>' +
            // '<td>' +
            // '<b>Synchrony Bank Credit : </b>' +
            // '<br>' +
            // (this.loadSynchronyAppOffers()) +
            // '<br>' +
            // '</td>' +
            // '</tr>' +
            // '<tr>' +
            // '<td>' +
            // '<b>Progressive Leasing : </b>' +
            // '<br>' +
            // (this.loadProgressiveAppOffers()) +
            // '<br>' +
            // '</td>' +
            // '<td>' +
            // '<b>UOWN Leasing : </b>' +
            // '<br>' +
            // (this.loadUOwnAppOffers()) +
            // '<br>' +
            // '</td>' +
            // '</tr>' +
            // '</table>' +
            '<br>' +
            '<b><u>Comments</u></b>' +
            '<br>' +
            '<span >' + (val) + '</span>' +
            '<table>' +
            '<tr>' +
            '<td>               </td>' +
            '<td>               </td>' +
            '<td>               </td>' +
            '<td>'+((val.replace("<br>","") === "") ? "<br><br><br><br>"+new Date().toLocaleString('en-US', {timeZone: 'America/Los_Angeles',}) : "<br>"+new Date().toLocaleString('en-US', {timeZone: 'America/Los_Angeles',}))+'</td>' +
            '</tr>' +
            '</table>' +
            '</body>' +
            '</html>';
    }

    printToCreditDepartment(to) {
        let obj = {
            toEmail: (to != "") ? to : "",
            cc: "",
            subject: (to != "") ? "Daniels Jewelers Credit Application" : "",
            emailContent: " ",                
            attachmentContent: this.htmlContent(),
            is_insurance: "",
            financial_partner : "",
        }
        if(to == "") {
            trackPromise(
                this.props.actions.printToCreditDepartment(obj).then(
                    response => {
                        if (response.requestStatus === "success") {
                            this.setState({ toastModal: true });
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
        else {
            trackPromise(
                this.props.actions.printToMagixStoreCode(obj).then(
                    response => {
                        if (response.requestStatus === "success") {
                            this.setState({ toastModal: true });
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    }

    submitPrintSummaryData() {
        let obj= {
            "customer_Id": localStorage.getItem("applicationId") != "null" ? localStorage.getItem("applicationId") : "",
            "store_Id": JSON.parse(localStorage.getItem("associateStores")),
            "magixStoreCode": (localStorage.getItem("storeCode") != "null" || localStorage.getItem("storeCode") != undefined || localStorage.getItem("storeCode") != "") ? localStorage.getItem("storeCode") : "",
            "magix_Id": localStorage.getItem("magixassociateId") != "null" ? localStorage.getItem("selectedCustomer") : "",
            "account_Id": this.state.customerId,
            "borrowerSuffix": (parseInt(this.state.formObj.suffix) > 0) ? this.state.lstSuffix.find(obj => obj.id == this.state.formObj.suffix)?.description : "",
            "coborrowerSuffix": (parseInt(this.state.coFormObj.suffix) > 0) ? this.state.lstSuffix.find(obj => obj.id == this.state.coFormObj.suffix)?.description : "",
            "state_Code": (parseInt(this.state.formObj.state) > 0) ? this.state.lstState.find(obj => obj.id == this.state.formObj.state)?.code : "",
            "title": this.state.formObj.title,
            "borrowerName": this.state.formObj.borrowerName,
            "suffix_Id": (this.state.formObj.suffix == "0" || this.state.formObj.suffix == "") ? 0 : this.state.formObj.suffix,
            "address": this.state.formObj.address,
            "apt": this.state.formObj.apt,
            "city": this.state.formObj.city,
            "state_Id": (this.state.formObj.state == "0" || this.state.formObj.state == "") ? 0 : this.state.formObj.state,
            "zipCode": this.state.formObj.zipCode,
            "mobileNo": this.state.formObj.mobileNo,
            "emailAddress": this.state.formObj.emailAdr,
            "secondaryPhone": this.state.formObj.mobileNo2,
            "birthDate": this.state.formObj.dob != "" ? Moment(new Date(this.state.formObj.dob)).format("YYYY-MM-DD") : null,
            "ssn": this.state.formObj.ssn,
            "idNumber": this.state.formObj.primaryIdNo,
            "empName": this.state.formObj.empName,
            "empPhone": this.state.formObj.empPhoneNo,
            "workJob": this.state.formObj.duties,
            "monthlyIncome": (this.state.formObj.netIncome == "" || this.state.formObj.netIncome == undefined ) ? 0 : this.state.formObj.netIncome,
            "howLong": this.state.formObj.empStartDate != "" ? GetDateFromMMYYYY(this.state.formObj.empStartDate) : null,
            "cborrowerTitle": this.state.coFormObj.title,
            "coborrowerName": this.state.coFormObj.coBorrowerName.trim(),
            "coborrowersuffix_Id": (this.state.coFormObj.suffix == "0" || this.state.coFormObj.suffix == "") ? 0 : this.state.coFormObj.suffix,
            "coborrowermobileNo": this.state.coFormObj.mobileNo,
            "coborroweremailAddress": this.state.coFormObj.emailAdr,
            "coborrowersecondaryPhone": this.state.coFormObj.mobileNo2,
            "coborrowerbirthDate": this.state.coFormObj.dob != "" ? Moment(new Date(this.state.coFormObj.dob)).format("YYYY-MM-DD") : null,
            "coborrowerssn": this.state.coFormObj.ssn,
            "coborroweridNumber": this.state.coFormObj.primaryIdNo,
            "coborrowerempName": this.state.coFormObj.empName,
            "coborrowerempPhone": this.state.coFormObj.empPhoneNo,
            "coborrowerworkJob": this.state.coFormObj.duties,
            "coborrowermonthlyIncome": (this.state.coFormObj.netIncome == "" || this.state.coFormObj.netIncome == undefined ) ? 0 : this.state.coFormObj.netIncome,
            "coborrowerhowLong": this.state.coFormObj.empStartDate != "" ? GetDateFromMMYYYY(this.state.coFormObj.empStartDate) : null,
            "printAtMyStore": this.state.printAtStore,
            "sendToMagixAccHomeStore": this.state.sentToMagix,
            "sendToCredit": this.state.emailCredit,
            "isMobileNoVerified": this.state.isSMSVerified,
            "isSMSCodeSent": this.state.SMSCodeSent,
            "isSMSOverride": this.state.isSMSOverride,
            "comments": this.state.comments,
            "modifiedBy_Associate": localStorage.getItem('loggedUserEmail'),
            "emailContent": this.htmlContent(),
          }
          this.props.actions.submitPrintSummaryData(obj).then(
            response => {
                if(response) {
                    this.setState({dataSavedError: false, dataSavedSuccess : true});
                }
                else {
                    this.setState({dataSavedError: true, dataSavedSuccess : true});
                }
                this.revertChanges();
            }
        ).catch(err => {
            APIFailedMessage.displayError(err);
        })
    }

    loadDropDowns(type) {
        let obj = {
            "type": type
        }
        trackPromise(
            this.props.applyNowActions.getCodeMaster(obj).then(
                response => {
                    if (response) {
                        if (type === "Suffix") {
                            this.setState({ lstSuffix: response.data });
                        }
                        else if (type === "State_Credit") {
                            this.setState({stateList_credit : response.data});
                        }
                        else if (type === "State") {
                            this.setState({ lstState: response.data });
                        }
                        else if (type === "HousingInfo") {
                            this.setState({ lstHousingInfo: response.data });
                        }
                        else if (type === "OftenPaid") {
                            this.setState({ lstOftenPaid: response.data });
                        }
                        else if (type === "PrimaryIdType") {
                            this.setState({ lstPrimaryIdType: response.data });
                        }
                        else if (type === "SecondIdType") {
                            this.setState({ lstSecondIdType: response.data });
                        }
                    }
                }
            ).catch(err => {
                APIFailedMessage.displayError(err);
            })
        )
    }

    updateState(controlId, controlValue) {
        const regx1 = /^(?!\s)[-a-zA-Z ]*$/;
        const regx2 = /^[0-9\b]+$/;
        if (controlId === "txtCustomer") {
            this.setState({ customerId: controlValue });
        }
        if (controlId === "txtStore") {
            const re = /^[0-9\b]+$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({ store: controlValue });
            }
        }
        else if (controlId === "txtTitle") {
            this.setState({
                formObj: { ...this.state.formObj, title: controlValue },
            });
        }
        else if (controlId === "txtBorrowerName") {
            // const re = /^(?!\s)[-a-zA-Z ]*$/;
            // if (controlValue === '' || re.test(controlValue)) {
            //     this.setState({
            //         formObj: { ...this.state.formObj, borrowerName: controlValue },
            //     });
            // }
            this.setState({
                formObj: { ...this.state.formObj, borrowerName: controlValue },
            });
        }
        else if (controlId === "txtSuffix") {
            this.setState({
                formObj: { ...this.state.formObj, suffix: controlValue },
            });
        }
        else if (controlId === "txtAddress") {
            this.setState({
                formObj: { ...this.state.formObj, address: controlValue },
            });
        }
        else if (controlId === "txtApt") {
            this.setState({
                formObj: { ...this.state.formObj, apt: controlValue },
            });
        }
        else if (controlId === "txtCity") {
            const re = /^(?!\s)[-a-zA-Z ]*$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, city: controlValue },
                });
            }
        }
        else if (controlId === "cmbState") {
            this.setState({
                formObj: { ...this.state.formObj, state: controlValue },
            });
        }
        else if (controlId === "txtZipCode") {
            const re = /^[0-9\b]+$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, zipCode: controlValue },
                });
            }

        }
        else if (controlId === "txtMobileNo") {
            this.setState({
                formObj: { ...this.state.formObj, mobileNo: controlValue },
            });

        }
        else if (controlId === "txtEmailAdr") {
            this.setState({
                formObj: { ...this.state.formObj, emailAdr: controlValue },
            });
        }
        else if (controlId === "txtMobileNo2") {
            this.setState({
                formObj: { ...this.state.formObj, mobileNo2: controlValue },
            });
        }
        else if (controlId === "txtDOB") {
            this.setState({
                formObj: { ...this.state.formObj, dob: controlValue },
            });
        }
        else if (controlId === "txtSSN") {
            this.setState({
                formObj: { ...this.state.formObj, ssn: controlValue },
            });
        }
        else if (controlId === "txtIDNo") {
            this.setState({
                formObj: { ...this.state.formObj, primaryIdNo: controlValue },
            });
        }
        else if (controlId === "txtEmpName") {
            this.setState({
                formObj: { ...this.state.formObj, empName: controlValue },
            });
        }
        else if (controlId === "txtEmpPhoneNo") {
            this.setState({
                formObj: { ...this.state.formObj, empPhoneNo: controlValue },
            });
        }
        else if (controlId === "txtDuties") {
            const re = /^(?!\s)[-a-zA-Z ]*$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    formObj: { ...this.state.formObj, duties: controlValue },
                });
            }
        }
        else if (controlId === "txtNetIncome") {
            this.setState({
                formObj: { ...this.state.formObj, netIncome: controlValue },
            });
        }

        else if (controlId === "coTxtTitle") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, title: controlValue },
            });
        }
        else if (controlId === "coTxtCoBorrowerName") {
            // const re = /^(?!\s)[-a-zA-Z ]*$/;
            // if (controlValue === '' || re.test(controlValue)) {
            //     this.setState({
            //         coFormObj: { ...this.state.coFormObj, coBorrowerName: controlValue },
            //     });
            // }
            this.setState({
                coFormObj: { ...this.state.coFormObj, coBorrowerName: controlValue },
            });
        }
        else if (controlId === "coTxtSuffix") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, suffix: controlValue },
            });
        }
        else if (controlId === "coTxtMobileNo") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, mobileNo: controlValue },
            });

        }
        else if (controlId === "coTxtEmailAdr") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, emailAdr: controlValue },
            });
        }
        else if (controlId === "coTxtMobileNo2") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, mobileNo2: controlValue },
            });
        }
        else if (controlId === "coTxtDOB") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, dob: controlValue },
            });
        }
        else if (controlId === "coTxtSSN") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, ssn: controlValue },
            });
        }
        else if (controlId === "coTxtIDNo") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, primaryIdNo: controlValue },
            });
        }
        else if (controlId === "coTxtEmpName") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, empName: controlValue },
            });
        }
        else if (controlId === "coTxtEmpPhoneNo") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, empPhoneNo: controlValue },
            });
        }
        else if (controlId === "coTxtDuties") {
            const re = /^(?!\s)[-a-zA-Z ]*$/;
            if (controlValue === '' || re.test(controlValue)) {
                this.setState({
                    coFormObj: { ...this.state.coFormObj, duties: controlValue },
                });
            }
        }
        else if (controlId === "coTxtNetIncome") {
            this.setState({
                coFormObj: { ...this.state.coFormObj, netIncome: controlValue },
            });
        }
        else if (controlId === "comments") {
            this.setState({
                comments: controlValue
            });
        }
        else if (controlId === "txtSmsMobileNumber") {
            this.setState({
                smsMobileNumber: controlValue
            });
        }
        else if (controlId === "txtVerificationCode") {
            this.setState({
                verificationCode: controlValue
            });
        }
        if(!this.state.masterCustomerEdit) {
        if (controlId === "masterCustFirstName") {
            if(regx1.test(controlValue)) {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, firstName : controlValue}},() => {
                this.handleFormValidation();
            });
            }
        }
        else if (controlId === "masterCustMiddleName") {
            if(regx1.test(controlValue)) {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, middleName : controlValue}},() => {
                this.handleFormValidation();                
            });
            }
        }
        else if (controlId === "masterCustLastName") {
            if(regx1.test(controlValue)) {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, lastName : controlValue}},() => {
                this.handleFormValidation();                
            });
            }
        }
        else if (controlId === "masterCustAddress") {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, address : controlValue}},() => {
                this.handleFormValidation();                
            });
        }
        else if (controlId === "masterCustApt") {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, apt : controlValue}},() => {
                this.handleFormValidation();                
            });
        }
        else if (controlId === "masterCustCity") {
            if(regx1.test(controlValue)) {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, city : controlValue}},() => {
                this.handleFormValidation();                
            });
            }
        }
        else if (controlId === "masterCustZipCode") {
            if(regx2.test(controlValue) || controlValue === "") {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, zipCode : controlValue}},() => {
                this.handleFormValidation();                
            });
            }
        }
        else if (controlId === "masterCustMobileNo") {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, mobileNo : controlValue}},() => {
                this.handleFormValidation();                
            });
        }
        else if (controlId === "masterCustMobileNoEmailAddress") {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, emailAddress : controlValue}},() => {
                this.handleFormValidation();                
            });
        }
        else if (controlId === "masterCustStateId") {
            this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, state_Id : controlValue}},() => {
                this.handleFormValidation();                
            });
        }
        }
    }


    handleBorrowerExpand() {
        this.setState({
            borrowerExpand: !this.state.borrowerExpand
        });
    };
    handleCo_BorrowerExpand() {
        this.setState({
            Co_BorrowerExpand: !this.state.Co_BorrowerExpand
        });
    };

    handleToggleSidebar() {
        this.setState({
            toggled: !this.state.toggled
        });
    };

    mobileNumberVerification = (type) => {
        if (type === "mobile") {
            if (this.state.smsMobileNumber === "") {
                this.setState({ phoneNumErr: "Mobile number is required." });
                return false;
            }
            else if (this.state.smsMobileNumber?.replace("-","").length < 10) {
                this.setState({ phoneNumErr: "Mobile number must be 10 digits." });
                return false;
            }
            else if (this.state.smsMobileNumber.slice(0, 1) == 1 || this.state.smsMobileNumber.slice(0, 1) == 0) {
                this.setState({ phoneNumErr: "Mobile number cannot be start with 0 or 1." });
                return false;
            }
            else if (this.state.smsMobileNumber !== this.state.formObj.mobileNo) {
                this.setState({ phoneNumErr: "Mobile number and phone number must be the same." });
                return false;
            }
            else {
                return true;
            }
        }
        else if (type === "code") {
            if (this.state.verificationCode === "") {
                this.setState({ verificationCodeErr: "We need to verify your cell phone. Please check your cell for the text message we just sent and enter the code above and click verify." });
                return false;
            }
            else if (this.state.verificationCode?.length !== 6) {
                this.setState({ verificationCodeErr: "Code must be 6 digits." });
                return false;
            }
            else {
                return true;
            }
        }

    }

    BypassSMS(event) {
        event.preventDefault();
        this.setState({ phoneNumErr: "", verificationCodeErr: "" });
        try {
            if (this.mobileNumberVerification("mobile")) {
                this.props.authActions.login().then(
                    response => {
                        if (response) {
                            this.setState({ isSMSOverride: true });
                        }
                    }
                )
            }
        }
        catch (error) {
            APIFailedMessage.displayError(error);
        }
    }

    submitMobileNo(event) {
        event.preventDefault();
        if (event.target.id === "mobileNoForOTP") {
            this.setState({ phoneNumErr: "" });
            if (this.mobileNumberVerification("mobile")) {
                let obj = {
                    "firstName": this.state.appBorrowerInfo.firstName === null ? "" : this.state.appBorrowerInfo.firstName,
                    "lastName": this.state.appBorrowerInfo.lastName === null ? "" : this.state.appBorrowerInfo.lastName,
                    "mobileNo": this.state.smsMobileNumber,
                    "message": "",
                    "code": "",
                    "sendRica": false,
                }
                trackPromise(
                    this.props.applyNowActions.mobilesendcodeormsg(obj).then(
                        response => {
                            if (response) {
                                if (response.twilioStatus?.toLowerCase() === "pending") {
                                    this.setState({ smsModal: true, SMSCodeSent: true });
                                    this.txtVerificationCode.focus();
                                }
                                else if (response.errorMessage?.length > 0 || response.twilioStatus === null) {
                                    this.setState({ isSMSError: true, smsModal: true });
                                }
                            }
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
        }
        else if (event.target.id === "codeVerificationBtn") {
            this.setState({ verificationCodeErr: "", phoneNumErr: "" });
            if (this.mobileNumberVerification("mobile") && this.mobileNumberVerification("code")) {
                let obj = {
                    "firstName": this.state.appBorrowerInfo.firstName === null ? "" : this.state.appBorrowerInfo.firstName,
                    "lastName": this.state.appBorrowerInfo.lastName === null ? "" : this.state.appBorrowerInfo.lastName,
                    "mobileNo": this.state.smsMobileNumber,
                    "message": "",
                    "code": this.state.verificationCode,
                    "sendRica": false,
                }
                trackPromise(
                    this.props.applyNowActions.mobilesendcodeormsg(obj).then(
                        response => {
                            if (response) {
                                if (response.twilioStatus?.toLowerCase() === "approved") {
                                    this.setState({ isSMSVerified: true });
                                }
                                else if (response.twilioStatus?.toLowerCase() === "pending" || response.twilioStatus === null) {
                                    this.setState({ verificationCodeErr: "Invalid Code." });
                                }
                            }
                        }
                    ).catch(err => {
                        APIFailedMessage.displayError(err);
                    })
                )
            }
        }
    }
    
    loadFortivaOffers() {
        let offers = this.state.fortivaOffer?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
       return FortivaOffersStatus(offers);
    }
    loadFortivaAppOffers() {
        let offers = this.state.applicationOffers?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
       return FortivaOffersStatus(offers);
    }

    loadSynchronyOffers() {
        if(this.state.offerStatus.synchronyStatus===true){
            let offers = this.state.synchronyOffers;
            return SynchronyOffersStatus(offers,this.state.formObj?.createdDate);
       }
        else {
            return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
        }      
    }
    // loadSynchronyAppOffers() {
    //     if(this.state.offerStatus.synchronyStatus===true){
    //         let offers = this.state.applicationOffers?.filter((val) => { return val.fpType?.toLowerCase() === "synchrony" })[0];
    //         return SynchronyOffersStatus(offers,this.state.formObj?.createdDate);
    //    }
    //     else {
    //         return "<span class='offersDesign'><b>Decision Status </b>: <span class='mb-1'>Not Applied</span></span>";
    //     }      
    // }

    loadProgressiveOffers() {
        let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "progressive" })[0];
        return ProgressiveOffersStatus(offers);
    }
    loadProgressiveAppOffers() {
        let offers = this.state.applicationOffers?.filter((val) => { return val.fpType?.toLowerCase() === "progressive" })[0];
        return ProgressiveOffersStatus(offers);
    }

    loadUOwnOffers() {
        let offers = this.state.cusPreApproval?.filter((val) => { return val.fpType?.toLowerCase() === "uown" })[0];
        return UOwnOffersStatus(offers);
    }
    loadUOwnAppOffers() {
        let offers = this.state.applicationOffers?.filter((val) => { return val.fpType?.toLowerCase() === "uown" })[0];
        return UOwnOffersStatus(offers);
    }
    // synchronPendingModal=()=>{
    //     this.setState({synchronyPending:!this.state.synchronyPending});
    // }
    // synchronPendingOk=()=>{
    //     localStorage.setItem('submitforFortiva', false);
    //     this.setState({synchronyPending:!this.state.synchronyPending, showProgUown: true});
    // }
    // synchronDeclinedOk=(event)=>{
    //     event.preventDefault();
    //     localStorage.setItem('submitforFortiva', false);
    //     this.setState({synchronyDeclined:false});
    //     let declined=true;
    //     this.submitFortiva("",declined);
    // }

    triggerTender = (curTen) => {
        this.setState({showTender : !this.state.showTender, currentTender : curTen});
    }
    //clicks no , means not redirected to credit applicaiton and remains the same 
    // handleProgUownApplication = (event) => {
    //     event.preventDefault();
    //     this.setState({showProgUown:false});
    //     sessionStorage.removeItem("submitAppFirst");
    //     //this.trackWaterfall();
    // }
    // trackWaterfall=()=>{
    //     let obj={
    //         waterfall_check:"No",
    //         applicationId:this.state.applicationId.toString()
    //     }
    //     trackPromise(
    //         this.props.actions.trackWaterfall_check(obj).then(
    //             response => {
    //                 if (response) {
    //                     if (response.errorMessage) {
    //                         console.log(response.errorMessage);
    //                     }
    //                 }
    //             }
    //         ).catch(err => {
    //             APIFailedMessage.displayError(err);
    //         })
    //     )
    // }
    // redirectProgUownApplication = (event) => {
    //     event.preventDefault();
    //     this.setState({showProgUown:!this.state.showProgUown});
    //     sessionStorage.removeItem("submitAppFirst");
    //     let appsettings = JSON.parse(localStorage.getItem("appsettings"));
    //     const CryptoJS = require('crypto-js');
    //     sessionStorage.setItem("logout/continue",false);
    //     sessionStorage.setItem("progUownApplications",true);
    //     var encryptedBytes = CryptoJS.AES.encrypt(appsettings.Keys.securityKey, JSON.parse(localStorage.getItem("appsettings")).Keys.securityKey);
    //     this.props.history.push("/apply-now?" + encryptedBytes);
    // }
    rejectFortiva=(pending)=>{
        let  customerId=localStorage.getItem("selectedCustomer")!="null"?localStorage.getItem("selectedCustomer"):0;
        let saveCustResponseFortiva="";
            if(pending){
                saveCustResponseFortiva="Not Sent";
            }
            else{
                saveCustResponseFortiva="No"; 
            }
       
          this.setState({showFortiva:false});
              trackPromise(
                this.props.applyNowActions.creditSubmitFortiva(customerId,saveCustResponseFortiva, this.state.associateId,this.state.store_Id ).then(
                    response => {
                        if (response) {
                            if (response.errorMessage) {
                                console.log(response.errorMessage);
                            }
                            else {
                                this.setState({successMsg:"Not send the application to Fortiva",offerModal:true});
                                if(!pending){
                                    this.setState({showProgUown:true});
                                }
                            }
                        }
                    }
                ).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
    } 
    // handleFortivaModal = (event) => {
    //     event.preventDefault();
    //     this.setState({showFortiva:!this.state.showFortiva});
    //     this.rejectFortiva();
    // }
    // submitFortiva=(event,declined)=>{
    //     if(event)
    //         event?.preventDefault();

    //     let  customerId=localStorage.getItem("selectedCustomer")!="null"?localStorage.getItem("selectedCustomer"):0;
    //     let saveCustResponseFortiva="";
    //         if(declined){
    //             saveCustResponseFortiva="Automatic";
    //         }
    //         else{
    //             saveCustResponseFortiva="Yes"; 
    //         }
    //       this.setState({showFortiva:false,synchronyDeclined:false});
    //           trackPromise(
    //             this.props.applyNowActions.creditSubmitFortiva(customerId,saveCustResponseFortiva, this.state.associateId,this.state.store_Id ).then(
    //                 response => {
    //                     if (response) {
    //                         if (response.errorMessage.length > 0) {
    //                             sessionStorage.removeItem("submitAppFirst");
    //                             localStorage.setItem("fortivaAPIErrorMsg",response.errorMessage);
    //                             this.setState({fortivaAppError:true,fortivaAppErrMsg:"An error occurred when sending the application to Fortiva and your application was not submitted. Please select a Fortiva Only application and apply again."})
    //                         }
    //                         else {
    //                             localStorage.setItem("fortivaAppId",response.customer.fortivaAppId);
    //                             let cust_Id = localStorage.getItem("customerId");
    //                             cust_Id = (cust_Id == "null" || cust_Id == "undefined" || cust_Id === null || cust_Id === undefined) ? 0 : cust_Id;
    //                             if(response) {
    //                                 trackPromise(
    //                                     this.props.actions.getCustomerViewOffers(cust_Id,customerId).then(
    //                                         response => {
    //                                             if(response){
    //                                                 this.setState({offers : response,showProgUown:true});
    //                                                 sessionStorage.setItem("updated_fortiva_response", JSON.stringify(response));
    //                                             }
    //                                         }
    //                                     ).catch(err => {
    //                                         APIFailedMessage.displayError(err);
    //                                     })
    //                                 )
    //                             }

    //                         }
    //                     }
    //                 }
    //             ).catch(err => {
    //                 APIFailedMessage.displayError(err);
    //             })
    //         )
    // }
    // handleFortivaErrorModal = () => {
    //     this.setState({fortivaAppError:!this.state.fortivaAppError});
    //     localStorage.removeItem("fortivaAPIErrorMsg");
    // }
    // redirectoFortivaApplication = (event) => {
    //     event.preventDefault();
    //     let fortivaAppError=!this.state.fortivaAppError;
    //     this.setState({fortivaAppError:fortivaAppError});
    //     let appsettings = JSON.parse(localStorage.getItem("appsettings"));
    //     const CryptoJS = require('crypto-js');
    //     sessionStorage.setItem("logout/continue",false);
    //     var encryptedBytes = CryptoJS.AES.encrypt(appsettings.Keys.securityKey, JSON.parse(localStorage.getItem("appsettings")).Keys.securityKey);
    //     this.props.history.push("/apply-now?" + encryptedBytes);
    // } 

    handleFormValidation(validate) {
        let formErrors = {};
        let formIsValid = true;
        let data = this.state.masterCustomerInfo;
        let regx = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(data.firstName === "") {
            formIsValid = false;
            formErrors["firstName"] = true;
        }
        if(data.lastName === "") {
            formIsValid = false;
            formErrors["lastName"] = true;
        }
        if(this.state.allAptUnit?.length > 0 && data.aptUnit?.length === 0) {
            formIsValid = false;
            formErrors["aptUnit"] = true;
        }
        if(data.address === "") {
            formIsValid = false;
            formErrors["address"] = true;
        }
        if(data.city === "") {
            formIsValid = false;
            formErrors["city"] = true;
        }
        if(data.state_Id === "0") {
            formIsValid = false;
            formErrors["stateID"] = true;
        }
        if(data.zipCode === "") {
            formIsValid = false;
            formErrors["zipCode"] = true;
        }
        if(data.mobileNo === "") {
            formIsValid = false;
            formErrors["mobileNo"] = true;
        }
        else if(data.mobileNo[0] == 0 || data.mobileNo[0] == 1) {
            formIsValid = false;
            formErrors["mobileNo"] = "Can't start with 0/1";
        }
        else if(data.mobileNo?.length < 12) {
            formIsValid = false;
            formErrors["mobileNo"] = "InValid";
        }
        if(data.emailAddress === "") {
                formIsValid = false;
                formErrors["email"] = true;
        }
        else if(!regx.test(data.emailAddress)){
                formIsValid = false;
                formErrors["email"] = "InValid";
        }
        this.setState({ formErrors: formErrors });
        return formIsValid;
    }

    cancelMasterCustomerEdit() {
        this.setState({masterCustomerInfo : this.state.custBorrowerInfo, editMasterCustomer : false});
    }

    masterCustomerEdit() {
        if(this.handleFormValidation()) {
            let updatedCustomerInfo = this.state.masterCustomerInfo;
            let modFields = [];
            if (updatedCustomerInfo.firstName !== this.state.custBorrowerInfo.firstName)
                modFields.push("firstName");
            if (updatedCustomerInfo.middleName !== this.state.custBorrowerInfo.middleName)
                modFields.push("middleName");
            if (updatedCustomerInfo.lastName !== this.state.custBorrowerInfo.lastName)
                modFields.push("lastName");
            if (updatedCustomerInfo.mobileNo !== this.state.custBorrowerInfo.mobileNo)
                modFields.push("mobileNo");
            if (updatedCustomerInfo.emailAddress !== this.state.custBorrowerInfo.emailAddress)
                modFields.push("emailAddress");
            if (updatedCustomerInfo.address !== this.state.custBorrowerInfo.address)
                modFields.push("address");
            if (updatedCustomerInfo.apt !== this.state.custBorrowerInfo.apt)
                modFields.push("apt");
            if (updatedCustomerInfo.city !== this.state.custBorrowerInfo.city)
                modFields.push("city");
            if (updatedCustomerInfo.state_Id != this.state.custBorrowerInfo.state_Id)
                modFields.push("state");
            if (updatedCustomerInfo.zipCode !== this.state.custBorrowerInfo.zipCode)
                modFields.push("zipCode");
            let obj = {
                customer_id: localStorage.getItem("customerId"),
                firstName: updatedCustomerInfo.firstName?.trim(),
                middleName: updatedCustomerInfo.middleName?.trim(),
                lastName: updatedCustomerInfo.lastName?.trim(),
                mobileNo: updatedCustomerInfo.mobileNo,
                emailAddress: updatedCustomerInfo.emailAddress?.trim(),
                address: updatedCustomerInfo.address?.trim(),
                apt: updatedCustomerInfo.apt,
                city: updatedCustomerInfo.city?.trim(),
                state_id: updatedCustomerInfo.state_Id,
                zipcode: updatedCustomerInfo.zipCode,
                modifiedAssociate: localStorage.getItem("loggedUserEmail"),
                modifiedValues: modFields.toString(),
                ismagixMapping: false,
            };
            trackPromise(
                this.props.customerUpdation
                  .updateMasterCustomer_Record(obj)
                  .then((res) => {
                    if (res) {
                      if (res.isSuccess === true) {
                        toast.success("Customer data updated successfully!");
                        let obj = localStorage.getItem("customerId");
                        trackPromise(
                            this.props.actions.getCustomerDetails_new(obj).then(
                                async response => {
                                    if(response){
                                        this.setState({ custBorrowerInfo: response[0], masterCustomerInfo : response[0] });
                                    }   
                                }
                            )
                        )
                        this.setState({editMasterCustomer : false});
                      } else {
                        toast.error("Customer data update failed!");
                      }
                    }
                  })
                  .catch((err) => {
                    APIFailedMessage.displayError(err);
                  })
              );
            }
    }

    loadAddress(obj) {
        if(obj === "") {
            this.setState({ masterCustomerInfo : {...this.state.masterCustomerInfo, address : "", aptUnit : "", city : "", zipCode : "", state_Id : '0' }, allAptUnit : [], allAddress : []}, () => {
                this.handleFormValidation();
            });
        }
        else if(obj?.length > 2) {
            obj = {
                line1 : obj.split(",")[0]?.replace(",",""),
                city : obj.split(",")[1] === undefined ? "" : obj.split(",")[1]?.trim(),
                state : obj.split(",")[2] === undefined ? "" : obj.split(",")[2]?.trim()
            }
            this.props.applyNowActions.getAddress(obj).then(
                response => {
                    if(response) {
                        let array = response.Results?.map((val) => {
                            return val.Address;
                        });
                        array?.map((item) => {
                            item.fullAddress = item?.AddressLine1+", "+item?.City+", "+item?.State+", "+item?.PostalCode?.split("-")[0]+(item?.SuiteName == "" ? "" : ", "+item?.SuiteName)
                        })
                        if(this.state.masterCustomerInfo.address?.length > 2)
                            this.setState({allAddress : array});
                        else
                            this.setState({allAddress : []});
                    }
                }
            ).catch(err => {
                APIFailedMessage.displayError(err);
            })
        }
    }

    autoFill(val) {
        let address = val;
        let data=this.state.allAddress?.find((val) => val.AddressKey == address);
        this.setState({allAptUnit : (data?.SuiteName !== "") ? data?.SuiteList : []});
        let state = this.state?.lstState.find((val) => val.code == data?.State);
        this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, address : data?.AddressLine1,aptUnit : "", city : data?.City, zipCode : data?.PostalCode.split("-")[0], state_Id : state?.id }},() => {
            this.handleFormValidation();
        });
        if(data?.SuiteName !== "") {
            document.getElementById("masterAptUnit").focus();
        }
    }

    render() {
        
        let count =0;

        let multipleMagixId = sessionStorage.getItem("multipleMagixId");
        multipleMagixId = multipleMagixId?.split(",");
        let userStoreCode= localStorage.getItem("storeCode");
        let borrowerName="";
      
        if(this.state.appBorrowerInfo.firstName){
            borrowerName= this.state.appBorrowerInfo.firstName;
        }
        if(this.state.appBorrowerInfo.middleName){
            borrowerName= borrowerName+" "+this.state.appBorrowerInfo.middleName;
        }
        if(this.state.appBorrowerInfo.lastName){
            borrowerName= borrowerName+" "+this.state.appBorrowerInfo.lastName;
        }

        let applicationType = "Apply Now";
        if (this.state.newApplication){
            applicationType = "Apply for New Account"
        }
        else if(parseFloat(this.state.selectedMagixAcct?.currentBalance) > 0.00) {
            applicationType = "Add-On"
        }
        else if (parseFloat(this.state.selectedMagixAcct?.currentBalance) <= 0.00){
            applicationType = "Re-Open"
        }

        return (
            <>
                <IdleTimeModal />
                <ToastContainer className="p-3" position="top-end">
                    <Toast bg="success" onClose={() => { this.setState({ toastModal: !this.state.toastModal }) }} show={this.state.toastModal} delay={3000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2"><FontAwesomeIcon icon={faCircleCheck} className="pr-2" />Print sent successfully!</Toast.Body>
                    </Toast>
                    <Toast bg={this.state.isSMSError ? "danger" : "success"} onClose={() => { this.setState({ smsModal: !this.state.smsModal }) }} show={this.state.smsModal} delay={3000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2">{this.state.isSMSError ? <FontAwesomeIcon icon={faCircleXmark} className="pr-2" /> : <FontAwesomeIcon icon={faCircleCheck} className="pr-2" />}{this.state.isSMSError ? "Error in sending sms" : "Verification code sent to phone."}</Toast.Body>
                    </Toast>
                    <Toast bg={this.state.dataSavedError ? "danger" : "success"} onClose={() => { this.setState({ dataSavedSuccess: !this.state.dataSavedSuccess }) }} show={this.state.dataSavedSuccess} delay={3000} animation autohide>
                        <Toast.Body className="text-white ml-2 mr-2">{this.state.dataSavedError ? <FontAwesomeIcon icon={faCircleXmark} className="pr-2" /> : <FontAwesomeIcon icon={faCircleCheck} className="pr-2" />}{this.state.dataSavedError ? "Data not saved." : "Data saved successfully!"}</Toast.Body>
                    </Toast>
                    {/* <Toast bg="success" onClose={() => {this.setState({offerModal : !this.state.offerModal})}} show={this.state.offerModal} delay={3000} animation autohide>
                            <Toast.Body className="text-white ml-2 mr-2"><FontAwesomeIcon icon={faCircleCheck} className="pr-2"/>{this.state.successMsg}</Toast.Body>
                        </Toast> */}
                </ToastContainer>
                <div className={` ${this.state.show ? "printSummaryPanelShow" : "printSummaryPanelHide"}`}>
                    <div className="printSummaryPanel">
                        <div className="container-fluid pt-4" id="printSummaryDiv">
                            <div className="row">
                                <div className="col-12 pl-4 pr-4">
                                    <p><span className="h5"><b><FontAwesomeIcon icon={faPrint} /> Print Summary</b></span><span className="float-right btn" onClick={() => { this.revertChanges(); }}><FontAwesomeIcon icon={faCircleXmark} size="2x" /></span></p>
                                    <p className="text-secondary font-weight-bold mb-1">FOR STORE USE ONLY. NOT FOR CUSTOMER DISTRIBUTUION. PLEASE DISPOSE OF THIS FORM PROPERLY.</p>
                                    <p>Edits are not saved, only emailed or printed.</p>
                                    <div className="row">
                                        <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                            <label>Customer ID</label>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 pt-2">
                                            {/* <input
                                                    className="form-control printSummaryFields"
                                                    type="text"
                                                    name="txtCustomer"
                                                    id="txtCustomer"
                                                    placeholder="Account No"
                                                    onChange={(event) => this.onChange(event)}
                                                    value={this.state.customerId}
                                                    disabled = { this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true }
                                                ></input> */}
                                            <p className={`mb-0 p-2 border printSummaryFields  ${this.state.customerId === "" ? " pt-4 pb-3" : ""}`} style={{ borderRadius: "5px" }}>{this.state.customerId?.split("-")[0]}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                            <label>Store</label>
                                        </div>
                                        <div className="col-12 col-md-4 col-lg-4 pt-2">
                                            {/* <input
                                                    className="form-control printSummaryFields"
                                                    type="text"
                                                    name="txtStore"
                                                    id="txtStore"
                                                    placeholder="Store"
                                                    onChange={(event) => this.onChange(event)}
                                                    value={this.state.store}
                                                    maxLength="3"
                                                    disabled = { this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true }
                                                ></input> */}
                                            <p className={`mb-0 p-2 border printSummaryFields  ${this.state.store === "" ? " pt-4 pb-3" : ""}`} style={{ borderRadius: "5px" }}>{this.state.store}</p>
                                        </div>
                                    </div>

                                    <p className="h5 font-weight-bold mt-4">Borrower
                                        {this.state.psBorrowerExpand === false ?
                                            <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => this.setState({ psBorrowerExpand: !this.state.psBorrowerExpand })} />
                                            :
                                            <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => this.setState({ psBorrowerExpand: !this.state.psBorrowerExpand })} />
                                        }
                                    </p>
                                    <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                                    <div className={` ${this.state.psBorrowerExpand === true ? "" : " d-none"}`}>
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Title</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control printSummaryFields"
                                                        name="txtTitle"
                                                        id="txtTitle"
                                                        placeholder="Title"
                                                        onChange={(event) => this.onChange(event)}
                                                        value={this.state.formObj.title}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Borrower Name</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control printSummaryFields"
                                                        name="txtBorrowerName"
                                                        id="txtBorrowerName"
                                                        placeholder="Borrower Name"
                                                        // onChange={(event) => this.onChange(event)}
                                                        value={this.state.formObj.borrowerName}
                                                        // disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                        disabled={true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Suffix</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <select id="txtSuffix" name="txtSuffix" className="form-select p-2" onChange={(event) => this.onChange(event)} value={this.state.formObj.suffix}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}>
                                                        <option value="0">-- Select --</option>
                                                        {this.state.lstSuffix.map((suffix) => <option value={suffix.id}>{suffix.description}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Address</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control printSummaryFields"
                                                        name="txtAddress"
                                                        id="txtAddress"
                                                        placeholder="Mailing Address"
                                                        onChange={(event) => this.onChange(event)}
                                                        value={this.state.formObj.address}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Apt</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control printSummaryFields"
                                                        name="txtApt"
                                                        id="txtApt"
                                                        placeholder="Apt"
                                                        onChange={(event) => this.onChange(event)}
                                                        value={this.state.formObj.apt}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>City</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.formObj.city}
                                                        className="form-control printSummaryFields"
                                                        name="txtCity"
                                                        id="txtCity"
                                                        placeholder="City"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>State</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <select id="cmbState" name="cmbState"
                                                        className="form-select p-2"
                                                        onChange={(event) => this.onChange(event)}
                                                        value={this.state.formObj.state}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}>
                                                        <option value="0">-- Select --</option>
                                                        {this.state.lstState?.map((state) => <option value={state.id}>{state.description}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Zip Code</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.formObj.zipCode}
                                                        className="form-control printSummaryFields"
                                                        name="txtZipCode"
                                                        id="txtZipCode"
                                                        placeholder="Zip Code"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Phone Number</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='000-000-0000'
                                                        value={this.state.formObj.mobileNo}
                                                        className="form-control printSummaryFields"
                                                        name="txtMobileNo"
                                                        id="txtMobileNo"
                                                        placeholder="Phone Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Email</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.formObj.emailAdr}
                                                        className="form-control printSummaryFields"
                                                        name="txtEmailAdr"
                                                        id="txtEmailAdr"
                                                        placeholder="Email Address"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Secondary Number</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='000-000-0000'
                                                        value={this.state.formObj.mobileNo2}
                                                        className="form-control printSummaryFields"
                                                        name="txtMobileNo2"
                                                        id="txtMobileNo2"
                                                        placeholder="Secondary Phone Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Birthdate</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <DatePicker
                                                        className="form-control printSummaryFields"
                                                        name="txtDOB"
                                                        id="txtDOB"
                                                        placeholderText="Date of Birth (MM/DD/YYYY)"
                                                        selected={this.state.formObj.dob === "" ? null :
                                                            new Date(this.state.formObj.dob)}
                                                        // onChange={(event) => this.onDOBChange(event)}
                                                        dateFormat="MM/dd/yyyy"
                                                        maxDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        // disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                        disabled={true}
                                                        customInput={
                                                            <IMaskInput mask="00/00/0000" />
                                                        }
                                                    ></DatePicker>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Soc Sec No/ITIN</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input 
                                                        type = "text"
                                                    // <IMaskInput
                                                    //     mask='XXX-XX-0000'
                                                        value={this.state.formObj.ssn}
                                                        className="form-control printSummaryFields"
                                                        name="txtSSN"
                                                        id="txtSSN"
                                                        placeholder="SSN"
                                                        // onChange={(event) => this.onChange(event)}
                                                        // disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>ID Number</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input 
                                                        type="text"
                                                    //<IMaskInput
                                                    //     mask='XXX-000'
                                                        value={this.state.formObj.primaryIdNo}
                                                        className="form-control printSummaryFields"
                                                        name="txtIDNo"
                                                        id="txtIDNo"
                                                        placeholder="ID Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Employer's Name</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.formObj.empName}
                                                        className="form-control printSummaryFields"
                                                        name="txtEmpName"
                                                        id="txtEmpName"
                                                        placeholder="Employer's Name"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Employer's Phone</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='000-000-0000'
                                                        value={this.state.formObj.empPhoneNo}
                                                        className="form-control printSummaryFields"
                                                        name="txtEmpPhoneNo"
                                                        id="txtEmpPhoneNo"
                                                        placeholder="Employers Phone Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Type of Work/Job Duties</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.formObj.duties}
                                                        className="form-control printSummaryFields"
                                                        name="txtDuties"
                                                        id="txtDuties"
                                                        placeholder="Occupation"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Monthly Income</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <CurrencyInput
                                                        name="txtNetIncome"
                                                        id="txtNetIncome"
                                                        placeholder="Monthly Income"
                                                        allowDecimals={false}
                                                        className="form-control printSummaryFields"
                                                        //onChange={(event) => this.onChange(event)}
                                                        onValueChange={(value, name) => this.onCurrencyChange(value, name)}
                                                        value={this.state.formObj.netIncome}
                                                        prefix={'$'}
                                                        step={10}
                                                        maxLength={5}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Start Date</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <DatePicker
                                                        className="form-control printSummaryFields"
                                                        name="txtEmpStartDate"
                                                        id="txtEmpStartDate"
                                                        placeholderText="Employment Start Date (MM/YYYY)"
                                                        onChange={(event) => this.onStartDateChange(event)}
                                                        selected={this.state.formObj.empStartDate === "" ? null :
                                                            new Date(this.state.formObj.empStartDate.split('/')[1], this.state.formObj.empStartDate.split('/')[0] - 1, "01")}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                        customInput={
                                                            <IMaskInput mask="00/0000" />
                                                        }
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* {sessionStorage.getItem("coBorrower")=="true"?
                                    <>
                                    <p className="h5 font-weight-bold mt-4">Co_Borrower
                                        {this.state.psCo_BorrowerExpand === false ?
                                            <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => this.setState({ psCo_BorrowerExpand: !this.state.psCo_BorrowerExpand })} />
                                            :
                                            <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => this.setState({ psCo_BorrowerExpand: !this.state.psCo_BorrowerExpand })} />
                                        }
                                    </p>
                                    <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                                    <div className={` ${this.state.psCo_BorrowerExpand === true ? "" : " d-none"}`}>
                                        <form>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Title</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control printSummaryFields"
                                                        name="coTxtTitle"
                                                        id="coTxtTitle"
                                                        placeholder="Title"
                                                        onChange={(event) => this.onChange(event)}
                                                        value={this.state.coFormObj.title}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Co-Borrower Name</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        className="form-control printSummaryFields"
                                                        name="coTxtCoBorrowerName"
                                                        id="coTxtCoBorrowerName"
                                                        placeholder="Co-Borrower Name"
                                                        onChange={(event) => this.onChange(event)}
                                                        value={this.state.coFormObj.coBorrowerName}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    ></input>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Suffix</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <select id="coTxtSuffix" name="coTxtSuffix" className="form-select p-2" onChange={(event) => this.onChange(event)} value={this.state.coFormObj.suffix}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}>
                                                        <option value="0">-- Select --</option>
                                                        {this.state.lstSuffix.map((suffix) => <option value={suffix.id}>{suffix.description}</option>)}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Phone Number</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='000-000-0000'
                                                        value={this.state.coFormObj.mobileNo}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtMobileNo"
                                                        id="coTxtMobileNo"
                                                        placeholder="Phone Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Email</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.coFormObj.emailAdr}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtEmailAdr"
                                                        id="coTxtEmailAdr"
                                                        placeholder="Email Address"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Secondary Number</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='000-000-0000'
                                                        value={this.state.coFormObj.mobileNo2}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtMobileNo2"
                                                        id="coTxtMobileNo2"
                                                        placeholder="Secondary Phone Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Birthdate</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <DatePicker
                                                        className="form-control printSummaryFields"
                                                        name="coTxtDOB"
                                                        id="coTxtDOB"
                                                        placeholderText="Date of Birth (MM/DD/YYYY)"
                                                        selected={this.state.coFormObj.dob === "" ? null :
                                                            new Date(this.state.coFormObj.dob)}
                                                        onChange={(event) => this.coOnDOBChange(event)}
                                                        dateFormat="MM/dd/yyyy"
                                                        maxDate={new Date()}
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        dropdownMode="select"
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                        customInput={
                                                            <IMaskInput mask="00/00/0000" />
                                                        }
                                                    ></DatePicker>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Soc Sec No/ITIN</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='XXX-XX-0000'
                                                        value={this.state.coFormObj.ssn}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtSSN"
                                                        id="coTxtSSN"
                                                        placeholder="SSN"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>ID Number</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.coFormObj.primaryIdNo}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtIDNo"
                                                        id="coTxtIDNo"
                                                        placeholder="ID Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Employer's Name</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.coFormObj.empName}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtEmpName"
                                                        id="coTxtEmpName"
                                                        placeholder="Employer's Name"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Employer's Phone</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <IMaskInput
                                                        mask='000-000-0000'
                                                        value={this.state.coFormObj.empPhoneNo}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtEmpPhoneNo"
                                                        id="coTxtEmpPhoneNo"
                                                        placeholder="Employers Phone Number"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Type of Work/Job Duties</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <input
                                                        type="text"
                                                        value={this.state.coFormObj.duties}
                                                        className="form-control printSummaryFields"
                                                        name="coTxtDuties"
                                                        id="coTxtDuties"
                                                        placeholder="Occupation"
                                                        onChange={(event) => this.onChange(event)}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Monthly Income</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <CurrencyInput
                                                        name="coTxtNetIncome"
                                                        id="coTxtNetIncome"
                                                        placeholder="Monthly Income"
                                                        allowDecimals={false}
                                                        className="form-control printSummaryFields"
                                                        //onChange={(event) => this.onChange(event)}
                                                        onValueChange={(value, name) => this.onCurrencyChange(value, name)}
                                                        value={this.state.coFormObj.netIncome}
                                                        prefix={'$'}
                                                        step={10}
                                                        maxLength={5}
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                                    <label>Start Date</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2">
                                                    <DatePicker
                                                        className="form-control printSummaryFields"
                                                        name="coTxtEmpStartDate"
                                                        id="coTxtEmpStartDate"
                                                        placeholderText="Employment Start Date (MM/YYYY)"
                                                        // value={this.state.coFormObj.empStartDate}
                                                        selected={this.state.coFormObj.empStartDate === "" ? null :
                                                            new Date(this.state.coFormObj.empStartDate.split('/')[1], this.state.coFormObj.empStartDate.split('/')[0] - 1, "01")}
                                                        onChange={(event) => this.coOnStartDateChange(event)}
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                        disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                                        customInput={
                                                            <IMaskInput mask="00/0000" />
                                                        }
                                                        maxDate={new Date()}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    </>:""} */}
                                    {/* <p className="h5 font-weight-bold mt-4">Application Status
                                        {this.state.customerPreApprove === false ?
                                            <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => this.setState({ customerPreApprove: !this.state.customerPreApprove })} />
                                            :
                                            <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => this.setState({ customerPreApprove: !this.state.customerPreApprove })} />
                                        }
                                    </p>
                                    <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                                    <div className={` ${this.state.customerPreApprove === true ? "" : " d-none"}`}>
                                        <form>
                                            <div className="row border-bottom">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                                    <label>Daniel’s Credit</label>
                                                </div>
                                                <div className="col-12 col-md-6 col-lg-6 pt-2 pl-1 pr-1">
                                                <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: DanielsOffersStatus(this.state.appBorrowerInfo.isDaniel,this.state?.formObj?.createdDate) }}></div></div>
                                                </div>
                                            </div>
                                            <div className="row border-bottom">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                                    <label>Fortiva</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                                    <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: this.loadFortivaAppOffers() }}></div></div>
                                                </div>
                                            </div>
                                            <div className="row border-bottom">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                                    <label>Synchrony Bank Credit</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                                    <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: this.loadSynchronyAppOffers() }}></div></div>
                                                </div>
                                            </div>
                                            <div className="row border-bottom">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                                    <label>Progressive Leasing</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                                    <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: this.loadProgressiveAppOffers() }}></div></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                                    <label>Uown Leasing</label>
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                                    <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: this.loadUOwnAppOffers() }}></div></div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                        </form>
                                    </div> */}
                                     <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                                    <div className="row">
                                        <span className="text-danger pl-2">{this.state.printError !== "" && this.state.printError}</span>
                                        <div className="col-12 font-weight-bold text-secondary d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                name="chkPrintAtStore"
                                                id="chkPrintAtStore"
                                                onClick={(event) => { this.onPrintOptionsChecked(event) }}
                                                value={this.state.printAtStore}
                                                checked={this.state.printAtStore ? "checked" : ""}
                                                ></input>
                                                <label htmlFor="chkPrintAtStore" style={{ margin: "10px" }} className="d-inline">
                                                    Print at my store.
                                                </label>
                                            </div>
                                            <div className="col-12 font-weight-bold text-secondary d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                name="chkSentToMagix"
                                                id="chkSentToMagix"
                                                onClick={(event) => { this.onPrintOptionsChecked(event) }}
                                                value={this.state.sentToMagix}
                                                checked={this.state.sentToMagix ? "checked" : ""}
                                                disabled={userStoreCode === "null" || userStoreCode === undefined || userStoreCode === "" ? true : false }
                                                ></input>
                                                <label htmlFor="chkSentToMagix" style={{ margin: "10px" }} className="d-inline">
                                                    Send to Magix account’s home store.
                                                </label>
                                            </div>
                                            {/* <div className="col-12 font-weight-bold text-secondary d-flex align-items-center">
                                            <input
                                                type="checkbox"
                                                name="chkEmailCredit"
                                                id="chkEmailCredit"
                                                onClick={(event) => { this.onPrintOptionsChecked(event) }}
                                                value={this.state.emailCredit}
                                                checked={this.state.emailCredit ? "checked" : ""}
                                                ></input>
                                                <label htmlFor="chkEmailCredit" style={{ margin: "10px" }} className="d-inline">
                                                    Send to credit.
                                                </label>
                                            </div> */}
                                            <div className={"col-12 " + (this.state.emailCredit ? "" : "d-none")}>
                                                <p className="w-100 mb-1 text-danger font-weight-bold">Print Summary is no longer used to send Add-Ons to Credit.  Please click Add-On/Re-Open/Apply to send an Add-On.</p>
                                                {/* <p className={"w-100 mt-2 h5 " + ((this.state.isSMSOverride || this.state.isSMSVerified) ? "" : "d-none")}><b>Mobile Number Verified.<FontAwesomeIcon icon={faCircleCheck} className="pl-2 text-success" /></b></p>
                                                <div className={"row mb-3 " + ((this.state.isSMSOverride || this.state.isSMSVerified) ? "d-none" : "")}>
                                                    <div className="col-12 col-md-6">
                                                    <div className="row">
                                                        <div className="col-6 pr-0">
                                                            <div className="form-group text-left mt-md-2">
                                                                <label className="text-secondary">Mobile Number </label>
                                                                <IMaskInput
                                                                    mask='000-000-0000'
                                                                    className="form-control printSummaryFields"
                                                                    name="txtSmsMobileNumber"
                                                                    id="txtSmsMobileNumber"
                                                                    placeholder="Mobile Number *"
                                                                    onChange={(event) => this.onChange(event)}
                                                                    value={this.state.smsMobileNumber}
                                                                    maxLength="12"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group text-left mt-md-2">
                                                                <label className="text-secondary">&nbsp;</label>
                                                                <button
                                                                    type="submit"
                                                                    className="form-control border-dark bg-secondary text-primary p-0"
                                                                    name="mobileNoForOTP"
                                                                    id="mobileNoForOTP"
                                                                    onClick={(event) => this.submitMobileNo(event)}
                                                                // disabled = { this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true }
                                                                >Send Code</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 pr-0">
                                                            {this.state.phoneNumErr !== "" &&
                                                                <div style={{ color: "red", paddingBottom: 10 }}>{this.state.phoneNumErr}</div>
                                                            }
                                                        </div>
                                                        <div className={"col-6 " +((this.state.SMSCodeSent || this.state.isSMSError) ? "" : " d-none")}>
                                                            <div className="form-group text-left mt-md-2 mb-0">
                                                                <button
                                                                    type="submit"
                                                                    className="form-control border-dark bg-secondary text-primary p-0"
                                                                    name="byPassSMS"
                                                                    id=""
                                                                    onClick={(event) => this.BypassSMS(event)}
                                                                // disabled = { this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true }
                                                                >Bypass SMS</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="row">
                                                        <div className="col-6 pr-0">
                                                            <div className="form-group text-left mt-md-2">
                                                                <label className="text-secondary">Verification code</label>
                                                                <input type="text"
                                                                    placeholder="Verification code"
                                                                    className="form-control border-dark"
                                                                    name="txtVerificationCode" id="txtVerificationCode"
                                                                    onChange={(event) => this.onChange(event)}
                                                                    value={this.state.verificationCode}
                                                                    maxLength="6"
                                                                    ref={(input) => { this.txtVerificationCode = input; }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="form-group text-left mt-md-2">
                                                                <label className="text-secondary">&nbsp;</label>
                                                                <button
                                                                    name="codeVerificationBtn"
                                                                    id="codeVerificationBtn"
                                                                    className="form-control border-dark bg-secondary text-primary"
                                                                    placeholder="Enter Code"
                                                                    onClick={(event) => {
                                                                        this.submitMobileNo(event);
                                                                    }}
                                                                >Verify</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 p-0">
                                                        {this.state.verificationCodeErr !== "" &&
                                                            <div style={{ color: "red", paddingBottom: 10 }}>{this.state.verificationCodeErr}</div>
                                                        }
                                                    </div>
                                                </div>
                                                </div> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <textarea
                                                id="comments"
                                                name="comments"
                                                rows="5"
                                                className="w-100 mt-2 p-2 printSummaryFields"
                                                placeholder="Comments"
                                                style={{ borderRadius: "5px" }}
                                                value={this.state.comments}
                                                onChange={(event) => this.onChange(event)}
                                                disabled={this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canAdd || this.state.permission_status.find((page) => page.sitePageDetail_id === 1)?.canEdit ? false : true : true}
                                            ></textarea>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 mt-4 mb-3">
                                            <div className="row">
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <p className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0" onClick={() => { this.revertChanges(); }}>Cancel</p>
                                                </div>
                                                {/* <div className="col-12 col-md-4 col-lg-4"> */}
                                                    {/* <button className="btn bg-secondary text-primary w-100" onClick={(event) => {
                                                            this.downloadPDF(event);
                                                        }}>Download as PDF</button> */}
                                                    <ReactToPrint
                                                        trigger={() =>
                                                            <p className="btn bg-secondary text-primary w-100 d-none" id="onScreenPrintTab">Print at Store</p>
                                                        }
                                                        content={() => this.printSummary}
                                                        pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                                                        documentTitle={`${this.state.customerId}-Information`}
                                                    />
                                                {/* </div> */}
                                                <div className="col-12 col-md-4 col-lg-4">
                                                    <button className="btn bg-secondary text-primary w-100" onClick={(event) => this.submitPrint(event)}
                                                        //disabled={this.state.emailCredit ? ((this.state.isSMSOverride || this.state.isSMSVerified) ? false : true) : false}
                                                        >Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="customerInfo" ref={el => (this.printSummary = el)}>
                                        <div className="ml-5 mb-3 mr-5 mt-4">
                                            <h2 className="text-secondary">Customer Information</h2>
                                            <h5 className="font-weight-bold">For store use only. Not for customer distribution. Please dispose of this form properly.</h5>
                                            <h6>Edits are not saved, only emailed or printed.</h6>
                                            <div className="mt-4">
                                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Customer ID</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.customerId}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Home Store</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{((localStorage.getItem("magixassociateId")) !== "null") ? this.state.appBorrowerInfo.store_code : (this.state.appBorrowerInfo?.magixStoreCode !== null ? this.state.appBorrowerInfo.magixStoreCode : "")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 offset-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Apply Store</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{JSON.parse(localStorage.getItem("associateStores"))}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-4 printDatas">
                                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                                    <div className="col-12 mt-3 mb-2">
                                                        <h4>Borrower</h4>
                                                        <hr className="mt-0"></hr>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Title {this.state.appBorrowerInfo.title_code !== null ? (this.state.appBorrowerInfo.title_code?.toLowerCase() !== this.state.formObj.title?.toLowerCase() ? "*" : "") : (this.state.formObj.title !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Secondary Number {this.state.appBorrowerInfo.secondaryPhone !== null ? ((formatPhoneNumber(this.state.appBorrowerInfo.secondaryPhone) !== formatPhoneNumber(this.state.formObj.mobileNo2)) ? "*" : "") : (this.state.formObj.mobileNo2 !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.mobileNo2}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Borrower Name {borrowerName?.toLowerCase() !== this.state.formObj.borrowerName?.toLowerCase() ? "*" : ""}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.borrowerName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Birth date {this.state.appBorrowerInfo.birthDate !== null ? (Moment(this.state.appBorrowerInfo.birthDate).format("MM/DD/YYYY") !== this.state.formObj.dob ? "*" : "") : (this.state.formObj.dob !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.dob}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Suffix {this.state.appBorrowerInfo.suffix_Id !== null && this.state.appBorrowerInfo.suffix_Id !== "0" ? (this.state.appBorrowerInfo.suffix_Id != this.state.formObj.suffix ? "*" : "") : (this.state.formObj.suffix !== "" && this.state.formObj.suffix !== "0" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.lstSuffix.map((suffix) => this.state.formObj.suffix == suffix.id ?
                                                                    suffix.description : ""
                                                                )}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Soc Sec No/ITIN {this.state.appBorrowerInfo.ssn !== null ? (this.state.appBorrowerInfo.ssn !== this.state.formObj.ssn ? "*" : "") : (this.state.formObj.ssn !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.ssn}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Address {this.state.appBorrowerInfo.address !== null ? ((this.state.appBorrowerInfo.address?.toLowerCase() + ", " + (this.state.appBorrowerInfo.apt !== null ? this.state.appBorrowerInfo.apt?.toLowerCase() : "")) !== this.state.formObj.address?.toLowerCase() + ", " + this.state.formObj.apt?.toLowerCase() ? "*" : "") : ((this.state.formObj.address + ", " + this.state.formObj.apt) !== ", " ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.address + ", " + this.state.formObj.apt}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">ID Number {this.state.appBorrowerInfo.idNumber !== null ? (this.state.appBorrowerInfo.idNumber?.toLowerCase() !== this.state.formObj.primaryIdNo?.toLowerCase() ? "*" : "") : (this.state.formObj.primaryIdNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.primaryIdNo}</p>
                                                                {/* <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.primaryIdNo == null || this.state.formObj.primaryIdNo == "" || this.state.formObj.primaryIdNo == undefined ? "" : "XXX-"+this.state.formObj.primaryIdNo?.slice(-3)}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">City {this.state.appBorrowerInfo.city !== null ? (this.state.appBorrowerInfo.city?.toLowerCase() !== this.state.formObj.city?.toLowerCase() ? "*" : "") : (this.state.formObj.city !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.city}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Name {this.state.appBorrowerInfo.empName !== null ? (this.state.appBorrowerInfo.empName?.toLowerCase() !== this.state.formObj.empName?.toLowerCase() ? "*" : "") : (this.state.formObj.empName !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.empName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">State {this.state.appBorrowerInfo.state_Id !== null ? (this.state.appBorrowerInfo.state_Id != this.state.formObj.state ? "*" : "") : (this.state.formObj.state !== "0" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.lstState.map((state) => this.state.formObj.state == state.id ?
                                                                    state.description : ""
                                                                )}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Phone Number {this.state.appBorrowerInfo.empPhone !== null ? (formatPhoneNumber(this.state.appBorrowerInfo.empPhone) !== formatPhoneNumber(this.state.formObj.empPhoneNo) ? "*" : "") : (this.state.formObj.empPhoneNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.empPhoneNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Zip Code {this.state.appBorrowerInfo.zipCode !== null ? (this.state.appBorrowerInfo.zipCode !== this.state.formObj.zipCode ? "*" : "") : (this.state.formObj.zipCode !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.zipCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Type of Work/Job Duties {this.state.appBorrowerInfo.workJob !== null ? (this.state.appBorrowerInfo.workJob?.toLowerCase() !== this.state.formObj.duties?.toLowerCase() ? "*" : "") : (this.state.formObj.duties !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.duties}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Phone Number {this.state.appBorrowerInfo.mobileNo !== null ? (formatPhoneNumber(this.state.appBorrowerInfo.mobileNo) !== formatPhoneNumber(this.state.formObj.mobileNo) ? "*" : "") : (this.state.formObj.mobileNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.mobileNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Monthly Net Income (from all sources) {this.state.appBorrowerInfo.monthlyIncome !== null ? (this.state.appBorrowerInfo.monthlyIncome !== this.state.formObj.netIncome ? "*" : "") : (this.state.formObj.netIncome !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>&#36; {this.state.formObj.netIncome}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Email Address {this.state.appBorrowerInfo.emailAddress !== null ? (this.state.appBorrowerInfo.emailAddress?.toLowerCase() !== this.state.formObj.emailAdr?.toLowerCase() ? "*" : "") : (this.state.formObj.emailAdr !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.emailAdr}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Start Date {this.state.appBorrowerInfo.howLong !== null ? (Moment(this.state.appBorrowerInfo.howLong).format("MM/YYYY") !== this.state.formObj.empStartDate ? "*" : "") : (this.state.formObj.empStartDate !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.formObj.empStartDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {sessionStorage.getItem("coBorrower")=="true"? 
                                            <div className="mt-4">
                                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                                    <div className="col-12 mt-4 mb-2">
                                                        <h4>Co-Borrower</h4>
                                                        <hr className="mt-0"></hr>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Title {this.state.appCoBorrowerInfo.title_code !== null ? (this.state.appCoBorrowerInfo.title_code !== this.state.coFormObj.title ? "*" : "") : (this.state.coFormObj.title !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Secondary Number {this.state.appCoBorrowerInfo.secondaryPhone !== null ? (formatPhoneNumber(this.state.appCoBorrowerInfo.secondaryPhone) !== formatPhoneNumber(this.state.coFormObj.mobileNo2) ? "*" : "") : (this.state.coFormObj.mobileNo2 !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.mobileNo2}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Co-Borrower Name {((this.state.appCoBorrowerInfo.firstName == null || this.state.appCoBorrowerInfo.firstName == "" ? "" : this.state.appCoBorrowerInfo.firstName) + " " + (this.state.appCoBorrowerInfo.middleName == null || this.state.appCoBorrowerInfo.middleName == "" ? "" : this.state.appCoBorrowerInfo.middleName) + " " + (this.state.appCoBorrowerInfo.lastName == null || this.state.appCoBorrowerInfo.lastName == "" ? "" : this.state.appCoBorrowerInfo.lastName)) !== this.state.coFormObj.coBorrowerName ? "*" : ""}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.coBorrowerName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Birth date {this.state.appCoBorrowerInfo.birthDate !== null && this.state.appCoBorrowerInfo.birthDate !== "" ? (Moment(this.state.appCoBorrowerInfo.birthDate).format("MM/DD/YYYY") !== this.state.coFormObj.dob ? "*" : "") : (this.state.coFormObj.dob !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.dob}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Suffix {this.state.appCoBorrowerInfo.suffix_Id !== null && this.state.appCoBorrowerInfo.suffix_Id !== "0" ? (this.state.appCoBorrowerInfo.suffix_Id != this.state.coFormObj.suffix ? "*" : "") : (this.state.coFormObj.suffix !== "" && this.state.coFormObj.suffix !== "0" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.lstSuffix.map((suffix) => this.state.coFormObj.suffix == suffix.id ?
                                                                    <p>{suffix.description}</p> : ""
                                                                )}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Soc Sec No/ITIN {this.state.appCoBorrowerInfo.masked_ssn !== null ? (this.state.appCoBorrowerInfo.masked_ssn !== this.state.coFormObj.ssn ? "*" : "") : (this.state.coFormObj.ssn !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.ssn}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Phone Number {this.state.appCoBorrowerInfo.mobileNo !== null ? (formatPhoneNumber(this.state.appCoBorrowerInfo.mobileNo) !== formatPhoneNumber(this.state.coFormObj.mobileNo) ? "*" : "") : (this.state.coFormObj.mobileNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.mobileNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">ID Number {this.state.appCoBorrowerInfo.idNumber !== null && this.state.appCoBorrowerInfo.idNumber !== undefined ? (this.state.appCoBorrowerInfo.idNumber !== this.state.coFormObj.primaryIdNo ? "*" : "") : (this.state.coFormObj.primaryIdNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.primaryIdNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Email Address {this.state.appCoBorrowerInfo.emailAddress !== null ? (this.state.appCoBorrowerInfo.emailAddress !== this.state.coFormObj.emailAdr ? "*" : "") : (this.state.coFormObj.emailAdr !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.emailAdr}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Name {this.state.appCoBorrowerInfo.empName !== null ? (this.state.appCoBorrowerInfo.empName !== this.state.coFormObj.empName ? "*" : "") : (this.state.coFormObj.empName !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.empName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Phone Number {this.state.appCoBorrowerInfo.empPhone !== null ? (formatPhoneNumber(this.state.appCoBorrowerInfo.empPhone) !== formatPhoneNumber(this.state.coFormObj.empPhoneNo) ? "*" : "") : (this.state.coFormObj.empPhoneNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.empPhoneNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Type of Work/Job Duties {this.state.appCoBorrowerInfo.workJob !== null ? (this.state.appCoBorrowerInfo.workJob !== this.state.coFormObj.duties ? "*" : "") : (this.state.coFormObj.duties !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.duties}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Monthly Net Income (from all sources) {this.state.appCoBorrowerInfo.monthlyIncome !== null ? (this.state.appCoBorrowerInfo.monthlyIncome !== this.state.coFormObj.netIncome ? "*" : "") : (this.state.coFormObj.netIncome !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>&#36; {this.state.coFormObj.netIncome}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Start Date {this.state.appCoBorrowerInfo.howLong !== null && this.state.appCoBorrowerInfo.howLong !== "" ? (Moment(this.state.appCoBorrowerInfo.howLong).format("MM/YYYY") !== this.state.coFormObj.empStartDate ? "*" : "") : (this.state.coFormObj.empStartDate !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{this.state.coFormObj.empStartDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:""}

                                            <div className="mt-4">
                                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                                    <div className="col-12 mt-4 mb-2">
                                                        <h4>Application Status</h4>
                                                        <hr className="mt-0"></hr>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p className="text-secondary mb-1">Daniel’s Credit</p>
                                                            </div>
                                                            <div className="col-7">
                                                            <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: DanielsOffersStatus(this.state.appBorrowerInfo.isDaniel,this.state?.formObj?.createdDate) }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6"></div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p className="text-secondary mb-1">Fortiva</p>
                                                            </div>
                                                            <div className="col-7">
                                                            <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: this.loadFortivaAppOffers() }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p className="text-secondary mb-1">Synchrony Bank Credit</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: this.loadSynchronyAppOffers() }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p className="text-secondary mb-1">Progressive Leasing</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: this.loadProgressiveAppOffers() }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-5">
                                                                <p className="text-secondary mb-1">UOWN Leasing</p>
                                                            </div>
                                                            <div className="col-7">
                                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                                    <div dangerouslySetInnerHTML={{ __html: this.loadUOwnAppOffers() }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="mt-4" style={{ borderRadius: "10px" }}>
                                                <div className="row bg-white">
                                                    <div className="col-12 mt-4 mb-2">
                                                        <h4>Comments</h4>
                                                        <hr className="mt-0"></hr>
                                                    </div>
                                                    <div className="col-12 mb-2">
                                                        <p className="mb-1" style={{ whiteSpace: "pre-line", wordBreak: "break-all" }}>{this.state.comments}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="float-right mr-5">{new Date().toLocaleString('en-US', {timeZone: 'America/Los_Angeles',})}</span>
                                    </div>
                                    <div id="customerInfo" ref={el => (this.printToStore = el)}>
                                        <div className="ml-5 mb-3 mr-5 mt-4">
                                            <div dangerouslySetInnerHTML={{ __html: this.state.appBorrowerInfo.printContent }} />
                                            <span className="float-right">{sessionStorage.getItem("createdDate") == "" ? "" : new Date(sessionStorage.getItem("createdDate")).toLocaleString()}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                    <Sidebar
                        toggled={this.state.toggled}
                        handleToggleSidebar={() => { this.handleToggleSidebar() }}
                    />
                    <main>
                        <CustomNavbar handleToggleSidebar={() => { this.handleToggleSidebar() }} currentPage={this.props.history.location.pathname} />
                        <div id="main" className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-8">
                                    <p className="mt-2 ml-3 mr-2 mb-0 pr-1 d-flex align-items-center h-100" style={{ fontSize: "20px" }}>
                                        <FontAwesomeIcon icon={faCircleInfo} className="pr-2 text-secondary" />
                                        <span style={{ fontSize: "20px" }}><b>Customer updates need to be made in Magix</b></span>
                                    </p>
                                </div>
                                <div className="col-12 col-md-4 mt-3 mt-md-0 mt-lg-0">
                                    <span className="printSummaryLabel mt-3 ml-3 mr-2 mb-0 pr-1" style={{ cursor: "pointer" }} onClick={() => this.setState({ show: !this.state.show, smsMobileNumber: this.state.formObj.mobileNo })}><FontAwesomeIcon icon={faPrint} className="pr-2" />Print Summary</span>
                                    <ReactToPrint
                                        trigger={() =>
                                            <span id="printToStore" className="printToStoreLabel mt-3 ml-3 mr-2 mb-0 pr-1" style={{ cursor: "pointer" }} onClick={() => this.setState({ show: !this.state.show })}><FontAwesomeIcon icon={faPrint} className="pr-2" />Print In Store</span>
                                        }
                                        content={() => this.printToStore}
                                        pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                                        documentTitle={`${this.state.customerId}-Information`}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 d-flex">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-2 shadow w-100">
                                        <Card.Body>
                                            <p className="h4 d-flex"><b>Customer Information</b>{this.state.admin_permission_status[0]?.canEdit ? (!this.state.editMasterCustomer ? <button className="btn border ml-auto" onClick={() => {this.setState({editMasterCustomer : true}); this.loadAddress(this.state.masterCustomerInfo?.address)}}>Edit</button> : <><button className="btn border ml-auto" onClick={() => this.cancelMasterCustomerEdit()}>Cancel</button><button className="btn bg-secondary text-primary ml-2" onClick={() => this.masterCustomerEdit()}>Save</button></>) : null }</p>
                                            <div>
                                                <p className="mt-4 mb-1 h5"><b>Borrower</b>
                                                    {this.state.borrowerExpand === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => { this.handleBorrowerExpand() }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => { this.handleBorrowerExpand() }} />
                                                    }
                                                </p>
                                                <hr className="mt-0 mb-2"></hr>
                                                {this.state.borrowerExpand === true ?
                                                    <div className={"row "+(sessionStorage.getItem("coBorrower")=="true" ? "mb-5" : "mb-3")}>
                                                        <div className="col-12 col-md-6">
                                                            <div className="row">
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    <b>Customer ID</b>
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custBorrowerInfo.customer_Id}
                                                                    {/* {localStorage.getItem("magixassociateId") !== "null" ? this.state.custBorrowerInfo.customer_Id : sessionStorage.getItem("magixInstoreData") !== "" ? this.state.custBorrowerInfo.magix_Id : localStorage.getItem("customerId")} */}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Borrower Name
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {!this.state.editMasterCustomer ? 
                                                                    <>
                                                                        {this.state.custBorrowerInfo.firstName} {this.state.custBorrowerInfo.middleName} {this.state.custBorrowerInfo.lastName}
                                                                        </>
                                                                    :
                                                                        <div className="d-flex">
                                                                            <div className="mr-1">
                                                                            <input 
                                                                                className={"form-control border "+(this.state.formErrors.firstName ? "border-danger" : "")}
                                                                                type="text"
                                                                                id="masterCustFirstName"
                                                                                name="masterCustFirstName"
                                                                                placeholder="First Name"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.firstName}
                                                                            />
                                                                            {this.state.formErrors.firstName ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                            </div>
                                                                            <div className="mr-1">
                                                                            <input 
                                                                                className={"form-control border"}
                                                                                type="text"
                                                                                id="masterCustMiddleName"
                                                                                name="masterCustMiddleName"
                                                                                placeholder="Middle Name"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.middleName}
                                                                            />
                                                                            </div>
                                                                            <div>
                                                                            <input 
                                                                                className={"form-control border "+(this.state.formErrors.lastName ? "border-danger" : "")}
                                                                                type="text"
                                                                                id="masterCustLastName"
                                                                                name="masterCustLastName"
                                                                                placeholder="Last Name"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.lastName}
                                                                            />
                                                                            {this.state.formErrors.lastName ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Address
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {!this.state.editMasterCustomer ? 
                                                                        <>{this.state.custBorrowerInfo.address}, {this.state.custBorrowerInfo.apt}</>
                                                                    :
                                                                        <div className="d-flex">
                                                                            {!this.state.addressAutoFillEnable === true ? 
                                                                            <>
                                                                            <div>
                                                                                <input 
                                                                                    className={"form-control mr-1 border "+(this.state.formErrors.address ? "border-danger" : "")}
                                                                                    type="text"
                                                                                    id="masterCustAddress"
                                                                                    name="masterCustaddress"
                                                                                    placeholder="Address"
                                                                                    onChange={(event) => this.onChange(event)}
                                                                                    value={this.state.masterCustomerInfo.address}
                                                                                />
                                                                                {this.state.formErrors.address ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                                </div>
                                                                                <div>
                                                                                <input 
                                                                                className="form-control border "
                                                                                type="text"
                                                                                id="masterCustApt"
                                                                                name="masterCustApt"
                                                                                placeholder="Apt"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.apt}
                                                                            />
                                                                            </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <div>
                                                                                <Autocomplete
                                                                                    items={this.state.allAddress}
                                                                                    shouldItemRender={(item, value) => item?.fullAddress?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1}
                                                                                    getItemValue={item => item?.AddressKey}
                                                                                    renderItem={(item, isHighlighted) =>
                                                                                        <div key={item?.AddressKey}>
                                                                                            <p style={{ background: isHighlighted ? '#007bff' : 'white', color : isHighlighted ? 'white' : '#1b1b1b', padding : "1px 5px" }} className="mb-0">{item.fullAddress}</p>
                                                                                        </div>
                                                                                    }
                                                                                    wrapperProps={{ style : {width : '100%', display: 'inline-block'} }}
                                                                                    menuStyle ={{
                                                                                        borderRadius: '5px',
                                                                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                                                        background: 'white',
                                                                                        padding: '3px 3px',
                                                                                        position: 'absolute',
                                                                                        maxHeight : "300%",
                                                                                        // maxHeight: mailingAdrErr === undefined || mailingAdrErr =="" ? '200%' : '120%',
                                                                                        overflow:'auto',
                                                                                        zIndex : '100',
                                                                                        // top: mailingAdrErr === undefined || mailingAdrErr =="" ? '85%' : '55%',
                                                                                        top: "100%",
                                                                                        left: '4%',
                                                                                    }}
                                                                                    value={this.state.masterCustomerInfo.address}
                                                                                    onChange={e => {this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, address : e.target.value}}); this.loadAddress(e.target.value)}}
                                                                                    onSelect={(val) => {this.autoFill(val)}}
                                                                                    inputProps={{
                                                                                        placeholder: 'Mailing Address',
                                                                                        className : ("form-control border mr-1 " + (this.state.formErrors.address ? ' border-danger' : '')),
                                                                                    }}
                                                                                />
                                                                                {this.state.formErrors.address ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                                </div>
                                                                                <div>
                                                                                <Autocomplete
                                                                                    items={this.state.allAptUnit}
                                                                                    shouldItemRender={(item, value) => item?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1}
                                                                                    getItemValue={item => item}
                                                                                    renderItem={(item, isHighlighted) =>
                                                                                        <div key={item}>
                                                                                            <p style={{ background: isHighlighted ? '#007bff' : 'white', color : isHighlighted ? 'white' : '#1b1b1b', padding : "1px 5px" }} className="mb-0">{item}</p>
                                                                                        </div>
                                                                                    }
                                                                                    wrapperProps={{ style : {width : '100%', display: 'inline-block'} }}
                                                                                    menuStyle ={{
                                                                                        borderRadius: '5px',
                                                                                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                                                        background: 'white',
                                                                                        padding: '3px 3px',
                                                                                        position: 'absolute',
                                                                                        maxHeight: '300%',
                                                                                        overflow:'auto',
                                                                                        zIndex : '100',
                                                                                        top: '100%',
                                                                                        left: '50%',
                                                                                    }}
                                                                                    value={this.state.masterCustomerInfo.aptUnit}
                                                                                    onChange={e => this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, aptUnit : e.target.value}})}
                                                                                    onSelect={(val) => this.setState({masterCustomerInfo : {...this.state.masterCustomerInfo, aptUnit : val}},() => {this.handleFormValidation();})}
                                                                                    inputProps={{
                                                                                        placeholder:"Apt/Unit (As Needed)",
                                                                                        className : ("form-control ml-1 border " + (this.state.formErrors.aptUnit ? ' border-danger' : '')),
                                                                                        name:"masterAptUnit",
                                                                                        id:"masterAptUnit"
                                                                                    }}
                                                                                />
                                                                                {this.state.formErrors.aptUnit ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                                </div>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    City State Zip
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {!this.state.editMasterCustomer ? 
                                                                        <>{this.state.custBorrowerInfo.city?this.state.custBorrowerInfo.city+",":""} {this.state.lstState.map((state) => this.state.custBorrowerInfo.state_Id == state.id ? state.description+",":"")} {this.state.custBorrowerInfo.zipCode}</>
                                                                    :
                                                                        <div className="d-flex">
                                                                            <div className="mr-1">
                                                                            <input 
                                                                                className={"form-control border "+(this.state.formErrors.city ? "border-danger" : "")}
                                                                                type="text"
                                                                                id="masterCustCity"
                                                                                name="masterCustCity"
                                                                                placeholder="City"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.city}
                                                                            />
                                                                            {this.state.formErrors.city ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                            </div>
                                                                            <div className="mr-1">
                                                                            <select id="masterCustStateId" name="masterCustStateId"
                                                                                className={"form-select p-2 "+(this.state.formErrors.stateID ? "border-danger" : "form-select-border")+(this.state.formErrors.city || this.state.formErrors.stateID || this.state.formErrors.zipCode ? " h-75" : "")}
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.state_Id} >
                                                                                <option value="0">-- Select --</option>
                                                                                {this.state.lstState?.map((state) => <option value={state.id}>{state.description}</option>)}
                                                                            </select>
                                                                            {this.state.formErrors.stateID ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                            </div>
                                                                            <div>
                                                                            <input 
                                                                                className={"form-control border "+(this.state.formErrors.zipCode ? "border-danger" : "")}
                                                                                type="text"
                                                                                id="masterCustZipCode"
                                                                                name="masterCustZipCode"
                                                                                maxLength={5}
                                                                                placeholder="Zip Code"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                value={this.state.masterCustomerInfo.zipCode}
                                                                            />
                                                                            {this.state.formErrors.zipCode ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Phone Number
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {!this.state.editMasterCustomer ? 
                                                                        <>{this.state.custBorrowerInfo.mobileNo?formatPhoneNumber(this.state.custBorrowerInfo.mobileNo):""}</>
                                                                    :
                                                                        <div className="d-flex">
                                                                            <div>
                                                                            <IMaskInput
                                                                                mask='000-000-0000'
                                                                                value={this.state.masterCustomerInfo.mobileNo}
                                                                                className={"form-control border "+(this.state.formErrors.mobileNo ? "border-danger" : "")}
                                                                                name="masterCustMobileNo"
                                                                                id="masterCustMobileNo"
                                                                                placeholder="Phone Number"
                                                                                onChange={(event) => this.onChange(event)}
                                                                            />
                                                                            {this.state.formErrors.mobileNo !== undefined ? (this.state.formErrors.mobileNo === true ? <p className="text-danger mb-0 small">*Required</p> : <p className="text-danger mb-0 small">*{this.state.formErrors.mobileNo}</p>) : null }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="row">
                                                                {/* <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    <b>Employer's Name</b>
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custBorrowerInfo.empName}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Employer's Phone
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custBorrowerInfo.empPhone}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Occupation
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custBorrowerInfo.workJob}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Start Date
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {parseInt(this.state.custBorrowerInfo.howLong) > 0 ? Moment(this.state.custBorrowerInfo.howLong).format("MM/YYYY") : ""}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Monthly Income
                                                                </div> */}
                                                                {/* <div className="col-6 pt-2">
                                                                    ${this.state.custBorrowerInfo.monthlyIncome}
                                                                </div> */}
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Email
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {!this.state.editMasterCustomer ? 
                                                                        <>{this.state.custBorrowerInfo.emailAddress}</>
                                                                    :
                                                                        <div className="d-flex">
                                                                            <div>
                                                                            <input 
                                                                                className={"form-control border "+(this.state.formErrors.email ? "border-danger" : "")}
                                                                                type="text"
                                                                                id="masterCustMobileNoEmailAddress"
                                                                                name="masterCustMobileNoEmailAddress"
                                                                                onChange={(event) => this.onChange(event)}
                                                                                placeholder="Email"
                                                                                value={this.state.masterCustomerInfo.emailAddress}
                                                                            />
                                                                            {this.state.formErrors.email === "InValid" ? <p className="text-danger mb-0 small">*InValid</p> : this.state.formErrors.email ? <p className="text-danger mb-0 small">*Required</p> : null }
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    SSN/ITIN
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custBorrowerInfo.masked_ssn}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Date Of Birth
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {parseInt(this.state.custBorrowerInfo.birthDate) > 0 ? Moment(this.state.custBorrowerInfo.birthDate).format("MM/DD/YYYY") : ""}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Identification
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custBorrowerInfo.masked_idNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>
                                            {sessionStorage.getItem("coBorrower")=="true"? 
                                            <div>
                                                <p className="mt-4 mb-1 h5"><b>Co-Borrower</b>
                                                    {this.state.Co_BorrowerExpand === false ?
                                                        <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => { this.handleCo_BorrowerExpand() }} />
                                                        :
                                                        <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => { this.handleCo_BorrowerExpand() }} />
                                                    }
                                                </p>
                                                <hr className="mt-0 mb-2"></hr>
                                                {this.state.Co_BorrowerExpand === true ?
                                                    <div className="row mb-1">
                                                        <div className="col-12 col-md-6">
                                                            <div className="row">
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    <b>Customer ID</b>
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {localStorage.getItem("magixassociateId") !== "null" ? this.state.custCoBorrowerInfo.magix_Id : sessionStorage.getItem("magixInstoreData") !== "" ? this.state.custCoBorrowerInfo.magix_Id : ""}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Co-Borrower Name
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.firstName} {this.state.custCoBorrowerInfo.middleName} {this.state.custCoBorrowerInfo.lastName}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Address
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.address}, {this.state.custCoBorrowerInfo.apt}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    City State Zip
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.city}, {this.state.lstState.map((state) => this.state.custCoBorrowerInfo.state_Id == state.id && state.description)}, {this.state.custCoBorrowerInfo.zipCode}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Phone Number
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.mobileNo}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-md-6">
                                                            <div className="row">
                                                                {/* <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    <b>Employer's Name</b>
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.empName}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Employer's Phone
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.empPhone}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Occupation
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.workJob}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Start Date
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {parseInt(this.state.custCoBorrowerInfo.howLong) > 0 ? Moment(this.state.custCoBorrowerInfo.howLong).format("MM/YYYY") : ""}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Monthly Income
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    ${this.state.custCoBorrowerInfo.monthlyIncome}
                                                                </div> */}
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Email
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.emailAddress}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    SSN/ITIN
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.masked_ssn}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Date Of Birth
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {parseInt(this.state.custCoBorrowerInfo.birthDate) > 0 ? Moment(this.state.custCoBorrowerInfo.birthDate).format("MM/DD/YYYY") : ""}
                                                                </div>
                                                                <div className="col-6 font-weight-bold pt-2 text-secondary">
                                                                    Identification
                                                                </div>
                                                                <div className="col-6 pt-2">
                                                                    {this.state.custCoBorrowerInfo.masked_idNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>:""}
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-sm-12">
                                    <Card className="text-left mt-3 ml-2 mr-2 mb-3 shadow">
                                        <Card.Body>
                                            {/* <p className="text-center text-danger" style={{ fontSize: "14px" }}>Check the Financial Partner portal for the most current account status.</p> */}
                                            <p className="font-weight-bold h5 mb-4">Application Status</p>
                                            <div className="row mb-4">
                                                <div className="col-6">
                                                    <p className="mb-2"><b>Daniel’s Credit</b></p>
                                                      <div dangerouslySetInnerHTML={{ __html: DanielsOffersStatus(this.state.custBorrowerInfo.isDaniel,this.state?.formObj?.createdDate, this.state.selectedMagixAcct) }}></div>
                                                      {(localStorage.getItem("magix_account_no") != "null" && localStorage.getItem("magix_account_no") != "" && this.state.isDanielsApplicationFlowChanges) ?
                                                            <div onClick={() =>  this.state.danielsApp ? this.addOnandreOpen("Daniel", applicationType) : null } style={{cursor: this.state.danielsApp? "pointer" : "context-menu"}}>
                                                            <p className={"h6 text-decoration-none pl-1 mb-0 " + (applicationType==="Apply for New Account"? "ml-2":"ml-4")+(this.state.danielsApp ? " text-secondary" : " text-muted")}>
                                                                <b>{applicationType}</b>
                                                                <FontAwesomeIcon icon={faCircleChevronRight} className={"pl-2 "+(this.state.danielsApp ? "text-secondary" : "text-muted")} />
                                                            </p>
                                                        </div>
                                                        :
                                                      <div onClick={() => this.state.danielsApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? this.addOnandreOpen("Daniel", applicationType) : null : null) : null } className={`mb-0 d-block h-50 ${this.state.danielsApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? "" : "disabled" : "disabled") : "disabled"}`} style={{ cursor: `${this.state.danielsApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? ((sessionStorage.getItem("isFortiva") == "true") ? ((sessionStorage.getItem("fortivaOffer_Accepted") == "true" || sessionStorage.getItem("fortivaOffer_Accepted") == "undefined") ? "pointer" : "context-menu") : "pointer") : "context-menu" : "context-menu") : "context-menu"}` }}>
                                                        <p className={"h6 ml-4 text-decoration-none pl-1 mb-0 "+(this.state.danielsApp ? "text-secondary" : "text-muted")}>
                                                        <span className="font-weight-bold">
                                                            {(localStorage.getItem("magix_account_no") != "null" && localStorage.getItem("magix_account_no") != "") ? "Add-On/Re-Open" : "Apply Now"}
                                                        </span><FontAwesomeIcon icon={faCircleChevronRight} className={"pl-2 "+(this.state.danielsApp ? "text-secondary" : "text-muted")} />
                                                        </p>
                                                      </div>
                                                    }
                                                </div>
                                                {/* <div className="col-6">
                                                    <p className="mb-1"><b>Synchrony</b></p>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadSynchronyOffers() }}></div>
                                                </div> */}
                                            </div>
                                            <hr></hr>
                                            <div className="row mb-4">
                                                <div className="col-6">
                                                    <p className="mb-1"><b>Fortiva</b></p>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadFortivaOffers() }}></div>
                                                    <div onClick={() => this.state.fortivaApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? this.addOnandreOpen("Fortiva", applicationType) : null : null) : null} className={`mb-0 d-block h-35 ${this.state.fortivaApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? "" : "disabled" : "disabled") : "disabled"}`} style={{ cursor: `${this.state.fortivaApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? ((sessionStorage.getItem("isFortiva") == "true") ? ((sessionStorage.getItem("fortivaOffer_Accepted") == "true" || sessionStorage.getItem("fortivaOffer_Accepted") == "undefined") ? "pointer" : "context-menu") : "pointer") : "context-menu" : "context-menu") : "context-menu"}` }}>
                                                        <p className={"h6 ml-4 text-decoration-none pl-1 mb-0 "+(this.state.fortivaApp ? "text-secondary" : "text-muted")}>
                                                            <span className="font-weight-bold">Apply</span><FontAwesomeIcon icon={faCircleChevronRight} className={"pl-3 "+(this.state.fortivaApp ? "text-secondary" : "text-muted")} />
                                                        </p>
                                                    </div>
                                                    {sessionStorage.getItem("tenderFortivaAccess") == "true" ? 
                                                    <div className="mb-0 d-block h-35" style={{ cursor: `${sessionStorage.getItem("tenderFortivaEnable") == "true" ? "pointer" : "context-menu"}` }}
                                                        onClick={sessionStorage.getItem("tenderFortivaEnable") == "true" ? () => this.triggerTender("Fortiva") : null}>
                                                            <p className={"h6 ml-4 text-decoration-none pl-1 mb-0 text-bold mt-2 "+ (sessionStorage.getItem("tenderFortivaEnable") == "true" ? "text-secondary" : "text-muted")} >
                                                                <span className="font-weight-bold"> Tender</span>
                                                                <FontAwesomeIcon icon={faCircleChevronRight} 
                                                                    className={"pl-2 "+ (sessionStorage.getItem("tenderFortivaEnable") == "true" ? "text-secondary" : "text-muted")} />
                                                            </p>
                                                    </div>
                                                    : null }
                                                </div>
                                                <div className="col-6">
                                                    <p className="mb-1"><b>Synchrony</b></p>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadSynchronyOffers() }}></div>
                                                    <div onClick={() => this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? this.addOnandreOpen("Synchrony", applicationType) : null : null} className={`mb-0 d-block h-35 ${this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? "" : "disabled" : "disabled"}`} style={{ cursor: `${this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? ((sessionStorage.getItem("isFortiva") == "true") ? ((sessionStorage.getItem("fortivaOffer_Accepted") == "true" || sessionStorage.getItem("fortivaOffer_Accepted") == "undefined") ? "pointer" : "context-menu") : "pointer") : "context-menu" : "context-menu"}` }}>
                                                        <p className={"h6 ml-4 text-decoration-none pl-1 mb-0 text-secondary"}>
                                                        <span className="font-weight-bold">Apply</span><FontAwesomeIcon icon={faCircleChevronRight} className={"pl-3 text-secondary"} />
                                                        </p>
                                                    </div>
                                                    {sessionStorage.getItem("tenderSynchronyAccess") == "true" ? 
                                                        <div  className={`mb-0 d-block h-35 ${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "" : "disabled"}`} 
                                                                style={{ cursor: `${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "pointer" : "context-menu"}` }}
                                                                onClick={(sessionStorage.getItem("tenderSynchronyEnable") == "true") ? () => this.triggerTender("Synchrony") : null }>
                                                                <p className={`h6 ml-4 text-decoration-none pl-1 mb-0 mt-2 ${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "text-secondary" : "text-muted"}`}><span className="font-weight-bold"> Tender</span><FontAwesomeIcon icon={faCircleChevronRight} className={`pl-2 ${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "text-secondary" : "text-muted"}`} /></p>
                                                            </div>
                                                      
                                                        : null }
                                                </div>
                                            </div> 
                                            <hr></hr>
                                            <div className="row mt-4">
                                                <div className="col-6">
                                                    <p className="mb-1"><b>Progressive</b></p>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadProgressiveOffers() }}></div>
                                                    <div onClick={() => this.state.progressiveApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? this.addOnandreOpen("Progressive", applicationType) : null : null) : null} className={`${this.state.progressiveApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? "" : "disabled" : "disabled") : "disabled"}`} style={{ cursor: `${this.state.progressiveApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? ((sessionStorage.getItem("isFortiva") == "true") ? ((sessionStorage.getItem("fortivaOffer_Accepted") == "true" || sessionStorage.getItem("fortivaOffer_Accepted") == "undefined") ? "pointer" : "context-menu") : "pointer") : "context-menu" : "context-menu") : "context-menu"}` }}>
                                                        <p className={"h6 ml-4 text-decoration-none pl-1 mb-0 "+(this.state.progressiveApp ? "text-secondary" : "text-muted")}>
                                                        <span className="font-weight-bold">Apply</span><FontAwesomeIcon icon={faCircleChevronRight} className={"pl-2 "+(this.state.progressiveApp ? "text-secondary" : "text-muted")} />
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <p className="mb-1"><b>UOWN</b></p>
                                                    <div dangerouslySetInnerHTML={{ __html: this.loadUOwnOffers() }}></div>
                                                    <div onClick={() => this.state.uownApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? this.addOnandreOpen("Uown", applicationType) : null : null) : null} className={`${this.state.uownApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? "" : "disabled" : "disabled") : "disabled"}`} style={{ cursor: `${this.state.uownApp ? (this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? ((sessionStorage.getItem("isFortiva") == "true") ? ((sessionStorage.getItem("fortivaOffer_Accepted") == "true" || sessionStorage.getItem("fortivaOffer_Accepted") == "undefined") ? "pointer" : "context-menu") : "pointer") : "context-menu" : "context-menu") : "context-menu"}` }}>
                                                        <p className={"h6 ml-4 text-decoration-none pl-1 mb-0 "+(this.state.uownApp ? "text-secondary" : "text-muted")}>
                                                        <span className="font-weight-bold">Apply</span><FontAwesomeIcon icon={faCircleChevronRight} className={"pl-2 "+(this.state.uownApp ? "text-secondary" : "text-muted")} />
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center mt-4">
                                                {/* <button className="btn text-primary bg-secondary pl-5 pr-5 pt-2 pb-2" onClick={() => this.state.permission_status !== "" ?( this.state.permission_status.find((page) => page.sitePageDetail_id === 2)?.canView ? this.goToViewOffers() : null) : null}>View Details</button> */}
                                                <Link to="/view-offers" className={`btn text-primary bg-secondary pl-5 pr-5 pt-2 pb-2 text-decoration-none ${this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 2)?.canView ? "" : " disabled" : "disabled"} ${(this.state.cusPreApproval.length > 0 || sessionStorage.getItem("isDaniel") =="true") ? "" : " disabled"}`}>View Details</Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                                {/* <div className="col-12 col-md-4 pl-md-0 d-flex">
                                    <div className="row w-100">
                                        <div className="col-12 pr-0">
                                            <div onClick={() => this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? this.creditApplication() : null : n${this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? "" : "disabled" : "disabled"}`} style={{ cursor: `${this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 3)?.canView ? ((sessionStorage.getItem("isFortiva") == "true") ? ((sessionStorage.getItem("fortivaOffer_Accepted") == "true" || sessionStorage.getItem("fortivaOffer_Accepted") == "undefined") ? "pointer" : "context-menu") : "pointer") : "context-menu" : "context-menu"}` }}>
                                                <Card className="text-left mt-3 ml-2 mr-2 mb-2 w-100 shadow" style={{ height: '85%' }}>
                                                    <Card.Body>
                                                        <div className="text-center d-flex h-100 justify-content-center align-items-center">
                                                            <p className={"h5 text-decoration-none pl-1 mb-0 text-secondary"}>
                                                                Add-On/Re-Open/Apply<FontAwesomeIcon icon={faCircleChevronRight} className={"pl-2 text-secondary"} />
                                                            </p>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        {sessionStorage.getItem("tenderFortivaAccess") == "true" ? 
                                        <div className="col-12 pr-0">
                                       
                                            style={{ cursor: `${sessionStorage.getItem("tenderFortivaEnable") == "true" ? "pointer" : "context-menu"}` }}
                                            onClick={sessionStorage.getItem("tenderFortivaEnable") == "true" ? () => this.triggerTender("Fortiva") : null}>
                                                <Card className="text-left mt-3 ml-2 mr-2 w-100 shadow" style={{ height: '85%' }}>
                                                    <Card.Body>
                                                        <div className="text-center d-flex h-100 justify-content-center align-items-center">
                                                            <p className={"h5 text-decoration-none pl-1 mb-0 "+ (sessionStorage.getItem("tenderFortivaEnable") == "true" ? "text-secondary" : "text-muted")} >
                                                                Tender Fortiva
                                                                <FontAwesomeIcon icon={faCircleChevronRight} 
                                                                    className={"pl-2 "+ (sessionStorage.getItem("tenderFortivaEnable") == "true" ? "text-secondary" : "text-muted")} />
                                                            </p>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        : null }
                                        {sessionStorage.getItem("tenderSynchronyAccess") == "true" ? 
                                        <div className="col-12 pr-0">
                                        <${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "" : "disabled"}`} 
                                                style={{ cursor: `${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "pointer" : "context-menu"}` }}
                                                onClick={(sessionStorage.getItem("tenderSynchronyEnable") == "true") ? () => this.triggerTender("Synchrony") : null }>
                                                <Card className="text-left mt-3 ml-2 mr-2 mb-3 w-100 shadow" style={{ height: '85%' }}>
                                                    <Card.Body>
                                                        <div className="text-center d-flex h-100 justify-content-center align-items-center">
                                                            <p className={`h5 text-decoration-none pl-1 mb-0 ${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "text-secondary" : "text-muted"}`}>Tender Synchrony<FontAwesomeIcon icon={faCircleChevronRight} className={`pl-2 ${(sessionStorage.getItem("tenderSynchronyEnable") == "true")? "text-secondary" : "text-muted"}`} /></p>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </div>
                                        : null }
                                        {(sessionStorage.getItem("tenderFortivaAccess") == "false" && sessionStorage.getItem("tenderSynchronyAccess") == "false") ? 
                                        <div className="col-12 pr-0">
                                            <Link to="/view-offers" className={`text-decoration-none ${this.state.permission_status !== "" ? this.state.permission_status.find((page) => page.sitePageDetail_id === 2)?.canView ? "" : " disabled" : "disabled"} ${(this.state.cusPreApproval.length > 0 || sessionStorage.getItem("isDaniel") == "true")? "" : " disabled"}`}>
                                                <div className="card mt-3 ml-2 mr-2 w-100 shadow" style={{ height: '85%' }}>
                                                    <div className="card-body">
                                                        <div className="text-center d-flex h-100 justify-content-center align-items-center">
                                                            {(this.state.cusPreApproval.length > 0 || sessionStorage.getItem("isDaniel") == "true")?
                                                                <p href="view-offers" className="h5 text-decoration-none text-secondary" >View Offers <FontAwesomeIcon icon={faCircleChevronRight} className="text-secondary" /></p>
                                                                :
                                                                <p href="view-offers" className="h5 text-decoration-none text-danger" >No Offers Found</p>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                        : null }
                                    </div>
                                </div> */}
                            </div>
                            {((localStorage.getItem("magix_account_no") !== "null") && sessionStorage.getItem("magixInstoreData") !== "")?
                            <>
                            <div className="row mb-3">
                            <div className="col-12">
                                <Card className="text-left ml-2 mr-2 mb-2 shadow">
                                    <Card.Body>
                                    <p className="h5 mb-3"><b>Daniel's Credit Account</b></p>
                                    {multipleMagixId?.map((item) => {
                                        ++count;
                                        let res = this.state.dpay_offers?.find(data => { return data?.accountID == item?.split("-")[0]});
                                                return (
                                                <div className="row">
                                        <div className="col-6 col-md-3 col-lg-2 border-right">
                                            <p className={count === 1 ? "" : "d-md-none d-lg-none"}><b>Store</b></p>
                                            <p>{item?.split("-")[1]}</p>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-2 border-right">
                                            <p className={count === 1 ? "" : "d-md-none d-lg-none"}><b>Account ID</b></p>
                                            <p>{item?.split("-")[0]}</p>
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-2 border-right">
                                            <p className={count === 1 ? "" : "d-md-none d-lg-none"}><b>Current Balance</b></p>
                                            {/* {res !== undefined ? <p>$ {parseFloat(res?.currentBalance)?.toFixed(2)}</p> : <p>-</p>} */}
                                            {item?.split("-")[2] !== "" ? <p>$ {parseFloat(item?.split("-")[2])?.toFixed(2)}</p> : <p>-</p>}
                                        </div>
                                        <div className="col-6 col-md-3 col-lg-2 border-right">
                                            <p className={count === 1 ? "" : "d-md-none d-lg-none"}><b>$0 Down Payment Offer</b></p>
                                            {res !== undefined ? <><p className="mb-0">$ {parseFloat(res?.baseOffer)?.toFixed(2)}</p>{parseFloat(res?.baseOffer)?.toFixed(2) != 0 ? <p><b><i>Subject to Credit Approval</i></b></p> : ""}</> : <p>-</p>}
                                        </div>
                                        {/* <div className="col-6 col-md-3 col-lg-2 ">
                                            <p><b>Dpay DP %</b></p>
                                            {multipleMagixId?.map((item) => {
                                                let res = this.state.dpay_offers?.find(data => { return data?.accountID == item?.split("-")[0]})?.downpayment
                                                return res !== undefined ? <p>{parseFloat(res)*100} %</p> : <p>-</p>;
                                            })}
                                        </div> */}
                                    </div>);
                                            })}
                                    </Card.Body>
                                </Card>
                                </div>
                            </div>
                            </> : null}
                            <hr className="mb-0"></hr>
                           <EmailApplyLink/>
                        </div>
                    </main>
                    <ConfirmDialog
                        show={this.state.creditModal}
                        handleModalPopup={this.creditApplication}
                        cancelPopup={true}
                        message={"<p class='mb-1'>This is for security purposes.</p><p class='mb-1'>You are applying again for customer <b>" + (localStorage.getItem('selectedUserName')) + "</b>. Continue or cancel to go back.</p>"}
                        button1_name="Stay In &amp; continue"
                        button1={this.stayInAndContinue}
                        button2_name="Logout &amp; continue"
                        button2={this.logOutAndContinue}
                    />

                    <TenderModal 
                        showTender = {this.state.showTender}
                        triggerTender = {this.triggerTender}
                        currentTender = {this.state.currentTender}
                    />
                    {/* display modal if synchrony status is pedning */}
                    {/* <ConfirmDialog
                        show={this.state.synchronyPending}
                        handleModalPopup={this.synchronPendingModal}
                        message={`<p class="mb-0  text-danger font-weight-bold text-left">Your application cannot be sent to Fortiva because the Synchrony status is Pending.  Contact Synchrony and resolve the pending issue first, then apply for Fortiva only.</p>`}
                        header_name = "Confirmation"
                        button2_name="OK"
                        button2={this.synchronPendingOk}
                        isSynchronyPending={this.state.synchronyPending}
                    /> */}
                     {/* display prog uown modal if first application process is completed */}
                     {/* <ProgUownDialog
                        show={this.state.showProgUown}
                        message={`<span class=\"mb-0 \">Do you want to apply for one of the following: Progressive Leasing, Uown Leasing?</span>`}
                        handleFortivaModal={this.handleProgUownApplication}
                        submitFortiva={this.redirectProgUownApplication}
                        btn1_name="No"
                        btn2_name="Yes"
                    /> */}
                    {/* display fortiva modal if synchrony is approved to submit fortiva app */}
                    {/* <FortivaDialog
                        show={this.state.showFortiva}
                        handleModalPopup={this.handleFortivaModal}
                        message={`<span class="mb-0 font-weight-bold" style="color:#0000ff">Your Synchrony application has been approved.  Do you still want to send your application to Fortiva?</span>`}
                        handleFortivaModal={this.handleFortivaModal}
                        submitFortiva={this.submitFortiva}
                        btn1_name="No"
                        btn2_name="Yes"
                    /> */}

                    {/* <FortivaErrorDialog
                        show={this.state.fortivaAppError}
                        handleModalPopup={this.handleFortivaErrorModal}
                        message={`<span class=\"mb-0 \">${this.state.fortivaAppErrMsg}</span>`}
                        submitFortiva={this.redirectoFortivaApplication}
                    />
                    <FortivaErrorDialog
                        show={this.state.synchronyDeclined}
                        message={`<span class="mb-0 font-weight-bold" style="color:#0000ff">Sending the application to Fortiva</span>`}
                        submitFortiva={this.synchronDeclinedOk}
                    />  */}

                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(customerDetailsActions, dispatch),
        applyNowActions: bindActionCreators(applyNowActions, dispatch),
        userRightsactions: bindActionCreators(userRightsActions, dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        syncActions: bindActionCreators(synchronyActions, dispatch),
        CusLookUpActions: bindActionCreators(customerLookupActions, dispatch),
        creditApplication: bindActionCreators(creditApplication, dispatch),
        adminActions : bindActionCreators(AdminActions, dispatch),
        accountSummaryAction: bindActionCreators(accountSummaryAction, dispatch),
        customerUpdation: bindActionCreators(customerUpdationActions, dispatch),
    }
}
export default connect(null, mapDispatchToProps)(CustomerDetails);