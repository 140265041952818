import React, {useRef}  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleUp,faCircleXmark,faAngleDown} from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from 'react-to-print';
import formatPhoneNumber from "../../components/component.formatPhoneNumber";
import Moment from 'moment';
import { DanielsOffersStatus } from "../../components/component.applicationStatus";

const CreditAppView=(props)=>{
    const {printsummaryShow,psBorrowerExpand,psCo_BorrowerExpand,customerId,
        printSummaryformObj,customerPreApproveSummary,loadSynchronyOffers,
        loadProgressiveOffers,loadUOwnOffers,expandPrintSummary,
        printSummarymobileVerificationExpand,borrowerInfo,coBorrowerInfo,loadFortivaOffers}=props;
    const componentRef = useRef(null);
    let borrowerName="";
        if(borrowerInfo.firstName){
            borrowerName= borrowerInfo.firstName;
        }
        if(borrowerInfo.middleName){
            borrowerName= borrowerName+" "+borrowerInfo.middleName;
        }
        if(borrowerInfo.lastName){
            borrowerName= borrowerName+" "+borrowerInfo.lastName;
        }
    return(
        <div className={` ${printsummaryShow ? "printSummaryPanelShow" : "printSummaryPanelHide"}`}>
        <div className="printSummaryPanel">
            <div className="container-fluid pt-4">
                <div className="row">
                    <div className="col-12 pl-4 pr-4">
                        <p><span className="h5"><b> Print Summary</b></span><span className="float-right btn p-0 mr-0"   onClick={() => expandPrintSummary("cancel")} ><FontAwesomeIcon icon={faCircleXmark} size="2x" /></span></p>
                        <div className="row">
                            <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                <label>Customer ID</label>
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 pt-2">
                                <label>{customerId}</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 pl-3 text-secondary d-flex align-items-center">
                                <label>Store</label>
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 pt-2">
                                <label>{printSummaryformObj.homeStore==null?printSummaryformObj.homeStore:printSummaryformObj.homeStore}</label>
                            </div>
                        </div>

                        <p className="h5 font-weight-bold mt-4">Borrower
                            {psBorrowerExpand === false ?
                                <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => expandPrintSummary("psBorrowerExpand") } />
                                :
                                <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => expandPrintSummary("psBorrowerExpand") } />
                            }
                        </p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        <div className={` ${psBorrowerExpand === true ? "" : " d-none"}`}>
                            <form>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Title {borrowerInfo.title_code !== null ? (borrowerInfo.title_code?.toLowerCase() !== printSummaryformObj.title?.toLowerCase() ? "*" : "") : (printSummaryformObj.title !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                        <label>{printSummaryformObj.title}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Borrower Name {borrowerName?.toLowerCase()!== printSummaryformObj.borrowerName?.toLowerCase() ? "*" : ""}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.borrowerName}</label>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Suffix{(borrowerInfo.suffix_Id !== null && borrowerInfo.suffix_Id!==undefined) ? (borrowerInfo.suffix_Id !== printSummaryformObj.borrowerSuffix ? "*" : "") : ((printSummaryformObj.borrowerSuffix !== "" && printSummaryformObj.borrowerSuffix!==null)  ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                        <label>{printSummaryformObj.borrowerSuffix}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Address {borrowerInfo.address !== null ? (borrowerInfo.address?.toLowerCase()  !== printSummaryformObj.address?.toLowerCase()? "*":"" ): (printSummaryformObj.address !== ""  ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                        <label>{printSummaryformObj.address}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                    <label>Apt { borrowerInfo.apt !==null?borrowerInfo.apt?.toLowerCase() !== printSummaryformObj.apt?.toLowerCase()  ? "*" : "":(printSummaryformObj.apt !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.apt}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>City{borrowerInfo.city !== null ? (borrowerInfo.city?.toLowerCase() !== printSummaryformObj.city?.toLowerCase() ? "*" : "") : (printSummaryformObj.city !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.city}</label>
                                       
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>State {(borrowerInfo.state_Id !== null && borrowerInfo.state_Id !== undefined) ? (borrowerInfo.state_Id?.toLowerCase() != printSummaryformObj.state?.toLowerCase() ? "*" : "") : (printSummaryformObj.state !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.state}</label>
                                       
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Zip Code {borrowerInfo.zipCode !== null ? (borrowerInfo.zipCode !== printSummaryformObj.zipCode ? "*" : "") : (printSummaryformObj.zipCode !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.zipCode}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Phone Number {borrowerInfo.mobileNo !== null ? (formatPhoneNumber(borrowerInfo.mobileNo) !== formatPhoneNumber(printSummaryformObj.mobileNo) ? "*" : "") : (printSummaryformObj.mobileNo !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                        <label>{printSummaryformObj.mobileNo}</label>
                                       
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Email {borrowerInfo.emailAddress !== null ? (borrowerInfo.emailAddress?.toLowerCase() !== printSummaryformObj.emailAdr?.toLowerCase() ? "*" : "") : (printSummaryformObj.emailAdr !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.emailAdr}</label>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Secondary Number{borrowerInfo.secondaryPhone !== null ? ((formatPhoneNumber(borrowerInfo.secondaryPhone) !== formatPhoneNumber(printSummaryformObj.secPhone)) ? "*" : "") : (printSummaryformObj.secPhone !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                        <label>{printSummaryformObj.secPhone}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Birthdate{borrowerInfo.birthDate !== null ? (Moment(borrowerInfo.birthDate).format("MM/DD/YYYY") !== printSummaryformObj.dob ? "*" : "") : (printSummaryformObj.dob !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                        <label>{printSummaryformObj.dob}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Soc Sec No/ITIN {borrowerInfo.ssn !== null ? (borrowerInfo.ssn !== printSummaryformObj.ssn ? "*" : "") : (printSummaryformObj.ssn !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.ssn}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>ID Number {borrowerInfo.idNumber !== null ? (borrowerInfo.idNumber?.toLowerCase() !== printSummaryformObj.idNumber?.toLowerCase() ? "*" : "") : (printSummaryformObj.idNumber !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.idNumber}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Employer's Name {borrowerInfo.empName !== null ? (borrowerInfo.empName?.toLowerCase() !== printSummaryformObj.empName?.toLowerCase() ? "*" : "") : (printSummaryformObj.empName !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.empName}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Employer's Phone {borrowerInfo.empPhone !== null ? (formatPhoneNumber(borrowerInfo.empPhone) !== formatPhoneNumber(printSummaryformObj.empPhoneNo) ? "*" : "") : (printSummaryformObj.empPhoneNo !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.empPhoneNo}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Type of Work/Job Duties {borrowerInfo.workJob !== null ? (borrowerInfo.workJob?.toLowerCase() !== printSummaryformObj.duties?.toLowerCase() ? "*" : "") : (printSummaryformObj.duties !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.duties}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Monthly Income {borrowerInfo.monthlyIncome !== null ? (borrowerInfo.monthlyIncome !== printSummaryformObj.monthlyIncome ? "*" : "") : (printSummaryformObj.monthlyIncome !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.monthlyIncome}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Start Date {borrowerInfo.howLong !== null ? (Moment(borrowerInfo.howLong).format("MM/YYYY") !== printSummaryformObj.empStartDate ? "*" : "") : (printSummaryformObj.empStartDate !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.empStartDate}</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* { sessionStorage.getItem("coBorrower")=="true"?
                        <>
                        <p className="h5 font-weight-bold mt-4">Co_Borrower
                            {psCo_BorrowerExpand === false ?
                                <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => expandPrintSummary("psCo_BorrowerExpand")} />
                                :
                                <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => expandPrintSummary("psCo_BorrowerExpand")} />
                            }
                        </p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        <div className={` ${psCo_BorrowerExpand === true ? "" : " d-none"}`}>
                            <form>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Title {coBorrowerInfo.title_code !== null ? (coBorrowerInfo.title_code?.toLowerCase() !== printSummaryformObj.cborrowerTitle?.toLowerCase() ? "*" : "") : (printSummaryformObj.cborrowerTitle !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.cborrowerTitle}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Co-Borrower Name{((coBorrowerInfo.firstName == null || coBorrowerInfo.firstName == "" ? "" : coBorrowerInfo.firstName + " ") + (coBorrowerInfo.middleName == null || coBorrowerInfo.middleName == "" ? "" : coBorrowerInfo.middleName + " ") + (coBorrowerInfo.lastName == null || coBorrowerInfo.lastName == "" ? "" : coBorrowerInfo.lastName)) !== printSummaryformObj.coborrowerName ? "*" : ""}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerName}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Suffix {coBorrowerInfo.suffix_Id !== null && coBorrowerInfo.suffix_Id !== "0" ? (coBorrowerInfo.suffix_Id != printSummaryformObj.coborrowerSuffix ? "*" : "") : (printSummaryformObj.coborrowerSuffix !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerSuffix}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Phone Number{coBorrowerInfo.mobileNo !== null ? (formatPhoneNumber(coBorrowerInfo.mobileNo) !== formatPhoneNumber(printSummaryformObj.coborrowermobileNo) ? "*" : "") : (printSummaryformObj.coborrowermobileNo !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowermobileNo}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Email {coBorrowerInfo.emailAddress !== null ? (coBorrowerInfo.emailAddress !== printSummaryformObj.coborroweremailAddress ? "*" : "") : (printSummaryformObj.coborroweremailAddress !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborroweremailAddress}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Secondary Number{coBorrowerInfo.secondaryPhone !== null ? (formatPhoneNumber(coBorrowerInfo.secondaryPhone) !== formatPhoneNumber(printSummaryformObj.coborrowersecondaryPhone) ? "*" : "") : (printSummaryformObj.coborrowersecondaryPhone !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowersecondaryPhone}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Birthdate{coBorrowerInfo.birthDate !== null && coBorrowerInfo.birthDate !== "" ? (Moment(coBorrowerInfo.birthDate).format("MM/DD/YYYY") !== printSummaryformObj.coborrowerbirthDate ? "*" : "") : (printSummaryformObj.coborrowerbirthDate !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerbirthDate}</label>
                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Soc Sec No/ITIN {coBorrowerInfo.masked_ssn !== null ? (coBorrowerInfo.masked_ssn !== printSummaryformObj.coborrowerssn ? "*" : "") : (printSummaryformObj.coborrowerssn !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerssn}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>ID Number {coBorrowerInfo.idNumber !== null && coBorrowerInfo.idNumber !== undefined ? (coBorrowerInfo.idNumber !== printSummaryformObj.coborroweridNumber ? "*" : "") : (printSummaryformObj.coborroweridNumber !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborroweridNumber}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Employer's Name {coBorrowerInfo.empName !== null ? (coBorrowerInfo.empName !== printSummaryformObj.coborrowerempName ? "*" : "") : (printSummaryformObj.coborrowerempName !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerempName}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Employer's Phone {coBorrowerInfo.empPhone !== null ? (formatPhoneNumber(coBorrowerInfo.empPhone) !== formatPhoneNumber(printSummaryformObj.coborrowerempPhone) ? "*" : "") : (printSummaryformObj.coborrowerempPhone !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerempPhone}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Type of Work/Job Duties {coBorrowerInfo.workJob !== null ? (coBorrowerInfo.workJob !== printSummaryformObj.coborrowerworkJob ? "*" : "") : (printSummaryformObj.coborrowerworkJob !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerworkJob}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Monthly Income {coBorrowerInfo.monthlyIncome !== null ? (coBorrowerInfo.monthlyIncome !==printSummaryformObj.coborrowermonthlyIncome ? "*" : "") : (printSummaryformObj.coborrowermonthlyIncome !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowermonthlyIncome}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                        <label>Start Date {coBorrowerInfo.howLong !== null && coBorrowerInfo.howLong !== "" ? (Moment(coBorrowerInfo.howLong).format("MM/YYYY") !== printSummaryformObj.coborrowerhowLong ? "*" : "") : (printSummaryformObj.coborrowerhowLong !== "" ? "*" : "")}</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>{printSummaryformObj.coborrowerhowLong}</label>
                                    </div>
                                </div>
                            </form>
                        </div></>:""}
                        <p className="h5 font-weight-bold mt-4">Application Status
                            {customerPreApproveSummary === false ?
                                <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => expandPrintSummary("customerPreApproveSummary")} />
                                :
                                <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => expandPrintSummary("customerPreApproveSummary")}  />
                            }
                        </p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        <div className={` ${customerPreApproveSummary === true ? "" : " d-none"}`}>
                            <form>
                                <div className="row border-bottom">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Daniel’s Credit</label>
                                    </div>
                                    <div className="col-6 col-md-6 col-lg-6 pt-2 pl-1 pr-1">
                                    <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: DanielsOffersStatus(printSummaryformObj?.isDaniel,Moment(printSummaryformObj?.createdDate).format("MM/DD/YYYY")) }}></div></div>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Fortiva</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadFortivaOffers() }}></div></div>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Synchrony Bank Credit</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadSynchronyOffers() }}></div></div>
                                    </div>
                                </div>
                                <div className="row border-bottom">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Progressive Leasing</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadProgressiveOffers() }}></div></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-start">
                                        <label>Uown Leasing</label>
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-4 pt-2 pl-1 pr-1">
                                        <div className="pl-2 d-block"><div dangerouslySetInnerHTML={{ __html: loadUOwnOffers() }}></div></div>
                                    </div>
                                </div>
                                <hr></hr>
                            </form>
                        </div>
                        <p className="h5 font-weight-bold mt-4"> Mobile Verification
                            {printSummarymobileVerificationExpand === false ? (
                            <FontAwesomeIcon icon={faAngleDown} className="float-right" size="lg" onClick={() => expandPrintSummary("mobileVerification")}/>
                            ) : 
                            (
                                <FontAwesomeIcon icon={faAngleUp} className="float-right" size="lg" onClick={() => expandPrintSummary("mobileVerification")}/>
                            )}
                         </p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        <div
                        className={` ${
                            printSummarymobileVerificationExpand === true
                            ? ""
                            : " d-none"
                        }`}
                        >
                        <form>
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                    <label> Mobile Number </label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <p>  {printSummaryformObj.mobileNo} </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-1 text-secondary d-flex align-items-center">
                                    <label>Is MobileNo Verified</label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-1">
                                    <p>
                                    {typeof printSummaryformObj.mobileNoVerified ===
                                    "boolean"
                                        ? printSummaryformObj.mobileNoVerified
                                        ? "true"
                                        : "false"
                                        : printSummaryformObj.mobileNoVerified}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-1 text-secondary d-flex align-items-center">
                                    <label> Is SMS Code Sent</label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-1">
                                    <p>
                                    {typeof printSummaryformObj.smsCodeSent === "boolean"
                                        ? printSummaryformObj.smsCodeSent
                                        ? "true"
                                        : "false"
                                        : printSummaryformObj.smsCodeSent}
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-1 text-secondary d-flex align-items-center">
                                    <label>  Is SMS Override </label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-1">
                                    <p>
                                    {typeof printSummaryformObj.smsOverride === "boolean"
                                        ? printSummaryformObj.smsOverride
                                        ? "true"
                                        : "false"
                                        : printSummaryformObj.smsOverride}
                                    </p>
                                </div>
                            </div>
                        </form>
                        </div> */}


                        <hr style={{ backgroundColor: "#1B1B1B" }} className={psBorrowerExpand? "" : "d-none"}></hr>
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                    <label> Print at my store</label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-2">
                                    <label>
                                        {typeof printSummaryformObj.printAtMyStore ===
                                        "boolean"
                                            ? printSummaryformObj.printAtMyStore
                                            ? "true"
                                            : "false"
                                            : printSummaryformObj.printAtMyStore}
                                    </label>
                                </div>
                            </div>      
                            <div className="row">     
                                <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                    <label> Send to Magix account’s home store</label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-2">
                                <label>
                                    {typeof printSummaryformObj.sendToMagixAccHomeStore ===
                                    "boolean"
                                        ? printSummaryformObj.sendToMagixAccHomeStore
                                        ? "true"
                                        : "false"
                                        : printSummaryformObj.sendToMagixAccHomeStore}
                                </label>
                                </div>
                            </div>
                            {/* <div className="row">
                            <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                <label>Send to credit</label>
                            </div>
                            <div className="col-6 col-md-4 col-lg-4 pt-2">
                                <label>
                                    {typeof printSummaryformObj.sendToCredit === "boolean"
                                        ? printSummaryformObj.sendToCredit
                                        ? "true"
                                        : "false"
                                        : printSummaryformObj.sendToCredit}
                                </label>
                            </div>
                        </div> */}

                        <p className="h5 font-weight-bold mt-4"> Comments</p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        
                            <div className="row">
                                <div className="col-6 col-md-4 col-lg-4 pt-2 d-flex align-items-center">
                                <div dangerouslySetInnerHTML={{__html : printSummaryformObj.comments }}></div>
                                </div>
                            </div>

                        <p className="h5 font-weight-bold mt-4"> Created Date</p>
                        <hr style={{ backgroundColor: "#1B1B1B" }}></hr>
                        
                            <div className="row">
                            <div className="col-6 col-md-4 col-lg-4 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                                    <label> Created Date </label>
                                </div>
                                <div className="col-6 col-md-4 col-lg-4 pt-2">
                                <p>  {Moment(printSummaryformObj.createdDate).format("MM/DD/YYYY, h:mm:ss a")} </p>
                                </div>
                            </div>
                            
                    <div className="row">
                        <div className="col-12 mt-4 mb-3">
                            <div className="row">
                            <div className="col-12 col-md-6 col-lg-6">
                                <p
                                className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                                onClick={() => expandPrintSummary("cancel") }
                                >
                                Cancel
                                </p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <ReactToPrint
                                trigger={() => (
                                    <p className="btn bg-secondary text-primary w-100">
                                    Print
                                    </p>
                                )}
                                content={() => componentRef.current}
                                pageStyle="@page { size: A4; margin : 0 0 0 0 !important;}"
                                documentTitle={`${printSummaryformObj.firstName}-Information`}
                                />
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="customerInfo" ref={componentRef}>
                        <div className="ml-5 mb-3 mr-5 mt-4">
                            <h2 className="text-secondary">Print Summary</h2>
                            <div className="mt-4">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Customer ID</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.account_Id}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Home Store</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{localStorage.getItem("applicationId") !== "null"?"":printSummaryformObj.magixHomeStore?printSummaryformObj.magixHomeStore:printSummaryformObj.homeStore}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 offset-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Apply Store</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.applyStore}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4 printDatas">
                                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                                    <div className="col-12 mt-3 mb-2">
                                                        <h4>Borrower</h4>
                                                        <hr className="mt-0"></hr>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Title {borrowerInfo.title_code !== null ? (borrowerInfo.title_code?.toLowerCase() !== printSummaryformObj.title?.toLowerCase() ? "*" : "") : (printSummaryformObj.title !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.title}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Secondary Number {borrowerInfo.secondaryPhone !== null ? ((formatPhoneNumber(borrowerInfo.secondaryPhone) !== formatPhoneNumber(printSummaryformObj.secPhone)) ? "*" : "") : (printSummaryformObj.secPhone !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.secPhone}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Borrower Name {borrowerName?.toLowerCase()!== printSummaryformObj.borrowerName?.toLowerCase() ? "*" : ""}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.borrowerName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Birth date {borrowerInfo.birthDate !== null ? (Moment(borrowerInfo.birthDate).format("MM/DD/YYYY") !== printSummaryformObj.dob ? "*" : "") : (printSummaryformObj.dob !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.dob}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Suffix {(borrowerInfo.suffix_Id !== null && borrowerInfo.suffix_Id !== undefined) ? (borrowerInfo.suffix_Id !== printSummaryformObj.borrowerSuffix ? "*" : "") : ((printSummaryformObj.borrowerSuffix !== "" && printSummaryformObj.borrowerSuffix!==null) ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.borrowerSuffix}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Soc Sec No/ITIN {borrowerInfo.ssn !== null ? (borrowerInfo.ssn !== printSummaryformObj.ssn ? "*" : "") : (printSummaryformObj.ssn !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.ssn}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Address {borrowerInfo.address !== null ? ((borrowerInfo.address?.toLowerCase() + ", " + (borrowerInfo.apt !== null ? borrowerInfo.apt?.toLowerCase() : "")) !== printSummaryformObj.address?.toLowerCase() + ", " + printSummaryformObj.apt?.toLowerCase() ? "*" : "") : ((printSummaryformObj.address + ", " + printSummaryformObj.apt) !== ", " ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.address + ", " + printSummaryformObj.apt}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">ID Number {borrowerInfo.idNumber !== null ? (borrowerInfo.idNumber?.toLowerCase() !== printSummaryformObj.idNumber?.toLowerCase() ? "*" : "") : (printSummaryformObj.idNumber !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.idNumber}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">City {borrowerInfo.city !== null ? (borrowerInfo.city?.toLowerCase() !== printSummaryformObj.city?.toLowerCase() ? "*" : "") : (printSummaryformObj.city !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.city}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Name {borrowerInfo.empName !== null ? (borrowerInfo.empName?.toLowerCase() !== printSummaryformObj.empName?.toLowerCase() ? "*" : "") : (printSummaryformObj.empName !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.empName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">State {(borrowerInfo.state_Id !== null && borrowerInfo.state_Id !== undefined) ? (borrowerInfo.state_Id?.toLowerCase() != printSummaryformObj.state?.toLowerCase() ? "*" : "") : (printSummaryformObj.state !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.state}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Phone Number {borrowerInfo.empPhone !== null ? (formatPhoneNumber(borrowerInfo.empPhone) !== formatPhoneNumber(printSummaryformObj.empPhoneNo) ? "*" : "") : (printSummaryformObj.empPhoneNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.empPhoneNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Zip Code {borrowerInfo.zipCode !== null ? (borrowerInfo.zipCode !== printSummaryformObj.zipCode ? "*" : "") : (printSummaryformObj.zipCode !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.zipCode}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Type of Work/Job Duties {borrowerInfo.workJob !== null ? (borrowerInfo.workJob?.toLowerCase() !== printSummaryformObj.duties?.toLowerCase() ? "*" : "") : (printSummaryformObj.duties !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.duties}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Phone Number {borrowerInfo.mobileNo !== null ? (formatPhoneNumber(borrowerInfo.mobileNo) !== formatPhoneNumber(printSummaryformObj.mobileNo) ? "*" : "") : (printSummaryformObj.mobileNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.mobileNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Monthly Net Income (from all sources) {borrowerInfo.monthlyIncome !== null ? (borrowerInfo.monthlyIncome !== printSummaryformObj.monthlyIncome ? "*" : "") : (printSummaryformObj.monthlyIncome !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>&#36; {printSummaryformObj.monthlyIncome}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Email Address {borrowerInfo.emailAddress !== null ? (borrowerInfo.emailAddress?.toLowerCase() !== printSummaryformObj.emailAdr?.toLowerCase() ? "*" : "") : (printSummaryformObj.emailAdr !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.emailAdr}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Start Date {borrowerInfo.howLong !== null ? (Moment(borrowerInfo.howLong).format("MM/YYYY") !== printSummaryformObj.empStartDate ? "*" : "") : (printSummaryformObj.empStartDate !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.empStartDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {sessionStorage.getItem("coBorrower")=="true"?
                                            <div className="mt-4">
                                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                                    <div className="col-12 mt-4 mb-2">
                                                        <h4>Co-Borrower</h4>
                                                        <hr className="mt-0"></hr>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Title {coBorrowerInfo.title_code !== null ? (coBorrowerInfo.title_code !== printSummaryformObj.cborrowerTitle ? "*" : "") : (printSummaryformObj.cborrowerTitle !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.cborrowerTitle}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Secondary Number {coBorrowerInfo.secondaryPhone !== null ? (formatPhoneNumber(coBorrowerInfo.secondaryPhone) !== formatPhoneNumber(printSummaryformObj.coborrowersecondaryPhone) ? "*" : "") : (printSummaryformObj.coborrowersecondaryPhone !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowersecondaryPhone}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Co-Borrower Name{((coBorrowerInfo.firstName == null || coBorrowerInfo.firstName == "" ? "" : coBorrowerInfo.firstName + " ") + (coBorrowerInfo.middleName == null || coBorrowerInfo.middleName == "" ? "" : coBorrowerInfo.middleName + " ") + (coBorrowerInfo.lastName == null || coBorrowerInfo.lastName == "" ? "" : coBorrowerInfo.lastName)) !== printSummaryformObj.coborrowerName ? "*" : ""}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coBorrowerName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Birth date {coBorrowerInfo.birthDate !== null && coBorrowerInfo.birthDate !== "" ? (Moment(coBorrowerInfo.birthDate).format("MM/DD/YYYY") !== printSummaryformObj.coborrowerbirthDate ? "*" : "") : (printSummaryformObj.coborrowerbirthDate !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowerbirthDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Suffix {coBorrowerInfo.suffix_Id !== null && coBorrowerInfo.suffix_Id !== "0" ? (coBorrowerInfo.suffix_Id != printSummaryformObj.coborrowerSuffix ? "*" : "") : (printSummaryformObj.coborrowerSuffix !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{ printSummaryformObj.coborrowerSuffix}</p> 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Soc Sec No/ITIN {coBorrowerInfo.masked_ssn !== null ? (coBorrowerInfo.masked_ssn !== printSummaryformObj.coborrowerssn ? "*" : "") : (printSummaryformObj.coborrowerssn !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowerssn}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Phone Number {coBorrowerInfo.mobileNo !== null ? (formatPhoneNumber(coBorrowerInfo.mobileNo) !== formatPhoneNumber(printSummaryformObj.coborrowermobileNo) ? "*" : "") : (printSummaryformObj.coborrowermobileNo !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowermobileNo}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">ID Number {coBorrowerInfo.idNumber !== null && coBorrowerInfo.idNumber !== undefined ? (coBorrowerInfo.idNumber !== printSummaryformObj.coborroweridNumber ? "*" : "") : (printSummaryformObj.coborroweridNumber !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborroweridNumber}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Email Address {coBorrowerInfo.emailAddress !== null ? (coBorrowerInfo.emailAddress !== printSummaryformObj.coborroweremailAddress ? "*" : "") : (printSummaryformObj.coborroweremailAddress !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborroweremailAddress}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Name {coBorrowerInfo.empName !== null ? (coBorrowerInfo.empName !== printSummaryformObj.coborrowerempName ? "*" : "") : (printSummaryformObj.coborrowerempName !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowerempName}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Employer's Phone Number {coBorrowerInfo.empPhone !== null ? (formatPhoneNumber(coBorrowerInfo.empPhone) !== formatPhoneNumber(printSummaryformObj.coborrowerempPhone) ? "*" : "") : (printSummaryformObj.coborrowerempPhone !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowerempPhone}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Type of Work/Job Duties {coBorrowerInfo.workJob !== null ? (coBorrowerInfo.workJob !== printSummaryformObj.coborrowerworkJob ? "*" : "") : (printSummaryformObj.coborrowerworkJob !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowerworkJob}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Monthly Net Income (from all sources) {coBorrowerInfo.monthlyIncome !== null ? (coBorrowerInfo.monthlyIncome !== printSummaryformObj.coborrowermonthlyIncome ? "*" : "") : (printSummaryformObj.coborrowermonthlyIncome !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>&#36; {printSummaryformObj.coborrowermonthlyIncome}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="row">
                                                            <div className="col-6">
                                                                <p className="text-secondary mb-1">Start Date {coBorrowerInfo.howLong !== null && coBorrowerInfo.howLong !== "" ? (Moment(coBorrowerInfo.howLong).format("MM/YYYY") !== printSummaryformObj.coborrowerhowLong ? "*" : "") : (printSummaryformObj.coborrowerhowLong !== "" ? "*" : "")}</p>
                                                            </div>
                                                            <div className="col-6">
                                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.coborrowerhowLong}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:""}

                            <div className="mt-4">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12 mt-4 mb-2">
                                        <h4>Application Status</h4>
                                        <hr className="mt-0"></hr>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Daniel’s Credit</p>
                                            </div>
                                            <div className="col-7">
                                            <div dangerouslySetInnerHTML={{ __html:DanielsOffersStatus(printSummaryformObj?.isDaniel,Moment(printSummaryformObj?.createdDate).format("MM/DD/YYYY")) }}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6"></div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Fortiva</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadFortivaOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Synchrony Bank Credit</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadSynchronyOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">Progressive Leasing</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadProgressiveOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-5">
                                                <p className="text-secondary mb-1">UOWN Leasing</p>
                                            </div>
                                            <div className="col-7">
                                                <div className="mb-1 d-block" style={{ wordBreak: "break-all" }}>
                                                    <div dangerouslySetInnerHTML={{ __html: loadUOwnOffers() }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-4">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12 mt-4 mb-2">
                                        <h4>Mobile Verification</h4>
                                        <hr className="mt-0"></hr>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Mobile Number</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>{printSummaryformObj.mobileNo}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Is Mobile Verified</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    {typeof printSummaryformObj.mobileNoVerified ===
                                                    "boolean"
                                                        ? printSummaryformObj.mobileNoVerified
                                                        ? "true"
                                                        : "false"
                                                        : printSummaryformObj.mobileNoVerified}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Is SMS Code Sent</p>
                                            </div>
                                            <div className="col-6">
                                            <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    {typeof printSummaryformObj.smsCodeSent ===
                                                    "boolean"
                                                        ? printSummaryformObj.smsCodeSent
                                                        ? "true"
                                                        : "false"
                                                        : printSummaryformObj.smsCodeSent}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Is SMS Override</p>
                                            </div>
                                            <div className="col-6">
                                            <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    {typeof printSummaryformObj.smsOverride ===
                                                    "boolean"
                                                        ? printSummaryformObj.smsOverride
                                                        ? "true"
                                                        : "false"
                                                        : printSummaryformObj.smsOverride}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <div className="mt-4">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12 mt-4 mb-2">
                                        {/* <h4>Mobile Verification</h4> */}
                                        <hr className="mt-0"></hr>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Print at my store</p>
                                            </div>
                                            <div className="col-6">
                                            <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    {typeof printSummaryformObj.printAtMyStore ===
                                                    "boolean"
                                                        ? printSummaryformObj.printAtMyStore
                                                        ? "true"
                                                        : "false"
                                                        : printSummaryformObj.printAtMyStore}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Send to Magix account’s home store</p>
                                            </div>
                                            <div className="col-6">
                                                <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    {typeof printSummaryformObj.sendToMagixAccHomeStore ===
                                                    "boolean"
                                                        ? printSummaryformObj.sendToMagixAccHomeStore
                                                        ? "true"
                                                        : "false"
                                                        : printSummaryformObj.sendToMagixAccHomeStore}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6">
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-secondary mb-1">Send to credit</p>
                                            </div>
                                            <div className="col-6">
                                            <p className="mb-1" style={{ wordBreak: "break-all" }}>
                                                    {typeof printSummaryformObj.sendToCredit ===
                                                    "boolean"
                                                        ? printSummaryformObj.sendToCredit
                                                        ? "true"
                                                        : "false"
                                                        : printSummaryformObj.sendToCredit}
                                                </p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="mt-2" style={{ borderRadius: "10px" }}>
                                <div className="row bg-white">
                                    <div className="col-12 mt-4">
                                        <h4>Comments</h4>
                                        <hr className="mt-0"></hr>
                                    </div>
                                    {printSummaryformObj.comments!==""?<div className="col-12 mb-2">
                                    <div  dangerouslySetInnerHTML={{__html : printSummaryformObj.comments }}></div>
                                    </div>:""}
                                </div>
                            </div>

                            <div className="mt-1">
                                <div className="row bg-white" style={{ borderRadius: "10px" }}>
                                    <div className="col-12">
                                    <span className="mb-1 float-right" >{Moment(printSummaryformObj.createdDate).format("MM/DD/YYYY, h:mm:ss a")}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
  )
}
export default CreditAppView;