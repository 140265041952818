import { Component } from "react";
import { bindActionCreators } from "redux";
import * as customerLookupActions from "../../redux/actions/customerLookup.actions";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import { connect } from "react-redux";
import Sidebar from "../../components/component.customSideBar";
import CustomNavbar from "../../components/component.customNavbar";
import { trackPromise } from "react-promise-tracker";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { Card, Modal } from "react-bootstrap";
import formatPhoneNumber from "../../components/component.formatPhoneNumber";
import "./component.masterCustomerUpdate.css";
import * as customerDetailsActions from "../../redux/actions/customerDetails.actions";
import Autocomplete from "react-autocomplete";
import * as applyNowActions from "../../redux/actions/applyNow.actions";
//{} destructring
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import * as customerUpdationActions from "../../redux/actions/customerUpdation.actions";
import { toast } from "react-toastify";
import RemotePagination from "./component.TableView";
import Moment from "moment";

class MasterInfo extends Component {
  constructor() {
    super();
    this.state = {
      //state for toogle sidebar in mobile view
      toggled: false,
      //state for validate user permissions
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: {
        canAdd: false,
        canEdit: false,
      },
      //state for passing admin menu to sidebar
      sitePageDetails: [],
      //state for search obj
      searchObj: {
        phoneNo: "",
        ssn: "",
        dob: "",
        customerID: "",
        lastName: "",
      },
      //state for list of master customers
      masterCustomersTableInfo: [],
      //state for selected customer
      customerInfo: [],
      activeId: "",
      //state for post api call parameters
      updatedCustomerInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNo: "",
        emailAddress: "",
        address: "",
        apt: "",
        city: "",
        state_Id: "0",
        zipCode: "",
      },
      noDataFound: false,
      firstTime: true,
      editModal: false,
      addressAutoFillEnable: true,
      allAddress: [],
      allAptUnit: [],
      lstState: [],
      formErrors: [],
      pageNo: 1,
      from: 1,
      to: 6,
      totalCustomers: undefined,
      // removeMiddleName: false,
      // removeAptName: false,
    };
  }

  //Hide/Show sidebar in mobile/table views.
  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }

  toogleEditModal() {
    this.setState({
      updatedCustomerInfo: {
        phoneNo: "",
        firstName: "",
        middleName: "",
        lastName: "",
        mobileNo: "",
        emailAddress: "",
        address: "",
        apt: "",
        city: "",
        state_Id: "0",
        zipCode: "",
      },
      customerInfo: [],
      allAddress: [],
      allAptUnit: [],
      editModal: false,
      activeId: "",
      formErrors: [],
    });
  }

  componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      let adminMenus = [];
      adminMenus = localStorage.getItem("sitePageDetails");
      adminMenus = JSON.parse(adminMenus);
      if (adminMenus) {
        this.setState({
          sitePageDetails: adminMenus,
        });
      } else {
        trackPromise(
          this.props.userRightAction
            .getUserRights()
            .then((response) => {
              if (response) {
                let adminMenus = response.sitePageDetails.filter(
                  (item) => item.sitePage_id === 2
                );
                this.setState({
                  sitePageDetails: adminMenus,
                });
                localStorage.setItem(
                  "sitePageDetails",
                  JSON.stringify(adminMenus)
                );
              }
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
      trackPromise(
        this.props.userRightAction
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
      let obj = {
        type: "State",
      };
      trackPromise(
        this.props.applyNowAction.getCodeMaster(obj).then((res) => {
          if (res) {
            this.setState({ lstState: res.data });
          }
        })
      );
      // this.onSubmit();
    }
  }

  getPermissions() {
    let role_name = localStorage.getItem("role_name"),
      page_name = "customer details update";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return (
          site_page_details.sitePage_id === getsitepages[0].id &&
          site_page_details.name?.toLowerCase() === page_name
        );
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({
      permission_status: {
        ...this.state.permission_status,
        canAdd: role_permission_check[0]?.canAdd,
        canEdit: role_permission_check[0]?.canEdit,
      },
    });
  }

  onChange(event) {
    event.preventDefault();
    let controlValue = "";
    if (event.target.name === "phoneNo") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, phoneNo: event.target.value },
        });
      }
    } else if (event.target.name === "ssn") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, ssn: event.target.value },
        });
      }
    } else if (event.target.name === "customerID") {
      let reg = /^[^a-zA-Z]+$/;
      if (event.target.value === "" || reg.test(event.target.value)) {
        this.setState({
          searchObj: { ...this.state.searchObj, customerID: event.target.value },
        });
      }
    } else if (event.target.name === "SearchlastName") {
      this.setState({
        searchObj: { ...this.state.searchObj, lastName: event.target.value },
      });
    } else if (event.target.name === "firstName") {
      const re = /^(?!\s)[a-zA-Z]*$/;
      if(event.nativeEvent.inputType === "deleteContentBackward" || re.test(event.nativeEvent.data) || event.nativeEvent.data === ' ') {
      controlValue = event.target.value;
      this.setState({
        updatedCustomerInfo: {
          ...this.state.updatedCustomerInfo,
          firstName: controlValue,
        },
      });
      }
    } else if (event.target.name === "middleName") {
      const re = /^(?!\s)[a-zA-Z]*$/;
      if(event.nativeEvent.inputType === "deleteContentBackward" || re.test(event.nativeEvent.data) || event.nativeEvent.data === ' ') {
      controlValue = event.target.value;
        this.setState({
          updatedCustomerInfo: {
            ...this.state.updatedCustomerInfo,
            middleName: controlValue,
          },
        });
      }
    } else if (event.target.name === "lastName") {
      const re = /^(?!\s)[a-zA-Z]*$/;
      if(event.nativeEvent.inputType === "deleteContentBackward" || re.test(event.nativeEvent.data) || event.nativeEvent.data === ' ') {
      controlValue = event.target.value;
        this.setState({
          updatedCustomerInfo: {
            ...this.state.updatedCustomerInfo,
            lastName: controlValue,
          },
        });
      }
    } else if (event.target.name === "mobileNo") {
      this.setState({
        updatedCustomerInfo: {
          ...this.state.updatedCustomerInfo,
          mobileNo: formatPhoneNumber(event.target.value),
        },
      });
    } else if (event.target.name === "emailAddress") {
      this.setState({
        updatedCustomerInfo: {
          ...this.state.updatedCustomerInfo,
          emailAddress: event.target.value?.trimStart(),
        },
      });
    } else if (event.target.name === "apt") {
      this.setState({
        updatedCustomerInfo: {
          ...this.state.updatedCustomerInfo,
          apt: event.target.value,
        },
      });
    } else if (event.target.name === "city") {
      controlValue = event.target.value.replace(",", "");
      const re = /^(?!\s)[-a-zA-Z ]*$/;
      if (controlValue === "" || re.test(controlValue)) {
        this.setState({
          updatedCustomerInfo: {
            ...this.state.updatedCustomerInfo,
            city: controlValue,
          },
        });
      }
    } else if (event.target.name === "state_Id") {
      this.setState({
        updatedCustomerInfo: {
          ...this.state.updatedCustomerInfo,
          state_Id: event.target.value,
        },
      });
    } else if (event.target.name === "zipCode") {
      const re = /^[0-9\b]+$/;
      if (event.target.value === "" || re.test(event.target.value)) {
        this.setState({
          updatedCustomerInfo: {
            ...this.state.updatedCustomerInfo,
            zipCode: event.target.value,
          },
        });
      }
    }
  }

  onDOBChange(event) {
    if (event === null) {
      this.setState({
        searchObj: { ...this.state.searchObj, dob: "" },
      });
    } else {
      this.setState({
        searchObj: {
          ...this.state.searchObj,
          dob: Moment(event).format("MM/DD/YYYY"),
        },
      });
    }
  }

  loadAddress(obj) {
    this.setState({ allAddress: [] });
    if (obj === "") {
      this.setState({
        formObj: {
          ...this.state.formObj,
          mailingAdr: "",
          aptUnit: "",
          city: "",
          zipCode: "",
          state: "0",
        },
        allAptUnit: [],
      });
    }
    if (obj?.length > 2) {
      obj = {
        line1: obj.split(",")[0]?.replace(",", ""),
        city: obj.split(",")[1] === undefined ? "" : obj.split(",")[1]?.trim(),
        state: obj.split(",")[2] === undefined ? "" : obj.split(",")[2]?.trim(),
      };
      this.props.applyNowAction
        .getAddress(obj)
        .then((response) => {
          if (response) {
            let array = response.Results?.map((val) => {
              return val.Address;
            });
            array?.map((item) => {
              item.fullAddress =
                item?.AddressLine1 +
                ", " +
                item?.City +
                ", " +
                item?.State +
                ", " +
                item?.PostalCode?.split("-")[0] +
                (item?.SuiteName == "" ? "" : ", " + item?.SuiteName);
            });
            this.setState({ allAddress: array });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        });
    }
  }

  autoFill(val) {
    let addressKey = val;
    let data = this.state.allAddress?.find(
      (val) => val.AddressKey == addressKey
    );
    this.setState({
      allAptUnit: data?.SuiteName !== "" ? data?.SuiteList : [],
    });
    let state = this.state.lstState?.find((val) => val.code == data?.State);
    this.setState({
      updatedCustomerInfo: {
        ...this.state.updatedCustomerInfo,
        address: data?.AddressLine1,
        city: data?.City,
        zipCode: data?.PostalCode.split("-")[0],
        state_Id: state?.id,
        apt: "",
      },
    });
  }

  onSubmit(type) {
    if (this.state.permission_status.canAdd || this.state.permission_status.canEdit) {
      this.setState({ masterCustomersTableInfo: [], totalCustomers: undefined });
      let pageNo = this.state.pageNo;
      if (type !== "Pagination") {
        pageNo = 1;
        this.setState({ pageNo: 1, from: 1, firstTime: true });
      }
      let obj = {
        phone: this.state.searchObj.phoneNo?.trim(),
        ssn: "",
        name: this.state.searchObj.lastName?.trim(),
        birthdate: "",
        id: "",
        accountId: this.state.searchObj.customerID?.trim(),
        zipCode: "",
        pageIndex: pageNo,
        pageSize: 6,
        sortBy: "modifiedDate",
        sortOrder: "desc",
      };
      trackPromise(
        this.props.lookUpAction
          .getCustomers_new(obj)
          .then((response) => {
            if (response) {
              this.setState({ firstTime: false });
              if (response.data.length === 0) {
                this.setState({
                  noDataFound: true,
                  masterCustomersTableInfo: [],
                  totalCustomers: response.totalCount
                });
              } else {
                response.data.map((item) => {
                  if (item.mobileNo != null) {
                    if (!item.mobileNo.includes("-")) {
                      item.mobileNo = formatPhoneNumber(item.mobileNo);
                    }
                  }
                  if (item.state_Id != "null" && item.state_Id != "") {
                    if (this.state.lstState?.length > 0) {
                      let res = this.state.lstState?.find(
                        (val) => val.id == item.state_Id
                      );
                      item.state_Code = res?.code;
                      item.state_Name = res?.description;
                    }
                  }
                });
                this.setState({
                  masterCustomersTableInfo: response.data,
                  totalCustomers: response.totalCount,
                });
              }
            } else {
              this.setState({ noDataFound: true, masterCustomersTableInfo: [] });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  // onRemoveMasterInfo(field) {
  //   if (field === "middleName") {
  //     this.setState({
  //       removeMiddleName: !this.state.removeMiddleName,
  //     });
  //   } else if (field === "apt") {
  //     this.setState({
  //       removeAptName: !this.state.removeAptName,
  //     });
  //   }
  // }

  onEdit(row) {
    // trackPromise(
    //   this.props.detailsAction
    //     .getCustomerDetails_new(row.customer_Id)
    //     .then((res) => {
    //       if (res) {
    //         let stateName = this.state.lstState?.find(
    //           (val) => val.id == res[0].state_Id
    //         );
    //         res?.map((item) => {
    //           item.stateName = stateName?.description;
    //         });
    //         this.setState({
    //           customerInfo: res[0],
    //           editModal: true,
    //           activeId: row.customer_Id,
    //           updatedCustomerInfo: {
    //             ...this.state.updatedCustomerInfo,
    //             firstName: res[0].firstName,
    //             middleName: res[0].middleName,
    //             lastName: res[0].lastName,
    //             mobileNo: res[0].mobileNo,
    //             emailAddress: res[0].emailAddress,
    //             address: res[0].address,
    //             apt: res[0].apt,
    //             city: res[0].city,
    //             state_Id: res[0].state_Id,
    //             zipCode: res[0].zipCode,
    //           },
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       APIFailedMessage.displayError(err);
    //     })
    // );
    this.setState({
      customerInfo: row,
      editModal: true,
      activeId: row.customer_Id,
      updatedCustomerInfo: {
        ...this.state.updatedCustomerInfo,
        firstName: row.firstName,
        middleName: row.middleName,
        lastName: row.lastName,
        mobileNo: row.mobileNo,
        emailAddress: row.emailAddress,
        address: row.address,
        apt: row.apt,
        city: row.city,
        state_Id: row.state_Id,
        zipCode: row.zipCode,
      },
    });
  }

  onUpdate() {
    if (this.formvalidation()) {
      let updatedCustomerInfo = this.state.updatedCustomerInfo;
      let modFields = [];
      if (updatedCustomerInfo.firstName !== this.state.customerInfo.firstName)
        modFields.push("firstName");
      if (updatedCustomerInfo.middleName !== this.state.customerInfo.middleName)
        modFields.push("middleName");
      if (updatedCustomerInfo.lastName !== this.state.customerInfo.lastName)
        modFields.push("lastName");
      if (updatedCustomerInfo.mobileNo !== this.state.customerInfo.mobileNo)
        modFields.push("mobileNo");
      if (updatedCustomerInfo.emailAddress !== this.state.customerInfo.emailAddress)
        modFields.push("emailAddress");
      if (updatedCustomerInfo.address !== this.state.customerInfo.address)
        modFields.push("address");
      if (updatedCustomerInfo.apt !== this.state.customerInfo.apt)
        modFields.push("apt");
      if (updatedCustomerInfo.city !== this.state.customerInfo.city)
        modFields.push("city");
      if (updatedCustomerInfo.state_Id != this.state.customerInfo.state_Id)
        modFields.push("state");
      if (updatedCustomerInfo.zipCode !== this.state.customerInfo.zipCode)
        modFields.push("zipCode");
      let obj = {
        customer_id: this.state.activeId,
        firstName: updatedCustomerInfo.firstName?.trim(),
        middleName: updatedCustomerInfo.middleName?.trim(),
        lastName: updatedCustomerInfo.lastName?.trim(),
        mobileNo: updatedCustomerInfo.mobileNo,
        emailAddress: updatedCustomerInfo.emailAddress?.trim(),
        address: updatedCustomerInfo.address?.trim(),
        apt: updatedCustomerInfo.apt,
        city: updatedCustomerInfo.city?.trim(),
        state_id: updatedCustomerInfo.state_Id,
        zipcode: updatedCustomerInfo.zipCode,
        modifiedAssociate: localStorage.getItem("loggedUserEmail"),
        modifiedValues: modFields.toString(),
        ismagixMapping: false,
      };
      this.toogleEditModal();
      trackPromise(
        this.props.customerUpdation
          .updateMasterCustomer_Record(obj)
          .then((res) => {
            if (res) {
              if (res.isSuccess === true) {
                toast.success("Customer data updated successfully!");
                this.onSubmit();
              } else {
                toast.error("Customer data update failed!");
              }
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  formvalidation() {
    let formError = [];
    let result = true;
    if (this.state.updatedCustomerInfo.firstName == "") {
      formError.firstName = "First name is required.";
      result = false;
    }
    if (this.state.updatedCustomerInfo.lastName == "") {
      formError.lastName = "Last name is required.";
      result = false;
    }
    if (this.state.updatedCustomerInfo.mobileNo == "") {
      formError.mobileNo = "Phone name is required.";
      result = false;
    }
    else if (this.state.updatedCustomerInfo.mobileNo?.length < 12 || this.state.updatedCustomerInfo.mobileNo?.length > 12) {
      formError.mobileNo = "Phone number must be 10 digits.";
      result = false;
    }
    if (this.state.updatedCustomerInfo.emailAddress == "") {
      formError.emailAddress = "Email is required.";
      result = false;
    }
    else if (this.state.updatedCustomerInfo.emailAddress != "") {
      let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!res.test(this.state.updatedCustomerInfo.emailAddress)) {
        formError.emailAddress = "Enter a valid email address.";
        result = false;
      }
    }
    if (this.state.updatedCustomerInfo.address == "") {
      formError.address = "Address is required.";
      result = false;
    }
    if (this.state.updatedCustomerInfo.city == "") {
      formError.city = "City is required.";
      result = false;
    }
    if (this.state.updatedCustomerInfo.state_Id == "" || this.state.updatedCustomerInfo.state_Id == "0") {
      formError.state = "State is required.";
      result = false;
    }
    if (this.state.updatedCustomerInfo.zipCode == "") {
      formError.zipCode = "Zip code is required.";
      result = false;
    }
    this.setState({ formErrors: formError });
    return result;
  }

  validate() {
    if (
      this.state.updatedCustomerInfo.firstName !==
      this.state.customerInfo.firstName ||
      this.state.updatedCustomerInfo.middleName !==
      this.state.customerInfo.middleName ||
      this.state.updatedCustomerInfo.lastName !==
      this.state.customerInfo.lastName ||
      this.state.updatedCustomerInfo.mobileNo !==
      this.state.customerInfo.mobileNo ||
      this.state.updatedCustomerInfo.emailAddress !==
      this.state.customerInfo.emailAddress ||
      this.state.updatedCustomerInfo.apt !== this.state.customerInfo.apt ||
      this.state.updatedCustomerInfo.city !== this.state.customerInfo.city ||
      this.state.updatedCustomerInfo.state_Id !=
      this.state.customerInfo.state_Id ||
      this.state.updatedCustomerInfo.zipCode !== this.state.customerInfo.zipCode
    ) {
      return false;
    }
    return true;
  }

  render() {
    let componentStyle = { wordWrap: "break-word" };

    const columns = [
      {
        dataField: "customer_Id",
        text: "Customer Id",
      },
      {
        dataField: "firstName",
        text: "Name",
        formatter: (cell, row) => {
          return (
            <div>{`${row.firstName === null ? "" : row.firstName} ${row.middleName === null ? "" : row.middleName
              } ${row.lastName === null ? "" : row.lastName}`}</div>
          );
        },
      },
      {
        dataField: "address",
        text: "Address",
        formatter: (cell, row) => {
          return (
            <div>{`${row.address === null ? "" : row.address}
            ${row.city === null ? "" : ", " + row.city}
            ${row.state_Name === null ? "" : ", " + row.state_Name}
            ${row.zipCode === null ? "" : ", " + row.zipCode}`}</div>
          );
        },
      },
      {
        dataField: "mobileNo",
        text: "Phone",
        type: Number,
      },
      {
        dataField: "ssn",
        text: "SSN/ ITIN",
      },
      {
        dataField: "",
        text: "Edit",
        formatter: (cell, row) => {
          return (
            <buttton
              className={"btn border-dark"}
              onClick={() => this.onEdit(row)}
              disabled={
                !(
                  this.state.permission_status.canAdd ||
                  this.state.permission_status.canEdit
                )
              }
            >
              Edit
            </buttton>
          );
        },
      },
    ];

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
      this.setState({ masterCustomersTableInfo: [], isPaginate: true, pageNo: page }, () => {
        this.onSubmit("Pagination");
      });

    };

    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {from}-{to} of {size}
          </div>
        </span>
      );
    };

    const rowStyle = (row, rowIndex) => {
      const style = {};
      if (row?.customer_Id == this.state.activeId) {
        style.backgroundColor = "#5C068C";
        style.color = "white";
      }
      return style;
    };

    return (
      <>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          {/* Side Panel/Menu Bar */}
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={this.state.sitePageDetails}
          />
          <main>
            {/* Navigation Bar */}
            <CustomNavbar
              handleToggleSidebar={() => {
                this.handleToggleSidebar();
              }}
              isAdmin={true}
            />
            <div id="main" className="container-fluid">
              <div className="row pl-1 pr-1 mt-4 mb-4">
                <div className="col-12 mb-2">
                  <h4 className="ml-1"><b>Customer Profile Update</b></h4>
                </div>
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="row">
                        <div className="col-12 col-md-3 searchDiv">
                          <div className="form-group ml-2 mr-2">
                            <label htmlFor="phoneNumber" className="text-secondary font-weight-bold">Phone Number</label>
                            <input
                              type="text"
                              className="form-control border-dark"
                              placeholder="Phone Number"
                              name="phoneNo"
                              value={this.state.searchObj.phoneNo}
                              onChange={(event) => this.onChange(event)}
                              disabled={
                                !(
                                  this.state.permission_status.canAdd ||
                                  this.state.permission_status.canEdit
                                )
                              }
                            />
                          </div>
                          <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                        </div>
                        {/* <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">

                          <div className="form-group w-100 ml-2 mr-2">
                            <label htmlFor="ssn" className="text-secondary font-weight-bold">SSN/ITIN</label>
                            <input
                              type="text"
                              className="form-control border-dark"
                              placeholder="SSN/ITIN"
                              name="ssn"
                              value={this.state.searchObj.ssn}
                              onChange={(event) => this.onChange(event)}
                              disabled={
                                !(
                                  this.state.permission_status.canAdd ||
                                  this.state.permission_status.canEdit
                                )
                              }
                            />
                          </div>
                          <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                        </div> */}
                        <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">

                          <div className="form-group w-100 ml-2 mr-2">
                            <label htmlFor="ssn" className="text-secondary font-weight-bold">Last Name</label>
                            <input
                              type="text"
                              className="form-control border-dark"
                              placeholder="Last Name"
                              name="SearchlastName"
                              value={this.state.searchObj.lastName}
                              onChange={(event) => this.onChange(event)}
                              disabled={
                                !(
                                  this.state.permission_status.canAdd ||
                                  this.state.permission_status.canEdit
                                )
                              }
                            />
                          </div>
                          <p className="m-0 pl-2 pr-2" id="spliter">OR</p>
                        </div>
                        <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">

                          <div className="form-group w-100 ml-2 mr-2">
                            <label htmlFor="ssn" className="text-secondary font-weight-bold">Customer ID</label>
                            <input
                              type="text"
                              className="form-control border-dark"
                              placeholder="Customer ID"
                              name="customerID"
                              value={this.state.searchObj.customerID}
                              onChange={(event) => this.onChange(event)}
                              disabled={
                                !(
                                  this.state.permission_status.canAdd ||
                                  this.state.permission_status.canEdit
                                )
                              }
                            />
                          </div>
                          {/* <p className="m-0 pl-2 pr-2" id="spliter">OR</p> */}
                        </div>
                        {/* <div className="col-12 col-md-3 searchDiv d-flex justify-content-center align-items-center">
                                                <div className="form-group w-100 ml-2 mr-2">
                            <label htmlFor="ssn" className="text-secondary font-weight-bold">Date of Birth</label>
                            <DatePicker
                            className="form-control border-dark"
                            name="dob"
                            id="dob"
                            placeholderText="MM/DD/YYYY"
                            // selected={
                            //   this.state.searchObj.birthdate === ""
                            //     ? null
                            //     : new Date(this.state.searchObj.birthdate)
                            // }
                            selected={
                              this.state.searchObj.dob === ""
                                ? null
                                : new Date(this.state.searchObj.dob)
                            }
                            onChange={(event) => this.onDOBChange(event)}
                            dateFormat="MM/dd/yyyy"
                            peekNextMonth
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={<IMaskInput mask="00/00/0000" />}
                            disabled={
                              !(
                                this.state.permission_status.canAdd ||
                                this.state.permission_status.canEdit
                              )
                            }
                          ></DatePicker>
                          </div>
                                                </div> */}
                        <div className="col-12 col-md-3 d-flex justify-content-center align-items-center flex-column">

                          <button
                            className="btn bg-secondary text-primary w-75"
                            disabled={
                              this.state.searchObj.phoneNo?.length > 9 ||
                                this.state.searchObj.lastName?.length > 0 ||
                                this.state.searchObj.customerID?.length > 0
                                // this.state.searchObj.ssn?.length > 3 ||
                                // this.state.searchObj.dob !== ""
                                ? false
                                : true
                            }
                            onClick={() => this.onSubmit()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>

                <div className="col-12 mt-4">
                  {!this.state.firstTime ? (
                    <>
                      <div id="cardrow" className="row">
                        {this.state.masterCustomersTableInfo &&
                          this.state.masterCustomersTableInfo?.map((val) => (
                            <div
                              className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                              key={val.customer_Id}
                            >
                              <Card className="text-left shadow">
                                <Card.Body
                                  className={`m-3 pt-0 ${val.customer_Id == this.state.activeId
                                    ? "bg-secondary text-primary"
                                    : ""
                                    }`}
                                >
                                  <p className="h3 ml-0 mt-2 mb-0">
                                    {val.customer_Id}
                                  </p>
                                  <hr
                                    style={
                                      val.customer_Id == this.state.activeId
                                        ? { backgroundColor: "#f8f6fa" }
                                        : { backgroundColor: "#1B1B1B" }
                                    }
                                  ></hr>
                                  <div className="row">
                                    <div className="col-6 mb-1">Name :</div>
                                    <div className="col-6">
                                      {(val.firstName === null
                                        ? ""
                                        : val.firstName + " ") +
                                        (val.middleName === null
                                          ? ""
                                          : val.middleName + " ") +
                                        (val.lastName === null
                                          ? ""
                                          : val.lastName)}
                                    </div>
                                    <div className="col-6 mb-1">Address :</div>
                                    <div className="col-6">
                                      {val.address === null ? "" : val.address}
                                      {val.city === null ? "" : ", " + val.city}
                                      {val.state_Name === null
                                        ? ""
                                        : ", " + val.state_Name}
                                      {val.zipCode === null
                                        ? ""
                                        : ", " + val.zipCode}
                                    </div>
                                    <div className="col-6 mb-1">Phone :</div>
                                    <div className="col-6">{val.mobileNo}</div>
                                    <div className="col-6 mb-1">SSN/ITIN :</div>
                                    <div className="col-6">{val.ssn}</div>
                                    <div className="col-12 mt-3">
                                      <button
                                        className="btn border w-100"
                                        onClick={() => this.onEdit(val)}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                </Card.Body>
                              </Card>
                            </div>
                          ))}
                      </div>
                      {/* <div className="masterCustomerTable">
                        <BootstrapTable
                          bootstrap4
                          hover
                          bordered={false}
                          keyField="customer_Id"
                          data={this.state.masterCustomersTableInfo}
                          columns={columns}
                          noDataIndication="No record found"
                          rowStyle={rowStyle}
                        /> */}
                      <RemotePagination
                        data={this.state.masterCustomersTableInfo}
                        page={this.state.pageNo}
                        from={this.state.from}
                        to={this.state.to}
                        sizePerPage={6}
                        totalSize={this.state.totalCustomers}
                        onTableChange={handleTableChange}
                        columns={columns}
                        rowStyle={rowStyle}
                        customTotal={customTotal}
                      />
                      {/* </div> */}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </main>
        </div>

        <Modal show={this.state.editModal} size="lg">
          <Modal.Header>
            <h4 className="mb-0">
              <b>Update Info</b>
            </h4>
            <FontAwesomeIcon
              icon={faTimesCircle}
              size="2x"
              style={{ cursor: "pointer" }}
              onClick={() => this.toogleEditModal()}
            />
          </Modal.Header>
          <Modal.Body>
            <div>
              <h4 className="mb-3">
                <b>Personal Information</b>
              </h4>
              <div className="row mb-3">
                <div className="col-6">
                  <p>First Name</p>
                </div>
                {/* <div className="col-4">
                  <p
                    style={componentStyle}
                    className="border text-left p-2 w-100"
                  >
                    {this.state.customerInfo?.firstName}
                  </p>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="First Name"
                    name="firstName"
                    value={this.state.updatedCustomerInfo?.firstName}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.firstName !== undefined
                      ? this.state.formErrors?.firstName
                      : null}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Middle Name</p>
                </div>
                {/* <div className="col-4 d-flex">
                  <p
                    className={
                      "border text-left w-100 " +
                      (this.state.customerInfo?.middleName === ""
                        ? "p-3 mb-4"
                        : "p-2")
                    }
                  >
                    {this.state.removeMiddleName ? <del>{this.state.customerInfo?.middleName}</del> : this.state.customerInfo?.middleName }
                  </p>
                  <button
                    onClick={() => this.onRemoveMasterInfo("middleName")}
                    className={
                      "border w-25 bg-white " +
                      (this.state.customerInfo?.middleName === ""
                        ? "mb-4"
                        : "mb-3")
                    }
                    disabled={this.state.customerInfo?.middleName === "" ? true : false}
                  >
                    {" "}
                    <FontAwesomeIcon
                      icon={this.state.removeMiddleName ? faRefresh : faTimes}
                    />
                  </button>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="Middle Name"
                    name="middleName"
                    value={this.state.updatedCustomerInfo.middleName}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.middleName !== undefined
                      ? this.state.formErrors?.middleName
                      : null}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Last Name</p>
                </div>
                {/* <div className="col-4">
                  <p className="border text-left p-2 w-100">
                    {this.state.customerInfo?.lastName}
                  </p>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="Last Name"
                    name="lastName"
                    value={this.state.updatedCustomerInfo.lastName}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.lastName !== undefined
                      ? this.state.formErrors?.lastName
                      : null}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Mobile Number</p>
                </div>
                {/* <div className="col-4">
                  <p className="border text-left p-2 w-100">
                    {this.state.customerInfo?.mobileNo}
                  </p>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="Mobile Number"
                    name="mobileNo"
                    maxLength="12"
                    value={this.state.updatedCustomerInfo.mobileNo}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.mobileNo !== undefined
                      ? this.state.formErrors?.mobileNo
                      : null}
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Email Address</p>
                </div>
                {/* <div className="col-4">
                  <p
                    style={componentStyle}
                    className="border text-left p-2 w-100"
                  >
                    {this.state.customerInfo?.emailAddress}
                  </p>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="Email"
                    name="emailAddress"
                    value={this.state.updatedCustomerInfo.emailAddress}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.emailAddress !== undefined
                      ? this.state.formErrors?.emailAddress
                      : null}
                  </div>
                </div>
              </div>
              <hr></hr>
              <h4 className="mb-3">
                <b>Residence Information</b>
              </h4>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Mailing Address</p>
                </div>
                {/* <div className="col-4">
                  <p className="border text-left p-2 w-100">
                    {this.state.customerInfo?.address}
                  </p>
                </div> */}
                <div className="col-6">
                  {!this.state.addressAutoFillEnable === true ? (
                    <>
                      <input
                        type="text"
                        className="form-control border-dark"
                        name="txtMailingAdr"
                        id="txtMailingAdr"
                        placeholder="Mailing Address"
                        onChange={(event) => this.onChange(event)}
                        value={this.state.updatedCustomerInfo?.address}
                        maxLength="500"
                        disabled={
                          !(
                            this.state.permission_status.canAdd ||
                            this.state.permission_status.canEdit
                          )
                        }
                      ></input>
                      <div className="text-danger">
                        {this.state.formErrors?.address !== undefined
                          ? this.state.formErrors?.address
                          : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        items={this.state.allAddress}
                        shouldItemRender={(item, value) =>
                          item?.fullAddress
                            ?.toLowerCase()
                            ?.indexOf(value?.toLowerCase()) > -1
                        }
                        getItemValue={(item) => item?.AddressKey}
                        renderItem={(item, isHighlighted) => (
                          <div key={item?.AddressKey}>
                            <p
                              style={{
                                background: isHighlighted ? "#007bff" : "white",
                                color: isHighlighted ? "white" : "#1b1b1b",
                                padding: "1px 5px",
                              }}
                              className="mb-0"
                            >
                              {item.fullAddress}
                            </p>
                          </div>
                        )}
                        wrapperProps={{
                          style: { width: "100%", display: "inline-block" },
                        }}
                        menuStyle={{
                          borderRadius: "5px",
                          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                          background: "white",
                          padding: "3px 3px",
                          position: "absolute",
                          maxHeight: "200%",
                          overflow: "auto",
                          zIndex: "100",
                          top: "85%",
                          left: "4%",
                        }}
                        value={this.state.updatedCustomerInfo?.address}
                        onChange={(e) => {
                          this.setState({
                            updatedCustomerInfo: {
                              ...this.state.updatedCustomerInfo,
                              address: e.target.value,
                            },
                          });
                          this.loadAddress(e.target.value);
                        }}
                        onSelect={(val) => {
                          this.autoFill(val);
                        }}
                        inputProps={{
                          placeholder: "Mailing Address",
                          className: "form-control border-dark autoAddressField",
                        }}
                        disabled={
                          !(
                            this.state.permission_status.canAdd ||
                            this.state.permission_status.canEdit
                          )
                        }
                      />
                      <div className="text-danger">
                        {this.state.formErrors?.address !== undefined
                          ? this.state.formErrors?.address
                          : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Apt/Unit (As Needed)</p>
                </div>
                {/* <div className="col-4 d-flex">
                  <p
                    className={
                      "border text-left w-100 " +
                      (this.state.customerInfo?.apt === "" ? "p-3 mb-4" : "p-2")
                    }
                  >
                    {this.state.removeAptName ? <del>{this.state.customerInfo?.apt}</del> : this.state.customerInfo?.apt }
                  </p>
                  <button
                    onClick={() => this.onRemoveMasterInfo("apt")}
                    className={
                      "border w-25 bg-white " +
                      (this.state.customerInfo?.apt === "" ? "mb-4" : "mb-3")
                    }
                    disabled={this.state.customerInfo?.apt === "" ? true : false}
                  >
                    <FontAwesomeIcon
                      icon={this.state.removeAptName ? faRefresh : faTimes}
                    />
                  </button>
                </div> */}
                <div className="col-6">
                  {!this.state.addressAutoFillEnable === true ? (
                    <>
                      <input
                        type="text"
                        className="form-control border-dark"
                        name="txtAptUnit"
                        id="txtAptUnit"
                        placeholder="Apt/Unit (As Needed)"
                        onChange={(event) => this.onChange(event)}
                        value={this.state.updatedCustomerInfo?.apt}
                        maxLength="100"
                        disabled={
                          !(
                            this.state.permission_status.canAdd ||
                            this.state.permission_status.canEdit
                          )
                        }
                      ></input>
                      <div className="text-danger">
                        {this.state.formErrors?.apt !== undefined
                          ? this.state.formErrors?.apt
                          : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <Autocomplete
                        items={this.state.allAptUnit}
                        shouldItemRender={(item, value) =>
                          item?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                        }
                        getItemValue={(item) => item}
                        renderItem={(item, isHighlighted) => (
                          <div key={item}>
                            <p
                              style={{
                                background: isHighlighted ? "#007bff" : "white",
                                color: isHighlighted ? "white" : "#1b1b1b",
                                padding: "1px 5px",
                              }}
                              className="mb-0"
                            >
                              {item}
                            </p>
                          </div>
                        )}
                        wrapperProps={{
                          style: { width: "100%", display: "inline-block" },
                        }}
                        menuStyle={{
                          borderRadius: "5px",
                          boxShadow: "0 2px 12px rgba(0, 0, 0, 0.1)",
                          background: "white",
                          padding: "3px 3px",
                          position: "absolute",
                          maxHeight: "200%",
                          overflow: "auto",
                          zIndex: "100",
                          top: "85%",
                          left: "4%",
                        }}
                        value={this.state.updatedCustomerInfo?.apt}
                        onChange={(e) =>
                          this.setState({
                            updatedCustomerInfo: {
                              ...this.state.updatedCustomerInfo,
                              apt: e.target.value,
                            },
                          })
                        }
                        onSelect={(val) =>
                          this.setState({
                            updatedCustomerInfo: {
                              ...this.state.updatedCustomerInfo,
                              apt: val,
                            },
                          })
                        }
                        inputProps={{
                          placeholder: "Apt/Unit (As Needed)",
                          className: "form-control border-dark",
                        }}
                        disabled={
                          !(
                            this.state.permission_status.canAdd ||
                            this.state.permission_status.canEdit
                          )
                        }
                      />
                      <div className="text-danger">
                        {this.state.formErrors?.apt !== undefined
                          ? this.state.formErrors?.apt
                          : null}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>City</p>
                </div>
                {/* <div className="col-4">
                  <p className="btn border text-left p-2 w-100">
                    {this.state.customerInfo?.city}
                  </p>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="City"
                    name="city"
                    value={this.state.updatedCustomerInfo.city}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.city !== undefined
                      ? this.state.formErrors?.city
                      : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <p>State</p>
                </div>
                {/* <div className="col-4">
                  <p className="btn border text-left p-2 w-100">
                    {this.state.customerInfo?.stateName}
                  </p>
                </div> */}
                <div className="col-6">
                  <div className="form-group">
                    <select
                      id="state_Id"
                      name="state_Id"
                      className="form-select border-dark p-2"
                      onChange={(event) => this.onChange(event)}
                      disabled={
                        !(
                          this.state.permission_status.canAdd ||
                          this.state.permission_status.canEdit
                        )
                      }
                    >
                      <option value="0">-- Select --</option>
                      {this.state.lstState?.map((state) => (
                        <option
                          value={state.id}
                          selected={
                            this.state.updatedCustomerInfo.state_Id == state.id
                              ? "selected"
                              : ""
                          }
                        >
                          {state.description}
                        </option>
                      ))}
                    </select>
                    <div className="text-danger">
                      {this.state.formErrors?.state !== undefined
                        ? this.state.formErrors?.state
                        : null}
                    </div>
                  </div>


                </div>
              </div>
              <div className="row mb-3">
                <div className="col-6">
                  <p>Zip Code</p>
                </div>
                {/* <div className="col-4">
                  <p className="btn border text-left p-2 w-100">
                    {this.state.customerInfo?.zipCode}
                  </p>
                </div> */}
                <div className="col-6">
                  <input
                    type="text"
                    className="form-control border-dark"
                    placeholder="Zip Code"
                    name="zipCode"
                    maxLength="5"
                    value={this.state.updatedCustomerInfo.zipCode}
                    onChange={(event) => this.onChange(event)}
                    disabled={
                      !(
                        this.state.permission_status.canAdd ||
                        this.state.permission_status.canEdit
                      )
                    }
                  />
                  <div className="text-danger">
                    {this.state.formErrors?.zipCode !== undefined
                      ? this.state.formErrors?.zipCode
                      : null}
                  </div>
                </div>
              </div>
              <hr></hr>
              <div className="row justify-content-end mr-1">
                <button
                  className="btn mr-3 border-dark pl-4 pr-4"
                  onClick={() => this.toogleEditModal()}
                >
                  Cancel
                </button>
                <button
                  disabled={this.validate()}
                  className="btn bg-secondary text-primary pl-4 pr-4"
                  onClick={() => this.onUpdate()}
                >
                  Update
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    lookUpAction: bindActionCreators(customerLookupActions, dispatch),
    detailsAction: bindActionCreators(customerDetailsActions, dispatch),
    userRightAction: bindActionCreators(userRightsActions, dispatch),
    applyNowAction: bindActionCreators(applyNowActions, dispatch),
    customerUpdation: bindActionCreators(customerUpdationActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(MasterInfo);
