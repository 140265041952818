import React, { Component } from "react";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import { Card, Toast, ToastContainer } from "react-bootstrap";
import "./component.admin.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as adminActions from "../../redux/actions/admin.actions";
import { trackPromise } from 'react-promise-tracker';
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import Constant from "../../utilites/constants";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import ConfirmDialog from "../../components/component.confirmDialog";
import DailyNotes from "../dailyNotes/component.dailyNotes";
import AlterPopup from "../alterPopup/component.alterPopup";

class Admin extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      signOutTime: 1,
      adminSettingRes : {},
      //augmentesSignOutTime: 1,
      isSave: false,
      //augmentesIsSave: false,
      noDataFound : false,
      toastModal: false,
      toastAlertModal: false,
      sitePageDetails: [],
      roles : [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      alertObj:{
        id:"",
        alertMsg:"",
        newAlertMsg:""
      },
      showDelete:false,
      successMsg:"",
    };
  }
  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }
  onChange(event) {
    const value = Number(event.target.value);
    this.setState({ signOutTime: value, isSave: true });
  }
  onAlertChange(event) {
    event.preventDefault();
    let value=event.target.value;
    value=value.trimStart();
    this.setState({alertObj: { ...this.state.alertObj, newAlertMsg: value } });
  }
  // augmentesOnChange(event) {
  //   const value = Number(event.target.value);
  //   this.setState({ augmentesSignOutTime: value, augmentesIsSave: true });
  // }
  componentDidMount() {
    if(sessionStorage.getItem("isLoggedIn") === "true") {
      let adminMenus = [];
      adminMenus = localStorage.getItem("sitePageDetails");
      adminMenus = JSON.parse(adminMenus);
      if (adminMenus) {
        this.setState({
          sitePageDetails: adminMenus,
        });
      }
      else{
      trackPromise(
        this.props.userRightsactions.getUserRights().then(
            response => {
                if(response) {
                 let adminMenus = response.sitePageDetails.filter(item=>item.sitePage_id===2);
                    this.setState({
                        sitePageDetails: adminMenus,
                    });
                    localStorage.setItem("sitePageDetails",JSON.stringify(adminMenus))
                }
        }).catch(err => {
            APIFailedMessage.displayError(err);
        })
       )
      }
      trackPromise(
        this.props.userRightsactions.getUserRights().then(
            response => {
                if(response) {
                    this.setState({
                        roles: response.roles, 
                        site_page_details: response.sitePageDetails, 
                        sitePages : response.sitePages, 
                        userRights : response.userRights
                    });
                    this.getPermissions()
                }
        }).catch(err => {
            APIFailedMessage.displayError(err);
        })
      )
      const signOutInMins= localStorage.getItem("signOutInMins");
      //const augmentesSignOutInMins= localStorage.getItem("augmentesSignOutInMins");
      // if(signOutInMins){
      //   this.setState({ signOutTime: signOutInMins, 
      //     // augmentesSignOutTime : augmentesSignOutInMins
      //   });
      // }
      // else{
        trackPromise(
          this.props.adminActions.getSignOutTime().then(
            response=>{
              if(response){
                this.setState({ signOutTime: response.signOutInMins , adminSettingRes : response
                  //augmentesSignOutTime : response.sessionOutMins
                 });
                localStorage.setItem("signOutInMins",Number(response.signOutInMins));
                //localStorage.setItem("augmentesSignOutInMins",Number(response.sessionOutMins));
              }
            }
          ).catch(err=>{
             APIFailedMessage.displayError(err);
          })
        )
      // }
      this.getMessage();
    }
    else 
      this.props.history.push( "/");
  }
  getMessage() {
    this.props.adminActions.getAlertMsg().then(
      response => {
        if (response) {
          if (response.alertMsg !== "\n") {
            this.setState({ alertObj: response });
            this.setState({ alertObj: { ...this.state.alertObj, newAlertMsg: response.alertMsg } });
          }
        }
      }).catch(err => {
        APIFailedMessage.displayError(err);
      });
  }
  onSave(event) {
    let obj={
        signOutInMins: this.state.signOutTime,
        sessionOutMins: this.state.adminSettingRes.augmentesSignOutTime,
        isAutoPayEnabled: this.state.adminSettingRes.isAutoPayEnabled,
        isSendEmail_Synchrony: this.state.adminSettingRes.isSynchronySendEmail,
        isAddressAutoComplete: this.state.adminSettingRes.isAddressAutoComplete,
        isCoBorrowerEnable: this.state.adminSettingRes.isCoBorrowerEnable,
        isCSSEnable:this.state.adminSettingRes.isCSSEnable,
        isWaterFallEnable:this.state.adminSettingRes.isWaterFallEnable,
        isEstatementEnable:this.state.adminSettingRes.isEstatementEnable,
        isInsurance_FortivaEnabled: this.state.adminSettingRes.isInsurance_FortivaEnabled,
        isInsurance_DanielsEnabled: this.state.adminSettingRes.isInsurance_DanielsEnabled,
        isDanielsFileAClaim:this.state.adminSettingRes.isDanielsFileAClaim,
        isDanielsCancelEnrollment:this.state.adminSettingRes.isDanielsCancelEnrollment,
        isFortivaFileAClaim:this.state.adminSettingRes.isFortivaFileAClaim,
        isFortivaCancelEnrollment:this.state.adminSettingRes.isFortivaCancelEnrollment,
        isDanielsApplicationFlowChanges:this.state.adminSettingRes.isDanielsApplicationFlowChanges,
        isAssociateStore_IP: this.state.adminSettingRes.isAssociateStore_IP,
        timervalue: this.state.adminSettingRes.timervalue
    }
    this.props.adminActions
      .saveSignOutTime(obj)
      .then((res) => {
        this.setState({ isSave:  false, toastModal: !this.state.toastModal, signOutTime : res.signOutInMins });
        localStorage.setItem("signOutInMins",Number(res.signOutInMins));
       // localStorage.setItem("augmentesSignOutInMins",Number(res.sessionOutMins));
      })
      .catch((err) => {
        APIFailedMessage.displayError(err);
      });
  }

  onAlertSave(event) {
    let obj={
      id: 0,
      alertMsg: this.state.alertObj.newAlertMsg,
      createdBy: localStorage.getItem("loggedUserEmail")
    }
      this.saveAlertMsg(obj);
  }

  onAlertDelete(event) {
    event.preventDefault();
    this.setState({showDelete:true});
  }

  handleModalPopup = () => {
    this.setState({showDelete: !this.state.showDelete,alertObj: { ...this.state.alertObj, newAlertMsg: this.state.alertObj.alertMsg } });
  }
  saveAlertMsg(obj,isDelete) {
    trackPromise(
      this.props.adminActions.saveAlertMsg(obj)
        .then((res) => {
          this.setState({ toastAlertModal:!this.state.toastAlertModal });
          this.setState({successMsg:isDelete?'Alert Message deleted successfully!':'Alert Message updated successfully!'});
          this.getMessage();
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  }
  deleteMsg=()=>{
    let obj={
      id: 0,
      alertMsg: "",
      createdBy: localStorage.getItem("loggedUserEmail")
    }
    let isDelete=true;
    this.saveAlertMsg(obj,isDelete);
    this.setState({showDelete: !this.state.showDelete,alertObj: { ...this.state.alertObj, newAlertMsg: "",alertMsg:"" } });
  }

  getPermissions() {
    let role_name = localStorage.getItem('role_name')
    const getsitepages = this.state.sitePages.filter((site_pages) => { return site_pages.name === 'Admin Page' })
    const getSitepagedetails = this.state.site_page_details.filter((site_page_details) => { return site_page_details.sitePage_id ===getsitepages[0].id })
    const getRole = this.state.roles.filter((roles) => { return roles.name.toLowerCase() === role_name.toLowerCase() });
    let role_id = getRole[0].id;
    let role_permissons = getSitepagedetails.length > 0 ?
      getSitepagedetails.map((page_details) => {
        const role_permission_check = this.state.userRights 
      })
    : []
  }

  render() {
    return (
      <>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={this.state.sitePageDetails}
          />
          <main>
            <CustomNavbar
              handleToggleSidebar={() => {
                this.handleToggleSidebar();
              }}
              isAdmin={true}
            />
            <ToastContainer className="p-3" position="top-end">
              <Toast bg="success" onClose={() => {this.setState({toastModal : !this.state.toastModal})}} show={this.state.toastModal} delay={3000} animation autohide>
                <Toast.Body className="text-white ml-2 mr-2"><FontAwesomeIcon icon={faCircleCheck} className="pr-2"/>SignOut time updated successfully!</Toast.Body>
              </Toast>
            </ToastContainer>
            <ToastContainer className="p-3" position="top-end">
              <Toast bg="success" onClose={() => {this.setState({toastAlertModal : !this.state.toastAlertModal})}} show={this.state.toastAlertModal} delay={3000} animation autohide>
                <Toast.Body className="text-white ml-2 mr-2"><FontAwesomeIcon icon={faCircleCheck} className="pr-2"/>{this.state.successMsg}</Toast.Body>
              </Toast>
            </ToastContainer>
            <div id="main" className="container-fluid">
              <div className="row">
                <Card className="w-100 signOutSection">
                  <Card.Body className="pl-2 pr-2 pl-md-4 pr-md-4">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="mt-1 mb-0" >
                        Automatically sign out user from the portal
                      </p>
                      <p className="mb-2">If no activity is detected </p>
                    </div>
                    <div className="col-12 col-md-6 pl-md-0 d-flex justify-content-start justify-content-md-end align-items-center">
                        <p className="mr-1 mb-0 mr-md-2 mr-lg-2">Sign out after</p>
                        <div className="select m-0" name="signOut">
                          <select
                            name="slct"
                            id="slct"
                            onChange={(event) => this.onChange(event)}
                            value={this.state.signOutTime}
                          >
                            {Constant.signOutInMins.map((item) => {
                              return <option value={`${item}`}>{item}</option>;
                            })}
                          </select>
                        </div>

                        <p className="mb-0 ml-1 ml-md-2">Minutes</p>
                        <button
                          className="btn bg-secondary text-white ml-auto"
                          style={{
                            padding: "3px 20px",
                          }}
                          disabled={this.state.isSave ? false : true}
                          onClick={(event) => this.onSave(event)}
                        >
                          Save
                        </button>
                    </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              {/* <div className="row">
                <Card className="w-100 signOutSection mt-0">
                  <Card.Body className="pl-2 pr-2 pl-md-4 pr-md-4">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <p className="mt-1 mb-0" >
                        Automatic sign out user from the Augementes
                      </p>
                      <p className="mb-2">If no activity is detected </p>
                    </div>
                    <div className="col-12 col-md-6 pl-md-0 d-flex justify-content-start justify-content-md-end align-items-center">
                        <p className="mr-1 mb-0 mr-md-2 mr-lg-2">Sign out after</p>
                        <div className="select m-0" name="signOut">
                          <select
                            name="slct"
                            id="slct"
                            onChange={(event) => this.augmentesOnChange(event)}
                            value={this.state.augmentesSignOutTime}
                          >
                            {Constant.augmentesTimeMinutes.map((item) => {
                              return <option value={`${item}`}>{item}</option>;
                            })}
                          </select>
                        </div>

                        <p className="mb-0 ml-1 ml-md-2">Minutes</p>
                        <button
                          className="btn bg-secondary text-white ml-auto"
                          style={{
                            padding: "3px 20px",
                          }}
                          disabled={this.state.augmentesIsSave ? false : true}
                          onClick={(event) => this.onSave(event)}
                        >
                          Save
                        </button>
                    </div>
                    </div>
                  </Card.Body>
                </Card>
              </div> */}
              <div className="row">
              <Card className="w-100 signOutSection mt-0">
                  <Card.Body className="pl-2 pr-2 pt-3 pb-3 pl-md-3 pr-md-3 pl-lg-3 pr-lg-3">
                    
                <div className="col-12 col-md-6 pl-1 pr-1">
                  <p className="mb-0">Alert Message</p>
                </div>
               
                  <div className="col-12 col-md-6 pl-1 pr-1">
                        <textarea
                            id="alertMsg"
                            name="alertMsg"
                            rows="3"
                            className="p-2 printSummaryFields w-100"
                            style={{ borderRadius: "5px"}}
                            value={this.state.alertObj.newAlertMsg}
                            onChange={(event) => this.onAlertChange(event)}
                            placeholder="Alert Message"
                            maxLength="400"
                        ></textarea>
                        <span className="m-0">Enter up to 400 characters</span>
                  </div>
                  <div className="col-12 col-md-6 pr-1">
                  <button  className="btn bg-secondary text-white float-right"
                            style={{
                              padding: "3px 20px",
                            }} onClick={(event) => this.onAlertSave(event)}
                            disabled={(this.state.alertObj.newAlertMsg==this.state.alertObj.alertMsg || this.state.alertObj.newAlertMsg=="")?true:false}
                            >Save</button>
                  <button  className="btn bg-secondary text-white float-right mr-2"
                            style={{
                              padding: "3px 20px",
                            }} onClick={(event) => this.onAlertDelete(event)}
                            disabled={this.state.alertObj.alertMsg==""?true:false}
                            >Delete</button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
              
              <div>
              <DailyNotes />
              </div>

              <div className="mt-1">
                <AlterPopup />
                </div>
              
            </div>
            <ConfirmDialog
                  show={this.state.showDelete}
                  handleModalPopup={this.handleModalPopup}
                  message={"<p>Are you sure you want to delete the message?</p>"}
                  button1_name="Cancel"
                  button1={this.handleModalPopup}
                  button2_name="Ok"
                  button2={this.deleteMsg}
              />
          </main>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    userRightsactions:bindActionCreators(userRightsActions,dispatch)
  };
};
export default connect(null, mapDispatchToProps)(Admin);
