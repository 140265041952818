import React, { useRef } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from 'react-to-print';

const DanielsEStatementDisclosure = (props) => {
    const { show, onDanielEStatementDisclosureChecked, eStatementContent } = props;
    const componentRef = useRef(null);
    return (
        <Modal id="customModel" show={show} >
            <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel’s Jewelers e-Statement Agreement</b><FontAwesomeIcon icon={faClose} className="btn" onClick={(event) => { onDanielEStatementDisclosureChecked(event) }} /></Modal.Header>
            <Modal.Body className="pb-0">
                <div>
                    <p className="printThisPage">
                        <span>Please</span>
                        <ReactToPrint
                            trigger={() =>
                                <span className="ml-0" style={{ color: "rgb(0, 174, 239)", cursor: "pointer" }}><u>print this page</u></span>
                            }
                            content={() => componentRef.current}
                            pageStyle="@page { size: 210mm 297mm; margin: 20mm 20mm; }"
                            documentTitle="Daniel’s E-statement disclosures"
                        />
                        <span className="ml-0"> for your record.</span>
                    </p>
                </div>
                <div ref={componentRef}>
                    <div dangerouslySetInnerHTML={{ __html: eStatementContent }}></div>
                </div>
            </Modal.Body>
        </Modal>
    )
}
export default DanielsEStatementDisclosure;