import { Link } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from 'react-pro-sidebar';
import danielsLogo from "../images/DanielWhite.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userRightsActions from "../redux/actions/adminUserRights.actions";
import * as adminUserListActions from '../redux/actions/adminUserList.actions';
import Moment from 'moment';
import APIFailedMessage from "./component.apiFailedMessage";
import * as authActions from "../redux/actions/authActions";
import * as InsuranceActions from "../redux/actions/insurance.actions";
import { trackPromise } from 'react-promise-tracker';
import * as adminActions from '../redux/actions/admin.actions';

class HamburgerMenu extends Component{

    constructor() {
        super();
        this.state = {
            homeStore : "",
            isAssociateStore_IP:false
        }
    };
    componentDidMount() {
        sessionStorage.setItem("sidebar", false);
        sessionStorage.setItem("hamburger", true);
        this.setState({homeStore : JSON.parse(localStorage.getItem("associateStores"))});
        this.props.adminActions.getSignOutTime()
        .then(res => {
            if (res) {
               this.setState({ isAssociateStore_IP:res.isAssociateStore_IP });
            }
        })
        .catch(error => {
            APIFailedMessage.displayError(error);
        });
    }

    onChange(event) {
        if (event.target.id === "cmbAssociateStoreIds") {
            this.setState({homeStore : event.target.value});
            localStorage.setItem('associateStores', JSON.stringify(event.target.value));
        }
        trackPromise(
            this.props.insuranceAction
            .getInsurancePlans()
            .then((response) => {
            if (response) {
                var storeList = JSON.parse(sessionStorage.getItem("storeList"));
                storeList = storeList.find((item) => {
                    return item.code == event.target.value
                });
                let stateCode = storeList?.address2?.split(",")[1]?.trim();
                sessionStorage.setItem("AssociateState", stateCode);
                let plans = response?.getInsuranceResponses?.find(item => {
                    return item.insurance_partner === "Fortiva" && item.isActive === true && item.state == stateCode;
                });
                if(plans!== undefined)
                    sessionStorage.setItem("havingFortivaInsurance", true)
                else sessionStorage.setItem("havingFortivaInsurance", false)
                plans = response?.getInsuranceResponses?.find(item => {
                    return item.insurance_partner === "Daniels" && item.isActive === true && item.state == stateCode;
                });
                if(plans!== undefined)
                    sessionStorage.setItem("havingDanielInsurance", true)
                else sessionStorage.setItem("havingDanielInsurance", false)
            }
            }).catch((err) => {
                APIFailedMessage.displayError(err);
            })
        )
    }
    render() {
            var loggedUserName = localStorage.getItem("loggedUserName");
            loggedUserName = loggedUserName?.split(" ");
        return (
        <>
          <div id='hamburger' className={(this.props.toggled ? "hamburger" : "" )}>
          <ProSidebar
                    toggled={this.props.toggled}
                    onToggle={this.handleToggleSidebar}
                >
                    <SidebarHeader>
                        <Menu className="pl-4">
                            <img src={danielsLogo} alt="logo" width="100" />
                            <button className="float-right mr-3 btn text-primary" onClick={this.props.handleToggleSidebar}><FontAwesomeIcon icon={faAnglesLeft} /></button>
                        </Menu>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu >
                            <div className="pl-4 d-flex align-items-center">
                                <FontAwesomeIcon icon={faCircleUser} size="2x" className="pr-2" />
                                <div className="d-block">
                                    <b>Hello {loggedUserName[0]} !</b>
                                    <form>
                                    <small>
                                            Store
                                            {this.state.isAssociateStore_IP?
                                            <span>{JSON.parse(localStorage.getItem('associateStores'))}</span>:
                                          <select name="cmbAssociateStoreIds" id="cmbAssociateStoreIds" className="border-0 storeNo" value={this.state.homeStore} onChange={(event) => this.onChange(event)}>
                                                {JSON.parse(sessionStorage.getItem("storeList"))?.map((val) => (
                                                    <option className="selectStyle" value={val.code} > {val.code}</option>
                                                ))}
                                            </select>}
                                        </small>
                                    </form>
                                </div>
                            </div>
                            <hr></hr>
                            <div>
                                <MenuItem active={window.location.pathname === "/customer-lookup"}>
                                    Customer Lookup <Link to={`/customer-lookup`} />
                                </MenuItem>
                                {JSON.parse(localStorage.getItem("hasPortalAccess")) ?
                                    <MenuItem active={window.location.pathname === "/synchrony-lookup"}>
                                        Synchrony Lookup <Link to="/synchrony-lookup" />
                                    </MenuItem> : ""}
                                {JSON.parse(localStorage.getItem("hasAdminAccess")) ?
                                    <MenuItem onClick={this.props.onAdmin}>
                                        Admin Pages
                                    </MenuItem> : ""}
                                <MenuItem onClick={this.props.handleModal}>
                                    Logout
                                </MenuItem>
                            </div>
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter className="border-top-0">
                        <p className="p-3 mb-0" style={{ background: "#400163" }}><small>&copy; Copyright 2022 Daniel's Jewelers, All Rights Reserved.</small></p>
                    </SidebarFooter>
                </ProSidebar>
          </div>
    </>
  );
}
}
const mapStateToProps = state => {
    
  return {
  data: state.associateStore.associateStoreDetails
  };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions:bindActionCreators(adminUserListActions,dispatch),
        userRightsactions:bindActionCreators(userRightsActions,dispatch),
        authActions: bindActionCreators(authActions, dispatch),
        insuranceAction: bindActionCreators(InsuranceActions, dispatch),
        adminActions:bindActionCreators(adminActions,dispatch)
    };
};
 export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);