import React, { Component } from "react";
import { Card, Modal } from "react-bootstrap";
// import CustomNavbar from "../../components/component.customNavbar";
// import Sidebar from "../../components/component.customSideBar";
// import IdleTimeModal from "../../components/component.idleTimeModal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { trackPromise } from 'react-promise-tracker';
// import "./component.danielsCreditOffer.css";
import * as viewOfferActions from "../../redux/actions/viewOffers.actions";
import Moment from 'moment';
import { faClose} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetInfo from "../../components/component.getInfo";
import Insurance from "../insurance/component.insurance";
import * as InsuranceActions from "../../redux/actions/insurance.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import * as billingCycleActions from "../../redux/actions/billingCycle.actions";

class DanielsCreditOffers extends Component{
    constructor(){
        super();
        this.state={
            isDanielsOffer:false,
            isContinue:false,
            isMatch:false,
            ratingCode:'',
            isMatchFound:false,
            isAccept:false,
            approvalId:"",
            insuranceDaniels:false,
            insurance:false,
            isCSSaccountCreated:false,
            isBillingCycle:false,
            billingCycleId:"",
            associateId:localStorage.getItem('loggedUserEmail')!="null"?localStorage.getItem('loggedUserEmail'):"",
            store_Id:localStorage.getItem("associateStores")!="null"?JSON.parse(localStorage.getItem("associateStores")):"",
            danielAppId:localStorage.getItem("danielAppId")
            
        };
    }
   
    loadDanielsCredit() {
        let createdDate=sessionStorage.getItem("createdDate");
        if(createdDate){
            createdDate=Moment(sessionStorage.getItem("createdDate")).format("MM/DD/YYYY");
        }
        let danielAppId= this.state.danielAppId
        if (danielAppId != "null" && danielAppId != "undefined" && danielAppId != "") {
            return (
                (!this.state.isCSSaccountCreated)?
                <div className="col-12">
                    <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                    
                        <Card.Body>
                        <div className="row">
                            <div className="col-12 col-md-4 col-lg-3 pr-md-0">
                            <Card className="h-100">
                                <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center card-body">
                                <p className="h5 my-auto"><b>DANIEL'S CREDIT</b></p>
                                <div className="my-auto text-secondary">
                                    <p className="mb-0"><b>Your application has been submitted to Credit for review.</b></p>
                                </div>
                                </Card.Body>
                            </Card>
                            </div>
                            <div className="col-12 col-md-8 col-lg-9 pl-lg-4 pt-4 pt-md-0 pt-lg-0">
                                <div className="row">
                                    <p className="pl-3 pr-3 mt-2">
                                        Your application has been submitted to the Credit department for review. Once you receive your approval on the roll printer and your customer would like to make a purchase , press the <span className="text-secondary font-weight-bold">Accept Offer </span>button below to create the customer account. 
                                    </p>
                                    <p className="pl-3 pr-3 mt-2"> Terms and Conditions placeholder.</p>
                                    <div className="pl-3 pr-3 col-12">
                                            <button className="btn bg-secondary text-primary p-2 applyOfferBtn" onClick={()=>{this.handleAcceptOffer()}}>
                                                <span className="ml-3 mr-3">Accept Offer</span>
                                            </button>
                                        
                                        </div>
                                    <div className="mt-3 pl-3 pr-3 w-100 col-12 d-flex">
                                        <div className="ml-auto">
                                            <p className="mb-0">Application Id : {this.state.danielAppId}</p>
                                            <p className="mb-0">Application Date : { createdDate}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Card.Body>
                    </Card>
                </div>:
                // <div className="col-6">
                //     <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                    
                //         <Card.Body>
                //         <div className="row">
                //             <div className="col-12">
                //                 <Card className="h-100">
                //                     <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center card-body">
                //                     <p className="h5 my-auto text-secondary"><b>DANIEL'S CREDIT</b></p>
                //                     <div className="my-auto text-danger">
                //                         <p className="mb-0"><b>Application has not been submitted to Daniel's Credit.</b></p>
                //                     </div>
                //                     </Card.Body>
                //                 </Card>
                //             </div>
                //         </div>
                //         </Card.Body>
                //     </Card>
                // </div>
                (this.state.isCSSaccountCreated) ?
                <div className="col-12 col-lg-8">
                            <Card className="text-left mt-4 ml-2 mr-2 mb-2 shadow">
                                <Card.Body>
                                <div className="row">
                                    <div className="col-12 col-md-6 col-lg-6 pr-md-0">
                                    <Card className="h-100">
                                        <Card.Body className="bg-light p-4 bg-primary subCard d-flex justify-content-center">
                                            <div className="mb-4"><p className="h5 my-auto mb-4"><b>DANIEL'S CREDIT</b></p></div>
                                            <div className="my-auto mb-4 text-secondary">
                                            <p className="h5 my-auto mb-4">Congratulations! Please see a sales associate for further assistance.</p>
                                            </div>
                                            {/* <div className="my-auto mt-2 text-secondary" >
                                                <p className="mb-0 mt-4"> Credit Limit: ${fortivaOffer.approvalLimit.toLocaleString("en-US")}</p>
                                                <p className="mb-0">Required Down Payment: {fortivaOffer.downPaymentPct}%</p>
                                                {(fortivaOffer.introDuration!=="0" && fortivaOffer.introDuration!=null)?
                                                <p className="mb-0">No Interest, Promotional Period:  {fortivaOffer.introDuration} months</p>:""}
                                            </div> */}
                                        </Card.Body>
                                    </Card>
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pl-lg-4 pt-4 pt-md-0 pt-lg-0 d-flex flex-column">
                                        <div className="mt-auto">
                                            <p className="mb-0">Application Id : {this.state.danielAppId}</p>
                                            <p className="mb-0">Application Date :{ createdDate}</p>
                                        </div>
                                    </div>
                                </div>
                                </Card.Body>
                            </Card>
                            
                        </div>:""
            )
        }
    }
    handleAcceptOffer=()=>{
        this.setState({isDanielsOffer:!this.state.isDanielsOffer});
    }
    onContinueOffer= async()=>{
        let obj=await this.getRatingCode();
        if(obj){
            if(obj.isMatchFound){
                if((obj.ratingCode?.toLowerCase()=="z" || obj.ratingCode=="null" || obj.ratingCode=="" )){
                    this.setState({isMatch:true,isDanielsOffer:false});
                }
                else{
                    this.setState({isContinue:!this.state.isContinue,isDanielsOffer:false});
                }
            }
            else
                this.setState({isMatch:true,isDanielsOffer:false});
        }
    }
    getRatingCode=async()=>{
        this.setState({isDanielsOffer:false});
        let applicationId=this.state.danielAppId;
        if((applicationId !== "null" && applicationId !== "0" && applicationId !== "") && this.state.approvalId){
            let response=await trackPromise(this.props.actions.GetRating(GetInfo.getCustomerId(),applicationId,this.state.approvalId));
            if (response) {
                if (response.rating_Code) {
                    let saveRatingCode={
                        ratingCode:response.rating_Code,
                        approvalCode:this.state.approvalId,
                        customerId:GetInfo.getCustomerId(),
                        applicationid:applicationId,
                        store_id: JSON.parse(localStorage.getItem("associateStores"))
                    }
                    await trackPromise(this.props.actions.saveApprovalAndRatingCode(saveRatingCode));
                    this.setState({ratingCode:response.rating_Code, isMatchFound:true,approvalId:""});
                    return {ratingCode:response.rating_Code,isMatchFound:true }
                }
                else {
                    this.setState({isMatchFound:false,approvalId:"",ratingCode:""});
                    return {ratingCode:"",isMatchFound:false }
                }
            }
            else {
                this.setState({isMatchFound:false,approvalId:"",ratingCode:""});
                return {ratingCode:"",isMatchFound:false }
            }
        }
    }
    onCancelOffer = ()=>{
        this.setState({isDanielsOffer:false,approvalId:""});
    }
    onBillingcycle=()=>{
        //let createdDate=sessionStorage.getItem("createdDate");
        let createdDate=Moment(new Date()).format('YYYY-MM-DD h:mm:ss');
        let cycleObj={
            createdDate:createdDate,
            application_id: this.state.danielAppId
        }
        this.setState({isContinue:!this.state.isContinue});
        trackPromise(
        this.props.billingCycleActions.getBillingCycle(cycleObj)
        .then(resp=>{
             this.setState({billingCycleId:resp.cycle,isBillingCycle:!this.state.isBillingCycle});
        }).catch(err=>{
            APIFailedMessage.displayError();
        })
        )
    }
    onAccept=()=>{
        if(this.props.isDanielsInsuranceEnable && sessionStorage.getItem("havingDanielInsurance") == "true"){
            this.setState({insuranceDaniels:!this.state.insuranceDaniels,isBillingCycle:!this.state.isBillingCycle,insurance:!this.state.insurance});
        }
        else{
            this.setState({isBillingCycle:!this.state.isBillingCycle});
            this.createCSSAccount();
        }
    }
    createCSSAccount() {
        let obj = {
          storeCode: JSON.parse(localStorage.getItem("associateStores")),
          customerId: localStorage.getItem("customerId"),
          applicationId: this.state.danielAppId
        };
        trackPromise(
        this.props.Insuranceactions.createDanielsAccountNumber(obj)
          .then(async resp => {
            if (resp) {
              this.setState({ danielsAcctNumber: resp });
              let cssObj = {
                "customerId": JSON.parse(localStorage.getItem("customerId")),
                "application_id": this.state.danielAppId,
                "danielAccountNo": resp,
                "credit_director": false,
                "isInsurance":false
              };
              let creditData=await trackPromise(this.props.Insuranceactions.getCreditDirectorData(cssObj));
             if(creditData){
                    let creditSaveObj={
                    "crediT_LIMIT": creditData.crediT_LIMIT,
                    "experiaN_SCORE": creditData.experiaN_SCORE,
                    "tranS_UNION_SCORE": creditData.tranS_UNION_SCORE,
                    "equifaX_SCORE": creditData.equifaX_SCORE,
                    "tranS_UNION_SCORE_1": creditData.tranS_UNION_SCORE_1,
                    "id": creditData.id,
                    "crediT_BUREAU_USED": creditData.crediT_BUREAU_USED,
                    "totaL_RATIO": creditData.totaL_RATIO,
                    "trades": creditData.trades,
                    "neT_INCOME": creditData.neT_INCOME,
                    "generiC_STRING_FIELD_8": creditData.generiC_STRING_FIELD_8,
                    "applicatioN_TYPE": creditData.applicatioN_TYPE,
                    "totaL_SCORE": creditData.totaL_SCORE,
                    "applicatioN_SCORE": creditData.applicatioN_SCORE,
                    "reV_RATIO": creditData.reV_RATIO,
                    "tradeS_DEROGS": creditData.tradeS_DEROGS,
                    "in_File_YYMM": creditData.in_File_YYMM,
                    "experiaN_SCORE_1": creditData.experiaN_SCORE_1,
                    "experiaN_SCORE_2": creditData.experiaN_SCORE_2,
                    "tranS_UNION_SCORE_2":  creditData.tranS_UNION_SCORE_2,
                    "equifaX_SCORE_1": creditData.equifaX_SCORE_1,
                    "equifaX_SCORE_2": creditData.equifaX_SCORE_2,
                    "crediT_RATING": creditData.crediT_RATING,
                    "ratinG_CODE": creditData.ratinG_CODE,
                    "lasT_BUREAU_DATE": creditData.lasT_BUREAU_DATE,
                    "maintaineD_DATE_TIME": creditData.maintaineD_DATE_TIME,
                    "client_acnt_number2": creditData.client_acnt_number2,
                    "fnd_rcpt_id": creditData.fnd_rcpt_id,
                    application_Id:this.state.danielAppId,
                    customer_Id:JSON.parse(localStorage.getItem("customerId"))
                  }
                  await this.props.Insuranceactions.CreditDirectorSaveData(creditSaveObj)
                  .then(async resp => {
                    if (resp==true) {
                        let response=await trackPromise(this.props.Insuranceactions.createCSSAccount(cssObj));
                        if (response.success == "true") {
                            this.setState({isCSSaccountCreated:!this.state.isCSSaccountCreated});
                        }
                    }
                  });
                }
            }
          }).catch((err) => {
            APIFailedMessage.displayError(err);
          })
          )
      }
    onAcceptCancel = ()=>{
        this.setState({isContinue:false});
    }
   
    onMatch=()=>{
        this.setState({isMatch:false});
    }
    onTryAgain=()=>{
        this.setState({isDanielsOffer:!this.state.isDanielsOffer,isMatch:false});
    }
    handleMatchnotFound=()=>{
        this.setState({isMatch:!this.state.isMatch});
    }
    onChange(event){
        //    let reg=/^[0-9]+$/;
        //    if(reg.test(event.target.value)){
                this.setState({approvalId:event.target.value})
            //}
    }
    triggerInsurance = (status) => {
        if(status=="Declined"){
            this.setState({isCSSaccountCreated:!this.state.isCSSaccountCreated});
        }
        else if(status=="Completed"){
            this.setState({insurance : !this.state.insurance,isCSSaccountCreated:!this.state.isCSSaccountCreated});
        }
        else{
            this.setState({insurance : !this.state.insurance});
        }
       
    }
  
    render(){
        let message="";
        if(this.state.isMatchFound){
            if(this.state.ratingCode?.toLowerCase()=="z")
            message="Please contact the Credit Department regarding this application.";
            else if(this.state.ratingCode=="null" || this.state.ratingCode=="")
                message="This application is under review.  Try again once the approval is sent via the roll printer.";
        }
        else 
            message="Approval number not found, please enter in your approval number again.";

        return(
            <>
            {/* <IdleTimeModal /> */}
            <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
                {/* <Sidebar
                toggled={this.state.toggled}
                handleToggleSidebar={() => {this.handleToggleSidebar()}}
                /> */}
                <main>
                    {/* <CustomNavbar handleToggleSidebar={() => {this.handleToggleSidebar()}}/> */}
                        <div id="main" className="container-fluid">
                            <div className="row mb-3">
                                <div className="col-12 p-0">
                                    {this.loadDanielsCredit()}
                                </div>
                            </div>
                    </div>
                </main>
                <Modal show={this.state.isDanielsOffer}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onCancelOffer()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="row">
                            <div className="col-12 ">
                                <p className="mb-0"><label className="font-weight-bold">Customer :</label> <label>{localStorage.getItem('selectedUserName')}</label></p>
                            </div>
                            <div className="col-12">
                                <p><label className="font-weight-bold">Application ID :</label> {this.state.danielAppId}</p>
                            </div>
                            <div className="col-12 col-md-4 col-lg-4 font-weight-bold pt-2 pr-0">
                                <label>Enter Approval ID:</label>
                            </div>
                            <div className="col-12 col-md-6 col-lg-6">
                                <input
                                type="number"
                                className="form-control border-dark "
                                name="approvalId"
                                id="approvalId"
                                placeholder="Approval ID"
                                value={this.state.approvalId}
                                onChange={(event) => this.onChange(event)}
                                ></input>
                            </div>
                        </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-3">
                    <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onContinueOffer()}} disabled={this.state.approvalId?false:true}>Continue</button>
                        <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onCancelOffer()}>Cancel</p>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isContinue}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onAcceptCancel()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p>Congratulations ! You have been approved for Daniel's Credit ! Would you like to accept the offer and create a customer account?</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                    <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onBillingcycle()}}>Accept</button>
                        <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onAcceptCancel()}>Cancel</p>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isBillingCycle}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Billing Cycle</b></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p>Billing Cycle <b>{this.state.billingCycleId}</b> has been assigned to this customer account.</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                        <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onAccept()}}>OK</button>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isAccept}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.onContinueCancel()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 font-weight-bold pt-2">
                                    <p>Congratulations! you have been approved for Daniel's Credit! Would you like to accept the offer and create a customer account?</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                    <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onContinueOffer()}}>Accept</button>
                        <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.onContinueCancel()}>Cancel</p>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.isMatch}>
                    <Modal.Header className="pb-0"><b className="text-secondary h4">Daniel's Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" 
                    onClick={() => ((this.state.ratingCode?.toLowerCase()=="z" || this.state.ratingCode=="null" || this.state.ratingCode=="") && this.state.isMatchFound)?this.onMatch():this.handleMatchnotFound()} /></Modal.Header>
                    <Modal.Body className="pb-0">
                            <div className="row">
                                <div className="col-12 pt-2">
                                    <p>{message}</p>
                                </div>
                            </div>
                    </Modal.Body> 
                    <Modal.Footer className="border-top-0 mt-1">
                     {((this.state.ratingCode?.toLowerCase()=="z" || this.state.ratingCode=="null" || this.state.ratingCode=="") && this.state.isMatchFound)?
                      <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onMatch()}}>OK</button>:
                     <>
                       <button className="btn ml-1 mr-1 mb-0 reStay bg-secondary text-primary"  onClick={()=>{this.onTryAgain()}}>Try Again</button>
                       <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#1B1B1B",color:"white"}}onClick={()=>this.handleMatchnotFound()}>Cancel</p>
                       </>
                     }
                    </Modal.Footer>
                </Modal>
                {this.state.insuranceDaniels ? 
                        <Insurance 
                            from="offers" 
                            insurance={this.state.insurance}
                            triggerInsurance={this.triggerInsurance}
                            partnerType = "Daniels"
                            offerAcceptedState={sessionStorage.getItem("AssociateState")}
                        />
                    : null}
            </div>
            </>
        );
    }
}
const mapStateToProps = state => {
    
    return {
        data: state.fortiva.fortivaObj
    };
  };

const mapDispatchToProps = (dispatch) => {
    return {
        actions :bindActionCreators(viewOfferActions,dispatch),
        Insuranceactions: bindActionCreators(InsuranceActions, dispatch),
        billingCycleActions: bindActionCreators(billingCycleActions, dispatch),
        }
}
export default connect(mapStateToProps, mapDispatchToProps)(DanielsCreditOffers);
