import {
  faEdit,
  faSave,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AdminActions from "../../redux/actions/admin.actions";
import { trackPromise } from "react-promise-tracker";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { toast } from "react-toastify";

class AddingIPAddressTab extends Component {
  constructor() {
    super();
    this.state = {
      ip: [],
      add: false,
    };
  }

  componentDidMount() {
    this.loadIP();
  }

  loadIP() {
    trackPromise(
        this.props.action
          .getIPAddress(this.props.selectedStore?.code)
          .then((res) => {
            if (res) {
              res.map((item) => {
                  item.isEdit = false;
                  item.isError = false;
                  return item;
              });
              this.setState({ip : res});
              sessionStorage.setItem("orgIP", JSON.stringify(res));
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
  }

  onChange(event) {
    event.preventDefault();
    let inputName = event.target.name?.split("_")[0];
    let inputId = event.target.name?.split("_")[1];
    let data = this.state.ip;
    const re = /^(?!\s)[0-9.]*$/;
    if(re.test(event.target.value)) {
      data = data.map((item) => {
        if (item.id == inputId) {
          if (inputName == "fromIP") item.fromIP = event.target.value;
          else item.toIP = event.target.value;
        }
        return item;
      });
      this.setState({ ip: data });
    }
  }

  render() {
    const info = this.props.selectedStore;

    const onSave = (item) => {
        let data = JSON.parse(sessionStorage.getItem("orgIP"));
        data = data?.find((val) => {
            return val.id === item.id;
        });
        if(item.fromIP.length === 0 || item.toIP.length === 0) {
            let cData = this.state.ip.map((val) => {
                if(val.id === item.id)
                    if(item.fromIP.length === 0 && item.toIP.length === 0) val.isError = true;
                    else if(item.fromIP.length === 0) val.isError = "fromIP";
                    else val.isError = "toIP";
                return val;
            });
            this.setState({ ip: cData });
        }
        else if(data !== undefined && (data?.fromIP === item.fromIP && data?.toIP === item.toIP)) {
            let cData = this.state.ip.map((val) => {
                val.isEdit = false;
                val.isError = false;
                return val;
            });
            this.setState({ip : cData, add : false});
        }
        else {
            let obj = {};
            if(data === undefined) {
                obj = {
                    id: 0,
                    fromIP : item.fromIP,
                    toIP : item.toIP,
                    store_Id : this.props.selectedStore?.code,
                    isAdd : true,
                    createdBy : localStorage.getItem('loggedUserEmail'),
                    modifiedBy : "",
                }
            }          
            else {
                obj = {
                    id: item.id,
                    fromIP : item.fromIP,
                    toIP : item.toIP,
                    store_Id : item.store_Id,
                    isAdd : false,
                    createdBy : "",
                    modifiedBy : localStorage.getItem('loggedUserEmail'),
                }
            }
            trackPromise(
                this.props.action.addIP(obj).then((res) => {
                    if(res.status === true) {
                        if(data === undefined) toast.success("IP address added successfully.")
                        else toast.success("IP address updated successfully.")
                        this.loadIP();
                        this.setState({add : false});
                    }
                    else {
                        toast.error(`IP address is already assigned to store ${res.store_id}.`);
                        let val = this.state.ip;
                        val.pop();
                        this.setState({ip : val, add : false});
                    }
                }).catch(err => {
                    APIFailedMessage.displayError(err);
                })
            )
        }
    };

    const onEdit = (id) => {
      let data = this.state.ip.map((element, index) => {
        if (element.id === id) element.isEdit = true;
        else element.isEdit = false;
        return element;
      });
      data = data.filter(val => {
        return val.id !== 0;
      })
      this.setState({ ip: data, add : true});
    };

    const onDelete = (id) => {
        trackPromise(
            this.props.action.deleteIP(id).then((res) => {
                if(res.status === true) {
                    let data = this.state.ip;
                    data = data?.filter((item) => {
                        return item.id !== id;
                    });
                    this.setState({ ip: data });
                    toast.success("IP address deleted successfully.")
                }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
        )
    };

    const onAdd = () => {
      let data = {
        id: 0,
        fromIP: "",
        toIP: "",
        isEdit: true,
      };
      this.setState({ ip: [...this.state.ip, data], add: true });
    };

    return (
      <div
        className={` ${
          this.props.show ? "printSummaryPanelShow" : "printSummaryPanelHide"
        }`}
      >
        <div className="printSummaryPanel">
          <div className="container-fluid pt-4">
            <div className="row p-4">
              <div className="col-12 pl-4 pr-4">
                <h3 className="mb-3">
                  <b>Adding IP Address</b>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    className="float-right"
                    onClick={() => this.props.toogleIPAddTab("")}
                    style={{cursor:"pointer"}}
                  />
                </h3>
                <hr></hr>
              </div>
              <div className="col-12 pl-4 pr-4">
                <h4 className="mb-3 ">
                  <b>{info?.code}</b>
                  <span className="badge badge-pill bg-secondary text-primary ml-3">
                    {this.state.ip?.length}
                  </span>
                </h4>
                <h5 className="mb-3">
                  <b>Store Name : </b>
                  {info?.name}
                </h5>
                <h6 className="mb-3">
                  <b>Manager Name : </b>
                  {info?.managerName}
                </h6>
                <p className="mb-3">
                  <b>Location : </b>
                  {info?.address1 + "," + info?.address2}
                </p>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <hr></hr>
                  </div>
                  <div className="col-5 border-right">
                    <h5>
                      <b>From</b>
                    </h5>
                  </div>
                  <div className="col-5">
                    <h5>
                      <b>To</b>
                    </h5>
                  </div>
                </div>
                {this.state.ip?.map((item) => {
                  return (
                    <div className="row" key={item.id}>
                      <div className="col-12">
                        <hr></hr>
                      </div>
                      <div className="col-5 border-right">
                        <input
                          value={item.fromIP}
                          onChange={(event) => this.onChange(event)}
                          name={"fromIP_" + item.id}
                          className="form-control border"
                          disabled={!item.isEdit}
                        />
                        {item.isError === "fromIP" || item.isError === true ? <p className="mb-0 text-danger">From IP should not be empty.</p> : null}
                      </div>
                      <div className="col-5">
                        <input
                          value={item.toIP}
                          onChange={(event) => this.onChange(event)}
                          name={"toIP_" + item.id}
                          className="form-control border"
                          disabled={!item.isEdit}
                        />
                        {item.isError === "toIP" || item.isError === true ? <p className="mb-0 text-danger">To IP should not be empty.</p> : null}
                      </div>
                      <div className="col-1">
                        {item.isEdit ? (
                          ""
                        ) : (
                          <FontAwesomeIcon
                            icon={faEdit}
                            className="btn"
                            onClick={() => onEdit(item.id)}
                          />
                        )}
                      </div>
                      <div className="col-1">
                        {item.isEdit ? (
                          <FontAwesomeIcon
                            icon={faSave}
                            className="btn"
                            onClick={() => onSave(item)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faTrashAlt}
                            className="btn"
                            onClick={() => onDelete(item.id)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
                <hr></hr>
                <div className="d-flex">
                  {this.state.add ? null : (
                    <button
                      className="btn bg-secondary text-primary mx-auto"
                      onClick={() => onAdd()}
                    >
                      <b className="mr-2">+</b>ADD
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    action: bindActionCreators(AdminActions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(AddingIPAddressTab);
