import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminActions from '../../redux/actions/admin.actions';
import CustomNavbar from "../../components/component.customNavbar";
import BootstrapTable from "react-bootstrap-table-next";
import Sidebar from "../../components/component.customSideBar";
import { trackPromise } from "react-promise-tracker";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import {Card, Modal} from 'react-bootstrap';
import filterFactory, { textFilter,  customFilter, } from "react-bootstrap-table2-filter";
import "./component.stores.css";
import { toast } from "react-toastify";
import AddingIPAddressTab from "./component.addingIP";
import CSVFile from "./component.csvfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

const NoDataIndication = () => {
  return(
    <div className="mt-4">
        <p className="text-secondary font-weight-bold">No Data found </p>
    </div>
  ) 
};
 
const RemotePagination = ({
    data,
    page,
    onTableChange,
    dataField,
    order,
    columns,
    noDataFound
  }) => (
    <div>
          <div className="storesTable mt-3">
            <BootstrapTable
              className="pagination table_desktop"
              bootstrap4
              hover
              bordered={false}
              condensed={true}
              keyField="id"
              data={data}
              columns={columns}
              onTableChange={onTableChange}
              headerClasses="header-class"
              filter={filterFactory()}
              noDataIndication={noDataFound ? "" : <NoDataIndication/>}
            />
          </div>
    </div>
  );

  class CustomStatusFilter extends React.Component {
    render() {
      let opt=this.props.column.dataField==="issynchrony"?"Synchrony":this.props.column.dataField==="isfortiva"?"Fortiva":this.props.column.dataField==="isProgressive"?"Progressive":this.props.column.dataField==="isUown"?"Uown":this.props.column.dataField==="isActive"?"Active":"Daniel";
      return [
        <select
          key="select"
          ref={(node) => (this.select = node)}
          value={this.props.select_value}
          className="form-control"
          name="status"
          onChange={(e)=>{this.props.handleChange(e,this.props.column.dataField)}}
        >
          <option value="">{`Select ${opt} enabled or disabled...`}</option>:
          <option value="true">Enabled</option>
          <option value="false">Disabled</option>
        </select>,
      ];
    }
  }
class StoreList extends Component {
    constructor() {  
        super();
        this.state = {  
          storeslist:[],
          storeListCopy:[],
          isSave:false,
          dataField: "name",
          updatedStores:[],
          isAll:false,
          filterObj: {
            id: "",
            name: "",
            code: "",
            isSynchrony: ""
          },
          filterData:[],
          noDataFound:false,
          roles: [],
          site_page_details: [],
          sitePages: [],
          userRights: [],
          permission_status: '',
          toggled : false,
          isSearchDropDown: "name",
          filterValue: "",
          activeStatusChanged : false,
          showIPAddTab : false,
          selectedStore : "",
          creditDirectorPopup : false,
          synchronyNonAPI : []
        }  
    }
    async componentDidMount(){
      sessionStorage.removeItem("orgIP");
      await trackPromise(
        this.props.userRightsactions.getUserRights().then(
            response => {
                if (response) {
                    this.setState({
                        roles: response.roles,
                        site_page_details: response.sitePageDetails,
                        sitePages: response.sitePages,
                        userRights: response.userRights
                    });
                    this.getPermissions()
                }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
    )
    await trackPromise(
        this.props.adminActions.getallstoredetails()
          .then((res) => {
          if(res.length === 0 ) {
            this.setState({ noDataFound:true });
          }
          else {
            // res.forEach(item=>{
            //   if(item.isDaniels){
            //     item.disableFortiva=true;
            //     item.disableDaniel=false;
            //   }
            //   else if(item.isFortiva){
            //     item.disableFortiva=false;
            //     item.disableDaniel=true;
            //   }
            //   else{
            //     item.disableFortiva=false;
            //     item.disableDaniel=false;
            //   }
            // })
            this.setState({ storeslist: res, storeListCopy : res});
          }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      )
    }

    getPermissions() {
      let role_name = localStorage.getItem("role_name"),
        page_name = "Store List";
      const getsitepages = this.state.sitePages.filter((site_pages) => {
        return site_pages.name === "Admin Page";
      });
      const getSitepagedetails = this.state.site_page_details.filter(
        (site_page_details) => {
          return (
            site_page_details.sitePage_id === getsitepages[0].id &&
            site_page_details.name === page_name
          );
        }
      );
      const getRole = this.state.roles.filter((roles) => {
        return roles.name.toLowerCase() === role_name.toLowerCase();
      });
      let role_id = getRole[0].id;
      const role_permission_check = this.state.userRights.filter(
        (user_rights) => {
          return (
            user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
            user_rights.role_id === role_id
          );
        }
      );
      this.setState({ permission_status: role_permission_check[0] });
    }

    onChange(event,store){
      if(event.target.name.toLowerCase()==="issynchrony"){
        this.state.storeslist.forEach((item,index)=>{
          if(item.code===store.code){
            let temp_state = [...this.state.storeslist];
            let temp_element = { ...temp_state[index] };
            temp_element.isSynchronyCD=!store.isSynchronyCD;
            temp_state[index] = temp_element;
            this.setState({storeslist : temp_state,isSave:true, activeStatusChanged: false});
           }
        });
      }
      else  if(event.target.name.toLowerCase()==="isfortiva"){
        this.state.storeslist.forEach((item,index)=>{
          if(item.code===store.code){
            let temp_state = [...this.state.storeslist];
            let temp_element = { ...temp_state[index] };
            // if(event.target.checked){
            //   temp_element.disableFortiva=false;
            //   temp_element.disableDaniel=true;
            // }
            // else if(store.isDaniel){
            //   temp_element.disableFortiva=true;
            //   temp_element.disableDaniel=false;
            // }
            // else{
            //   temp_element.disableFortiva=false;
            //   temp_element.disableDaniel=false;
            // }
            temp_element.isFortiva=!store.isFortiva;
            temp_state[index] = temp_element;
            this.setState({storeslist : temp_state,isSave:true, activeStatusChanged: false});
           }
        });
      } else if(event.target.name.toLowerCase()==="isprogressive"){
        this.state.storeslist.forEach((item,index)=>{
          if(item.code===store.code){
            let temp_state = [...this.state.storeslist];
            let temp_element = { ...temp_state[index] };
            temp_element.isProgressive=!store.isProgressive;
            temp_state[index] = temp_element;
            this.setState({storeslist : temp_state,isSave:true, activeStatusChanged: false});
           }
        });
      } else if(event.target.name.toLowerCase()==="isuown"){
        this.state.storeslist.forEach((item,index)=>{
          if(item.code===store.code){
            let temp_state = [...this.state.storeslist];
            let temp_element = { ...temp_state[index] };
            temp_element.isUown=!store.isUown;
            temp_state[index] = temp_element;
            this.setState({storeslist : temp_state,isSave:true, activeStatusChanged: false});
           }
        });
      } else if(event.target.name.toLowerCase()==="isactive"){
        this.state.storeslist.forEach((item,index)=>{
          if(item.code===store.code){
            let temp_state = [...this.state.storeslist];
            let temp_element = { ...temp_state[index] };
            temp_element.isActive=!store.isActive;
            temp_state[index] = temp_element;
            this.setState({storeslist : temp_state, activeStatusChanged : true},() => {
              this.saveStores();
            });
           }
        });
      }  
      else{
        this.state.storeslist.forEach((item,index)=>{
          if(item.code===store.code){
            let temp_state = [...this.state.storeslist];
            let temp_element = { ...temp_state[index] };
            temp_element.isDaniels=!store.isDaniels;
            temp_state[index] = temp_element;
            // if(event.target.checked){
            //   temp_element.disableFortiva=true;
            //   temp_element.disableDaniel=false;
            // }
            // else if(store.isFortiva){
            //   temp_element.disableFortiva=false;
            //   temp_element.disableDaniel=true;
            // }
            // else{
            //   temp_element.disableFortiva=false;
            //   temp_element.disableDaniel=false;
            // }
            temp_element.isDaniels=!store.isDaniels;
            temp_state[index] = temp_element;
           this.setState({storeslist : temp_state,isSave:true, activeStatusChanged: false});
           }
        });
      }
    }

    onselectAll(event){
      let deepClone = JSON.parse(JSON.stringify(this.state.storeslist));
      if(event.target.checked){
        deepClone.map((ls)=>{
          ls.isSynchronyCD=true;
          // if(ls.isDaniels){
          //   ls.isFortiva=false;
          // }
          // else if(ls.isFortiva){
          //   ls.isDaniels=false;
          // }
          // else{
          //   ls.isFortiva=false;
          //   ls.isDaniels=true;
          //   ls.disableFortiva=true;
          //   ls.disableDaniel=false;
          // }
          ls.isFortiva = true;
          ls.isDaniels = true;
          ls.isProgressive = true;
          ls.isUown = true;
        })
      }
      else{
        deepClone.map((ls)=>{
          ls.isSynchronyCD=false;
          ls.isFortiva=false;
          ls.isDaniels=false;
          ls.disableFortiva=false;
          ls.disableDaniel=false;
          ls.isProgressive = false;
          ls.isUown = false;
        })
      }
      this.setState({storeslist : deepClone,isSave:true,isAll:!this.state.isAll});
    }

    synchronyNonAPIToogle() {
      let synchronyNonAPI = [];
      synchronyNonAPI = this.state.storeListCopy.filter((item) => {
        let data = this.state.storeslist.find((val) => {
          return val.id === item.id;
        });
        return (item.isSynchronyCD === true && data.isSynchronyCD === false);
      })
      if(synchronyNonAPI?.length !== 0) {
      //   let data = "";
      //   synchronyNonAPI = synchronyNonAPI?.map((item, index) => {
      //   if(index === synchronyNonAPI.length-1) {
      //     data=data+item.code+".";
      //   }
      //   else {
      //     data=data+item.code+", ";
      //   }
      // })
        // this.setState({synchronyNonAPI : data, creditDirectorPopup : !this.state.creditDirectorPopup});
        this.setState({creditDirectorPopup : !this.state.creditDirectorPopup});
      }
      else {
        this.saveStores();
      }
    }

    saveStores(){
      this.setState({creditDirectorPopup : false});
      let data=[];
      this.state.storeslist.forEach(item=>{
        let obj={
          store_Code:item.code,
          isSynchronyCD:item.isSynchronyCD,
          isFortiva:item.isFortiva,
          isDaniels:item.isDaniels,
          isProgressive:item.isProgressive,
          isUown:item.isUown,
          isActive: item.isActive,
          isStoreEnable_Disable: this.state.activeStatusChanged,
        }
        data.push(obj);
     })

      let obj={
        isFinancialAndStoreEnaDisa: data
      }
      trackPromise(
        this.props.adminActions.EnaDis_Financial_partner_and_store(obj)
          .then((res) => {
            if(res == true) {
              this.setState({ isSave:false });
              if(!this.state.activeStatusChanged) window.location.reload();
              else toast.success("Status changed successfully.");
            }
            else {
              toast.error("Error : please try after some time.");
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      )
    }
    // searchHeader = (filters, page) => {
        
    //   if(this.state.filterObj.name.length>2){
    //     let result=[];
    //     result=this.state.storeslist.filter((store)=>{
    //       return store.contains(this.state.filterObj.name);
    //     });
    //     this.setState({filterData:result});
    //     if(result.length===0) this.setState({noDataFound:true});
    //   }
    //   else{
    //     if(this.state.filterObj.code.length>2){
    //       let result=[];
    //       result=this.state.storeslist.filter((store)=>{
    //         return store.includes(this.state.filterObj.code);
    //       });
    //       this.setState({filterData:result});
    //       if(result.length===0) this.setState({noDataFound:true});
    //     }

    //   }
      
    // }
    handleToggleSidebar() {
      this.setState({
          toggled: !this.state.toggled
      });
    }
    handleSearchDropdown = (e) => {
      this.setState({ isSearchDropDown: e.target.value, filterValue: "" });
    };

    handleChangeSearchDropdown = (e) => {
      this.setState({ filterValue: e.target.value });
      if (e.target.value) {
        if(this.state.isSearchDropDown==="name"){
          if(e.target.value.length>2){
            let result=[];
            result=this.state.storeslist.filter((store)=>{
              return store.name.toLowerCase().includes(e.target.value);
            });
            this.setState({filterData:result});
            result.length===0 && this.setState({noDataFound:false});
          }
        }
        else{
          if(e.target.value.length>2){
            let result=[];
            result=this.state.storeslist.filter((store)=>{
              return store.code.includes(e.target.value);
            });
            this.setState({filterData:result});
            result.length===0 && this.setState({noDataFound:false});
          }
  
        }
       
      }
      else
      this.setState({filterData:[]});
    };

  toogleIPAddTab = (row) => {
    this.setState({showIPAddTab : !this.state.showIPAddTab, selectedStore : row });
  }
  onFileUpload= (ips,fileData)=>{
    let formData = new FormData();
    formData.append('file', fileData);
    let data={
      ipAdd:ips
    }
      trackPromise(
         this.props.adminActions.uploadCSV(data).then(
            response => {
                if (response) {
                    this.props.adminActions.uploadCSVfile(formData).then(
                      response => {
                          if (response) {
                            toast.success("File uploaded successfully.");
                              }
                      }).catch(err => {
                          APIFailedMessage.displayError(err);
                      })
                    }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
     )
  };

    render() {
      const columns = [
        {
          dataField: "isActive",
          text: "Active",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div className="form-group form-check">
              <input type="checkbox"
              className="form-check-input"
              id="isActive"
              name="isActive"
              onChange={(event) => this.onChange(event,row)}
              checked={row.isActive}
              disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
              />
          </div>
            );
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomStatusFilter
              select_value={this.state?.searchObj?.status}
              onFilter={onFilter}
              column={column}
              handleChange={handleCustomFilter}
              handleSortChange={this.handleCustomSortStatus}
              dataField={this.state.dataField}
              order={this.state.order}
            />
          ),
        },
        {
          dataField: "name",
          text: "Store Name",
          headerClasses: "input_form_control",
          filter : textFilter({
          defaultValue : null
          
          }),
          formatter: (cell, row) => {
            return (
              <div onClick={() => this.toogleIPAddTab(row)} style={{cursor:"pointer"}}>{row.name}</div>
            );
          },
        },
        {
          dataField: "code",
          text: "Store Code",
          headerClasses: "input_form_control",
          filter : textFilter({
            defaultValue : null
            
            }),
            formatter: (cell, row) => {
              return (
                <div onClick={() => this.toogleIPAddTab(row)} style={{cursor:"pointer"}}>{row.code}</div>
              );
            },
        },
        {
          dataField: "issynchrony",
          text: "Is Synchrony",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div className="form-group form-check">
              <input type="checkbox"
              className="form-check-input"
              id="isSynchrony"
              name="isSynchrony"
              onChange={(event) => this.onChange(event,row)}
              checked={row.isSynchronyCD}
              disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
              />
          </div>
            );
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomStatusFilter
              select_value={this.state?.searchObj?.status}
              onFilter={onFilter}
              column={column}
              handleChange={handleCustomFilter}
              handleSortChange={this.handleCustomSortStatus}
              dataField={this.state.dataField}
              order={this.state.order}
            />
          ),
        },
        {
          dataField: "isfortiva",
          text: "Is Fortiva",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div className="form-group form-check">
              <input type="checkbox"
              className="form-check-input"
              id="isFortiva"
              name="isFortiva"
              onChange={(event) => this.onChange(event,row)}
              checked={row.isFortiva}
              disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? row.disableFortiva? true: false : true) : true}
              />
          </div>
            );
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomStatusFilter
              select_value={this.state?.searchObj?.status}
              onFilter={onFilter}
              column={column}
              handleChange={handleCustomFilter}
              handleSortChange={this.handleCustomSortStatus}
              dataField={this.state.dataField}
              order={this.state.order}
            />
          ),
        },
        {
          dataField: "isdaniel",
          text: "Is Daniel",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div className="form-group form-check">
              <input type="checkbox"
              className="form-check-input"
              id="isDaniel"
              name="isDaniel"
              onChange={(event) => this.onChange(event,row)}
              checked={row.isDaniels}
              disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? row.disableDaniel? true: false : true) : true}
              />
          </div>
            );
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomStatusFilter
              select_value={this.state?.searchObj?.status}
              onFilter={onFilter}
              column={column}
              handleChange={handleCustomFilter}
              handleSortChange={this.handleCustomSortStatus}
              dataField={this.state.dataField}
              order={this.state.order}
            />
          ),
        },
        {
          dataField: "isProgressive",
          text: "Is Progressive",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div className="form-group form-check">
              <input type="checkbox"
              className="form-check-input"
              id="isProgressive"
              name="isProgressive"
              onChange={(event) => this.onChange(event,row)}
              checked={row.isProgressive}
              disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
              />
          </div>
            );
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomStatusFilter
              select_value={this.state?.searchObj?.status}
              onFilter={onFilter}
              column={column}
              handleChange={handleCustomFilter}
              handleSortChange={this.handleCustomSortStatus}
              dataField={this.state.dataField}
              order={this.state.order}
            />
          ),
        },
        {
          dataField: "isUown",
          text: "Is Uown",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div className="form-group form-check">
              <input type="checkbox"
              className="form-check-input"
              id="isUown"
              name="isUown"
              onChange={(event) => this.onChange(event,row)}
              checked={row.isUown}
              disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
              />
          </div>
            );
          },
          filter: customFilter(),
          filterRenderer: (onFilter, column) => (
            <CustomStatusFilter
              select_value={this.state?.searchObj?.status}
              onFilter={onFilter}
              column={column}
              handleChange={handleCustomFilter}
              handleSortChange={this.handleCustomSortStatus}
              dataField={this.state.dataField}
              order={this.state.order}
            />
          ),
        }
      ];
      const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, filters }) => {
        if (type === "filter") {
            this.setState({
              filterObj: {
                ...this.state.filterObj,
                name: filters?.name?.filterVal? filters?.name?.filterVal: "",
                code: filters?.code?.filterVal? filters?.code?.filterVal: "",
              },
            });
            //this.searchHeader(filters,page);
        }
      };

      const handleCustomFilter = (e,column) => {
        this.setState({filterObj: {...this.state.filterObj,isSynchrony: e.target.value}});
          if(e.target.value){
          if(column==="isfortiva"){
            if(e.target.value==="true"){
              let data=this.state.storeslist.filter(item=>{
                return item.isFortiva===true;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
            else{
              let data=this.state.storeslist.filter(item=>{
                return item.isFortiva===false;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
          }
          else if(column==="issynchrony"){
            if(e.target.value==="true"){
              let data=this.state.storeslist.filter(item=>{
                return item.isSynchronyCD===true;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
            else{
              let data=this.state.storeslist.filter(item=>{
                return item.isSynchronyCD===false;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
          }
          else if(column==="isProgressive"){
            if(e.target.value==="true"){
              let data=this.state.storeslist.filter(item=>{
                return item.isProgressive===true;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
            else{
              let data=this.state.storeslist.filter(item=>{
                return item.isProgressive===false;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
          }
          else if(column==="isUown"){
            if(e.target.value==="true"){
              let data=this.state.storeslist.filter(item=>{
                return item.isUown===true;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
            else{
              let data=this.state.storeslist.filter(item=>{
                return item.isUown===false;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
          }
          else if(column==="isActive"){
            if(e.target.value==="true"){
              let data=this.state.storeslist.filter(item=>{
                return item.isActive===true;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
            else{
              let data=this.state.storeslist.filter(item=>{
                return item.isActive===false;
              })
              this.setState({filterData:data,noDataFound: data.length===0&& false});
            }
          }
          else{
              if(e.target.value==="true"){
                let data=this.state.storeslist.filter(item=>{
                  return item.isDaniels===true;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
              else{
                let data=this.state.storeslist.filter(item=>{
                  return item.isDaniels===false;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
            
          }
        }
      };

      const handleCustomFilterMobile = (e,isMobileView) => {
        this.setState({filterObj: {...this.state.filterObj,isSynchrony: e.target.value}});
          if(isMobileView) this.setState({filterValue:e.target.value});
          if(e.target.value){
            if(e.target.name==="isFortiva"){
              if(e.target.value==="true"){
                let data=this.state.storeslist.filter(item=>{
                  return item.isFortiva===true;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
              else{
                let data=this.state.storeslist.filter(item=>{
                  return item.isFortiva===false;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
            }
            else if(e.target.name==="isSynchrony"){
              if(e.target.value==="true"){
                let data=this.state.storeslist.filter(item=>{
                  return item.isSynchronyCD===true;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
              else{
                let data=this.state.storeslist.filter(item=>{
                  return item.isSynchronyCD===false;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
            }
            else if(e.target.name==="isProgressive"){
              if(e.target.value==="true"){
                let data=this.state.storeslist.filter(item=>{
                  return item.isProgressive===true;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
              else{
                let data=this.state.storeslist.filter(item=>{
                  return item.isProgressive===false;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
            }
            else if(e.target.name==="isUown"){
              if(e.target.value==="true"){
                let data=this.state.storeslist.filter(item=>{
                  return item.isUown===true;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
              else{
                let data=this.state.storeslist.filter(item=>{
                  return item.isUown===false;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
            } else if(e.target.name==="isActive"){
              if(e.target.value==="true"){
                let data=this.state.storeslist.filter(item=>{
                  return item.isActive===true;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
              else{
                let data=this.state.storeslist.filter(item=>{
                  return item.isActive===false;
                })
                this.setState({filterData:data,noDataFound: data.length===0&& false});
              }
            }
            else{
                if(e.target.value==="true"){
                  let data=this.state.storeslist.filter(item=>{
                    return item.isDaniels===true;
                  })
                  this.setState({filterData:data,noDataFound: data.length===0&& false});
                }
                else{
                  let data=this.state.storeslist.filter(item=>{
                    return item.isDaniels===false;
                  })
                  this.setState({filterData:data,noDataFound: data.length===0&& false});
                }
              
            }
        }
      };

      const CardView = ({ data, handleCardSelect, activeEmail }) => {
        return (
          <>
            <hr className="mt-1 mb-1"></hr>
            <div className="row">
              {data &&
                data.map((val) => (
                  <div
                    className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                    key={val.associateEmail}
                  >
                    <Card
                      className="text-left shadow">
                      <Card.Body
                        className="m-3 pt-0" 
                        style={{ border: "2px solid #1B1B1B" }}
                      >
                        <p className="h3 ml-0 mt-2 mb-0">
                          {val.displayName === null || undefined
                            ? ""
                            : val.displayName}
                        </p>
                        <hr
                          style={
                            val.associateEmail === activeEmail
                              ? { backgroundColor: "#f8f6fa" }
                              : { backgroundColor: "#1B1B1B" }
                          }
                        ></hr>
                        <div className="row">
                          <div className="col-6">Active :</div>
                          <div className="col-6">
                            <div className="form-group form-check">
                                    <input type="checkbox"
                                    className="form-check-input"
                                    id="isActive"
                                    name="isActive"
                                    onChange={(event) => this.onChange(event,val)}
                                    checked={val.isActive}
                                    disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
                                    />
                              </div>
                          </div>
                          <div className="col-6">Store Name :</div>
                          <div className="col-6">{val.name}</div>
                          <div className="col-6">Store Code :</div>
                          <div className="col-6">{val.code}</div>
                          <div className="col-6">Is Synchrony:</div>
                          <div className="col-6">
                            <div className="form-group form-check">
                                    <input type="checkbox"
                                    className="form-check-input"
                                    id="isSynchrony"
                                    name="isSynchrony"
                                    onChange={(event) => this.onChange(event,val)}
                                    checked={val.isSynchronyCD}
                                    disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
                                    />
                              </div>
                          </div>
                          <div className="col-6">Is Fortiva:</div>
                          <div className="col-6">
                            <div className="form-group form-check">
                                    <input type="checkbox"
                                    className="form-check-input"
                                    id="isFortiva"
                                    name="isFortiva"
                                    onChange={(event) => this.onChange(event,val)}
                                    checked={val.isFortiva}
                                    disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? val.disableFortiva? true: false : true) : true}
                                    />
                              </div>
                          </div>
                          <div className="col-6">Is Daniel:</div>
                          <div className="col-6">
                            <div className="form-group form-check">
                                    <input type="checkbox"
                                    className="form-check-input"
                                    id="isDaniel"
                                    name="isDaniel"
                                    onChange={(event) => this.onChange(event,val)}
                                    checked={val.isDaniels}
                                    disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? val.disableDaniel? true: false : true) : true}
                                    />
                              </div>
                          </div>
                          <div className="col-6">Is Progressive:</div>
                          <div className="col-6">
                            <div className="form-group form-check">
                                    <input type="checkbox"
                                    className="form-check-input"
                                    id="isProgressive"
                                    name="isProgressive"
                                    onChange={(event) => this.onChange(event,val)}
                                    checked={val.isProgressive}
                                    disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
                                    />
                              </div>
                          </div>
                          <div className="col-6">Is Uown:</div>
                          <div className="col-6">
                            <div className="form-group form-check">
                                    <input type="checkbox"
                                    className="form-check-input"
                                    id="isUown"
                                    name="isUown"
                                    onChange={(event) => this.onChange(event,val)}
                                    checked={val.isUown}
                                    disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
                                    />
                              </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
            </div>
          </>
        );
      };
     
      let adminMenus = [];
        adminMenus = localStorage.getItem("sitePageDetails");
        adminMenus = JSON.parse(adminMenus);
        let storeData=[];
        if(this.state.filterValue){
          storeData=this.state.filterData;
        }
        else
          storeData=this.state.storeslist;
        return (
            <>
            {this.state.showIPAddTab && <AddingIPAddressTab show={this.state.showIPAddTab} selectedStore={this.state.selectedStore} toogleIPAddTab={this.toogleIPAddTab}/> }
            <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
                <Sidebar toggled={this.state.toggled} handleToggleSidebar={() => {this.handleToggleSidebar()}} isAdmin={true} adminMenus={adminMenus}/>
                <main>
                    <CustomNavbar handleToggleSidebar={() => { this.handleToggleSidebar()}} isAdmin={true}/>
                    <div id="main" className="container-fluid mt-3">
                        <div className="row mb-2 mt-3 pl-2 pr-2">
                        <div className="col-12">
                                <p className="h4 mb-3"><b>Bulk CSV upload IP's</b>
                                </p>
                                <p className="mb-0 bg-white command_bar">
                                  <CSVFile onFileUpload={this.onFileUpload}  />
                                </p>
                            </div><br/>
                            <div className="col-12 mt-3">
                                <p className="h4 mb-3"><b>Store List</b>
                                </p>
                                <p className="mb-0 bg-white command_bar">
                                  <div className="form-group form-check d-inline-block mt-3">
                                   <label className="mt-1 d-inline font-weight-bold h5">All</label>
                                     <input type="checkbox"
                                     className="form-check-input ml-1 mt-0"
                                     id="isSynchrony"
                                     name="isSynchrony"
                                     onChange={(event) => this.onselectAll(event)}
                                     checked={this.state.isAll}
                                     value={this.state.isAll}
                                     disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true) : true}
                                       />
                                   </div>
                                   <button
                                        className="btn bg-secondary float-right text-primary pl-4 pr-4 mt-2 mr-3"
                                        disabled={this.state.permission_status !== "" ? ((this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? this.state.isSave === false : true) : true}
                                        onClick={() => this.synchronyNonAPIToogle()}>
                                           SAVE
                                   </button></p>
                            </div>
                           
                              <div className="col-12">
                              <div id="cardrow">
                                  <p className="h4 text-secondary mb-0 mt-3 ml-2">
                                    Filter By
                                  </p>
                                  <div className="row pl-1 pr-3">
                                    <div className="col-6 mt-3 mb-2">
                                      <select
                                        value={this.state.isSearchDropDown}
                                        className="form-select pt-2 pb-2 drp"
                                        onChange={(e) => this.handleSearchDropdown(e)}
                                      >
                                        <option value="storeName">Store Name</option>
                                        <option value="storeCode">Store Code</option>
                                        <option value="isSynchrony">Is Synchrony</option>
                                        <option value="isFortiva">Is Fortiva</option>
                                        <option value="isDaniel">Is Daniel</option>
                                        <option value="isProgressive">Is Progressive</option>
                                        <option value="isUown">Is Uown</option>
                                        <option value="isActive">Active</option>
                                      </select>
                                    </div>
                                    <div className="col-6 mt-3 mb-2 form-group text-white mt-md-2 input_width ">
                                      <form className="form_pad">
                                        {this.state.isSearchDropDown === "isSynchrony" || this.state.isSearchDropDown === "isFortiva" || this.state.isSearchDropDown === "isDaniel" || this.state.isSearchDropDown === "isProgressive" || this.state.isSearchDropDown === "isUown" || this.state.isSearchDropDown === "isActive" ? (
                                          <select
                                          key="select"
                                          ref={(node) => (this.select = node)}
                                          value={this.props.select_value}
                                          className="form-control"
                                          name={this.state.isSearchDropDown}
                                          onChange={(e)=>handleCustomFilterMobile(e,true)}
                                        >
                                          <option value="">{
                                          this.state.isSearchDropDown === "isSynchrony"? "Select Synchrony enabled or disabled...":
                                          this.state.isSearchDropDown === "isFortiva"?"Select Fortiva enabled or disabled...":
                                          this.state.isSearchDropDown === "isProgressive"?"Select Progressive enabled or disabled...":
                                          this.state.isSearchDropDown === "isUown"?"Select Uown enabled or disabled...":
                                          "Select Daniel enabled or disabled..."}</option>
                                          <option value="true">Enabled</option>
                                          <option value="false">Disabled</option>
                                        </select>
                                        ) : (
                                          <input
                                            type="text"
                                            className="form-control bg-secondary text-white text_box_style"
                                            placeholder={this.state.isSearchDropDown}
                                            value={this.state.filterValue}
                                            onChange={(e) =>
                                              this.handleChangeSearchDropdown(e)
                                            }
                                          ></input>
                                        )}
                                      </form>
                                    </div>
                                  </div>
                                  <CardView
                                    data={storeData}
                                    handleCardSelect={this.handleCardSelect}
                                    activeEmail={this.state.associateEmail}
                                  />
                                  {(storeData.length===0 && this.state.filterValue.length>2) &&
                                    <Card className="mt-4">
                                      <Card.Body>
                                        <p className="text-center font-weight-bold mb-0" style={{color:"#5C068C"}}>No Data found</p>
                                      </Card.Body>
                                    </Card>}
                                </div>
                                <RemotePagination
                                    data={this.state.filterObj.isSynchrony?this.state.filterData:this.state.storeslist}
                                    dataField={this.state.dataField}
                                    onTableChange={handleTableChange}
                                    columns={columns}
                                    noDataFound={this.state.noDataFound}
                                  />
                              </div>
                        </div>
                    </div>

                  <Modal show={this.state.creditDirectorPopup}>
                    <Modal.Header>
                      <h4><b>Info</b></h4><FontAwesomeIcon className="float-right mr-2" icon={faTimesCircle} size="2x" onClick={() => {this.setState({creditDirectorPopup: !this.state.creditDirectorPopup})}}/>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                      {/* <p className="mb-1"><b>{this.state.synchronyNonAPI}</b></p> */}
                        <p className="mb-0">You are disabling the API. The Synchrony application will only be sent to Credit Department. Please contact credit for application status.</p>
                    </Modal.Body>
                    <Modal.Footer className="border-0 pt-0">
                      <button className="btn float-right bg-secondary text-primary" onClick={() => {this.saveStores()}}>OK</button>
                    </Modal.Footer>
                  </Modal>

                </main>
            </div>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),   
  };
};
export default connect(null, mapDispatchToProps)(StoreList);