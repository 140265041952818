import { Link } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent ,SubMenu } from 'react-pro-sidebar';
import danielsLogo from "../images/DanielWhite.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesLeft, faCircleUser, faClose } from '@fortawesome/free-solid-svg-icons';
import { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userRightsActions from "../redux/actions/adminUserRights.actions";
import * as adminUserListActions from '../redux/actions/adminUserList.actions';
import { trackPromise } from "react-promise-tracker";
import { Modal } from "react-bootstrap";
import Moment from 'moment';
import APIFailedMessage from "./component.apiFailedMessage";
import * as customerDetailsActions from "../redux/actions/customerDetails.actions";
import TenderModal from './component.tenderModal';
import GetInfo from './component.getInfo';
import RouteName from '../router/routeName';
import * as InsuranceActions from "../redux/actions/insurance.actions";
import * as synchronyActions from "../redux/actions/synchronyLookup.actions";
import * as adminActions from '../redux/actions/admin.actions';

class Sidebar extends Component{
    constructor() {
        super();
        this.state = {
            associateStoreIds: "0",
            roles : [],
            site_page_details: [],
            sitePages: [],
            userRights: [],
            permission_status: '',
            creditModal: false,
            accountSummaryExpand:  localStorage.getItem("subMenuExpand") && JSON.parse(localStorage.getItem("subMenuExpand")),
            appSummaryExpand:  localStorage.getItem("appSummaryExpand") && JSON.parse(localStorage.getItem("appSummaryExpand")),
            homeStore : "",
            offers : [],
            showTender : false,
            currentTender : "",
            isAssociateStore_IP:false
        }
    };

    componentDidMount() {
        sessionStorage.setItem("sidebar", true);
        sessionStorage.setItem("hamburger", false);
        sessionStorage.setItem("logout/continue",false);
        this.setState({homeStore : JSON.parse(localStorage.getItem("associateStores"))});
        trackPromise(
            this.props.userRightsactions.getUserRights().then(
                response => {
                    if(response) {
                        this.setState({
                            roles: response.roles, 
                            site_page_details: response.sitePageDetails, 
                            sitePages : response.sitePages, 
                            userRights : response.userRights
                        });
                        this.getPermissions()
                        
                  }
          }).catch(err => {
            APIFailedMessage.displayError(err);
          })
        )

        this.props.adminActions.getSignOutTime()
        .then(res => {
            if (res) {
               this.setState({ isAssociateStore_IP:res.isAssociateStore_IP });
            }
        })
        .catch(error => {
            APIFailedMessage.displayError(error);
        });
          
        
        if(!this.props.isAdmin){
            if(sessionStorage.getItem("isNewApplication") != "true") {
                let obj = localStorage.getItem("customerId");
                let isFortiva = sessionStorage.getItem("isFortiva");
                if((obj !== "null" && obj !== null && obj !== undefined && obj!=="") && (isFortiva == "true")){
                    trackPromise(
                        this.props.customerDetailsActions.getViewOfferFortiva(obj).then(
                            response => {
                                if(response){
                                    this.setState({offers : response});                              
                                }
                            }
                        ).catch(err => {
                            APIFailedMessage.displayError(err);
                        })
                    )
                }
                obj = localStorage.getItem("synchronyAppId")== null ? localStorage.getItem("magixassociateId") : localStorage.getItem("synchronyAppId");
                let ssnandzipcode = sessionStorage.getItem("ssn&zipcode");
                let filterObj = {
                    ssn:ssnandzipcode?.split(",")[0]?.replace(/-/g,""),
                    zipCode:ssnandzipcode?.split(",")[1],
                    phoneNumber:"",
                    accountNumber:"",
                    homeStore : sessionStorage.getItem("synchronyStoreCode")
                }
                // let homeStore=localStorage.getItem("associateStores")!=="null"?JSON.parse(localStorage.getItem("associateStores")):"";
                if(obj!="null" && obj!==undefined && obj!=null){
                        trackPromise(
                        this.props.synchronyLokup.getsynchronyLookup(filterObj).then(
                            response => {
                                if(response?.accountNumber) {
                                    sessionStorage.setItem("tenderSynchronyEnable", true);
                                    sessionStorage.setItem("synchronyAccountNo", response?.accountNumber);
                                } else
                                    sessionStorage.setItem("tenderSynchronyEnable", false);
                            }).catch(err => {
                                APIFailedMessage.displayError(err);
                            })
                    )
                }
                else
                    sessionStorage.setItem("tenderSynchronyEnable", false);
             }
        }
    }

  
    redirectFuncton() {
        let path_name = window.location.pathname;
        if (path_name === '/admin-user') {
            if(this.state.permission_status?.user_list) {
                
            } else  if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }  else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            } else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === '/admin-userRole') {
            if(this.state.permission_status?.user_role) {
                
            } else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            }  else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            } else {
                window.location = '/customer-lookup'
            }
        }  else if (path_name === '/admin-userRights') {
            if(this.state.permission_status?.user_rights) {
                
            } else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            }  else  if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            } else {
                window.location = '/customer-lookup'
            }
        } else if(path_name.includes('/customer-details')){
            if(this.state.permission_status?.customer_details) {
               
            } else  if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else  if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else {
                window.location = '/customer-lookup'
            }
        } else if(path_name.includes('/account-summary-daniels')){
            if(this.state.permission_status?.account_summary) {
               
            } else  if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else  if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else  if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else {
                window.location = '/customer-lookup'
            }
        } else if ( path_name === '/apply-now' ) {
            if(!this.state.permission_status?.apply_now) {
                localStorage.setItem('apply_now', false)
            } else if (this.state.permission_status?.apply_now) {
                localStorage.setItem('apply_now', true)
            } else  if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === '/view-offers') {
            if(this.state.permission_status?.view_offer) {
                 
            } else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            }  else  if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            }  else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/admin") {
            if (this.state.permission_status?.admin_settings) {
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/credit-application") {
            if(this.state.permission_status?.credit_application) {
            }
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            } 
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/application-history") {
            if(this.state.permission_status?.application_history) {
            }
            else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else if(this.state.permission_status?.account_summary) {
                window.location = '/account-summary-daniels'
            } else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/transaction-history") {
            if(this.state.permission_status?.transaction_history) {
            }
            else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else if(this.state.permission_status?.account_summary) {
                window.location = '/account-summary-daniels'
            } else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/stores") {
            if (this.state.permission_status?.store_list) {
            }
            else  if(this.state.permission_status?.admin_settings) {
                window.location = '/admin'
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === RouteName.ADMININSURANCEMATRIX) {
            if (this.state.permission_status?.insurance_matrix) {
            }
            else  if(this.state.permission_status?.admin_settings) {
                window.location = '/admin'
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        }else if (path_name === RouteName.BILLINGCYCLE) {
            if (this.state.permission_status?.billing_cycle) {
            }
            else  if(this.state.permission_status?.admin_settings) {
                window.location = '/admin'
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/admin-features") {
            if (this.state.permission_status?.admin_features) {
            }
            else  if(this.state.permission_status?.admin_settings) {
                window.location = '/admin'
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/admin-customer-details-update") {
            if (this.state.permission_status?.admin_customer_details_update) {
            }
            else  if(this.state.permission_status?.admin_settings) {
                window.location = '/admin'
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/application-req-response") {
            if (this.state.permission_status?.creditApp_reqResp) {
            }
            else if (this.state.permission_status?.admin_customer_details_update) {
                window.location = '/admin-customer-details-update'
            }
            else  if(this.state.permission_status?.admin_settings) {
                window.location = '/admin'
            }
            else  if(this.state.permission_status?.user_list) {
                window.location = '/admin-user'
            } 
            else if(this.state.permission_status?.user_role) {
                window.location = '/admin-userRole'
            }
            else  if(this.state.permission_status?.user_rights) {
                window.location = '/admin-userRights'
            }
            else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/account-summary-synchrony") {
            if(this.state.permission_status?.account_summary_synchrony) {
            }
            else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else if(this.state.permission_status?.account_summary) {
                window.location = '/account-summary-daniels'
            } else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/account-summary-progressive") {
            if(this.state.permission_status?.account_summary_progressive) {
            }
            else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else if(this.state.permission_status?.account_summary) {
                window.location = '/account-summary-daniels'
            } else {
                window.location = '/customer-lookup'
            }
        } else if (path_name === "/account-summary-uown") {
            if(this.state.permission_status?.account_summary_uown) {
            }
            else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else if(this.state.permission_status?.account_summary) {
                window.location = '/account-summary-daniels'
            } else {
                window.location = '/customer-lookup'
            }
        }else if (path_name === "/account-summary-fortiva") {
            if(this.state.permission_status?.account_summary_fortiva) {
            }
            else if(this.state.permission_status?.customer_details) {
                window.location = `/customer-details/${JSON.parse(localStorage.getItem("customerId")) !== null ? localStorage.getItem("customerId") : localStorage.getItem("magixassociateId") }`
            } else if(this.state.permission_status?.view_offer) {
                window.location = '/view-offers'
            } else if(this.state.permission_status?.apply_now) {
                window.location = '/apply-now'
            } else if(this.state.permission_status?.account_summary) {
                window.location = '/account-summary-daniels'
            } else {
                window.location = '/customer-lookup'
            }
        }
    }

    async getPermissions() {
        let role_name = localStorage.getItem('role_name')
        const getsitepages = this.state.sitePages.filter((site_pages) => { return site_pages.name === 'Admin Page' })
        const getPageDetailsArray = this.state.sitePages.length > 0 ?
            this.state.sitePages?.map((data) => {
                return this.state.site_page_details.filter((site_page_details) => { return site_page_details.sitePage_id ===data.id })
            })
        :[];
        let role_permissions = await Promise.all(getPageDetailsArray);
        const getSitepagedetails = [ ...role_permissions[0], ...role_permissions[1] ]
        // const getSitepagedetails = this.state.site_page_details.filter((site_page_details) => { return site_page_details.sitePage_id ===getsitepages[0].id })
        const getRole = this.state.roles.filter((roles) => { return roles.name?.toLowerCase() === role_name?.toLowerCase() });
        let role_id = getRole[0].id;
        let permisson_roles = {
            admin_settings: false,
            user_list: false,
            user_role: false,
            user_rights: false,
            customer_details: false,
            account_summary: false,
            account_summary_synchrony: false,
            account_summary_progressive: false,
            account_summary_uown: false,
            account_summary_fortiva:false,
            view_offer: false,
            apply_now: false,
            credit_application: false,
            application_history: false,
            transaction_history: false,
            store_list: false,
            admin_features: false,
            admin_customer_details_update: false,
            creditApp_reqResp:false,
            tender_fortiva : false,
            tender_synchrony : false,
            insurance_matrix: false,
            billing_cycle:false
        };
        const role_permissions_check_1 = getSitepagedetails.length > 0 && getSitepagedetails?.map((data) => {
            const role_permission_check = this.state.userRights.filter((user_rights) => { return user_rights.sitePageDetail_id === data.id && user_rights.role_id === role_id});
            if ( data.name === 'User List') {
                permisson_roles.user_list = role_permission_check[0]?.canView
            } else if ( data.name === 'User Role') {
                permisson_roles.user_role = role_permission_check[0]?.canView
            } else if ( data.name === 'User Rights') {
                permisson_roles.user_rights = role_permission_check[0]?.canView
            } else if ( data.name === 'Admin Settings') {
                permisson_roles.admin_settings = role_permission_check[0]?.canView
            } else if ( data.name === 'Customer Details') {
                permisson_roles.customer_details = role_permission_check[0]?.canView
            } else if ( data.name === 'Account Summary') {
                permisson_roles.account_summary = role_permission_check[0]?.canView
            } else if ( data.name === 'View Offers') {
                permisson_roles.view_offer = role_permission_check[0]?.canView
            } else if ( data.name === 'Add-On/Re-Open/Apply') {
                permisson_roles.apply_now = role_permission_check[0]?.canView
            } else if (data.name === 'Credit Application') {
                permisson_roles.credit_application = role_permission_check[0]?.canView
            } else if (data.name === 'Application History') {
                permisson_roles.application_history = role_permission_check[0]?.canView
            } else if (data.name === 'Transaction History') {
                permisson_roles.transaction_history = role_permission_check[0]?.canView
            } else if (data.name === 'Store List') {
                permisson_roles.store_list = role_permission_check[0]?.canView
            } else if (data.name === 'Features') {
                permisson_roles.admin_features = role_permission_check[0]?.canView
            } else if (data.name?.toLowerCase() === 'customer details update') {
                permisson_roles.admin_customer_details_update = role_permission_check[0]?.canView
            }else if (data.name?.toLowerCase() === 'credit application req/resp') {
                    permisson_roles.creditApp_reqResp = role_permission_check[0]?.canView
            } else if (data.name === 'Account Summary Synchrony') {
                permisson_roles.account_summary_synchrony = role_permission_check[0]?.canView
            } else if (data.name === 'Account Summary Progressive') {
                permisson_roles.account_summary_progressive = role_permission_check[0]?.canView
            } else if (data.name === 'Account Summary Uown') {
                permisson_roles.account_summary_uown = role_permission_check[0]?.canView
            }else if (data.name === 'Account Summary Fortiva') {
                permisson_roles.account_summary_fortiva = role_permission_check[0]?.canView
            }else if (data.name === 'Tender Fortiva') {
                permisson_roles.tender_fortiva = role_permission_check[0]?.canView
                sessionStorage.setItem("tenderFortivaAccess",role_permission_check[0]?.canView);
            }else if (data.name === 'Tender Synchrony') {
                permisson_roles.tender_synchrony = role_permission_check[0]?.canView
                sessionStorage.setItem("tenderSynchronyAccess",role_permission_check[0]?.canView);
            }else if (data.name === 'Daniels Autopay') {
                sessionStorage.setItem("danielsAutopayAccess",role_permission_check[0]?.canView);
            }else if (data.name === 'Insurance Matrix') {
                sessionStorage.setItem("insuranceMatrixAccess",role_permission_check[0]?.canView);
                permisson_roles.insurance_matrix = role_permission_check[0]?.canView
            }else if (data.name === 'Billing Cycle') {
                sessionStorage.setItem("billingCycleAccess",role_permission_check[0]?.canView);
                permisson_roles.billing_cycle = role_permission_check[0]?.canView
            }else if (data.name === 'Daniels E-Statement') {
                sessionStorage.setItem("danielsEStatement",role_permission_check[0]?.canView);
            }
        });

        this.setState({ permission_status: permisson_roles })
        this.redirectFuncton()
    }

    onChange = (event) => {
        let homeStore = localStorage.getItem('associateStores');
        if (event.target.id === "cmbAssociateStoreIds") {
            this.setState({homeStore : event.target.value});
            localStorage.setItem('associateStores', JSON.stringify(event.target.value));
        }
        trackPromise( 
            this.props.insuranceAction
                .getInsurancePlans()
                .then((response) => {
                if (response) {
                    var storeList = JSON.parse(sessionStorage.getItem("storeList"));
                    storeList = storeList.find((item) => {
                        return item.code == event.target.value
                    });
                    let stateCode = storeList?.address2?.split(",")[1]?.trim();
                    sessionStorage.setItem("AssociateState", stateCode);
                    let plans = response?.getInsuranceResponses?.find(item => {
                        return item.insurance_partner === "Fortiva" && item.isActive === true && item.state == stateCode;
                    });
                    if(plans!== undefined)
                        sessionStorage.setItem("havingFortivaInsurance", true)
                    else sessionStorage.setItem("havingFortivaInsurance", false)
                    plans = response?.getInsuranceResponses?.find(item => {
                        return item.insurance_partner === "Daniels" && item.isActive === true && item.state == stateCode;
                    });
                    if(plans!== undefined)
                        sessionStorage.setItem("havingDanielInsurance", true)
                    else sessionStorage.setItem("havingDanielInsurance", false)
                }
            }).catch((err) => {
                APIFailedMessage.displayError(err);
            })
        )    
    }

    onSubMenuChange(){
        this.setState({accountSummaryExpand:!this.state.accountSummaryExpand});
        localStorage.setItem("subMenuExpand",!this.state.accountSummaryExpand);
    }
    onAppSummaryExpand(){
        this.setState({appSummaryExpand:!this.state.appSummaryExpand});
        localStorage.setItem("appSummaryExpand",!this.state.appSummaryExpand);
    }

    triggerTender = (curTen) => {
            this.setState({showTender : !this.state.showTender, currentTender : curTen});
    }

    render() {
            const customerId = localStorage.getItem("customerId") ;
            var loggedUserName = localStorage.getItem("loggedUserName");
            loggedUserName = loggedUserName?.split(" ");
            let isFortiva=false;
            let fortivaOffer = undefined;
            let tenderFortivaEnable = false;
            sessionStorage.setItem("tenderFortivaEnable", false);
            let updated_fortiva_response = JSON.parse(sessionStorage.getItem("updated_fortiva_response"));
            if(updated_fortiva_response === null) {
                fortivaOffer=this.state.offers?.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
            }
            else {
                fortivaOffer=updated_fortiva_response?.data?.filter((val) => { return val.fpType?.toLowerCase() === "fortiva" })[0];
            }
            if(fortivaOffer){
                if(fortivaOffer?.tenderFortivaAccount !== null && fortivaOffer?.tenderFortivaAccount !== "null" && fortivaOffer?.tenderFortivaAccount !== "") {
                    tenderFortivaEnable = true
                    sessionStorage.setItem("tenderFortivaEnable", true);
                    sessionStorage.setItem("fortivaAccountNo", fortivaOffer?.tenderFortivaAccount);
                }
                if(fortivaOffer?.applicationStatus?.toLowerCase()==="a"){
                    if(fortivaOffer?.responseType==null && fortivaOffer?.responseTypeOutcome==null){
                        isFortiva=true;
                        sessionStorage.setItem("fortivaOffer_Accepted", false);
                    }
                    else{
                        isFortiva=false;
                        sessionStorage.setItem("fortivaOffer_Accepted", true);
                    }
                }
                else {
                    //fortiva declined or rejected or any status other than approved
                    isFortiva =false;
                    sessionStorage.setItem("fortivaOffer_Accepted", true);
                }
            }
        return (
        <>
            {
            (sessionStorage.getItem("isNewApplication") == "false" && sessionStorage.getItem("logout/continue") == "false")? (
                <ProSidebar
                    toggled={this.props.toggled}
                    onToggle={this.handleToggleSidebar}
                    breakPoint="lg"
                >
                    <SidebarHeader>
                        <Menu className="pl-4">
                            <img src={danielsLogo} alt="logo" width="100" />
                            <span className="float-right mr-3 mt-1 sidePanelClose" onClick={this.props.handleToggleSidebar}><FontAwesomeIcon icon={faAnglesLeft} /></span>
                        </Menu>
                    </SidebarHeader>
                    <SidebarContent>
                        <Menu >
                            {this.props.isAdmin?
                            <div className="pl-4 d-flex align-items-center">
                                <FontAwesomeIcon icon={faCircleUser} size="2x" className="pr-2" />
                                <div className="d-block">
                                    <b>Admin</b>
                                </div>
                            </div>:
                            <div className="pl-4 d-flex align-items-center">
                                <FontAwesomeIcon icon={faCircleUser} size="2x" className="pr-2" />
                                <div className="d-block">
                                    <b>Hello {loggedUserName[0]} !</b>
                                    <form>
                                        <small>
                                            Store
                                            {this.state.isAssociateStore_IP?
                                            <span>{JSON.parse(localStorage.getItem('associateStores'))}</span>:
                                          <select name="cmbAssociateStoreIds" id="cmbAssociateStoreIds" className="border-0 storeNo" value={this.state.homeStore} onChange={(event) => this.onChange(event)}>
                                                {JSON.parse(sessionStorage.getItem("storeList"))?.map((val) => (
                                                    <option className="selectStyle" value={val.code} > {val.code}</option>
                                                ))}
                                            </select>}
                                        </small>
                                    </form>
                                </div>
                            </div>
                            }
                            <hr></hr>
                            {this.props.isAdmin?
                            <div>
                            { sessionStorage.getItem("admin_settings_permission") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMIN} >
                                Admin Settings <Link to={RouteName.ADMIN} />
                                </MenuItem>
                                :
                                null
                            }
                            { sessionStorage.getItem("user_list_permission") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMINUSERLIST}>
                                User List <Link to={RouteName.ADMINUSERLIST} />
                                </MenuItem>
                                :
                                null
                            }
                            { sessionStorage.getItem("user_role_permission") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMINUSERROLE} >
                                User Role <Link to={RouteName.ADMINUSERROLE} />
                                </MenuItem>
                                :
                                null
                            }
                            { sessionStorage.getItem("user_rights_permission") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMINUSERRIGHTS} >
                                User Rights <Link to={RouteName.ADMINUSERRIGHTS} />
                                </MenuItem>
                                : null
                            }
                            { sessionStorage.getItem("user_creditApp_permission") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.CREDITAPPLICATIONLIST} >
                                Credit Application <Link to={RouteName.CREDITAPPLICATIONLIST} />
                                </MenuItem>
                              : null
                            }
                            { sessionStorage.getItem("store_list") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.STORESLIST} >
                                Store List <Link to={RouteName.STORESLIST} />
                                </MenuItem>
                              : null
                            }
                            { sessionStorage.getItem("features") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMINFEATURES} >
                                Features <Link to={RouteName.ADMINFEATURES} />
                                </MenuItem>
                              : null
                            }
                            { sessionStorage.getItem("customer_details_update") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMINCUSTOMERDETAILSUPDATE} title='Customer Details Update'>
                                Customer Details Update <Link to={RouteName.ADMINCUSTOMERDETAILSUPDATE} />
                                </MenuItem>
                              : null
                            }
                            { sessionStorage.getItem("creditApp_reqResp") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.APPLICATIONREQRESPONSE} title=' Credit Application Req/Resp'>
                                Credit Application Req/Resp <Link to={RouteName.APPLICATIONREQRESPONSE} />
                                </MenuItem>
                             : null
                            } 
                            { sessionStorage.getItem("insuranceMatrixAccess") === "true" ?
                                <MenuItem active={window.location.pathname === RouteName.ADMININSURANCEMATRIX} title=' Insurance Matrix'>
                                Insurance Matrix <Link to={RouteName.ADMININSURANCEMATRIX} />
                                </MenuItem>
                             : null
                            }
                            { sessionStorage.getItem("billingCycleAccess") === "true" ? 
                                <MenuItem active={window.location.pathname === RouteName.BILLINGCYCLE} title=' Billing Cycle'>
                                Billing Cycle <Link to={RouteName.BILLINGCYCLE} />
                                </MenuItem>
                             : null
                            } 
                            </div>:
                            <div>
                            { sessionStorage.getItem("customer_details_permission") === "true" ?
                                <MenuItem active={window.location.pathname === `/customer-details/${customerId}`}>
                                    Customer Details <Link to={`/customer-details/${customerId}`} />
                                </MenuItem> : null
                            }
                            { sessionStorage.getItem("account_summary_permission") === "true" ?
                                <SubMenu title="Account Summary"  open={this.state.accountSummaryExpand} onOpenChange={()=>{this.onSubMenuChange()}} popperarrow={true} >
                                    {/* {((localStorage.getItem("magixassociateId") !== "null") || (sessionStorage.getItem("magixInstoreData") !=="null" && sessionStorage.getItem("magixInstoreData") !=="") )? */}
                                    <MenuItem 
                                    active={window.location.pathname === RouteName.DANIELSACCOUNTSUMMARY}>Daniel's Credit <Link to={RouteName.DANIELSACCOUNTSUMMARY}/> </MenuItem>
                                    {/* :<MenuItem style={{backgroundColor:"#555055"}}>Daniel's Credit</MenuItem>
                                    
                                    } */}
                                    {sessionStorage.getItem("account_summary_fortiva") == "true" ?
                                    //    fortivaOffer != undefined?
                                        <MenuItem active={window.location.pathname === RouteName.FORTIVAACCOUNTSUMMARY}>Fortiva <Link to={RouteName.FORTIVAACCOUNTSUMMARY}/></MenuItem>
                                        // <MenuItem style={{backgroundColor:"#555055"}}>Fortiva</MenuItem>
                                     :null}
                                    {sessionStorage.getItem("account_summary_synchrony") == "true" ?
                                    // ((sessionStorage.getItem("isSynchrony") == "true")?
                                        <MenuItem active={window.location.pathname === RouteName.SYNCHRONYACCOUNTSUMMARY}>Synchrony Bank <Link to={RouteName.SYNCHRONYACCOUNTSUMMARY}/></MenuItem>
                                        //     :
                                        // <MenuItem style={{backgroundColor:"#555055"}} >Synchrony Bank </MenuItem>) 
                                        : null }
                                    {sessionStorage.getItem("account_summary_progressive") == "true" ?
                                    // (sessionStorage.getItem("isProgressive") == "true" ? 
                                    <MenuItem active={window.location.pathname === RouteName.PROGRESSIVEACCOUNTSUMMARY}>Progressive Leasing <Link to={RouteName.PROGRESSIVEACCOUNTSUMMARY}/></MenuItem>
                                    //  : 
                                    //  <MenuItem style={{backgroundColor:"#555055"}} >Progressive Leasing </MenuItem>) 
                                    : null }
                                    {sessionStorage.getItem("account_summary_uown") == "true" ?
                                    // (sessionStorage.getItem("isUown") == "true" ?                                     
                                    <MenuItem active={window.location.pathname === RouteName.UOWNACCOUNTSUMMARY}>Uown Leasing <Link to={RouteName.UOWNACCOUNTSUMMARY}/></MenuItem>
                                    // : 
                                    //  <MenuItem style={{backgroundColor:"#555055"}} >Uown Leasing </MenuItem>) 
                                    : null }
                                </SubMenu> : null
                            }
                            <SubMenu title="Application Summary" open={this.state.appSummaryExpand} onOpenChange={()=>{this.onAppSummaryExpand()}} popperarrow={true} >
                                { sessionStorage.getItem("view_offer_permission") === "true" ?
                                    <MenuItem active={window.location.pathname === RouteName.VIEWOFFERS}>
                                        View Offers <Link to={RouteName.VIEWOFFERS} />
                                    </MenuItem> : null
                                }
                                { sessionStorage.getItem("apphistory_permission") === "true" ?
                                    <MenuItem active={window.location.pathname===RouteName.APPLICATIONHISTORY}>
                                        Application History <Link to={RouteName.APPLICATIONHISTORY} />
                                    </MenuItem> : null                                                              
                                }
                            </SubMenu>
                            <SubMenu title="Tenders" popperarrow={true}>
                            {this.state.permission_status?.tender_fortiva ?
                                tenderFortivaEnable ? <MenuItem onClick={() => this.triggerTender("Fortiva")}>Fortiva</MenuItem> : <MenuItem style={{backgroundColor:"#555055"}} >Fortiva</MenuItem>
                            : null }

                            {this.state.permission_status?.tender_synchrony ? 
                                (sessionStorage.getItem("tenderSynchronyEnable") == "true") ? <MenuItem onClick={() => this.triggerTender("Synchrony")}>Synchrony</MenuItem> : <MenuItem style={{backgroundColor:"#555055"}} >Synchrony</MenuItem>
                            : null }
                            </SubMenu>
                            { sessionStorage.getItem("transhistory_permission") === "true" ?
                                <MenuItem active={window.location.pathname===RouteName.TRANSACTIONHISTORY}>
                                      Transaction History <Link to={RouteName.TRANSACTIONHISTORY} />
                                </MenuItem> : null                              
                            }
                             <MenuItem active={window.location.pathname === RouteName.SYNCHRONYLOOKUP}>
                                Synchrony Lookup <Link to={RouteName.SYNCHRONYLOOKUP} />
                            </MenuItem>
                            </div>}
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter className="border-top-0">
                        <p className="p-3 mb-0" style={{ background: "#400163" }}><small>&copy; Copyright 2022 Daniel's Jewelers, All Rights Reserved.</small></p>
                    </SidebarFooter>
                </ProSidebar>
            ) : ("")
        }

        <TenderModal 
            showTender = {this.state.showTender}
            triggerTender = {this.triggerTender}
            currentTender = {this.state.currentTender}
        />
    </>
  );
}
}
const mapStateToProps = state => {
    
  return {
  data: state.associateStore.associateStoreDetails
  };
};
const mapDispatchToProps = (dispatch) => {
    return {
        actions:bindActionCreators(adminUserListActions,dispatch),
        userRightsactions:bindActionCreators(userRightsActions,dispatch),
        customerDetailsActions: bindActionCreators(customerDetailsActions, dispatch),
        insuranceAction: bindActionCreators(InsuranceActions, dispatch),
        synchronyLokup: bindActionCreators(synchronyActions, dispatch),
        adminActions:bindActionCreators(adminActions,dispatch)
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);