import React, { Component } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import * as transactionHistoryActions from "../../redux/actions/transactionHistory.action";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import IdleTimeModal from "../../components/component.idleTimeModal";
import * as customerDetails from "../../redux/actions/customerDetails.actions";
import Moment from "moment";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import RemotePagination from "./component.TableView";
import { CardView } from "./component.CardView";
import CustomNavbar from "../../components/component.customNavbar";
import Loader from 'react-loader-spinner';
import Sidebar from "../../components/component.customSideBar";
import DatePicker from 'react-datepicker';
import { IMaskInput } from 'react-imask';
import "./component.transactionHistory.css";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import TitleCard from "../../components/component.titleCard";

class TransactionHistory extends Component {
  constructor() {
    super();
    this.state = {
      toggled: false,
      show: false,
      isSerarch: false,
      transactionDateList: [],
      subTableData: [],
      isLoad: false,
      isPaginate: false,
      noDataFound: false,
      searchObj: {
        fromDate:"",
        toDate:"",
        transactionType:"All",
        magixAccNo:"",
      },
      apiObj: {
        fromDate:"",
        toDate:"",
        transactionType:"All",
        magixAccNo:"",
      },
      fromDateErr : "",
      toDateErr : "",
      pageNo: 1,
      from: 1,
      to: 20,
      isFirstTime: false,
      totalRecords: undefined,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      selectedRowId : "",
      subCardtoggle: false,
      permission_status : null,
    };
  }

  handleToggleSidebar() {
    this.setState({
        toggled: !this.state.toggled
    });
};

  onChange(event) {
  if (event.target.name === "transactionType") {
      this.setState({
        searchObj: { ...this.state.searchObj, transactionType: event.target.value },
      });
    }
  else if (event.target.name === "magixAccNo") {
    this.setState({
      searchObj: { ...this.state.searchObj, magixAccNo: event.target.value },
    });
  }
  }

  onChangeFromDate(event) {
    if(event === null) {
        this.setState({
          searchObj: { ...this.state.searchObj, fromDate: "" },
        });
    }
    else {
        this.setState({
          searchObj: { ...this.state.searchObj, fromDate: event },
        });
    }
}

onChangeToDate(event) {
  if(event === null) {
      this.setState({
        searchObj: { ...this.state.searchObj, toDate: "" },
      });
  }
  else {
      this.setState({
        searchObj: { ...this.state.searchObj, toDate: event },
      });
  }
}

  handleSubmit = async (event) => {
    event.preventDefault();
    if(this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) {
    if(this.state.searchObj.toDate === "" && this.state.searchObj.fromDate !== "") {
      this.setState({toDateErr : "To Date cannot be empty.", fromDateErr : ""});
    }
    else if(this.state.searchObj.toDate !== "" && this.state.searchObj.fromDate === "") {
      this.setState({fromDateErr : "From Date cannot be empty.", toDateErr : ""});
    }
    else {
    this.setState({
      noDataFound: false,
      isSerarch: true,
      transactionDateList: [],
      subTableData: [],
      totalRecords: undefined,
      pageNo: 1,
      selectedRowId: "",
      fromDateErr : "",
      toDateErr : "",
      apiObj: {
        fromDate: (this.state.searchObj.fromDate > this.state.searchObj.toDate) ? this.state.searchObj.toDate : this.state.searchObj.fromDate,
        toDate: (this.state.searchObj.fromDate > this.state.searchObj.toDate) ? this.state.searchObj.fromDate : this.state.searchObj.toDate,
        transactionType: this.state.searchObj.transactionType,
        magixAccNo : this.state.searchObj.magixAccNo,
      },
    },() => {
      this.loadData();
    });
    }
    }
  };

  paginationWithIndex = (page) => {
    let multipleMagixId = sessionStorage.getItem("multipleMagixId");
    multipleMagixId = multipleMagixId?.split(",");
    multipleMagixId = multipleMagixId?.find((val) => {
      return this.state.apiObj?.magixAccNo === val?.split("-")[0];
    })
    let obj = {
        fromDate: this.state.apiObj.fromDate === "" ? "" : Moment(this.state.apiObj.fromDate).format("MM/DD/YYYY"),
        toDate: this.state.apiObj.toDate === "" ? "" : Moment(this.state.apiObj.toDate).format("MM/DD/YYYY"),
        transactionType: this.state.apiObj.transactionType,
        pageIndex: page,
        pageSize: 20,
        magixId:this.state.apiObj.magixAccNo,
        storeCode: multipleMagixId?.split("-")[1],
      };
    window.scrollTo(0, 0);
    trackPromise(
      this.props.actions
        .getTransactionHistory(obj)
        .then((response) => {
          if (response?.length !== 0) {
          if (response[0]?.data?.length === 0) {
            this.setState({ noDataFound: true });
          } else {
            response[0]?.data.map((item) => {
              item.magixAccNo = this.state.apiObj.magixAccNo
            })
            this.setState({
              transactionDateList: response[0].data,
              isLoad: true,
              totalRecords: response[0].totalCount,
              isPaginate: false,
              pageNo: page,
            });
          }
          } else {
            this.setState({ noDataFound: true });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  async componentDidMount() {
    if (JSON.parse(sessionStorage.getItem("isLoggedIn"))) {
      let obj = sessionStorage.getItem("ssn&dob");
            obj={
                ssn:obj?.split("&")[0],
                dob:Moment(obj?.split("&")[1]).format("MM/DD/YYYY")
            }
            await trackPromise(
              this.props.customerDetailsActions.getAllMagixAccountNumbers(obj).then(response => {
                  if(response) {
                        if(response?.length > 0) {
                          let magixID = response?.map((val) => {
                              return (val.magix_Id+"-"+val.magixStoreCode+"-"+val.currentBalance);
                          });
                          sessionStorage.setItem("multipleMagixId",magixID);
                        }
                        else {
                          localStorage.setItem("magix_account_no", "");
                          sessionStorage.setItem("multipleMagixId","");
                        }
                  }
              })
            );
      let magixAccNo = localStorage.getItem("magix_account_no"); 
      let multipleMagixId = sessionStorage.getItem("multipleMagixId");
      multipleMagixId = multipleMagixId?.split(",");
      if (magixAccNo !== "") {
        this.setState({
          searchObj : {...this.state.searchObj, magixAccNo : multipleMagixId[0]?.split("-")[0]},
          apiObj : {...this.state.apiObj, magixAccNo : multipleMagixId[0]?.split("-")[0]
        }},() => {
          this.loadData();
        })
      } 
      trackPromise(
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else this.props.history.push("/");
  }
  loadData() {
    let magixAccNo = localStorage.getItem("magix_account_no");
    let multipleMagixId = sessionStorage.getItem("multipleMagixId");
    multipleMagixId = multipleMagixId?.split(",");
    multipleMagixId = multipleMagixId?.find((val) => {
      return this.state.apiObj?.magixAccNo === val?.split("-")[0];
    })
    if(magixAccNo?.length > 0) {
      let obj =  {
        fromDate: this.state.apiObj.fromDate === "" ? "" : Moment(this.state.apiObj.fromDate).format("MM/DD/YYYY"),
        toDate: this.state.apiObj.toDate === "" ? "" : Moment(this.state.apiObj.toDate).format("MM/DD/YYYY"),
        transactionType: this.state.apiObj.transactionType,
        pageIndex: 1,
        pageSize: 20,
        magixId: this.state.apiObj.magixAccNo,
        storeCode: multipleMagixId?.split("-")[1],
      };
      trackPromise(
        this.props.actions.getTransactionHistory(obj)
          .then((response) => {
            if (response?.length !== 0) {
              if (response[0]?.data?.length === 0) {
                this.setState({ noDataFound: true });
              } else {
                response[0]?.data.map((item) => {
                  item.magixAccNo = this.state.apiObj.magixAccNo
                })
                this.setState({
                  transactionDateList: response[0].data,
                  isLoad: true,
                  totalRecords: response[0].totalCount,
                  isFirstTime: true,
                });
              }
            } else {
              this.setState({ noDataFound: true });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  }

  getPermissions() {
    let role_name = localStorage.getItem('role_name'), page_name = 'Transaction History'
    const getsitepages = this.state.sitePages.filter((site_pages) => { return site_pages.name === 'Portal Page' })
    const getSitepagedetails = this.state.site_page_details.filter((site_page_details) => { return site_page_details.sitePage_id === getsitepages[0].id && site_page_details.name === page_name })
    const getRole = this.state.roles.filter((roles) => { return roles.name.toLowerCase() === role_name.toLowerCase() });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter((user_rights) => { return user_rights.sitePageDetail_id === getSitepagedetails[0].id && user_rights.role_id === role_id });
    this.setState({ permission_status: role_permission_check[0] })
}

  GetDateFromString(date) {
    if (date) {
      return date.split(" ")[0];
    }
  }

  handleCardSelect = (id, receiptId, receiptDate) => {
    if(this.state.selectedRowId == id) {
      this.setState({subCardtoggle : !this.state.subCardtoggle, subTableData: [], selectedRowId : ""});
    }
    else {
      let obj =  {
        receiptId: receiptId,
        custId : this.state.apiObj.magixAccNo,
        rcptDt : Moment(receiptDate).format("MM-DD-YYYY")
      };
      trackPromise(
        this.props.actions.getReceiptHistory(obj)
          .then((response) => {
            if (response) {
              if (response?.length === 0) {
                this.setState({ noDataFound: true });
              } else {
                this.setState({
                  subTableData: response,
                  subCardtoggle : !this.state.subCardtoggle,
                  selectedRowId : id
                });
              }
            } else {
              this.setState({ noDataFound: true });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    }
  };

  handleRowSelect = (id, rcptId, rcptDt) => {
    if(this.state.selectedRowId == id) {
      this.setState({subCardtoggle : !this.state.subCardtoggle, subTableData: [], selectedRowId : ""});
    }
    else {
      let obj = {
        receiptId: rcptId,
        custId : this.state.apiObj.magixAccNo,
        rcptDt : Moment(rcptDt).format("MM-DD-YYYY")
      }
      // trackPromise(
        this.props.actions
          .getReceiptHistory(obj)
          .then((response) => {
            if (response) {
              if (response?.length === 0) {
                this.setState({ noDataFound: true });
              } else {
                this.setState({
                  subTableData: response,
                  selectedRowId : id,
                });
              }
            } else {
              this.setState({ noDataFound: true });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      // );
    }
  };

  render() {

    let multipleMagixId = sessionStorage.getItem("multipleMagixId");
    multipleMagixId = multipleMagixId?.split(",");

    const columns = [
      {
        dataField: "magixAccNo",
        text: "Daniel's Account",
        headerClasses: "input_form_control header0",
      },
      {
        dataField: "associateId",
        text: "Associate Id",
        headerClasses: "input_form_control header1",
      },
      {
        dataField: "rcptDt",
        text: "Receipt Date",
        headerClasses: "input_form_control header2",
        formatter: (cell, row) => {
          return (
            <div>{`${row.rcptDt === null ? "" : this.GetDateFromString(row.rcptDt)}`}</div>
          );
        },
      },
      {
          dataField: "rcptId",
          text: "Receipt Id",
          headerClasses: "input_form_control header3",
      },
      {
          dataField: "transactionType",
          text: "Transaction Type",
          headerClasses: "input_form_control",
      },
      {
          dataField: "storeNumber",
          text: "Store Number",
          headerClasses: "input_form_control",
      },
      {
        dataField: "receiptTotal",
        text: "Receipt Total",
        headerClasses: "input_form_control",
        formatter: (cell, row) => {
          return (
            <div className={`${row.receiptTotal > 0? "" : "text-danger"}`}>{`${row.receiptTotal === null ? "$ " : "$ "+row.receiptTotal.toFixed(2)}`}</div>
          );
        },
      },
      {
        dataField: "customerBal",
        text: "Customer Balance",
        headerClasses: "input_form_control",
        formatter: (cell, row) => {
          return (
            <div className={`${row.customerBal >= 0? "" : "text-danger"}`}>{`${row.customerBal === null ? "$ " : "$ "+row.customerBal.toFixed(2)}`}</div>
          );
        },
      },
      ];
	  const nextedColumns = [
        {
          dataField: "storeNumber",
          text: "Sale Site Id",
          headerClasses: "input_form_control",
          formatter: (cell, row) => {
            return (
              <div>{`${row?.storeNumber === null ? "" : row?.storeNumber}`}</div>
            );
          },
        },
        {
          dataField: "productId",
          text: "Product ID",
          headerClasses: "input_form_control",
        },
        {
          dataField: "description",
          text: "Description",
          headerClasses: "input_form_control",
        },
        {
            dataField: "quantity",
            text: "Quantity",
            headerClasses: "input_form_control",
        },
        {
            dataField: "unitAmount",
            text: "Unit Amount",
            headerClasses: "input_form_control",
            formatter: (cell, row) => {
              return (
                <div className={`${row.unitAmount >= 0? "" : "text-danger"}`}>{`${row.unitAmount === null ? "$ " : "$ "+row.unitAmount.toFixed(2)}`}</div>
              );
            },
        },
        {
            dataField: "totalAmt",
            text: "Total Amount",
            headerClasses: "input_form_control",
            formatter: (cell, row) => {
              return (
                <div className={`${row.totalAmt >= 0? "" : "text-danger"}`}>{`${row.totalAmt === null ? "$ " : "$ "+row.totalAmt.toFixed(2)}`}</div>
              );
            },
        },
        ];
   

    const handleTableChange = ( type, { page } ) => {
      if (type === "pagination") {
        this.setState({ transactionDateList: [], isPaginate: true, pageNo: page, subTableData : [], selectedRowId: ""});
        this.paginationWithIndex(page);
        this.setState({ pageNo: page });
      }
    };


    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {from}-{to} of {size}
          </div>
        </span>
      );
    };

    const expandRow = {
      renderer: row => (
        <div id='expandRow'>
          <BootstrapTable
            bordered={false}
            keyField="id"
            data={this.state.subTableData}
            columns={nextedColumns}
          />
      </div>
      ),
      showExpandColumn: true,
      expandColumnPosition: 'right',
      onlyOneExpanding: true,
      expandColumnRenderer: ({ expanded, rowKey, expandable }) => {
        if(expanded) {
          return <p>View <FontAwesomeIcon icon={faAngleUp} /></p>
        }
        if(expandable) {
          this.setState({subTableData: []});
          return <p>View <FontAwesomeIcon icon={faAngleDown} /></p>
        }
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return <p className="mt-3">View</p> 
      },
      onExpand: (row, isExpand, rowIndex, e) => {
        if(isExpand){
          this.handleRowSelect(row.id, row.rcptId, row.rcptDt);
        }
      },
    };

    const rowStyle = (row, rowIndex) => {
      // const style = {};
      // if(this.state.selectedRowId == row.id){
      //   style.backgroundColor = "#5C068C";
      //   style.color = "white";
      // }        
      // return style;
    };

    return (
      <>
        <IdleTimeModal />
        <div className={`app ${this.state.toggled ? 'toggled' : ''}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => { this.handleToggleSidebar() }}
          />
          {this.state.showSpinner ? (
            <div
              style={{
                flexDirection: "column",
              }}
              className="d-flex justify-content-center align-items-center w-100"
            >
              <Loader
                type="ThreeDots"
                color="#5C068C"
                height="100"
                width="100"
              />
            </div>
          ) : (

            <main>
              <CustomNavbar handleToggleSidebar={() => { this.handleToggleSidebar() }} currentPage={this.props.history.location.pathname} />
              <div id="main" className="container-fluid mt-3 pl-4 pr-4">
                <div className="row mb-4">
                  <TitleCard accType={"Daniel's Credit"} /> 
                  <div className="col-12">
                    <Card>
                      <Card.Body>
                        <h4><b>Transaction History</b></h4>
                        <hr></hr>
                        <form onSubmit={(event) => this.handleSubmit(event)}>
                          <div className='row'>
                            <div className='col-12 col-md-6 col-lg-2 mt-3 mt-md-0' id="transactionHistorySearchDiv">
                              <div className='form-group text-left'>
                                <label className="text-secondary font-weight-bold">Daniel's Account</label>
                                <select id="magixAccNo" name="magixAccNo" className="form-select p-2" onChange={(event) => this.onChange(event)} 
                                  disabled={(this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true}
                                >
                                  {multipleMagixId?.map((val) => {
                                    return (
                                    <option value={val?.split("-")[0]}>{val?.split("-")[0]}</option>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                            <div className='col-12 col-md-6 col-lg-2 mt-3 mt-md-0' id="transactionHistorySearchDiv">
                              <label className="text-secondary font-weight-bold">From Date (MM/DD/YYYY)</label>
                              <DatePicker
                                className="form-control border"
                                name="fromDate"
                                id="fromDate"
                                placeholderText="From Date (MM/DD/YYYY)"
                                selected={this.state.searchObj.fromDate}
                                onChange={(event) => this.onChangeFromDate(event)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                  <IMaskInput mask="00/00/0000" />
                                }
                                disabled={(this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true}
                              ></DatePicker>
                              {(this.state.fromDateErr !== "") ? <span className="text-danger">{this.state.fromDateErr}</span> : null}
                            </div>
                            <div className='col-12 col-md-6 col-lg-2 mt-3 mt-md-0' id="transactionHistorySearchDiv">
                              <label className="text-secondary font-weight-bold">To Date (MM/DD/YYYY)</label>
                              <DatePicker
                                className="form-control border"
                                name="toDate"
                                id="toDate"
                                placeholderText="To Date  (MM/DD/YYYY)"
                                selected={this.state.searchObj.toDate}
                                onChange={(event) => this.onChangeToDate(event)}
                                dateFormat="MM/dd/yyyy"
                                maxDate={new Date()}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                  <IMaskInput mask="00/00/0000" />
                                }
                                disabled={(this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true}
                              ></DatePicker>
                              {(this.state.toDateErr !== "") ? <span className="text-danger">{this.state.toDateErr}</span> : null}
                            </div>
                            <div className='col-12 col-md-6 col-lg-2 mt-3 mt-md-0' id="transactionHistorySearchDiv">
                              <div className='form-group text-left'>
                                <label className="text-secondary font-weight-bold">Transaction Type</label>
                                <select id="transactionType" name="transactionType" className="form-select p-2" onChange={(event) => this.onChange(event)}
                                  disabled={(this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true}
                                >
                                  <option value="All">All</option>
                                  {/* <option value="SALES TAX">Sales Tax</option> */}
                                  <option value="Instore transaction">Instore transaction</option>
                                  <option value="Billing Transaction">Billing Transaction</option>
                                  {/* <option value="Finance Charge">Finance Charge</option>
                                  <option value="Payment">Payment</option> */}
                                </select>
                              </div>
                            </div>
                            <div className='offset-0 col-12 offset-md-6 col-md-6 offset-lg-0 col-lg-2 mt-3 mt-md-0 d-flex' id="transactionHistorySearchDiv">
                              <button
                                className={"btn border bg-secondary text-primary form-control mt-auto searchButton w-100 " +((this.state.fromDateErr !== "" || this.state.toDateErr !== "" ) ? "mb-4" : "mb-3")}
                                onClick={(event) => this.handleSubmit(event)}
                                disabled={(this.state.permission_status?.canAdd || this.state.permission_status?.canEdit) ? false : true}
                              >
                                Search
                              </button>
                            </div>
                          </div>
                        </form>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="row mt-2 pl-1 pr-1">
                  <div className="col-12">
                    {this.state.transactionDateList.length !== 0 ? 
                    <>
                    <div id="cardrow">
                      <CardView data={this.state.transactionDateList} handleCardSelect={this.handleCardSelect} activeID={this.state.selectedRowId} subTableData={this.state.subTableData} />
                    </div>
                    <RemotePagination
                      data={this.state.transactionDateList}
                      page={this.state.pageNo}
                      sizePerPage={20}
                      firstPageText="<<"
                      totalSize={this.state.totalRecords}
                      onTableChange={handleTableChange}
                      columns={columns}
                      customTotal={customTotal}
                      rowStyle={rowStyle}
                      expandRow={expandRow}
                    />
                    </>
                    :
                    <>
                      <div className="card mt-3 mb-3 d-flex" id="cardnotfound">
                        <div className="card-body d-flex justify-content-center align-items-center">
                          {this.state.transactionDateList.length === 0 &&
                          !this.state.isPaginate ? (
                            <p className="text-secondary">
                              No data found
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
  }
                  </div>
                </div>
              </div>
            </main>
          )}
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    customerDetailsActions: bindActionCreators(customerDetails, dispatch),
    actions: bindActionCreators(transactionHistoryActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(TransactionHistory);