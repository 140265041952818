import React from 'react';

import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

function CSVReader(props) {
  return(
    <Modal show={props.show} >  
      <Modal.Header className="pb-0"><b className="text-secondary h4">Info</b><FontAwesomeIcon icon={faClose} className="btn" onClick={()=>props.handleCSVPopup()} /></Modal.Header>
        <Modal.Body className={`pb-0  text-center"}`}>
            <div>
                <p><span className='text-danger'>Note</span>: Please be mindful that the data originates from an external resource.</p>
                <hr></hr>
            </div>
        <table className="table table-bordered mb-0 w-100">
        <thead className="border-dark border-top">
          <tr>
            {props?.data?.length>0 ?
              Object.keys(props.data[0]).map((key, index) => (
                <th key={index}>{key}</th>
              )):""}
          </tr>
        </thead>
        <tbody>
            {props?.data?.length>0 ?
          props?.data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.values(row).map((value, colIndex) => (
                <td key={colIndex}>{value}</td>
              ))}
            </tr>
          )):""}
        </tbody>
      </table>
        </Modal.Body> 
        <Modal.Footer className="border-top-0 mt-1">
            <p className="btn ml-1 mr-1 mb-0 reCancel" style={{backgroundColor:"#5c068c",color:"white"}} onClick={()=>props.handleCSVPopup()}>Cancel</p>
            <p className="btn ml-1 mr-1 mb-0 reStay" style={{backgroundColor:"#5c068c",color:"white"}} onClick={()=>{props.onFileUpload()}}>Upload</p>  
        </Modal.Footer>
    </Modal>
  )
    
}

export default CSVReader;
