import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import Constants from "../../utilites/constants";

const RemotePagination = ({
  data,
  page,
  sizePerPage = Constants.LookUpPageSize,
  onTableChange,
  totalSize,
  columns,
  customTotal,
  rowStyle,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        paginationTotalRenderer: customTotal,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div className="masterCustomerTable">
         <BootstrapTable
            className="pagination"
            bootstrap4
            hover
            remote={{
              pagination: true
            }}
            bordered={false}
            condensed={true}
            keyField="customer_Id"
            noDataIndication="No record found"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            rowStyle={rowStyle}
            {...paginationTableProps}
          />
          <div className="d-flex justify-content-between">
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

export default RemotePagination;
