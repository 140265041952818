import HttpMethods from "../redux/_http.methods";
import axios from 'axios';
import Auth from "../redux/_http.services.js";
import APIFailedMessage from "../components/component.apiFailedMessage";

export class ApiWrapper {
  static LoadData(data) {
    let associatebaseURL="";
    let customerBaseUrl="";
    let magixBaseUrl="";
    let customerUrl="";
    let associateUrl="";
    let magixUrl="";
    let autoFillurl="";
    let appsettings = JSON.parse(localStorage.getItem("appsettings"));
    if (!appsettings){
      axios.get("/appsettings.json").then((response) => {
        appsettings = response.data;
        localStorage.setItem("appsettings", JSON.stringify(appsettings));
        associatebaseURL = appsettings.links.instoreportalAssociates;
        customerBaseUrl=appsettings.links.instoreportalCustomers;
        magixBaseUrl=appsettings.links.instoreportalMagix;
        autoFillurl=appsettings.links.addressAutoFillurl;
      });
    } 
    else{
      associatebaseURL = appsettings.links.instoreportalAssociates;
      customerBaseUrl = appsettings.links.instoreportalCustomers;
      magixBaseUrl = appsettings.links.instoreportalMagix;
      autoFillurl = appsettings.links.addressAutoFillurl;
    }
    associateUrl = associatebaseURL + data.ResourceUrl;
    customerUrl = customerBaseUrl + data.ResourceUrl;
    magixUrl = magixBaseUrl + data.ResourceUrl;
    autoFillurl = autoFillurl + data.ResourceUrl;
    switch (data.MethodType) {
      case HttpMethods.Get():
        if (data) {
          if(data.anonymouseApi && data.isAssociate){
            return axios.get(`${associateUrl}`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.anonymouseApi && data.isCustomer){
            return axios.get(`${customerUrl}`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.thirdPartyApi){
            return axios.get(`${data.ResourceUrl}`)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.isAssociate){
            return Auth.axiosMethod().get(associateUrl)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.isMagix){
            return Auth.axiosMethod().get(magixUrl)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.isAutoFill){
            return axios.get(`${autoFillurl}`)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          else{
          return Auth.axiosMethod().get(customerUrl)
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
        }
        break;
      case HttpMethods.Post():
        if (data.Resource) {
          if(data.isAssociate) {
            return Auth.axiosMethod().post(associateUrl, data.Resource )
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.isMagix) {
            return Auth.axiosMethod().post(magixUrl, data.Resource )
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          if(data.isCSV) {
            return Auth.axiosMethod().post(customerUrl, data.Resource, { headers: { 'Content-Type': 'multipart/form-data' } } )
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }

          else {
            return Auth.axiosMethod().post(customerUrl, data.Resource )
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      case HttpMethods.Put():
        if (data.Resource) {
          if(data.isAssociate) {
            return Auth.axiosMethod().put(associateUrl, data.Resource )
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          else {
            return Auth.axiosMethod().put(customerUrl, data.Resource )
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      case HttpMethods.Delete():
        if (data) {
          if(data.isAssociate) {
            return Auth.axiosMethod().delete(associateUrl, data.Resource )
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              APIFailedMessage.displayError(error);
            });
          }
          else {
            return Auth.axiosMethod().delete(customerUrl, data.Resource )
              .then((res) => {
                return res.data;
              })
              .catch((error) => {
                APIFailedMessage.displayError(error);
              });
          }
        }
        break;
      default:
        break;
    }
  }
}