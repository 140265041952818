import { ApiWrapper } from "../../api/thunk.helper"; //storeNumber.actions.js
import HttpMethods from "../../redux/_http.methods";

export function updateMasterCustomer_Record(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/customers/Update-Customer",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}