import { ApiWrapper } from "../../api/thunk.helper"; //storeNumber.actions.js
import HttpMethods from "../../redux/_http.methods";

// export function getBillingCycles() {
//   return function (dispatch) {
//     const data = {
//       MethodType: HttpMethods.Post(),
//       Resource: "",
//       ResourceUrl: "v1/customers/get-All_billing_cycle_data",
//       isAssociate:true
//     };
//     return ApiWrapper.LoadData(data)
//       .then((response) => {
//         if (response) {
//           return response;
//         } else {
//           return response;
//         }
//       })
//       .catch((error) => {
//         throw error;
//       });
//   };
// }
export function getBillingCycles() {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: "",
      ResourceUrl: "v1/customers/get-All_billing_cycle_data",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function saveBillingCycle(cycleObj) {
    return function (dispatch) {
        const data = {
            MethodType: HttpMethods.Post(),
            Resource: cycleObj,
            ResourceUrl: "v1/customers/update-billing-data",
           
        };
        return ApiWrapper.LoadData(data)
            .then((response) => {
                if (response) {
                    return response;
                } else {
                    return response;
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export function getBillingCycle(cycleObj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Get(),
          Resource: cycleObj,
          ResourceUrl: `v1/customers/get-billing-data?createdDate=${cycleObj.createdDate}&application_id=${cycleObj.application_id}`,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}