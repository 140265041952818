export const CADisclosure = (partnerType, toPrint) => {
    return ("<table class='print-component'>" +
        "<tbody>" +
        "<tr>" +
        "<td>" +
        "<div class='mt-3'>" +
        "<h4 class='text-center' style='text-align:center !important'>CREDIT INSURANCE PRE-PURCHASE AND SUMMARY OF COVERAGES DISCLOSURE</h4>" +
        "<p class='mb-0'><b><u>Gold Plan includes: Life, Disability, Involuntary Unemployment and Property Insurance</u></b></p>" +
        "<p class='mb-0'><b><u>Silver Plan includes: Life, Disability and Property Insurance</u></b></p>" +
        "<p><b><u>Bronze Plan includes: Property Insurance</u></b></p>" +
        "<p class='mb-0'><b>Insurers:</b> Minnesota Life Insurance Company, Securian Casualty Company</p>" +
        "<p class='mb-0'><b>Administrator:</b>  Summit Administrators, Inc. 800-275-3414</p>" +
        (partnerType === 'Fortiva' ? 
        "<p class='mb-0'><b>Creditor:</b> Bank of Missouri as issuer of credit arranged by Fortiva Retail Credit on behalf of Daniel's Jewelers</p>" : 
        "<p class='mb-0'><b>Creditor:</b> Daniel’s Jewelers</p>"
        ) +
        "<p class='mb-0'><b>Agent License Number: 0F23531</b></p>" +
        "<ul>" +
        "<li>This disclosure is required by California law, and is not part of the credit insurance contract. <u>Read your policy of certificate for contract provisions.</u></li>" +
        "<li>The purchase of credit insurance is optional, and is <u>not required in order to obtain the loan or credit approval.</u></li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Eligibility</b></h5>" +
        "<ul>" +
        "<li><b>Life, Disability and Involuntary Unemployment.</b> You are eligible for insurance if you have not reached your 65th birthday. You are eligible for Disability and Involuntary Unemployment insurance as to each advance only if you are working for wages or profit 30 hours a week for Disability or 24 hours a week for Involuntary Unemployment on the date you sign for the advance. If you are not, that particular advance will not be insured until you return to work. Loss of income due to retirement is not covered.</li>" +
        (partnerType === 'Fortiva' ? 
        "<li><b>Property.</b> There are no eligibility conditions for property other than having a loan from the Creditor.</li>" : "<li><b>Property.</b> There are no eligibility conditions for property other than having a loan from Daniel’s.</li>"
        ) +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Life Benefit – Gold and Silver</b></h5>" +
        "<ul>" +
        "<li><b>Life Insurance.</b> If you (or if you have joint life, your co-borrower) die while you are insured for life coverage we will pay the amount due on the loan (excluding unearned finance charges) at the time of your death after we receive proof of your death up to the Maximum Amount of Life Insurance. Only one death benefit is payable under the policy.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Disability Benefit – Gold and Silver</b></h5>" +
        "<ul>" +
        (partnerType === 'Fortiva' ?
            "<li><b>Total Disability Insurance. If you are insured for total</b> disability insurance, we will pay a benefit if you file written proof that you became totally disabled while insured and continue to be totally disabled for more than 14 days. Payment will be calculated from the 1st day of disability. Payments will stop when you are not totally disabled anymore or when your loan with the Creditor is paid off, whichever comes first. The benefit for each day of disability to be compensated will be 1/30th of your required monthly loan payment or the Maximum Monthly Disability Benefit, whichever is less.</li>"
            :
            "<li><b>Total Disability Insurance. If you are insured for total</b> disability insurance, we will pay a benefit if you file written proof that you became totally disabled while insured and continue to be totally disabled for more than 14 days. Payment will be calculated from the 1st day of disability. Payments will stop when you are not totally disabled anymore or when your loan with Daniel’s is paid off, whichever comes first. The benefit for each day of disability to be compensated will be 1/30th of your required monthly loan payment or the Maximum Monthly Disability Benefit, whichever is less.</li>"
        ) +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Involuntary Unemployment Benefit – Gold</b></h5>" +
        "<ul>" +
        (partnerType === 'Fortiva' ?
            "<li><b>Involuntary Unemployment Insurance.</b> We will pay your required monthly payment on Your account if you are Involuntarily unemployed for more than 30 days. Benefits will be paid retroactively to the first day. Payments will stop when you no longer involuntarily unemployed or when your loan with the Creditor is paid off.</li>"
            :
            "<li><b>Involuntary Unemployment Insurance.</b> We will pay your required monthly payment on Your account if you are Involuntarily unemployed for more than 30 days. Benefits will be paid retroactively to the first day. Payments will stop when you no longer involuntarily unemployed or when your loan with Daniel’s is paid off.</li>"
        ) +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Property Benefit – All Plans</b></h5>" +
        "<ul>" +
        "<li><b>Property Insurance.</b> In the event of a loss, our liability will be the lesser of our cost to repair or replace the property with material of like kind or quality or the amount of the original loan (limit of liability). We will endeavor to replace property that is the same or nearly identical to the property that is lost, but we are not required to do so and may substitute property of like kind or quality.</li>" +
        "</ul>" + "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>When Insurance Stops or Termination – All Plans</b></h5>" +
        "<ul>" +
        "<li><b>Single or Joint Life, Disability and Involuntary Unemployment.</b> This insurance stops: on the first billing date after the creditor receives your written request to stop the insurance; or on the first billing date after you withdraw your authorization for the addition of charges for the insurance to your loan; or on the first billing date after you reach your 65th birthday (this does not apply if you stated in your application that you are 65 or older and we do not refund your premium within 75 days of the Effective Date); or on the date your loan stops; or on the billing date upon which your account has any portion of any payment 3 months or more past due; or on the date the Group Policy stops.</li>" +
        "<li><b>Credit Property - Termination.</b> Coverage ends when the first of the following occurs: the date the loan is paid off; or the first billing date upon which your account has any portion of any payment 3 months or more past due; 36 months from the date of purchase; or when your account is terminated.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Exclusions – All Plans</b></h5>" +
        "<ul>" +
        "<li><b>Life.</b> We will not pay a claim for an advance on your loan if you commit suicide within 6 months after the date of the advance, but we will return the life insurance charge on that advance. This also applies to your co-borrower, if you applied for joint life coverage.</li>" +
        "<li><b>Total Disability.</b> We will not pay a claim or refund the insurance charge if your disability is a result of normal pregnancy or childbirth; or intentionally self-inflicted injury.</li>" +
        "<li><b>Involuntary Unemployment.</b> We will not pay a claim or refund the insurance charge if your unemployment is due to seasonal shut-down; discharge from active military service; strike, lockout or labor dispute; voluntary forfeiture of income; resignation; retirement; disability; willful misconduct; or nuclear or natural disaster of any kind.</li>" +
        "<li><b>Property.</b> Coverage will not be provided when loss or damage is: due to frost, cold weather, ice, snow or sleet; due to rain, snow, sand, or dust unless the building where the property is kept is first damaged by a covered peril; from wear, tear, or normal usage; from mechanical or electrical breakdown; to any form of money, notes, or manuscripts; to TV or radio antennas; due to nuclear or radioactivity; from a result of war-like action by any government or military force.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Conditions or Other Limitations – All Plans</b></h5>" +
        "<p class='ml-4 pl-3'>You are insured only for advances actually received by you. You are not insured for any unused credit which may be available to you. Only the Primary Borrower is eligible for disability and involuntary unemployment insurance. Coverage will not apply to purchases made while You are receiving benefits under the Certificate.</p>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Property Perils – All Plans</b></h5>" +
        "<ul>" +
        "<li><b>Property Perils.</b> We will insure against loss or damage to property caused by: fire or lightning; windstorm or hail; explosion; riot or civil disturbance; aircraft or vehicles; smoke; flood; collapse of building; bursting, burning or freezing of any appliance except water heaters; robbery or burglary with evidence of forced entry.</li>" +
        "</ul>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Options for All Programs – All Plans</b></h5>" +
        "<ul>" +
        "<li><b>Available coverage options.</b> If you qualify, you may purchase life coverage with disability; and/or involuntary unemployment and/or property coverage together as a package.</li>" +
        "<li><b>Cancellation and refunds or credits.</b> You may cancel this insurance and receive a full refund within the first 30 days after receiving your policy or certificate. If you cancel after the 30 days, any unearned premium will be refunded in accordance with applicable law.</li>" +
        "<li><b>Other Insurance.</b> If you have other life, disability, involuntary unemployment or property insurance that covers the same risk insured by the credit insurance being offered, you may not want or need to purchase this insurance.</li>" +
        (
            partnerType === 'Fortiva' ?
                "<li><b>Insurance Evaluation.</b> The Creditor is not qualified or authorized to evaluate the adequacy of your existing coverages.</li>"
                : "<li><b>Insurance Evaluation.</b> Daniel’s is not qualified or authorized to evaluate the adequacy of your existing coverages.</li>"
        ) +
        "<li><b>Finance charges.</b> If any portion of the premium for this insurance is financed, it will be subject to finance charges at the rate applicable to your loan.</li>" +
        "</ul>" +
        "</div>" +
        (
            toPrint === true ? propertyValueToPrint(partnerType) : propertyValueToEmail(partnerType)
        ) +
        "</td >" +
        "</tr >" +
        "</tbody >" +
        "<tfoot class='table-footer'>" +
        "<tr>" +
        "<td class='d-flex'>" +
        "<span >N2389-0708</span> <span class='mx-auto'>N2389 (DAN) 1110</span>  <span>Form: DAN14-241</span>" +
        "</td>" +
        "</tr>" +
        "</tfoot>" +
        "</table >" 
    );
}

const propertyValueToPrint = (partnerType) => {
    return (
        "<div class='mt-3'>" +
        "<h5 class='border p-1'><b>Price, Signatures and Notice – All Plans per $100 per Month times your outstanding balance</b></h5>" +
        "<div class='container pl-5 pr-5 mt-3'>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Life Single:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.047</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Life Joint:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.073</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Disability:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.047</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Involuntary Unemployment:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.721</b></p></div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'><b>Property:</b></div>" +
        "<div class='col-6'><p class='border-bottom'><b>$0.462</b></p></div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<div class='container'>" +
        "<div class='row'>" +
        "<div class='col-6 border'>PROPOSED INSURED SIGNATURE<p></p></div>" +
        "<div class='col-6 border'>DATE<p></p></div>" +
        "<div class='col-6 border'>PROPOSED JOINT INSURED<p></p></div>" +
        "<div class='col-6 border'>DATE<p></p></div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<h5 class='text-center mb-0' style='text-align:center !important'>NOTICE</h5>" +
        "<p>Your satisfaction is very important to us. If you should have a question about your certificate, if you should need assistance with a problem, or if you wish to file a claim, you should first contact Daniel’s Jewelers or the insurance company issuing your certificate. When contacting the insurance company issuing your certificate, please call " + (partnerType === 'Fortiva' ? '1-800-275-3414' : '1-800-873-6102') + " for assistance or when filing a claim.</p>" +
        "<p>Should you feel you are not being treated fairly with respect to a claim, you may contact the California Department of Insurance with your complaint at their Consumer Hotline 1-800-927-4357. Please refer to your certificate for additional details.</p>" +
        "<p class='ml-4'>IT IS IMPORTANT TO US THAT YOU ARE SATISFIED WITH THIS POLICY AND THE SERVICE YOU RECEIVE FROM US.</p>" +
        "<p class='ml-5 mr-5'>IF YOU HAVE AN UNRESOLVED COMPLAINT, THE CALIFORNIA INSURANCE DEPARTMENT SUGGESTS THAT YOU NOTIFY THEIR CONSUMER AFFAIRS OFFICE. CONTACT SHOULD BE MADE ONLY AFTER COMMUNICATIONS BETWEEN YOU AND US (THE AGENT OR OTHER REPRESENTATIVE) HAVE FAILED TO PRODUCE A SATISFACTORY SOLUTION TO THE PROBLEM.</p>" +
        "<p class='text-center mb-0' style='text-align:center !important'>CONTACT: DANIEL’S CUSTOMER SERVICE AT 310-665-2110</p>" +
        "<p class='text-center mb-0' style='text-align:center !important'>OR</p>" +
        "<p class='text-center mb-0' style='text-align:center !important'>MINNESOTA LIFE INSURANCE COMPANY OR SECURIAN CASUALTY COMPANY</p>" +
        "<div class='container pl-5 pr-5 mt-3'>" +
        "<div class='row'>" +
        "<div class='col-6'>HOME OFFICE</div>" +
        "<div class='col-6'>ADMINISTRATOR</div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'>400 ROBERT STREET NORTH</div>" +
        "<div class='col-6'>110 WEST ROSAMOND</div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'>ST. PAUL, MN 55101-2098</div>" +
        "<div class='col-6'>HOUSTON TX 77076</div>" +
        "</div>" +
        "<div class='row'>" +
        "<div class='col-6'>651-665-3500</div>" +
        "<div class='col-6'>800-275-3414</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<p class='text-center' style='text-align:center !important'>QUESTIONS ABOUT THIS NOTICE OR ANY UNRESOLVED COMPLAINT MAY BE DIRECTED TO:</p>" +
        "<div class='d-flex flex-column justify-content-center align-items-center'>" +
        "<div>" +
        "<p class='mb-0'>DEPARTMENT OF INSURANCE</p>" +
        "<p class='mb-0'>CONSUMER AFFAIRS DEPARTMENT</p>" +
        "<p class='mb-0'>300 SOUTH SPRING STREET</p>" +
        "<p class='mb-0'>LOS ANGELES, CA 90013</p>" +
        "<p class='mb-0'>213-897-8921</p>" +
        "<p class='mb-0'>TOLL FREE TELEPHONE FOR CALIFORNIA ONLY:</p>" +
        "<p class='mb-0'>800-927-4357</p>" +
        "<p class='mb-0'>OFFICE HOURS: 9 A.M. TO 5 P.M.</p>" +
        "</div>" +
        "</div>" +
        " <p class='text-center mt-3' style='text-align:center !important'>THIS NOTICE PROVIDES CONTACT INFORMATION ONLY AND IS NOT A CONDITION OF THE POLICY.</p>" +
        "</div>"
    )
}


const propertyValueToEmail = (partnerType) => {
    return (
        "<div class='mt-3 mb-4'>" +
        "<h5 class='border p-1'><b>Price, Signatures and Notice – All Plans per $100 per Month times your outstanding balance</b></h5>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        "<td><b>Life Single:</b></td>" +
        "<td><b><u>$0.047</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Life Joint:</b></td>" +
        "<td><b><u>$0.073</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Disability:</b></td>" +
        "<td><b><u>$0.047</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Involuntary Unemployment:</b></td>" +
        "<td><b><u>$0.721</u></b></td>" +
        "</tr>" +
        "<tr>" +
        "<td><b>Property:</b></td>" +
        "<td><b><u>$0.462</u></b></td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<table>" +
        "<tbody>" +
        "<tr>" +
        "<td>PROPOSED INSURED SIGNATURE<p></p></td>" +
        "<td>DATE<p></p></td>" +
        "</tr>" +
        "<tr>" +
        "<td>PROPOSED JOINT INSURED<p></p></td>" +
        "<td>DATE<p></p></td>" +
        "</tr>" +
        "</tbody>" +
        "</table>" +
        "</div>" +
        "<div class='mt-3'>" +
        "<p class='text-center' style='text-align:center !important'>NOTICE</p>" +
        "<p>Your satisfaction is very important to us. If you should have a question about your certificate, if you should need assistance with a problem, or if you wish to file a claim, you should first contact Daniel’s Jewelers or the insurance company issuing your certificate. When contacting the insurance company issuing your certificate, please call " + (partnerType === 'Fortiva' ? '1-800-275-3414' : '1-800-873-6102') + " for assistance or when filing a claim.</p>" +
        "<p>Should you feel you are not being treated fairly with respect to a claim, you may contact the California Department of Insurance with your complaint at their Consumer Hotline 1-800-927-4357. Please refer to your certificate for additional details.</p>" +
        "<p>IT IS IMPORTANT TO US THAT YOU ARE SATISFIED WITH THIS POLICY AND THE SERVICE YOU RECEIVE FROM US.</p>" +
        "<p>IF YOU HAVE AN UNRESOLVED COMPLAINT, THE CALIFORNIA INSURANCE DEPARTMENT SUGGESTS THAT YOU NOTIFY THEIR CONSUMER AFFAIRS OFFICE. CONTACT SHOULD BE MADE ONLY AFTER COMMUNICATIONS BETWEEN YOU AND US (THE AGENT OR OTHER REPRESENTATIVE) HAVE FAILED TO PRODUCE A SATISFACTORY SOLUTION TO THE PROBLEM.</p>" +
        "<p class='text-center mb-0' style='text-align:center !important'>CONTACT: DANIEL’S CUSTOMER SERVICE AT 310-665-2110</p>" +
        "<p class='text-center mb-0' style='text-align:center !important'>OR</p>" +
        "<p class='text-center mb-0' style='text-align:center !important'>MINNESOTA LIFE INSURANCE COMPANY OR SECURIAN CASUALTY COMPANY</p>" +
        "<table>"+
        "<tbody>"+
        "<tr>"+
        "<td>HOME OFFICE</td>"+
        "<td>ADMINISTRATOR</td>"+
        "</tr>"+
        "<tr>"+
        "<td>400 ROBERT STREET NORTH</td>"+
        "<td>110 WEST ROSAMOND</td>"+
        "</tr>"+
        "<tr>"+
        "<td>ST. PAUL, MN 55101-2098</td>"+
        "<td>HOUSTON TX 77076</td>"+
        "</tr>"+
        "<tr>"+
        "<td>651-665-3500</td>"+
        "<td>800-275-3414</td>"+
        "</tr>"+
        "</tbody>"+
        "</table>"+
        "</div>"+
        "<div class='mt-3'>" +
        "<p class='text-center' style='text-align:center !important'>QUESTIONS ABOUT THIS NOTICE OR ANY UNRESOLVED COMPLAINT MAY BE DIRECTED TO:</p>" +
        "<div class='d-flex flex-column justify-content-center align-items-center'>" +
        "<div>" +
        "<p class='mb-0' style='text-align:center !important'>DEPARTMENT OF INSURANCE</p>" +
        "<p class='mb-0' style='text-align:center !important'>CONSUMER AFFAIRS DEPARTMENT</p>" +
        "<p class='mb-0' style='text-align:center !important'>300 SOUTH SPRING STREET</p>" +
        "<p class='mb-0' style='text-align:center !important'>LOS ANGELES, CA 90013</p>" +
        "<p class='mb-0' style='text-align:center !important'>213-897-8921</p>" +
        "<p class='mb-0' style='text-align:center !important'>TOLL FREE TELEPHONE FOR CALIFORNIA ONLY:</p>" +
        "<p class='mb-0' style='text-align:center !important'>800-927-4357</p>" +
        "<p class='mb-0' style='text-align:center !important'>OFFICE HOURS: 9 A.M. TO 5 P.M.</p>" +
        "</div>" +
        "</div>" +
        " <p class='text-center mt-3' style='text-align:center !important'>THIS NOTICE PROVIDES CONTACT INFORMATION ONLY AND IS NOT A CONDITION OF THE POLICY.</p>" +
        "</div>"
    )
}
