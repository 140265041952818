import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faCircleXmark,
  faTrash,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Card, Toast, ToastContainer } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authActions from "../../redux/actions/authActions";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
  customFilter,
} from "react-bootstrap-table2-filter";
import CustomNavbar from "../../components/component.customNavbar";
import Sidebar from "../../components/component.customSideBar";
import "./component.adminUserRole.css";
import * as adminUserRoleActions from "../../redux/actions/adminUserRole.actions";
import { trackPromise } from "react-promise-tracker";
import * as userRightsActions from "../../redux/actions/adminUserRights.actions";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import ConfirmDialog from "../../components/component.confirmDialog";
import Constants from "../../utilites/constants";

const NoDataIndication = () => (
  <div className="mt-4">
    <p className="mb-0 pt-0 text-secondary">No data found</p>
  </div>
);

const RemotePagination = ({
  data,
  page,
  sizePerPage = 10,
  totalSize,
  onTableChange,
  dataField,
  order,
  columns,
  selectRow,
  rowClasses,
  customTotal,
  noDataFound,
}) => (
  <div>
    <PaginationProvider
      pagination={paginationFactory({
        custom: true,
        page,
        sizePerPage,
        totalSize,
        paginationTotalRenderer: customTotal,
      })}
    >
      {({ paginationProps, paginationTableProps }) => (
        <div className="userRoleTable mt-3">
          <BootstrapTable
            className="pagination table_desktop"
            bootstrap4
            hover
            remote={{
              sort: true,
              pagination: true,
              filter: true,
            }}
            bordered={false}
            condensed={true}
            keyField="id"
            data={data}
            columns={columns}
            onTableChange={onTableChange}
            selectRow={selectRow}
            rowClasses={rowClasses}
            filter={filterFactory()}
            sort={{ dataField: dataField, order: order }}
            headerClasses="header-class"
            noDataIndication={noDataFound ? () => <NoDataIndication /> : null}
            {...paginationTableProps}
          />

          <div className="d-flex justify-content-between">
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
        </div>
      )}
    </PaginationProvider>
  </div>
);

class CustomStatusFilter extends React.Component {
  render() {
    return [
      this.props.dataField === "status" && this.props.order === "desc" ? (
        <span
          style={{ cursor: "pointer" }}
          className="sort_desc"
          onClick={this.props.handleSortChange}
        />
      ) : this.props.dataField === "status" && this.props.order === "asc" ? (
        <span
          style={{ cursor: "pointer" }}
          className="sort_asc"
          onClick={this.props.handleSortChange}
        />
      ) : (
        <span
          style={{ cursor: "pointer" }}
          className="sort_asc_desc"
          onClick={this.props.handleSortChange}
        />
      ),
      <select
        key="select"
        ref={(node) => (this.select = node)}
        value={this.props.select_value}
        className="form-control"
        name="status"
        onChange={this.props.handleChange}
      >
        <option value="">Select Status...</option>
        <option value="Active">Active</option>
        <option value="InActive">InActive</option>
      </select>,
    ];
  }
}
class UserRole extends Component {
  constructor() {
    super();
    this.state = {
      searchObj: {
        name: "",
        status: "",
      },
      formObj: {
        role_id: "",
        name: "",
        status: "",
      },
      isEditRoleOpen: false,
      isAddRoleOpen: false,
      isEditButton: false,
      rowId: "",
      pageNo: 1,
      from: 1,
      to: 10,
      isPaginate: false,
      roleList: [],
      noDataFound: false,
      isFirstTime: false,
      isSort: true,
      isLoad: false,
      isSearchDropDown: "name",
      totalRoles: undefined,
      isSelectedRoleName: "",
      addToast: false,
      editToast: false,
      dataField: "name",
      order: "asc",
      respSuccess: false,
      roles: [],
      site_page_details: [],
      sitePages: [],
      userRights: [],
      permission_status: "",
      errorWarning: null,
      isDeleteOpen: false,
      isModalDeleteOpen: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem("isLoggedIn") === "true") {
      this.setState({
        roleList: [],
        totalRoles: undefined,
        pageNo: 1,
        rowId: "",
      });
      const colName =
        this.state.dataField === "name" ? "rolename" : this.state.dataField;
      let obj = {
        pageIndex: 1,
        pageSize: Constants.userPageSize,
        sortBy: colName,
        sortOrder: this.state.order,
      };
      localStorage.removeItem("role_status");
      trackPromise(
        this.props.actions
          .getUserRole(obj)
          .then((response) => {
            if (response) {
              if (response.data.length === 0) {
                this.setState({
                  noDataFound: true,
                  roleList: [],
                  totalRoles: 0,
                });
              }
              this.setState({
                roleList: response.data,
                isLoad: true,
                totalRoles: response.totalCount,
                isFirstTime: true,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          }),
        this.props.userRightsactions
          .getUserRights()
          .then((response) => {
            if (response) {
              this.setState({
                roles: response.roles,
                site_page_details: response.sitePageDetails,
                sitePages: response.sitePages,
                userRights: response.userRights,
              });
              this.getPermissions();
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else this.props.history.push("/");
  }

  getPermissions() {
    let role_name = localStorage.getItem("role_name"),
      page_name = "User Role";
    const getsitepages = this.state.sitePages.filter((site_pages) => {
      return site_pages.name === "Admin Page";
    });
    const getSitepagedetails = this.state.site_page_details.filter(
      (site_page_details) => {
        return (
          site_page_details.sitePage_id === getsitepages[0].id &&
          site_page_details.name === page_name
        );
      }
    );
    const getRole = this.state.roles.filter((roles) => {
      return roles.name.toLowerCase() === role_name.toLowerCase();
    });
    let role_id = getRole[0].id;
    const role_permission_check = this.state.userRights.filter(
      (user_rights) => {
        return (
          user_rights.sitePageDetail_id === getSitepagedetails[0].id &&
          user_rights.role_id === role_id
        );
      }
    );
    this.setState({ permission_status: role_permission_check[0] });
  }

  sort_status_custom = (dataField, order) => {
    let obj = {
      filter1_RoleName: this.state.searchObj.name,
      filter2_Status: this.state?.searchObj?.status,
      pageIndex: 1,
      pageSize: Constants.userPageSize,
      sortBy: dataField,
      sortOrder: order,
    };
    trackPromise(
      this.props.actions
        .getUserRole(obj)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
          }
          this.setState({
            roleList: response.data,
            isLoad: true,
            totalRoles: response.totalCount,
            isPaginate: false,
          });
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  handleCustomSortStatus = () => {
    let order;
    if (
      this.state.dataField === "rolename" ||
      this.state.dataField === "name"
    ) {
      order = "desc";
      this.setState({ dataField: "status", order: "desc", pageNo: 1 });
      this.sort_status_custom("status", order);
    } else if (
      this.state.dataField === "status" &&
      this.state.order === "desc"
    ) {
      order = "asc";
      this.setState({ dataField: "status", order: "asc", pageNo: 1 });
      this.sort_status_custom("status", order);
    } else if (
      this.state.dataField === "status" &&
      this.state.order === "asc"
    ) {
      order = "desc";
      this.setState({ dataField: "status", order: "desc", pageNo: 1 });
      this.sort_status_custom("status", order);
    }
  };

  handleEditChange = (e) => {
    this.setState({
      formObj: { ...this.state.formObj, [e.target.name]: e.target.value },
    });
  };

  handleChange = (e) => {
    this.setState({
      searchObj: { ...this.state.searchObj, [e.target.name]: e.target.value },
    });
  };

  handleOpenAddRole = () => {
    this.setState({ isAddRoleOpen: true, formObj: { name: null } });
  };

  handleOpenEditRole = (row) => {
    const getEditableROwValue = this.state.roleList.filter(
      (data) => data.id === this.state.rowId
    );
    this.setState({
      formObj: {
        name: getEditableROwValue[0].name,
        status: getEditableROwValue[0].status_Id === 43 ? "active" : "inactive",
      },
      isSelectedRoleName: getEditableROwValue[0].name,
    });
    this.setState({ isEditRoleOpen: true });
  };

  handleCardSelect = (id) => {
    this.setState({ isEditButton: true, isDeleteOpen: true, rowId: id });
  };

  handleSaveRole = (e) => {
    e.preventDefault();
      let obj = {
        id: this.state.rowId,
        name:
          this.state.formObj.name === this.state.isSelectedRoleName
            ? null
            : this.state.formObj.name,
        createdBy: localStorage.loggedUserEmail,
        status: this.state.formObj.status === "active" ? 43 : 44,
      };
      trackPromise(
        this.props.actions
          .addAndEditUserRole(obj)
          .then((response) => {
            if (response.requestStatus === "success") {
              this.setState({
                isEditRoleOpen: false,
                editToast: !this.state.editToast,
                responseMsg: "User updated successfully!",
                respSuccess: true,
                formObj: { name: null },
                rowId: null,
                isEditButton: false,
                isDeleteOpen: false,
              });
              this.paginationWithIndex(this.state.pageNo);
            } else {
              this.setState({
                isEditRoleOpen: false,
                editToast: !this.state.editToast,
                responseMsg: response.errorMessage,
                respSuccess: false,
                formObj: { name: null },
                rowId: null,
                isEditButton: false,
                isDeleteOpen: false,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
  };

  handleSearchDropdown = (e) => {
    this.setState({ isSearchDropDown: e.target.value });
  };

  handleSearchRoleName = (e) => {
    e.preventDefault();
    this.setState({
      searchObj: { ...this.state.searchObj, name: e.target.value },
    });
    let obj = {
      filter1_RoleName: e.target.value,
      filter2_Status: this.state.searchObj?.status
        ? this.state.searchObj?.status
        : null,
      pageIndex: this.state.pageNo,
      pageSize: Constants.userPageSize,
    };
    trackPromise(
      this.props.actions
        .getUserRole(obj)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
          } else {
            this.setState({
              noDataFound: false,
              roleList: response.data,
              isLoad: true,
              totalRoles: response.totalCount,
              isPaginate: false,
              pageNo: this.state.pageNo,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  handleChangeSearchDropdown = (e) => {
    this.setState({
      searchObj: { ...this.state.searchObj, [e.target.name]: e.target.value },
    });
    if (this.state.isSearchDropDown === "name") {
      const colName =
        this.state.dataField === "name" ? "rolename" : this.state.dataField;
      let obj = {
        filter1_RoleName: e.target.value,
        filter2_Status: this.state.searchObj?.status
          ? this.state.searchObj?.status
          : null,
        pageIndex: 1,
        pageSize: Constants.userPageSize,
        sortBy: colName,
        sortOrder: this.state.order,
      };
      trackPromise(
        this.props.actions
          .getUserRole(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
            } else {
              this.setState({
                noDataFound: false,
                roleList: response.data,
                isLoad: true,
                totalRoles: response.totalCount,
                isPaginate: false,
                pageNo: 1,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else if (this.state.isSearchDropDown === "status") {
      const colName =
        this.state.dataField === "name" ? "rolename" : this.state.dataField;
      localStorage.setItem("role_status", e.target.value);
      let obj = {
        filter2_Status: e.target.value ? e.target.value : null,
        filter1_RoleName: this.state.searchObj?.name
          ? this.state.searchObj?.name
          : null,
        pageIndex: 1,
        pageSize: Constants.userPageSize,
        sortBy: colName,
        sortOrder: this.state.order,
      };
      trackPromise(
        this.props.actions
          .getUserRole(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
            } else {
              this.setState({
                noDataFound: false,
                roleList: response.data,
                isLoad: true,
                totalRoles: response.totalCount,
                isPaginate: false,
                pageNo: 1,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else if (e.target.value === 0) {
      this.paginationWithIndex(this.state.pageNo);
    }
  };

  handleCustomFilter = (e) => {
    const colName =
      this.state.dataField === "name" ? "rolename" : this.state.dataField;
    this.setState({
      searchObj: { ...this.state.searchObj, status: e.target.value },
    });
    localStorage.setItem("role_status", e.target.value);
    let obj = {
      filter1_RoleName: this.state.searchObj?.name
        ? this.state.searchObj?.name
        : null,
      filter2_Status: e.target.value ? e.target.value : null,
      pageIndex: 1,
      pageSize: Constants.userPageSize,
      sortBy: colName,
      sortOrder: this.state.order,
    };
    trackPromise(
      this.props.actions
        .getUserRole(obj)
        .then((response) => {
          if (response.data.length === 0) {
            this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
          } else {
            this.setState({
              noDataFound: false,
              roleList: response.data,
              isLoad: true,
              totalRoles: response.totalCount,
              isPaginate: false,
              pageNo: 1,
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  selectOptions = {
    Active: "Active",
    InActive: "InActive",
  };

  columnFunction = () => {
    return [
      {
        dataField: "name",
        text: "Role Name",
        sort: true,
        onSort: (field, order) => {
          this.setState({ isSort: true });
        },
        headerClasses: "table_header_class input_form_control",
        filter: textFilter({
          placeholder: null,
          defaultValue: this.state?.searchObj?.name
            ? this.state?.searchObj?.name
            : null,
        }),
      },
      {
        dataField: "status",
        text: "Status",
        headerClasses: "table_header_class input_form_control",
        filter: customFilter(),
        filterRenderer: (onFilter, column) => (
          <CustomStatusFilter
            select_value={this.state?.searchObj?.status}
            onFilter={onFilter}
            column={column}
            handleChange={this.handleCustomFilter}
            handleSortChange={this.handleCustomSortStatus}
            dataField={this.state.dataField}
            order={this.state.order}
          />
        ),
      },
    ];
  };

  columns = [
    {
      dataField: "name",
      text: "Role Name",
      sort: true,
      onSort: (field, order) => {
        this.setState({ isSort: true });
      },
      headerClasses: "table_header_class",
      filter: textFilter({
        placeholder: "Role Name",
        defaultValue: this.state?.searchObj?.name
          ? this.state?.searchObj?.name
          : null,
      }),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      onSort: (field, order) => {
        this.setState({ isSort: true });
      },
      headerClasses: "table_header_class",
      filter: selectFilter({
        options: this.selectOptions,
      }),
    },
  ];

  handleAddChange = (e) => {
    let data = e.target.value.trim("");
    this.setState({ formObj: { name: data } });
    this.setState({ errorWarning: "" });
  };

  handleAddRole = (event) => {
    event.preventDefault();
    this.setState({ errorWarning: "" });
    if (this.state.formObj.name) {
      let obj = {
        name: this.state.formObj.name,
        createdBy: localStorage.loggedUserEmail,
      };
      trackPromise(
        this.props.actions
          .addAndEditUserRole(obj)
          .then((response) => {
            if (response.requestStatus === "success") {
              this.setState({
                responseMsg: "User added successfully!",
                respSuccess: true,
                addToast: !this.state.addToast,
                name: null,
              });
              this.paginationWithIndex(this.state.pageNo);
              this.setState({ isAddRoleOpen: false });
            } else {
              this.setState({
                errorWarning: response.errorMessage,
                name: null,
              });
            }
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    } else {
      this.setState({ errorWarning: "Role name is required" });
    }
  };

  handleModalExit = () => {
    this.setState({ isModalDeleteOpen: false });
  };
  handleDeleteOpen = () => {
    const getDeleteRowValue = this.state.roleList.filter(
      (data) => data.id === this.state.rowId
    );
    this.setState({
      formObj: {
        name: getDeleteRowValue[0].name,
        status: getDeleteRowValue[0].status_Id === 43 ? "active" : "inactive",
        row_id: getDeleteRowValue[0].id,
      },
      isSelectedRoleName: getDeleteRowValue[0].name,
    });
    this.setState({ isModalDeleteOpen: true });
  };

  handleDelete = (event) => {
    event.preventDefault();
    let obj = {
      role_id: this.state.formObj.row_id,
    };
    trackPromise(
      this.props.actions
        .deleteRole(obj)
        .then((response) => {
          if (response.requestStatus === "success") {
            this.setState({
              isDeleteOpen: false,
              editToast: !this.state.editToast,
              responseMsg: "User Deleted successfully!",
              respSuccess: true,
              isModalDeleteOpen: false,
              isEditButton: false,
              rowId: null,
              formObj: { name: null },
            });
            this.paginationWithIndex(this.state.pageNo);
          } else {
            this.setState({
              isDeleteOpen: false,
              editToast: !this.state.editToast,
              responseMsg: response.errorMessage,
              respSuccess: false,
              isModalDeleteOpen: false,
              isEditButton: false,
              rowId: null,
              formObj: { name: null },
            });
          }
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  handleToggleSidebar() {
    this.setState({ toggled: !this.state.toggled });
  }

  paginationWithIndex = (page, filters) => {
    const colName =
      this.state.dataField === "name" ? "rolename" : this.state.dataField;
    let obj = {
      filter1_RoleName: this.state.searchObj?.name
        ? this.state.searchObj?.name
        : null,
      filter2_Status: this.state.searchObj?.status,
      pageIndex: page,
      pageSize: Constants.userPageSize,
      sortBy: colName,
      sortOrder: this.state.order,
    };
    window.scrollTo(0, 0);
    trackPromise(
      this.props.actions
        .getUserRole(obj)
        .then((response) => {
          this.setState({
            roleList: response.data,
            isLoad: true,
            totalRoles: response.totalCount,
            isPaginate: false,
            pageNo: page,
          });
        })
        .catch((err) => {
          APIFailedMessage.displayError(err);
        })
    );
  };

  render() {
    let adminMenus = [];
    adminMenus = localStorage.getItem("sitePageDetails");
    adminMenus = JSON.parse(adminMenus);
    const sortingPage = (page) => {
      const colName =
        this.state.dataField === "name" ? "rolename" : this.state.dataField;
      let obj = {
        filter1_RoleName: this.state.searchObj.name,
        filter2_Status: this.state?.searchObj?.status,
        pageIndex: 1,
        pageSize: Constants.userPageSize,
        sortBy: colName,
        sortOrder: this.state.order,
      };
      trackPromise(
        this.props.actions
          .getUserRole(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
            }
            this.setState({
              roleList: response.data,
              isLoad: true,
              totalRoles: response.totalCount,
              isPaginate: false,
            });
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const sortingOnCards = (sortOrder, SortBy) => {
      const colName =
        this.state.dataField === "name" ? "rolename" : this.state.dataField;
      let obj = {
        filter1_RoleName: this.state.searchObj.name,
        filter2_Status: this.state.searchObj.status,
        pageIndex: 1,
        pageSize: Constants.userPageSize,
        sortBy: colName,
        sortOrder: sortOrder,
      };
      trackPromise(
        this.props.actions
          .getUserRole(obj)
          .then((response) => {
            if (response.data.length === 0) {
              this.setState({ noDataFound: true, roleList: [], totalRoles: 0 });
            }
            this.setState({
              roleList: response.data,
              isLoad: true,
              totalRoles: response.totalCount,
              isPaginate: false,
            });
          })
          .catch((err) => {
            APIFailedMessage.displayError(err);
          })
      );
    };

    const customTotal = (from, to, size) => {
      return (
        <span className="react-bootstrap-table-pagination-total ml-0 mt-3">
          <div className="bg-white pl-2 pr-2 pt-1 pb-1 rounded">
            {from}-{to} of {size}
          </div>
        </span>
      );
    };

    const selectRow = {
      clickToSelect: true,
      onSelect: (row) => {
        this.setState({
          formObj: {
            name: row.name,
            status: row.status_Id === 43 ? "active" : "inactive",
            role_id: row.id,
          },
          isSelectedRoleName: row.name,
        });
        this.setState({
          isEditButton: true,
          isDeleteOpen: true,
          rowId: row.id,
        });
      },
      onSelectAll: this.handleOnSelectAll,
    };

    const rowClasses = (row, rowIndex) => {
      let classes = null;
      if (row.id === this.state.rowId) {
        classes = "selected_row_class";
      }
      return classes;
    };

    const onValueChange = (e) => {
      if (e.target.value === "asc") {
        this.setState({ order: "asc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else if (e.target.value === "desc") {
        this.setState({ order: "desc", pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      } else {
        this.setState({ dataField: e.target.value, pageNo: 1 }, () => {
          sortingOnCards(this.state.order, this.state.dataField);
        });
      }
    };

    const CardView = ({ data, handleCardSelect, activeID }) => {
      return (
        <>
          <hr className="mt-1 mb-1"></hr>
          <div className="row">
            {data &&
              data.map((val) => (
                <div
                  className="col-sm-12 col-md-6 pt-2 pb-2 d-flex"
                  key={val.id}
                >
                  <Card
                    className="text-left shadow"
                    style={{ width: "100%" }}
                    onClick={(id) => handleCardSelect(val.id)}
                  >
                    <Card.Body
                      className={`m-3 ${
                        val.id === activeID ? "bg-secondary text-primary" : ""
                      }`}
                      style={{ border: "2px solid #1B1B1B" }}
                    >
                      <p className="h3">{val.name}</p>
                      <hr
                        style={
                          val.id === activeID
                            ? { backgroundColor: "#f8f6fa" }
                            : { backgroundColor: "#1B1B1B" }
                        }
                      ></hr>
                      <div className="row">
                        <div className="col-6">Status :</div>
                        <div className="col-6">{val.status}</div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        </>
      );
    };

    const searchHeader = (filters, page) => {
      const colName =
        this.state.dataField === "name" ? "rolename" : this.state.dataField;
      let obj = {
        filter1_RoleName: filters?.name ? filters.name.filterVal : null,
        filter2_Status: this.state.searchObj.status,
        pageIndex: page,
        pageSize: Constants.userPageSize,
        sortBy: colName,
        sortOrder: this.state.order,
      };
      if (!obj.filter1_RoleName && !obj.filter2_Status) {
        this.paginationWithIndex(this.state.pageNo);
      } else {
        trackPromise(
          this.props.actions
            .getUserRole(obj)
            .then((response) => {
              if (response.data.length === 0) {
                this.setState({
                  noDataFound: true,
                  roleList: [],
                  totalRoles: 0,
                });
              }
              this.setState({
                roleList: response.data,
                isLoad: true,
                totalRoles: response.totalCount,
                isPaginate: false,
                pageNo: page,
              });
            })
            .catch((err) => {
              APIFailedMessage.displayError(err);
            })
        );
      }
    };

    const handleTableChange = (
      type,
      { page, sizePerPage, sortField, sortOrder, filters }
    ) => {
      if (type === "pagination") {
        this.setState({ roleList: [], isPaginate: true, isSort: false });
        this.paginationWithIndex(page);
        this.setState({ pageNo: page });
      } else if (type === "sort") {
        this.setState({ isFirstTime: false });
        if (this.state.isSort && !this.state.isFirstTime) {
          this.setState({ dataField: sortField, order: sortOrder, pageNo: 1 });
          setTimeout(() => {
            sortingPage(page);
          }, 1000);
        }
      } else if (type === "filter") {
        if (filters?.name) {
          this.setState({
            searchObj: {
              ...this.state.searchObj,
              name: filters?.name?.filterVal,
            },
          });
        } else {
          this.setState({ searchObj: { ...this.state.searchObj, name: "" } });
        }
        if (filters?.name?.filterVal.length > 1) {
          searchHeader(filters, page);
        } else {
          this.paginationWithIndex(1, filters);
        }
      }
    };

    return (
      <>
        <div
          className={
            this.state.isEditRoleOpen
              ? " RolePanelShow"
              : this.state.isAddRoleOpen
              ? " RolePanelShow"
              : "RolePanelHide "
          }
        >
          {this.state.isEditRoleOpen && (
            <div className="RolePanel">
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col-12 pl-4 pr-4">
                    <p>
                      <span className="h5">
                        <b> Edit Role </b>
                      </span>
                      <span
                        className="btn closeIcon"
                        onClick={() => this.setState({ isEditRoleOpen: false })}
                      >
                        <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                      </span>
                    </p>
                    <form>
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-5 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                          <label>Role Name</label>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 pt-2">
                          <input
                            type="text"
                            className="form-control border "
                            name="name"
                            id="name"
                            value={this.state.formObj?.name}
                            onChange={this.handleEditChange}
                            disabled
                          ></input>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-5 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                          <label>Status</label>
                        </div>
                        <div className="col-12 col-md-4 col-lg-5 pt-2">
                          <select
                            defaultValue={this.state.formObj?.status}
                            className="form-control dropdown "
                            name="status"
                            id="status"
                            onChange={this.handleEditChange}
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-4 mb-4">
                          <div className="row">
                            <div className="col-12 col-md-4 col-lg-5">
                              <button
                                className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                                onClick={() =>
                                  this.setState({ isEditRoleOpen: false })
                                }
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-12 col-md-4 col-lg-5">
                              <button
                                className="btn bg-secondary text-primary w-100"
                                onClick={this.handleSaveRole}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.isAddRoleOpen && (
            <div className="RolePanel">
              <div className="container-fluid pt-4">
                <div className="row">
                  <div className="col-12 pl-4 pr-4">
                    <p>
                      <span className="h5">
                        <b> Add Role </b>
                      </span>
                      <span
                        className="btn closeIcon"
                        onClick={() =>
                          this.setState({
                            isAddRoleOpen: false,
                            errorWarning: "",
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faCircleXmark} size="2x" />
                      </span>
                    </p>
                    <form>
                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-5 font-weight-bold pt-2 text-secondary d-flex align-items-center">
                          <label>Role Name</label>
                        </div>
                        <div className="form-group col-12 col-md-4 col-lg-5 pt-2">
                          <input
                            className="form-control-Synchrony border-dark-Synchrony"
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Role Name"
                            onChange={this.handleAddChange}
                          ></input>
                          {this.state.errorWarning ? (
                            <span className="warning">
                              {this.state.errorWarning}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 mt-4 mb-4">
                          <div className="row">
                            <div className="col-12 col-md-4 col-lg-5">
                              <button
                                className="btn bg-primary text-secondary w-100 mb-3 mb-md-0 mb-lg-0"
                                onClick={() =>
                                  this.setState({ isAddRoleOpen: false })
                                }
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-12 col-md-4 col-lg-5">
                              <button
                                className="btn bg-secondary text-primary w-100"
                                onClick={this.handleAddRole}
                                disabled={this.state.formObj.name?false:true}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`app ${this.state.toggled ? "toggled" : ""}`}>
          <Sidebar
            toggled={this.state.toggled}
            handleToggleSidebar={() => {
              this.handleToggleSidebar();
            }}
            isAdmin={true}
            adminMenus={adminMenus}
          />
          <main>
            <CustomNavbar
              handleToggleSidebar={() => {
                this.handleToggleSidebar();
              }}
              isAdmin={true}
            />
            <ToastContainer className="p-3" position="top-end">
              <Toast
                bg={this.state.respSuccess ? "success" : "danger"}
                onClose={() => {
                  this.setState({ editToast: !this.state.editToast });
                }}
                show={this.state.editToast}
                delay={3000}
                animation
                autohide
              >
                <Toast.Body className="text-white ml-2 mr-2">
                  {this.state.responseMsg}
                </Toast.Body>
              </Toast>
            </ToastContainer>
            <ToastContainer className="p-3" position="top-end">
              <Toast
                bg={this.state.respSuccess ? "success" : "danger"}
                onClose={() => {
                  this.setState({ addToast: !this.state.addToast });
                }}
                show={this.state.addToast}
                delay={3000}
                animation
                autohide
              >
                <Toast.Body className="text-white ml-2 mr-2">
                  {this.state.responseMsg}
                </Toast.Body>
              </Toast>
            </ToastContainer>
            <div>
              <div className="row mr-3 ml-3">
                <div className="col-12 mt-4">
                  <div
                    className="row ml-2 mr-3"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p className="h4 ml-2 ml-md-0 text-left">
                      <b>User Role</b>
                    </p>
                  </div>
                  <div className="row m-1 pl-1 pr-1 mb-2">
                    {!this.state.permission_status?.canAdd &&
                    !this.state.permission_status.canEdit &&
                    !this.state.permission_status.canDelete ? null : (
                      <div className="col-12 mt-4 command_bar">
                        {this.state.permission_status?.canAdd ? (
                          <button
                            className="add_button"
                            onClick={() => this.handleOpenAddRole()}
                          >
                            <FontAwesomeIcon icon={faAdd} className="mr-1" />
                            Add
                          </button>
                        ) : null}
                        {this.state.permission_status?.canEdit ? (
                          <button
                            className={
                              this.state.isEditButton
                                ? "edit_button"
                                : "edit_button_unselect"
                            }
                            disabled={this.state.isEditButton ? false : true}
                            onClick={() => this.handleOpenEditRole()}
                          >
                            <FontAwesomeIcon icon={faEdit} className="mr-1" />
                            Edit
                          </button>
                        ) : null}
                        {this.state.permission_status?.canDelete ? (
                          <button
                            className={
                              this.state.isDeleteOpen
                                ? "edit_button"
                                : "edit_button_unselect"
                            }
                            disabled={this.state.isDeleteOpen ? false : true}
                            onClick={() => this.handleDeleteOpen()}
                          >
                            <FontAwesomeIcon icon={faTrash} className="mr-1" />
                            Delete
                          </button>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <>
                    <div id="cardrow">
                      <p className="h4 text-secondary mb-0 mt-3 ml-2">
                        Sort By
                      </p>
                      <div className="row pl-1 pr-3">
                        <div className="col-6 mt-3 mb-2">
                          <select
                            value={this.state.dataField}
                            className="form-select pt-2 pb-2"
                            onChange={(e) => onValueChange(e)}
                          >
                            <option value="name">Role Name</option>
                            <option value="status">Status</option>
                          </select>
                        </div>
                        <div className="col-3 mt-3 mb-2">
                          <select
                            value={this.state.order}
                            className="form-select pt-2 pb-2"
                            style={{ width: "100%" }}
                            onChange={(e) => onValueChange(e)}
                          >
                            <option value="asc">ASC</option>
                            <option value="desc">DESC</option>
                          </select>
                        </div>
                      </div>
                      <p className="h4 text-secondary mb-0 mt-3 ml-2 mobile_card_view">
                        Filter By
                      </p>
                      <div className="row pl-1 pr-3 mobile_card_view">
                        <div className="col-6 mt-3 mb-2">
                          <select
                            value={this.state.isSearchDropDown}
                            className="form-select pt-2 pb-2"
                            onChange={(e) => this.handleSearchDropdown(e)}
                          >
                            <option value="name">Role Name</option>
                            <option value="status">Status</option>
                          </select>
                        </div>
                        <div className="col-6 mt-3 mb-2 form-group text-white mt-md-2 input_width ">
                          <form className="form_pad">
                            {this.state.isSearchDropDown === "name" ? (
                              <input
                                type="text"
                                className="form-control bg-secondary text-white text_box_style"
                                name={
                                  this.state.isSearchDropDown === "name"
                                    ? "name"
                                    : "status"
                                }
                                id={
                                  this.state.isSearchDropDown === "name"
                                    ? "name"
                                    : "status"
                                }
                                placeholder={
                                  this.state.isSearchDropDown === "name"
                                    ? "name"
                                    : "status"
                                }
                                onChange={(e) =>
                                  this.handleChangeSearchDropdown(e)
                                }
                                value={
                                  this.state.isSearchDropDown === "name"
                                    ? this.state.searchObj.name
                                    : this.state.searchObj.status
                                }
                              />
                            ) : (
                              <select
                                value={this.state.searchObj.status}
                                name="status"
                                className="form-select pt-2 pb-2"
                                style={{ width: "100%" }}
                                onChange={(e) =>
                                  this.handleChangeSearchDropdown(e)
                                }
                              >
                                <option value="">Choose a Status</option>
                                <option value="Active">Active</option>
                                <option value="InActive">InActive</option>
                              </select>
                            )}
                          </form>
                        </div>
                      </div>
                      <CardView
                        data={this.state.roleList}
                        handleCardSelect={this.handleCardSelect}
                        activeID={this.state.rowId}
                      />
                    </div>
                    <RemotePagination
                      data={this.state.roleList}
                      page={this.state.pageNo}
                      from={this.state.from}
                      to={this.state.to}
                      sizePerPage={10}
                      firstPageText="<<"
                      totalSize={this.state.totalRoles}
                      onTableChange={handleTableChange}
                      dataField={this.state.dataField}
                      order={this.state.order}
                      columns={this.columnFunction()}
                      customTotal={customTotal}
                      selectRow={selectRow}
                      rowClasses={rowClasses}
                      filter={filterFactory()}
                      noDataFound={this.state.noDataFound}
                    />
                    {this.state.noDataFound === true && (
                      <div id="no_found_table">
                        <div className="userRoleTable mt-3">
                          <div className="react-bootstrap-table">
                            <table id="resulttable" className="header-class">
                              <thead>
                                <tr className="header-class">
                                  <th
                                    className="selection-cell-header"
                                    data-row-selection="true"
                                  ></th>
                                  <th
                                    tabindex="0"
                                    aria-label="Role Name sort asc"
                                    className="sortable table_header_class"
                                  >
                                    Role Name
                                    <span className="caret-4-asc"></span>
                                    <label
                                      className="filter-label"
                                      for="text-filter-column-name"
                                    >
                                      <span className="sr-only">
                                        Filter by Role Name
                                      </span>
                                      <input
                                        name="name"
                                        delay="500"
                                        type="text"
                                        id="text-filter-column-name"
                                        className="filter text-filter form-control undefined"
                                        placeholder="Role Name"
                                        value={this.state.searchObj.name}
                                        onChange={(e) =>
                                          this.handleChangeSearchDropdown(e)
                                        }
                                      />
                                    </label>
                                  </th>
                                  <th
                                    tabindex="0"
                                    aria-label="Status sortable"
                                    className="sortable table_header_class"
                                  >
                                    Status
                                    <span className="order-4"></span>
                                    <label
                                      className="filter-label"
                                      for="select-filter-column-status"
                                    >
                                      <span className="sr-only">
                                        Filter by Status
                                      </span>
                                      <select
                                        value={this.state.searchObj.status}
                                        name="status"
                                        onChange={(e) =>
                                          this.handleChangeSearchDropdown(e)
                                        }
                                        id="select-filter-column-status"
                                        className="filter select-filter form-control  placeholder-selected"
                                      >
                                        <option value="" selected="">
                                          Select Status...
                                        </option>
                                        <option value="Active">Active</option>
                                        <option value="InActive">
                                          InActive
                                        </option>
                                      </select>
                                    </label>
                                  </th>
                                </tr>
                              </thead>
                              <tbody></tbody>
                            </table>
                            <div
                              id="nomatchfound"
                              className="text-center mw-100 d-flex justify-content-center align-items-center"
                            >
                              <p className="mb-0 pt-0 text-secondary">
                                No data found
                              </p>
                            </div>
                            <div className="card mt-3 mb-2" id="cardnotfound">
                              <div className="card-body d-flex justify-content-center align-items-center">
                                <p className="text-secondary">No match found</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </main>
        </div>

        <ConfirmDialog
          show={this.state.isModalDeleteOpen}
          handleModalPopup={this.handleModalExit}
          message={"<p>Are you sure you want to delete?</p>"}
          button1_name="Cancel"
          button1={this.handleModalExit}
          button2_name="Delete"
          button2={this.handleDelete}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(adminUserRoleActions, dispatch),
    authActions: bindActionCreators(authActions, dispatch),
    userRightsactions: bindActionCreators(userRightsActions, dispatch),
  };
};
export default connect(null, mapDispatchToProps)(UserRole);
