import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import React from "react";
import CustomerLookUp from "../modules/customerLookup/component.customerLookup_new";
import RouteName from "./routeName";
import StoreNumber from '../modules/storeNumber/component.storeNumber';
import CustomerDetails from '../modules/customerDetails/component.customerDetails';
import AccountSummary from '../modules/accountSummary/component.accountSummary';
import ApplyNow from "../modules/applyNow/component.applyNow";
import ViewOffers from "../modules/viewOffers/component.viewOffers";
import Admin from '../modules/admin/component.admin';
import AdminUserList from '../modules/adminUserList/component.adminUserList';
import AdminUserRole from '../modules/adminUserRole/component.adminUserRole'
import AdminUserRights from '../modules/adminUserRights/component.adminUserRights';
import CreditApplication from "../modules/creditApplication/component.creditApplication";
import ricaForm from "../modules/rica/component.rica";
import ApplicationHistory from "../modules/applicationHistory/component.applicationHistory";
import SynchronyLookUp from "../modules/synchronyLookup/component.synchronyLookUp";
import TransactionHistory from "../modules/transactionHistory/component.transactionHistory";
import SynchronyActSummary from '../modules/accountSummary/component.synchronyActSummary';
import Stores from '../modules/stores/component.stores';
import ProgressiveActSummary from '../modules/accountSummary/component.progressiveActSummry';
import UownActSummary from '../modules/accountSummary/component.uownActSummary';
import FortivaAccountSummary from '../modules/accountSummary/component.fortivaSummary';
import Features from "../modules/features/component.features";
import MasterInfo from "../modules/masterCustomerUpdate/component.masterCustomerUpdate";
import applicationReqResponse from "../modules/applicationReqResponse/component.applicationReqResponse";
import InsuranceMatrix from "../modules/insuranceMatrix/component.insuranceMatrix";
import BillingCycle from "../modules/billingCycle/component.billingCycle";

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={RouteName.STORENUMBER} component={StoreNumber} />
        <Route exact path={RouteName.CUSTOMERLOOKUP} component={CustomerLookUp}/> 
        <Route exact path={RouteName.CUSTOMERDETAILS} component={CustomerDetails} />
        <Route exact path={RouteName.DANIELSACCOUNTSUMMARY} component={AccountSummary} />
        <Route exact path={RouteName.VIEWOFFERS} component={ViewOffers} />
        <Route exact path={RouteName.APPLYNOW} component={ApplyNow} />
        <Route exact path={RouteName.TRANSACTIONHISTORY} component={TransactionHistory}/>
        <Route exact path={RouteName.ADMIN} component={Admin} />
        <Route exact path={RouteName.ADMINUSERLIST} component={AdminUserList} />
        <Route exact path={RouteName.ADMINUSERROLE} component={AdminUserRole} />
        <Route exact path={RouteName.ADMINUSERRIGHTS} component={AdminUserRights} />
        <Route exact path={RouteName.CREDITAPPLICATIONLIST} component={CreditApplication} />
        <Route exact path={RouteName.STORESLIST} component={Stores} />
        <Route exact path={RouteName.RICA} component={ricaForm} />
        <Route exact path={RouteName.APPLICATIONHISTORY} component={ApplicationHistory}/>
        <Route exact path={RouteName.SYNCHRONYLOOKUP} component={SynchronyLookUp}/>
        <Route exact path={RouteName.SYNCHRONYACCOUNTSUMMARY} component={SynchronyActSummary} />
        <Route exact path={RouteName.PROGRESSIVEACCOUNTSUMMARY} component={ProgressiveActSummary} />
        <Route exact path={RouteName.UOWNACCOUNTSUMMARY} component={UownActSummary} />
        <Route exact path={RouteName.FORTIVAACCOUNTSUMMARY} component={FortivaAccountSummary} />
        <Route exact path={RouteName.ADMINFEATURES} component={Features} />
        <Route exact path={RouteName.ADMINCUSTOMERDETAILSUPDATE} component={MasterInfo} />
        <Route exact path={RouteName.APPLICATIONREQRESPONSE} component={applicationReqResponse}/>
        <Route exact path={RouteName.ADMININSURANCEMATRIX} component={InsuranceMatrix} />
        <Route exact path={RouteName.BILLINGCYCLE} component={BillingCycle}/>
        <Redirect to='/' />
      </Switch>
    </BrowserRouter>
  );
};
export default Routes;
