import "./component.rica.css";
import React from "react";

export default function ricaForm() {
    
        return (
            <div id="ricaFrom">
            <div className="mt-3 mb-3">
                <center>
                <h4>
                    <b>
                    DANIEL’S JEWELERS PREFERRED CHARGE 
                    </b>
                </h4>
                <h5>
                    <b>
                    RETAIL INSTALLMENT CREDIT AGREEMENT
                    </b>
                </h5>
                
                </center>
            </div>
            <div className="container-fluid">
            <div className="row mb-3">
                <div className="col-12 mb-3 mb-md-0 col-md-6 left-col">
                    <table style={{minHeight:'100%'}}>
                        <tbody>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",textAlign:"left",padding: '5px' }}>
                                    <p className="mb-0">Annual</p>
                                    <p className="mb-0">Percentage</p>
                                    <p className="mb-0">Rate</p>
                                </td>
                                <td style={{ width: "78%", backgroundColor: "white",textAlign:"left",padding: '5px' }}>
                                    <p className="mb-0">35.88% in AZ, CA, NV, NM, PA, SC, and VA</p>
                                    <p className="mb-0">18.00% in FL and NC</p>
                                    <p className="mb-0">21.00% in GA and TX<br /></p>
                                    24.99% in <span className="m-0">NY, MI, and </span>OH<br />

                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",textAlign:"left" ,padding: '5px' }}>
                                    <p className="mb-0">Periodic</p>
                                    <p>Rate</p></td>
                                <td style={{ width: "78%", backgroundColor: "white",textAlign:"left" ,padding: '5px' }}>
                                    2.99% in AZ, CA, NV, NM, PA, SC, and VA<br />
                                    1.50% in FL and NC<br />
                                    <span className="ml-0">1.50% in FL and NC<br /></span>
                                    2.0825% in <span>NY, MI, and </span>OH<br />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",textAlign:"left",padding: '5px' }}>
                                    Grace period for repayment of balances for purchases
                                </td>
                                <td style={{ width: "78%", backgroundColor: "white",textAlign:"left",padding: '5px' }}>There is no <b>FINANCE CHARGE</b> on new charges to your account when there is no balance outstanding at the start of the billing cycle and if you pay the balance of your account in full by the due date shown on your statement. </td>
                            </tr>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",textAlign:"left",padding: '5px' }}>Minimum FINANCE CHARGE</td>
                                <td style={{ width: "78%", backgroundColor: "white",textAlign:"left",padding: '5px'  }}>None, if paid during grace period, otherwise, $0.50 in AZ, CA, NV, and SC; <span className="hide_if_need">$1.00 in FL, GA, OH, and PA;</span> and $0.75  MI, NY, and TX. There is no minimum finance charge in NC, NM and VA.</td>
                            </tr>

                        </tbody>
                    </table>
                </div>

                <div className="col-12 col-md-6 right-col">
                    <table style={{minHeight:"100%"}} >
                        <tbody>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",verticalAlign: "baseline"}}>Method of<br />computing<br />the periodic<br />FINANCE<br />CHARGE</td>
                                <td style={{ width: "78%", backgroundColor: "white",verticalAlign: "baseline"}}>
                                <p style={{ padding: '5px' }}>
                                We calculate the finance charge using the Average DailyBalance Method. To get the “Average Daily Balance” we take the balance of your account at the beginning of the billing cycle, not including any unpaid finance charges that are included in your account balance and add any new purchases and subtract payments or credits each day (we do not addin any new purchases or subtract payments or creditsin NM). This gives us the daily balance.
                                    Then, we add up all the daily balancesfor the billing cycle and divide the total by the number of days in the billing cycle. This gives us the Average Daily Balance.
                                    <br /><br />
                                    We figure the FINANCE CHARGE on your account by multiplying the Average Daily Balance of your account by the Periodic Rate.
                              </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
            <center className="col-12">
                <h4><b> FEES</b></h4></center>
            </div>
            <div className="row mb-2">
                <div className="col-12 col-md-6 mb-3 mb-md-0 left-col">
                <table style={{minHeight:"100%"}}>
                        <tbody>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",padding: '5px' }}>Annual Fee</td>
                                <td style={{ width: "78%", backgroundColor: "white",padding: '5px'}}>
                                    CA and NV: $15.00<br />
                                    AZ, <span className="hide_if_need">FL, GA, MI, NM, NY, NC, OH, PA, SC, TX </span>and VA: $0.0
                                </td>
                            </tr>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white", verticalAlign: "middle", padding: "5px" }}>Late Payment Fee</td>
                                <td style={{ width: "78%", backgroundColor: "white"  }}>
                                <p  style={{padding: '5px'  }}>If we do not receive your minimum payment by its due date <span className="hide_if_need"> (or within seven (7) days in VA, within ten (10) days in AZ, CA, GA, FL, MI, NM, NV, NY, and SC, within twenty-one (21) days in TX, and within thirty (30) days in NC, we</span> will charge a late payment fee which will generally be the highest amount permitted by law.</p>
                                <table >
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none" }}>
                                                    <b>State</b>
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    <b>Maximum Late Payment Fee </b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    AZ, CA, NV, TX, FL
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 10.00
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    GA,NY
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 25.00
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    NC
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 10.00 ( $5.00 if balance under $100)
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    SC
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    5% of amount past due, but not more than $21.00 and not less than $8.40
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    MI,OH,PA
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 39.00
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    VA
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    5% of installement ( max of $39.00)
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    NM
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    None
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p  style={{padding: '5px'  }}>We will add any late payment fee to your Account's regular balance</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-md-6 right-col">
                    <table style={{minHeight:"100%"}}>
                        <tbody>
                            <tr>
                                <td style={{ width: "22%", backgroundColor: "white",padding: '5px', verticalAlign: "middle" }}>Returned Payment Fee</td>
                                <td style={{ width: "78%", backgroundColor: "white"  }}>
                                <p style={{ padding: '5px' }}>If any check, instrument, or electronic authorization used to pay us is not honored by your financial institution, a returned payment fee (max. of $39) which will generally be the highest amount permitted by law may be assessed. This fee generally will not exceed the amount of the required minimum payment due immediately prior to the date on which the payment is returned.</p>
                                <table style={{width:"100%"}}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none" }}>
                                                    <b>State</b>
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    <b>Maximum Returned Payment Fee</b>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    AZ
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 10.00
                                                </td>
                                            </tr>
                                            <tr>    
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    CA
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 15.00
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    FL,NV
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 25.00
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    GA
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none"  }}>
                                                    $30.00
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    NC
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 25.00($0.00 for electronic payments)
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    NY
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 20.00($0.00 for electronic payments)
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    OH
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 39.00($0.00 for electronic payments)
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    SC,TX
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    $ 30.00
                                                </td>
                                            </tr>
                                            <tr>  
                                                <td style={{ width: "22%", backgroundColor: "white", padding: '5px', borderLeft: "none"  }}>
                                                    MI,NM,PA,VA
                                                </td>
                                                <td style={{ width: "78%", backgroundColor: "white", padding: '5px', borderRight: "none" }}>
                                                    None
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p  style={{padding: '5px'  }}>We will add any late payment fee to your Account's regular balance</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row mt-2 text-justify">
                <div className="col-12">
                    <p  className="text-justify" style={{border:"black", borderWidth:"2px", borderStyle:"solid",padding:"5px"}}><b>Notice to buyers: The disclosures above are accurate as of the date of publishing on September 1, 2022 and are effective September 1, 
                    2022. These terms are subject to change after this date. Write to Daniel’s Jewelers, P.O. Box 3750, Culver City, CA 90231-3750 or call 
                    (310) 665-2110 for the most recent version of this agreement</b></p>
                </div>
            </div>
            </div>
            </div>
        );
}

