import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../_http.methods";

export function getsynchronyLookup(filterObj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: filterObj,
      ResourceUrl: "v1/customers/accountlookup_synchrony",
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getsynchronyActSummmary(cust_Id,synchronyAppId,phoneNo,homeStore) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: synchronyAppId,
      ResourceUrl: "v1/customers/accountsummary_synchrony?CustomerId="+cust_Id+"&Application_Id="+synchronyAppId+"&phoneNumber="+phoneNo+"&homeStore="+homeStore,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function increaseSynLmt(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Put(),
      Resource: obj,
      ResourceUrl: "v1/customers/creditlimitincrease_synchrony?CustomerId="+obj.customerId+"&Application_Id="+obj.applicationId+"&requestedLimit="+obj.requestedLimit+"&income="+obj.income+"&homeStore="+obj.homeStore,
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
