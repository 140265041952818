import React, { Component } from "react";
import IdleTimer from 'react-idle-timer';
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as adminActions from '../redux/actions/admin.actions';
import APIFailedMessage from "./component.apiFailedMessage";
import ConfirmDialog from "./component.confirmDialog";

class AugmentesIdleTime extends Component{

    constructor(props){
        super(props);
        this.state={
            timeOutModel: false,
            sessionOutMins: 15
        }
        this.idleTimer = null
        this.sessionTimeOut = null
        this.onIdle = this.onIdle.bind(this)
        this.onAction = this.onAction.bind(this)
        this.onActive = this.onActive.bind(this)
    }
    componentDidMount(){
        this.props.adminActions.getSignOutTime()
        .then(res=>{
            if(res){
                this.setState({sessionOutMins : res.sessionOutMins});
            }
        })
        .catch(error=>{
            APIFailedMessage.displayError(error);
        })
    }

    handleTimeModal = () => {  
        this.setState({timeOutModel:!this.state.timeOutModel});
        clearTimeout(this.sessionTimeOut.current);
    }
    
    onIdle(e) {
        this.setState({timeOutModel: true});
        this.sessionTimeOut.current = setTimeout(() => {
          window.location = window.location.href;
        }, 20000);
    }
    onAction(e) {
        clearTimeout(this.sessionTimeOut.current);
    }
    onActive(e) {
        clearTimeout(this.sessionTimeOut.current);
    }

    render(){
       // const signOutTime=(this.props.signOutTime===undefined ||this.props.signOutTime===null)?1:this.props.signOutTime;
        return(
            <>
                <IdleTimer
                    ref={ref => { this.idleTimer = ref; this.sessionTimeOut = ref }}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    onActive={this.onActive}
                    timeout={60000 * this.state.sessionOutMins}
                />
                <ConfirmDialog
                    show={this.state.timeOutModel}
                    handleModalPopup={this.handleTimeModal}
                    message={"<p className='mb-0'>Your session is about to expire due to inactivity, please choose to stay In.</p><p className='mb-0'>Otherwise, please close the tab and reopen.</p>"}
                    header_name = "Warning!!"
                    button2_name="Stay In"
                    button2={this.handleTimeModal}
                />
            </>
        );
    };
}

const mapDispatchToProps=(dispatch)=>{
    return {
     adminActions:bindActionCreators(adminActions,dispatch)
    } 
}
export default connect(null,mapDispatchToProps)(AugmentesIdleTime);