import { faAngleLeft, faAngleRight, faCircle, faClose, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Card, Modal } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import APIFailedMessage from "../../components/component.apiFailedMessage";
import { bindActionCreators } from "redux";
import * as ApplyNowActions from "../../redux/actions/applyNow.actions";
import WaterFallMessageSS from "../../images/popup/WaterFallMessage.png";
import LeasingPopUpSS from "../../images/popup/LeasingPopUp.png";
import CAToolTip from "../../images/popup/CAToolTip.png";

class AlterPopup extends Component {

    constructor() {
        super();
        this.state = {
            showPopupList: "",
            currentPopupToEdit: "",
            content: "",
            currentContent : "",
            disableSaveBtn : true
        };
    }

    onChange(event) {
        event.preventDefault();
        if (this.state.showPopupList === "ApplyNow") {
                let val = this.state.currentContent;
                val.description[event.target.id.split('_')[1]-1] = event.target.value;
                this.setState({currentContent : val},() => 
                this.enableDisableSaveBtn());
        }
    }

    openList(pageName, type) {
        if (type === "loadData")
            this.loadData(pageName);
        else if (this.state.currentPopupToEdit !== "") {
            this.setState({
                currentPopupToEdit: "", disableSaveBtn : true
            });
        }
        else {
            this.setState({
                showPopupList: ""
            });
        }
    }

    loadData(pageName) {
        trackPromise(
            this.props.action.getPopUpMessage(pageName).then(res => {
                if(res) {
                    res.map(item=> {
                        return item.description = item.description?.replaceAll("*^*","'");
                    })
                    this.setState({content : res, showPopupList: pageName});
                }
            }).catch(err => {
                APIFailedMessage.displayError(err);
            })
        )
    }

    openEditTab(editTab) {
        const data1 = JSON.parse(JSON.stringify(this.state.content));
        let data2 = data1.find(item => item.name?.toLowerCase() == editTab.toLowerCase());
        if(data2 != undefined) {
            data2.description = data2.description?.split("~`~");
        }
        this.setState({ currentPopupToEdit: editTab, currentContent : data2});
    }

    async savePopupMessage() {
        let result = "";
        if(this.enableDisableSaveBtn()) {
        let obj = {
            name: this.state.currentContent.name,
            description:this.state.currentContent.description.join("~`~")?.replace(/'/g,'*^*'),
            modifiedBy: localStorage.getItem("loggedUserEmail"),
            pageName: this.state.currentContent.pageName
        }
        await trackPromise(
         this.props.action.postPopUpMessage(obj).then(res => {
            if(res) {
                result = res;
                toast.success("Message updated successfully!");
                this.setState({disableSaveBtn : true});
            }
            else {
                toast.error("Failed to update message, please try again");
            }
        }).catch(err => {
            APIFailedMessage.displayError(err);
        }));
        if(result) {
            await trackPromise(
            this.props.action.getPopUpMessage(this.state.showPopupList).then(res => {
                if(res) {
                    res.map(item=> {
                        return item.description = item.description?.replaceAll("*^*","'");
                    })
                    this.setState({content : res, currentPopupToEdit: ""});
                }
            }).catch(err => APIFailedMessage.displayError(err))
            )
        }
    }
    }

    enableDisableSaveBtn() {
        let disableSaveBtn = true;
        let data = JSON.parse(JSON.stringify(this.state.currentContent));
        if(data != "" && data != undefined) {
            data.description = data?.description?.join("~`~");
            let data2 = this.state.content.find(item => item.name === data?.name);
            if(data.description === data2.description) disableSaveBtn =  true;
            else disableSaveBtn = false;
        }
        this.setState({disableSaveBtn : disableSaveBtn});
        return !disableSaveBtn;
    }

    render() {
        return (
            <>
                <div className="row">
                    <Card className="w-100 signOutSection mt-0">
                        <Card.Body>
                            <h5 className="mb-0">
                                {this.state.showPopupList !== "" ? <div className="d-inline" onClick={() => this.openList()} style={{ cursor: "pointer" }}>Credit Application Pop-Up Message</div> : null}
                                {this.state.showPopupList === "" && <div className="d-inline" onClick={() => this.openList("ApplyNow", "loadData")} style={{ cursor: "pointer" }}>Credit Application Pop-Up Message<FontAwesomeIcon icon={faAngleRight} className="float-right mt-1" /></div>}
                            </h5>
                        </Card.Body>
                    </Card>
                    {this.state.showPopupList === "ApplyNow" ?
                        <>
                            {(this.state.currentPopupToEdit === "" || this.state.currentPopupToEdit === "WaterfallMessage") &&
                                <Card className="w-100 signOutSection mt-0">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <img src={WaterFallMessageSS} alt="img" width="100%" height="300px" />
                                                {this.state.currentPopupToEdit === "WaterfallMessage" &&
                                                    <p className="mb-0 pt-3 text-danger">Disclaimer : The above image is to understand how it's look like, it doesn't reflect the content.</p>
                                                }
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {this.state.currentPopupToEdit === "" ?
                                                    <>
                                                        <h4>Waterfall Message</h4>
                                                        <p>If all 3 credit applications, Daniel’s, Synchrony, and Fortiva, boxes are NOT checked, then show a message before the user moves on to the mobile verification section.</p>
                                                        <button className="btn pl-5 pr-5 border border-dark" onClick={() => this.openEditTab("WaterfallMessage")} >Edit</button>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="border border-dark rounded p-3">
                                                            {/* <h4 className="d-block text-secondary">
                                                                Info
                                                            </h4> */}
                                                            <input
                                                                type="text"
                                                                value={this.state.currentContent.description[0]}
                                                                className="form-control border mb-2"
                                                                id="WFM_1"
                                                                name="WFM_1"
                                                                onChange={(event) => { this.onChange(event) }}
                                                            />
                                                            <hr></hr>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                value={this.state.currentContent.description[1]}
                                                                className="form-control border mb-2"
                                                                id="WFM_2"
                                                                name="WFM_2"
                                                                onChange={(event) => { this.onChange(event) }}
                                                            />
                                                            <div class="d-flex">
                                                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                                                <input
                                                                    type="text"
                                                                    value={this.state.currentContent.description[2]}
                                                                    className="form-control border mb-2"
                                                                    id="WFM_3"
                                                                    name="WFM_3"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            <div class="d-flex">
                                                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                                                <input
                                                                    type="text"
                                                                    value={this.state.currentContent.description[3]}
                                                                    className="form-control border mb-2"
                                                                    id="WFM_4"
                                                                    name="WFM_4"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            <div class="d-flex">
                                                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                                                <input
                                                                    type="text"
                                                                    value={this.state.currentContent.description[4]}
                                                                    className="form-control border mb-2"
                                                                    id="WFM_5"
                                                                    name="WFM_5"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            {/* <div className="d-flex w-100 mt-2">
                                                                <span className="ml-auto rounded bg-secondary text-primary pl-4 pr-4 pt-2 pb-2">Ok</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex">
                                                        {(this.state.showPopupList !== "" && this.state.currentPopupToEdit !== "") && <button className="btn w-50 mr-3 border mt-3 bg-primary text-secondary" onClick={() => this.openList()}>CANCEL</button>}
                                                        {(this.state.showPopupList !== "" && this.state.currentPopupToEdit !== "") && <button className="btn w-50 mt-3 bg-secondary text-primary" onClick={() => this.savePopupMessage()} disabled={this.state.disableSaveBtn}>SAVE</button>}
                                                    </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                            {(this.state.currentPopupToEdit === "" || this.state.currentPopupToEdit === "CAToolTip") &&
                                <Card className="w-100 signOutSection mt-0">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <img src={CAToolTip} alt="img" width="100%" height="200px" />
                                                {this.state.currentPopupToEdit === "CAToolTip" &&
                                                    <p className="mb-0 pt-3 text-danger">Disclaimer : The above image is to understand how it's look like, it doesn't reflect the content.</p>
                                                }
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {this.state.currentPopupToEdit === "" ?
                                                    <>
                                                        <h4>ToolTip</h4>
                                                        <p>To each credit application box, display a message that will come up when someone hovers over.</p>
                                                        <button className="btn pl-5 pr-5 border border-dark" onClick={() => this.openEditTab("CAToolTip")}>Edit</button>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="p-2">
                                                            <div class="d-flex">
                                                                <span className="my-auto"><b>Synchrony:</b></span> 
                                                                <textarea
                                                                    // type="textarea"
                                                                    value={this.state.currentContent?.description[0]}
                                                                    className="form-control border text-center"
                                                                    id="CATT_1"
                                                                    name="CATT_1"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            <div class="d-flex mt-4">
                                                            <span className="my-auto"><b>Fortiva:</b></span> 
                                                                <textarea
                                                                    // type="text"
                                                                    value={this.state.currentContent?.description[1]}
                                                                    className="form-control border text-center"
                                                                    id="CATT_2"
                                                                    name="CATT_2"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            <div class="d-flex mt-4">
                                                                <span className="my-auto"><b>Daniel's:</b></span> 
                                                                <textarea
                                                                    // type="text"
                                                                    value={this.state.currentContent?.description[2]}
                                                                    className="form-control border text-center"
                                                                    id="CATT_3"
                                                                    name="CATT_3"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="w-100 d-flex">
                                                            {(this.state.showPopupList !== "" && this.state.currentPopupToEdit !== "") && <button className="btn w-50 mr-3 border mt-3 bg-primary text-secondary" onClick={() => this.openList()}>CANCEL</button>}
                                                            {(this.state.showPopupList !== "" && this.state.currentPopupToEdit !== "") && <button className="btn w-50 mt-3 bg-secondary text-primary" onClick={() => this.savePopupMessage()} disabled={this.state.disableSaveBtn}>SAVE</button>}
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                            {(this.state.currentPopupToEdit === "" || this.state.currentPopupToEdit === "ProgressiveUwon") &&
                                <Card className="w-100 signOutSection mt-0">
                                    <Card.Body>
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <img src={LeasingPopUpSS} alt="img" width="100%" height="300px" />
                                                {this.state.currentPopupToEdit === "ProgressiveUwon" &&
                                                    <p className="mb-0 pt-3 text-danger">Disclaimer : The above image is to understand how it's look like, it doesn't reflect the content.</p>
                                                }
                                            </div>
                                            <div className="col-12 col-md-6">
                                                {this.state.currentPopupToEdit === "" ?
                                                    <>
                                                        <h4>Leasing Popup</h4>
                                                        <p>Popup appears after submitting water fall Synchrony, Fortiva, Daniel's or combination</p>
                                                        <button className="btn pl-5 pr-5 border border-dark" onClick={() => this.openEditTab("ProgressiveUwon")}>Edit</button>
                                                    </>
                                                    :
                                                    <>
                                                    <div className="border border-dark rounded p-2">
                                                            {/* <h4 className="d-block">Confirmation</h4>
                                                            <hr></hr> */}
                                                            <input
                                                                type="text"
                                                                value={this.state.currentContent.description[0]}
                                                                className="form-control border mb-2"
                                                                id="PU_1"
                                                                name="PU_1"
                                                                onChange={(event) => { this.onChange(event) }}
                                                            />
                                                        <div>
                                                            <input
                                                                type="text"
                                                                value={this.state.currentContent.description[1]}
                                                                className="form-control border mb-2"
                                                                id="PU_2"
                                                                name="PU_2"
                                                                onChange={(event) => { this.onChange(event) }}
                                                            />
                                                            <div class="d-flex">
                                                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                                                <input
                                                                    type="text"
                                                                    value={this.state.currentContent.description[2]}
                                                                    className="form-control border mb-2"
                                                                    id="PU_3"
                                                                    name="PU_3"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            <div class="d-flex">
                                                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                                                <input
                                                                    type="text"
                                                                    value={this.state.currentContent.description[3]}
                                                                    className="form-control border mb-2"
                                                                    id="PU_4"
                                                                    name="PU_4"
                                                                    onChange={(event) => { this.onChange(event) }}
                                                                />
                                                            </div>
                                                            {/* <div className="d-flex w-100">
                                                                <span className="ml-auto rounded bg-secondary text-primary pl-4 pr-4 pt-2 pb-2">Ok</span>
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-100 d-flex">
                                                        {(this.state.showPopupList !== "" && this.state.currentPopupToEdit !== "") && <button className="btn w-50 mr-3 border mt-3 bg-primary text-secondary" onClick={() => this.openList()}>CANCEL</button>}
                                                        {(this.state.showPopupList !== "" && this.state.currentPopupToEdit !== "") && <button className="btn w-50 mt-3 bg-secondary text-primary" onClick={() => this.savePopupMessage()} disabled={this.state.disableSaveBtn}>SAVE</button>}
                                                    </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            }
                        </>
                        : null}
                    {/* {this.state.currentPopupToEdit === "WaterfallMessage" ?
                    <>
                      <Card className="w-100 signOutSection mt-0">
                            <Card.Body className="d-flex">
                                <div className="w-50 border border-dark p-2 ml-auto">
                                    <>
                                    <h4 className="d-block">Info <FontAwesomeIcon icon={faTimesCircle} size="lg" className="float-right" /></h4>
                                    <hr></hr>
                                    </>
                                <div>
                                <input 
                                    type="text"
                                    value = "WE STRONGLY RECOMMEND APPLYING FOR ALL 3 OPTIONS."
                                    className="form-control border mb-2"
                                    onChange={(event) => {this.onChange(event)}}
                                />
                                <div class="d-flex">
                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                <input 
                                    type="text"
                                    value = "Synchrony Bank Credit"
                                    className="form-control border mb-2"
                                    onChange={(event) => {this.onChange(event)}}
                                />
                                </div>
                                <div class="d-flex">
                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                <input 
                                    type="text"
                                    value = "Fortiva Retail Credit"
                                    className="form-control border mb-2"
                                    onChange={(event) => {this.onChange(event)}}
                                />
                                </div>
                                <div class="d-flex">
                                <FontAwesomeIcon icon={faCircle} className="pr-2 my-auto" size="xs" />
                                <input 
                                    type="text"
                                    value = "Daniel's Credit"
                                    className="form-control border mb-2"
                                    onChange={(event) => {this.onChange(event)}}
                                />
                                </div>
                                </div>
                                </div>
                            </Card.Body>
                        </Card>  
                    </>
                    : null} */}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        action: bindActionCreators(ApplyNowActions, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(AlterPopup);