import React from 'react';
import {Card} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faCircle } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';

const CardView = ({ data, handleCardSelect, activeCreditId, handleSubCardSelect, subTableData, }) => {
  // const Approved = () => {
  //   return(
  //        <span className='financial ml-0'>A</span>
  //   ) 
  // };
  // const Declined = () => {
  //   return(
  //        <span className='financial declined ml-0'>X</span>
  //   ) 
  // };
  // const Pending = () => {
  //   return(
  //        <span className='financial pending ml-0'>P</span>
  //   ) 
  // };
  // const Reapply = () => {
  //   return(
  //        <span className='financial reApply ml-0'>E</span>
  //   ) 
  // };

  // const DisplayStatus = (props) => {
  //   const {status }=props;
  //    return(
  //     status?.toLowerCase()=="approved" || status?.toLowerCase()=="pre-approved"?
  //     <Approved/>:
  //     status?.toLowerCase()=="declined"?
  //     <Declined/>:
  //     status?.toLowerCase()=="pending" || status?.toLowerCase()=="in process"?
  //     <Pending/>:
  //     status?.toLowerCase()=="eligible to reapply"?
  //     <Reapply/>
  //     :""
  //   ) 
  // };
  return (
    <>
      <hr className="mt-1 mb-1"></hr>
      <div className="row">
      {data &&
          data.map((val) => (
          <div
          className={"col-sm-12 col-md-6 pt-2 pb-2 "+(subTableData?.length > 0 ? "" : "d-flex")}
            key={val.creditId}
          >
            <Card
              className="text-left shadow"
              // onClick={() => handleCardSelect(val.id,val.custAcctStat, val.account,val.responseSource,val.magixStoreCode,val.account)}
            >
              <Card.Body
                className={(val.custAcctStat === activeCreditId) ? "m-3 pt-0 mb-1 bg-secondary text-primary" : "m-3 pt-0 "}
                style={{ border: "2px solid #1B1B1B" }}
                onClick={() => handleCardSelect(val.application_id,val.custAcctStat, val.customer_Id,val.responseSource,val.magixStoreCode,val.account, val.isDaniel,val.createdDate,val.modifiedDate)}
              >
                <p className="h3 ml-0 mt-2 mb-0">
                  {val.name === null || undefined ? "" : val.name}
                </p>
                <hr
                  style={
                    val.creditId === activeCreditId
                      ? { backgroundColor: "#f8f6fa" }
                      : { backgroundColor: "#1B1B1B" }
                  }
                ></hr>
                <div className="row">
                  <div className="col-6">Application Date :</div>
                  <div className="col-6">{val.createdDateDisplay}</div>
                  <div className="col-6">Application Type :</div>
                  <div className="col-6">{val.applicationType}</div>
                  <div className="col-6">Application ID :</div>
                  <div className="col-6">{val.application_id}</div>
                  <div className="col-6">Customer Name :</div>
                  <div className="col-6">{val.customerName}</div>
                  <div className="col-6">Associate ID :</div>
                  <div className="col-6">{val.associateId}</div>
                  <div className="col-6">Apply Store :</div>
                  <div className="col-6">{val.store_code}</div>
                  <table className="text-center w-100 mt-2 ml-2 mr-2 h-25">
                      <thead className="border-dark border-top">
                        <tr className={(((activeCreditId === val.custAcctStat)) ? "text-white" : "" )}>
                          <th>D</th>
                          <th>F</th>
                          <th>S</th>
                          <th>P</th>
                          <th>U</th>
                        </tr>
                      </thead>
                      <tbody className="border-dark border-bottom">
                      <tr>
                          <td>{val.responseSource==="application"?val.isDaniel? <FontAwesomeIcon icon={faCircle} className={(((activeCreditId === val.custAcctStat)) ? "text-white" : "text-secondary" )} style={{opacity : "0.6"}}/>:"":""}</td>
                          <td>{val.responseSource==="application"?val.isFortiva? <FontAwesomeIcon icon={faCircle} className={(((activeCreditId === val.custAcctStat)) ? "text-white" : "text-secondary" )} style={{opacity : "0.6"}}/>:"":""}</td>
                          <td>{val.responseSource==="application"?val.isSynchrony? <FontAwesomeIcon icon={faCircle} className={(((activeCreditId === val.custAcctStat)) ? "text-white" : "text-secondary" )} style={{opacity : "0.6"}}/>:"":""}</td>
                          <td>{val.responseSource==="application"?val.isProgressive? <FontAwesomeIcon icon={faCircle} className={(((activeCreditId === val.custAcctStat)) ? "text-white" : "text-secondary" )} style={{opacity : "0.6"}}/>:"":""}</td>
                          <td>{val.responseSource==="application"?val.isUown? <FontAwesomeIcon icon={faCircle} className={(((activeCreditId === val.custAcctStat)) ? "text-white" : "text-secondary" )} style={{opacity : "0.6"}}/>:"":""}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </Card.Body>
              {val?.applicationType === "Print Summary" ?
               null :
              <p className='text-center mb-3' onClick={() => handleSubCardSelect(val)}>View {((subTableData?.length > 0) && (activeCreditId === val.id)) ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} /> }</p> }
              <div className={"pl-4 pr-4 " +((subTableData?.length > 0) && (activeCreditId === val.application_id) ? "" : "d-none")}>
                    {/* <hr style={{border:"1px solid"}}></hr> */}
                    <p></p>
                    {activeCreditId === val.application_id ? (subTableData && subTableData.map((val) => (
                      <>
                            <div className="row">
                              <div className="col-6">Application Selected:</div>
                              <div className="col-6">{val?.fpType}</div>
                              <div className="col-6">Application/ Lease ID:</div>
                              <div className="col-6">
                                {val.fpType?.toLowerCase() === "progressive" ? val.accountNumber : 
                                val.fpType?.toLowerCase() === "uown" ? val.authorizationNumber : val.applicationId}  
                              </div>
                              <div className="col-6">Response Decision Status:</div>
                              <div className="col-6">{val.statusDescription}</div>
                              <div className="col-6">Approval Amount:</div>
                              <div className="col-6">{val?.approvalLimit}</div>
                              <div className="col-6">Status Message:</div>
                              <div className="col-6"></div>
                            </div>
                            <hr></hr>
                      </>
                    ))) : null}
                  </div>
            </Card>
          </div>
        ))}
      </div>
    </>
  );
};

  export default CardView;