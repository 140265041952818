import { ApiWrapper } from "../../api/thunk.helper";
import HttpMethods from "../_http.methods";

export function getCreditApplication(app_data) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: app_data,
      ResourceUrl: "v1/associates/search-credit-applications",
      isAssociate:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}

export function getPrintSummary(printSummaryId,customer_ID) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Get(),
      Resource: printSummaryId,
      ResourceUrl: "v1/associates/get-customer-printsummary?customer_ID="+customer_ID+"&printSummary_Id="+printSummaryId,
      isAssociate:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function getPrintSummaryMagix(obj) {
  return function (dispatch) {
    const data = {
      MethodType: HttpMethods.Post(),
      Resource: obj,
      ResourceUrl: "v1/associates/search-customerprintsummary-magix",
      isAssociate:true
    };
    return ApiWrapper.LoadData(data)
      .then((response) => {
        if (response) {
          return response;
        } else {
          return response;
        }
      })
      .catch((error) => {
        throw error;
      });
  };
}
export function getApplicationViewOffers(cust_Id,applicationId) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Get(),
          Resource: applicationId,
          ResourceUrl:
              "v1/customers/view-offers_ByAppId?CustomerId="+cust_Id+"&application_Id=" + applicationId,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function getApplicationIds(searchObj) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: searchObj,
          ResourceUrl:
              "v1/customers/get-Application_RequestResponse",
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

export function getApplicationReqResp(appId) {
  return function (dispatch) {
      const data = {
          MethodType: HttpMethods.Post(),
          Resource: appId,
          ResourceUrl:
              "v1/customers/get-Request_Response?application_id="+appId,
      };
      return ApiWrapper.LoadData(data)
          .then((response) => {
              if (response) {
                  return response;
              } else {
                  return response;
              }
          })
          .catch((error) => {
              throw error;
          });
  };
}

