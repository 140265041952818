import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

export default class FortivaAcceptOfferDialog extends React.Component{
  render(){
    return(
      <Modal show={this.props.show} onHide={this.props.handleModalPopup}>  
        <Modal.Header className="pb-0"><b className="text-secondary h4">Fortiva Offer Acceptance</b><FontAwesomeIcon icon={faClose} className="btn" onClick={() => this.props.handleModalPopup()} /></Modal.Header>
          <Modal.Body className="pb-0">
            <div className="form-group d-flex align-items-left justify-content-left">
                <div className="form-check form-check-inline mr-0 mb-0">
                    <input type="checkbox"
                        name="acceptOffer"
                        id="acceptOffer"
                        onClick={(event)=>this.props.onAgreement(event)}
                        value={this.props.cardAgreementChecked}
                        //checked={this.props.cardAgreementChecked ? "checked" : ""}
                    />
                    <label htmlFor="chkSyncOnly" className="mb-0 ml-2">Cardholder Agreement</label>
                </div>
            </div>
              <div dangerouslySetInnerHTML={{__html : this.props.message }}></div>
          </Modal.Body> 
          <Modal.Footer className="border-top-0 mt-1">
          <button className="btn bg-secondary text-primary p-2 ml-1 mr-1 mb-0 pr-4 pl-4"  onClick={(event)=>this.props.acceptOffer(event)} disabled={this.props.cardAgreementChecked?false:true} >Accept</button>  
          <button className="btn p-2 ml-1 mr-1 mb-0 pr-4 pl-4" style={{backgroundColor:"#ccb7d8",color:"#860fc8"}} onClick={()=>this.props.handleModalPopup()}>Cancel</button>
          </Modal.Footer>
      </Modal>
    )
  }
}