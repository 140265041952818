import AuthService from "../../services/service.authentication";
//import {isNormalLogin, getClientNetworkIp} from "../../utilities/helper";
import axios from 'axios';
import * as Msal from 'msal';

export const AUTH_ACTIONS = {
  LOGGED_IN: 'USER_LOGGED_IN',
  LOGGED_OUT: 'USER_LOGGED_OUT',
  NORMAL_LOGGED_IN : 'NORMAL_USER_LOGGED_IN',
  NORMAL_LOGGED_OUT : 'NORMAL_USER_LOGGED_OUT',
  NAV_PANEL : 'NAV.PANEL'

}
let appsettings = '';
let applicationConfig = '';
let config = '';
let app = '';

let redirectUri = window.location.protocol + '//' + window.location.hostname;
redirectUri = window.location.port !== '' ? redirectUri + ':' + window.location.port : redirectUri;

const loggedIn = ({account, loginUser}) => {
  return {
    type: AUTH_ACTIONS.LOGGED_IN,
    payload: {
      userToken: account,
      user: loginUser
    }
  };
}

const normalLoggedIn = (loginUser) => {
  return {
    type : AUTH_ACTIONS.NORMAL_LOGGED_IN,
  }
}

//Daniels login user
export const login = () => async dispatch => {

  await axios
  .get('/appsettings.json')
  .then(async (response) =>  {
    appsettings = response.data;
    localStorage.setItem('appsettings', JSON.stringify(appsettings));
    applicationConfig = {
      clientId: appsettings.azureAd.clientId,
      scopes: appsettings.azureAd.scopes,
      graphScopes: ["User.Read"],
    };

    config = {
      auth: {
        clientId: applicationConfig.clientId,
            authority: 'https://login.microsoftonline.com/808faee3-6fb8-427e-83a4-d680bcf801ca',
        redirectUri: redirectUri,
        navigateToLoginRequestUrl: false,
      },
      cache: {
        cacheLocation: 'localStorage'
      },
      system: {
        tokenRenewalOffsetSeconds: 60
      }
    }
  });

  const authService = new AuthService();
  //set the client network Ip address to localStorage for audit purpose
  //let clientIpAddress = "";
  //if (!localStorage.getItem("clientIp")) {
   // clientIpAddress = await getClientNetworkIp();
    //localStorage.setItem("clientIp", clientIpAddress);
 // }
  try {
    app = new Msal.UserAgentApplication(config);
    const user = app.getAccount();
    if (user == null) {
      let res= await authService.login();
      return res;
    }
    else{
      return user;
    }
  } catch (exception) {
    console.error(exception);
  }
};

//Normal Login User
export const normalLogin = () => async dispatch => {
  try{
    dispatch(normalLoggedIn());
  }catch(error){
    console.error("error")
  }
}

// export const silentNormalLogin = () => dispatch => {
//   let isNormal = isNormalLogin();
//   if(isNormal) {
//     dispatch(normalLoggedIn());
//   }
// }

export const normalLogout = () => dispatch => {
  dispatch({
    type:AUTH_ACTIONS.NORMAL_LOGGED_OUT
  })
}
export const silentLogin = () => dispatch => {
  const authService = new AuthService();
  const data = authService.checkLogin();
  if (data) {
    dispatch(loggedIn(data));
  }
};

export const logout = () => dispatch => {
  sessionStorage.clear();
  const authService = new AuthService();
  authService.logout();
  // return {
  //   type: AUTH_ACTIONS.LOGGED_OUT
  // };
};

// Action creator for enable or disable the navigation panel in bigger screens
export const $setNavPanel = () => {
  return {
    type: AUTH_ACTIONS.NAV_PANEL
  }
}