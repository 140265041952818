import axios from "axios";

// customer api end point
export default class Auth {
  static axiosMethod() {
    const token = localStorage.getItem("token") || "";
    const instance = axios.create();
    instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    return instance;
  }

  
}
